import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';

import './index.scss';
import UserList from './user/index';
import RoleList from './role/index';
import MenuMmanagement from './menuList/index';
import storage from "../../../utils/storage";
import BackIcon from "../../../components/BackIcon/index";

const User = () => {

    const [tableHight, setTableHight] = useState(0);

    const items = {
        'userList':<UserList tableHight={tableHight}/>,
        'roleList':<RoleList tableHight={tableHight}/>,
        'menuMmanagement':<MenuMmanagement tableHight={tableHight}/>,
    }

    const [directory, setDirectory] = useState([]);
    const [activeKey, setActiveKey] = useState("");

    // const items = [
    //     { label: '用户管理', key: '1', children: <UserList tableHight={tableHight}/> },
    //     { label: '角色及权限管理', key: '2', children: <RoleList tableHight={tableHight}/> },
    //     { label: '菜单管理', key: '3', children: <MenuMmanagement tableHight={tableHight}/> }
    //   ];

    useEffect(() => {
        // 计算table内容的高度
        let divHeight= document.querySelector('.layoutContent');
        setTableHight(divHeight.offsetHeight-355-15);
    }, []);
    useEffect(() => {
        let directory = JSON.parse(sessionStorage.directory)
        let newDirectory = directory.map(item=>{
            return {
                label:item.name,
                key:item.id,
                children:items[item.path]
            }
        })
        setDirectory(newDirectory)
        let currntKey = sessionStorage.activeKey ? sessionStorage.activeKey : newDirectory[0].key
        setActiveKey(currntKey)
    }, [tableHight]);

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        sessionStorage.activeKey = activeKey;
    }

    return (
        <div className="userFrame">
            <div  className="userTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={directory}></Tabs>
            </div>
            <BackIcon />
        </div>
    );
};
export default User;
