/*
    * 角色
*/ 
import myAxios from '../utils/request.js';

export default {
    
    // 获取角色列表
    async getRoles (params={}, cb) {
        let res = await myAxios.getJson('/businessRoles/selectName', params);
        return res.data;
    },
    // 分页查询业务角色及权限列表
    async getRolesList (params={}, cb) {
        let res = await myAxios.getJson('/businessRoles/select', params);
        return res.data;
    },
    // 修改业务角色及权限
    async updateRoles (params={}, cb) {
        let res = await myAxios.postJson('/businessRoles/update', params);
        return res.data;
    },
    // 新增业务角色及权限
    async addRoles (params={}, cb) {
        let res = await myAxios.postJson('/businessRoles/insert', params);
        return res.data;
    },
    // 删除业务角色及权限
    async deleteRole (params={}, cb) {
        let res = await myAxios.deleteJson('/businessRoles/delete', params);
        return res.data;
    },
    // 根据职务ID查询业务角色
    async selectByJobId (params={}, cb) {
        let res = await myAxios.getJson('/businessRoles/selectByJobId', params);
        return res.data;
    },




     // 分页系统查询角色及权限列表
     async getSysRolesList (params={}, cb) {
        let res = await myAxios.getJson('/sysRoles/selectPage', params);
        return res.data;
    },
    // 查询所有系统角色id及名称
    async getAllSysRolesList (params={}, cb) {
        let res = await myAxios.getJson('/sysRoles/all', params);
        return res.data;
    },
    // 修改系统角色及权限
    async updateSysRoles (params={}, cb) {
        let res = await myAxios.postJson('/sysRoles/update', params);
        return res.data;
    },
    // 新增系统角色及权限
    async addSysRoles (params={}, cb) {
        let res = await myAxios.postJson('/sysRoles/insert', params);
        return res.data;
    },
    // 删除系统角色及权限
    async deleteSysRole (params={}, cb) {
        let res = await myAxios.deleteJson('/sysRoles/delete', params);
        return res.data;
    },
    // 获取角色列表
    async getSysRoles (params={}, cb) {
        let res = await myAxios.getJson('/sysRoles/selectName', params);
        return res.data;
    },
    // 根据角色查询菜单权限树
    async getRoleMenuTree (params={}, cb) {
        let res = await myAxios.getJson('/sysPermissions/listRoleMenuTree', params);
        return res.data;
    },

    // 绑定用户系统角色
    async addUserRole (params={}, cb) {
        let res = await myAxios.postJson('/sysRoles/addUserRole', params);
        return res.data;
    },
}