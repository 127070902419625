import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {UploadOutlined, CloseOutlined} from "@ant-design/icons";
import {message, Upload, Button, Popconfirm} from "antd";
import Iconfont from "../../../components/Iconfont/Iconfont";
import SplitUpload from "../../../components/SplitUpload";
import storage from "../../../utils/storage";
import NoneData from "../../../components/NoneData";
import {zuStandStore} from "../../../zustand";

const {Dragger} = Upload;

const MyUpload = (props) => {
    const fileLimitType = [
        "application/pdf", // pdf
        // "image/png", // png
        // "image/jpeg", // jpeg
        "application/zip", // zip
        "application/x-zip-compressed", // zip
        // "text/plain", // txt
        "application/vnd.ms-works", // wps wdb
        "application/msword", // doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
        "application/vnd.ms-word.document.macroEnabled.12", // docm
        "application/vnd.ms-excel", // xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
        "application/vnd.ms-excel.sheet.macroEnabled.12", // xlsm
        "application/vnd.ms-powerpoint", // ppt
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
        "application/vnd.ms-powerpoint.presentation.macroEnabled.12", // pptm
    ];
    const {setModalFlag2, uploadType} = props;
    const uploadFileState = zuStandStore(state => state);
    const changeUploadStateFunc = uploadFileState.changeUploadStateFunc;

    let history = useHistory();
    const [upFileList, setUpFileList] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [fileList, setFileList] = useState([]);

    const toUpload = async () => {

        if (upFileList.length > 0) {
            setUploadLoading(true);

            let fileData = upFileList.map(item => {
                return item.originFileObj
            })

            let res = await SplitUpload(fileData);

            setUploadLoading(false);

            // 只上传了一个文件的时候直接跳转编辑页面
            // 多个文件跳转草稿箱
            if (res.length === 1) {
                let fileNameArry = fileData[0].name.split(".");
                let fileName = fileNameArry[0];

                let fileType = fileData[0].name.split(".").pop().toLowerCase();
                storage.removeItem("fileId");
                storage.removeItem("knowledgeDetailInfo");
                let encodedObject = encodeURIComponent(res[0].knowledgeManagementId);
                let fileIdEncode = encodeURIComponent(res[0].fileId);
                let url = `/page/newKnowledge?knowledgeId=${encodedObject}&fileId=${fileIdEncode}&fileType=${fileType}&fileName=${fileName}`;
                window.open(url);
            } else {
                history.push("/personalCenter/myUpload/notPublished");
            }

            setModalFlag2(false);
        } else {
            message.info("请选择文件后再上传");
        }
    };

    // 已选择文件
    async function selectFilesChangeFunc (fileList, state, e) {
        console.log(fileList[0]);
        let list = [...state.fileList];

        for (const item of fileList) {
            // accept 配置的文件类型，无法拦截拖动上传的文件
            if(fileLimitType.includes(item.type)){
                let encodeName = window.encodeURI(item.name);
                let idStr = `${item.lastModified}-${encodeName}-${item.size}-${item.type}-${Date.now()}`;
                item.fileId = window.btoa(idStr);
                list.push(item);
            }
        }

        setFileList(list);
        e.target.value = "";
    }

    // 删除已选择文件
    async function deleteSelectFileFunc (idx, state) {
        let list = [...state.fileList];
        list.splice(idx, 1);
        setFileList(list);
    }

    // 取消选择文件
    function closeSelectFile () {
        setModalFlag2(false);
    }

    // 确定上传文件
    function uploadFile (state) {
        const {fileList,} = state;
        if (fileList.length < 1) {
            closeSelectFile();
            return;
        }
        const {fileProcessObject, fileUploadStatus, uploadFileList} = uploadFileState;
        let processObject = {};
        let fileStatus = {};
        let list = [...uploadFileList];
        let newFileList = [];
        for (const item of fileList) {
            const {fileId} = item;
            processObject[fileId] = 0;
            fileStatus[fileId] = "processing";
            item.uploadType = uploadType || "common";
            newFileList.push(item);
        }
        list.push({
            uploadType,
            fileList: newFileList,
        })

        const newState = {
            uploadFileList: list,
            fileProcessObject: {
                ...fileProcessObject,
                ...processObject,
            },
            fileUploadStatus: {
                ...fileUploadStatus,
                ...fileStatus,
            },
            uploadOpen: true,
            modalStatus: "big",
        };
        changeUploadStateFunc(newState);
        setFileList([]);
        setModalFlag2(false);
    }

    const state = {
        fileList,
    };

    return (
        <div className="myUpload">
            <div className="uploadContentBox">
                {/* headBox */}
                <div className="headBox">
                    <span className="titleText">文件上传</span>
                    <span className="closeBtn" onClick={() => closeSelectFile()}><Iconfont type={"icon-close1"}/></span>
                </div>
                <div className="selectFileListBox">
                    {/* 选择文件按钮 */}
                    <div
                        className="uploadBtnBox"
                        // onClick={() => selectFilesFunc()}
                    >
                        <div className="uploadBtn">
                            <Iconfont type={"icon-shangchuan"}/>
                            <span className="tipText">
                                拖拽文件至此区域或点击此区域<br/>
                                压缩包仅支持 zip 格式
                            </span>
                        </div>

                        {/* 文件选择器 */}
                        <input
                            onDrop={(e, a) => {
                                e?.preventDefault();
                                console.log(e,"eeeee")
                                const files = e.dataTransfer.files;
                                selectFilesChangeFunc(files, state, e)
                            }}
                            id="fileInput"
                            type="file"
                            multiple={true}
                            accept={fileLimitType.join(",")}
                            onChange={(e) => selectFilesChangeFunc(e.target.files, state, e)}
                        />
                    </div>
                    {/* 文件列表 */}
                    <div className="fileListBox">
                        {
                            (fileList?.length > 0) ? fileList.map((item, idx) => {
                                const {name, fileId} = item;
                                return (
                                    <div className="fileItem" key={fileId}>
                                        <span className="fileName overflowEllipsis" title={name}>{name}</span>
                                        <Popconfirm
                                            placement={"right"}
                                            title={"确定删除吗？"}
                                            okText={"确定"}
                                            cancelText={"取消"}
                                            onConfirm={() => deleteSelectFileFunc(idx, state)}
                                            overlayClassName={"deletePupConfirmBox"}
                                        >
                                            <span className="deleteBtn"><Iconfont type="icon-shanchu"/></span>
                                        </Popconfirm>
                                    </div>
                                )
                            }) : NoneData({text: "请选择文件!"})
                        }
                    </div>
                </div>
                {/* footBox */}
                <div className="footBox">
                    <span
                        className="uploadBtn"
                        onClick={() => uploadFile(state)}
                    >{fileList.length ? "上传文件" : "关闭弹窗"}</span>
                </div>
            </div>
        </div>
    );
};

export default MyUpload;
