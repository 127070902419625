import React, {useState} from 'react';
import {message} from 'antd';
import SparkMD5 from 'spark-md5';
import Api from "../../api";
import {responseDataFilter, getWebSocketUrl} from "../../utils/tool";
import './index.scss';
import storage from "../../utils/storage";

let SOCKET_INSTANCE = null;
let SEND_FILE_LIST = [];

const createWebSocket = (callback) => {
    return new Promise((resolve, reject) => {
        if (!window.WebSocket) {
            message.error("浏览器不支持websocket！");
            return reject(null);
        }
        const socket = new WebSocket(`${getWebSocketUrl()}/websocket/upload/file`, [storage.getItem('WebSocketSubProtocol')]);

        socket.onopen = (evt) => {
            console.log("open");
            resolve(socket);
        }

        socket.onmessage = (evt) => {
            const messageData = JSON.parse(evt.data || JSON.stringify({}));
            const {data, code} = messageData;
            const {chunkSize, size: fileSize, currentChunk: currentChunkNum=0, fileToken, chunks} = data || {};
            console.log(data);
            if (code === 0) {
                if (callback) {
                    let size = ((currentChunkNum + 1) * chunkSize / fileSize);
                    const isSuccess = size >= 1;
                    console.log(data);
                    callback({
                        fileId: fileToken,
                        process: isSuccess ? 1 : size,
                        status: (currentChunkNum + 1) >= chunks ? "success" : "processing",
                        otherData: data || {},
                    }, socket);
                }
            } else {
                callback({
                    fileId: fileToken,
                    status: "fail",
                    msg: messageData.msg
                });
            }
        }

        socket.onerror = (error) => {
            message.error("连接失败！");
            SOCKET_INSTANCE = null;
            SEND_FILE_LIST = [];
            reject(error);
        }

        socket.onclose = () => {
            console.log("closed");
            if (SOCKET_INSTANCE) {
                callback({linkStatus: "stop"});
            }
            SOCKET_INSTANCE = null;
            SEND_FILE_LIST = [];
        }
    });
}

const createBase64File = (file) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            const result = e.target?.result || "";
            const idx = result.indexOf("base64,");
            resolve(result.slice(idx > -1 ? (idx + 7) : idx));
        }
        fileReader.readAsDataURL(file);
    })
}

const getMD5 = (file, fileListID) => {
    return new Promise((resolve, reject) => {
        // 使用sparkMD5的ArrayBuffer类，读取二进制文件
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();
        // 异步操作，读完后的结果
        fileReader.onload = (e) => {
            // 把文件开始传入spark
            spark.append(e.target.result);
            // spark计算出MD5后的结果
            const _md5 = spark.end();
            resolve(_md5);
            // 下面可以写一些自己需要的业务代码, 例如 fileItem.fileMD5 = _md5
        }
        // fileReader读取二进制文件
        fileReader.readAsArrayBuffer(file);
    })
}

const SplitUpload = async (fileList, callback) => {

    // 单个切片文件大小为2MB
    const chunkSize = 2097152;
    // 分片的最小值 5M
    const chunkMinSize = 5242880;
    let fileId = "";

    if (fileList && fileList.length > 0) {
        if (!SOCKET_INSTANCE) {
            try {
                SOCKET_INSTANCE = await createWebSocket(callback);
                callback({linkStatus: "success"})
            } catch (e) {
                console.log(e);
                callback({linkStatus: "fail"})
            }
        }

        for (let i = 0; i < fileList.length; i++) {
            // 当前文件
            const currentFile = fileList[i];

            if (SEND_FILE_LIST.includes(currentFile.fileId)) {
                continue;
            }

            // 获取文件的总分片数
            const chunkNum = Math.ceil(currentFile.size / chunkSize);

            // 取两个md5值作为整体文件的唯一标识
            let fileMd5 = '';
            if (chunkNum > 1) {
                let startMd5 = await getMD5(currentFile.slice(0, 1 * chunkSize));
                let endMd5 = await getMD5(currentFile.slice((chunkNum - 1) * chunkSize, chunkNum * chunkSize));
                fileMd5 = startMd5 + endMd5;
            } else {
                fileMd5 = await getMD5(currentFile);
            }
            // 判断文件是否存在
            const res = await Api.Common.checkFileExist({
                data: {fileMd5: fileMd5, uploadType: currentFile.uploadType || "common",},
            });
            console.log(res);
            const data = responseDataFilter(res);
            const {exist, id} = data || {};

            if (exist) {
                // 跳过这个文件,不传了
                message.success('文件上传成功!');
                if (callback) {
                    callback({
                        fileId: currentFile.fileId,
                        process: 1,
                        status: "success",
                        otherData: data,
                    }, SOCKET_INSTANCE);
                }
            } else {
                let chunkFlag = false;
                // 是否需要分片
                if (currentFile.size > chunkMinSize) {
                    chunkFlag = true;
                }
                const allMd5List = [], fileDataList = [], param = {
                    fileMd5: fileMd5,
                    chunkFlag: chunkFlag,
                    chunkNum: chunkNum,
                    file: currentFile,
                    chunkSize: chunkSize,
                    existingMd5: [],
                };
                if (chunkFlag) { //需要分片上传
                    for (let index = 0; index < chunkNum; index++) {
                        let currentChunkMd5 = await getMD5(currentFile.slice(index * chunkSize, (index + 1) * chunkSize));
                        allMd5List.push(currentChunkMd5);
                        fileDataList.push(currentFile.slice(index * chunkSize, (index + 1) * chunkSize));
                    }
                } else {
                    let currentChunkMd5 = await getMD5(currentFile);
                    allMd5List.push(currentChunkMd5);
                    fileDataList.push(currentFile);
                }

                // 上传文件
                await uploadFile({
                    ...param,
                    fileDataList: fileDataList,
                    allMd5List: allMd5List
                }, callback);
            }
        }
    } else {
        console.log('请先选择文件');
    }
}

const uploadFile = async (param, callback) => {
    const {
        chunkFlag, chunkNum, chunkSize,
        allMd5List, fileDataList,
        fileMd5, file, uploadType,
    } = param;
    const allMd5Len = allMd5List.length;

    // 文件不存在，准备上传
    for (let i = 0; i < allMd5Len; i++) {
        const currentChunkFile = fileDataList[i];
        let currentChunkMd5 = allMd5List[i];
        let params = {
            currentChunkMd5: [currentChunkMd5],
        };

        // 判定该分片文件是否已上传
        const splitFileRes = await Api.Common.checkFragmentOne({data: params,});
        const splitFileData = responseDataFilter(splitFileRes);
        if (splitFileData?.exist) {
            if (callback) {
                let size = ((i + 1) * chunkSize / file.size);
                callback({
                    fileId: file.fileId,
                    process: size >= 1 ? 1 : size,
                    status: i >= allMd5Len - 1 ? "success" : "processing",
                }, SOCKET_INSTANCE);
            }
        } else {
            // if(i !=2 && i!=4 && i!=5 && i!=7){
            let formData = {};
            // 分片上传
            formData.chunkFlag = chunkFlag;
            if (chunkFlag) { // 分片上传
                // 分片总数
                formData.chunks = chunkNum;
                // 当前分片下标
                formData.currentChunk = i;
                // 分片大小
                formData.chunkSize = chunkSize;
            } else { // 整体上传
                formData.chunks = 1;
                formData.currentChunk = 0;
                formData.chunkSize = file.size;
            }
            // 文件上传类型
            formData.uploadType = file.uploadType; // 知识类型文件  /  政策类型文件
            // 文件类型
            formData.type = file.type;
            // 文件总大小
            formData.size = file.size;
            // 文件名
            formData.name = file.name;
            // 整个文件的id值，及md5值
            formData.fileMd5 = fileMd5;
            // 计算当前文件分片的md5值
            formData.currentChunkMd5 = currentChunkMd5;
            formData.fileToken = file.fileId;
            formData.fileContent = await createBase64File(currentChunkFile);

            // 已发送过
            SEND_FILE_LIST.push(file.fileId);

            SOCKET_INSTANCE?.send(JSON.stringify(formData));
        }
    }

    // if (successLen >= allMd5Len) {
    //     message.success('文件上传成功');
    // } else {
    //     message.error('文件上传失败');
    // }
}

export default SplitUpload;
