import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {Input, Tooltip, Popconfirm, Modal, message, Spin, Space, Checkbox, Form, Tag} from "antd";
import { LoadingOutlined, CaretDownOutlined, CaretUpOutlined, PlusOutlined } from '@ant-design/icons';
import {ChromePicker} from "react-color";
import html2canvas from 'html2canvas';
import typeObject from "./typeObject";

import Iconfont from "../../../../../../components/Iconfont/Iconfont";
import NoneData from "../../../../../../components/NoneData";
import SelfDesignModal from "../../../../../../components/SelfDesignModal";
import MindMap from "../../../../../../components/MindMap";
import SelfPagination from "../../../../../../components/SelfPagination";

import Api from "../../../../../../api";
import {
    getSearchParams,
    rgbToStr,
    getRgbLevel,
    ModalConfirm,
    KnowledgeApply,
    jumpToPage
} from "../../../../../../utils/tool";
import "./index.scss";

const { TextArea, Search } = Input;

const knowledgeSystemOperate = () => {
    const history = useHistory();
    const menuRef = useRef('menu'); // 右键菜单ref
    const designRef = useRef('design'); // 画布ref
    const listenerRef = useRef(0); // 监听值 用于监听函数
    const isOpenRef = useRef(false); // 监听值 用于监听函数
    const clipImgAreaRef = useRef("clipImgArea"); // 截图区域
    const [addExplainForm] = Form.useForm();
    const ExplainLableInputRef = useRef(null);
    let searchParams = getSearchParams(); // 路径传递的参数
    const [systemName,setSystemName] = useState("")
    const [labelList, setLabelList] = useState([]); // 可选标签列表
    const [isAdding, setIsAdding] = useState(false); // 正在添加汇总的节点
    const [summaryNodeList, setSummaryNodeList] = useState([]); // 参与汇总的节点列表
    const [selfMenuStyle, setSelfMenuStyle] = useState({}); // 自定义右键菜单的样式
    const [positionStyle, setPositionStyle] = useState({left: 0, top: 0}); // 拖拽画布的样式
    const [moveStartPosition, setMoveStartPosition] = useState({x: 0, y: 0}); // 拖拽画布时鼠标初始位置
    const [isMoving, setIsMoving] = useState(false); // 是否在拖拽中
    const [isOpen, setIsOpen] = useState(false); // modal弹窗是否显示
    const [isCloseMenu, setIsCloseMenu] = useState(false); // 自定义右键菜单是否是可以被关闭
    const [menuList, setMenuList] = useState([
        {icon: "icon-roundadd", text: "添加子节点（Tab键）", key: typeObject.addChildNode},
        {icon: "icon-fujihua", text: "插入父节点", key: typeObject.insertParentNode},
        {icon: "icon-fuwuleixing", text: "节点属性", key: typeObject.setAttribute},
        {icon: "icon-bianji2", text: "重命名", key: typeObject.setName},
        {icon: "icon-yanse", text: "颜色填充", key: typeObject.colorFill},
        {icon: "icon-a-zu27018", text: "挂接笔记（双击）", key: typeObject.linkNote},
        {icon: "icon-bianji1", text: "撰写讲解", key: typeObject.writeExplain},
        {icon: "icon-copy", text: "引用体系", key: typeObject.referenceSystem},
        {icon: "icon-delete", text: "删除", key: typeObject.delete},
    ]); // 自定义右键菜单列表
    const [choiceColor, setChoiceColor] = useState(null); // 选中的颜色
    const [isChangeColor, setIsChangeColor] = useState(false); // 准备改变颜色
    const [treeData, setTreeData] = useState([
        {
            id: Date.now().toString(), // 节点id 唯一
            nodeName: '中心主题', // 节点名称
            background: {r: 255, g: 255, b: 255, a: 1,}, // 节点背景
            noteList: [], // 挂接的笔记列表
            intro: '', // 讲解文字
            labelId: null, // 添加的标签节点 不可修改标签名
            children: [],
        }
    ]); // 体系图数据
    const [selectedTreeNodeData, setSelectedTreeNodeData] = useState(null); // 选中的某个体系节点
    const [selectedTreeNodePosition, setSelectedTreeNodePosition] = useState(null); // 选中的某个体系节点 位置
    const [isCreateNode, setIsCreateNode] = useState(0); // 监听该值变化添加节点
    const [refresh, setRefresh] = useState(true); // 强制刷新
    const [modalOption, setModalOption] = useState(null); // 弹窗配置
    const [systemList, setSystemList] = useState([]); // 引用体系列表
    const [systemId, setSystemId] = useState(""); // 引用体系ID
    const [confirmLoading, setConfirmLoading] = useState(false); // 弹窗确认按钮加载
    const [modalLoading, setModalLoading] = useState(false); // 弹窗内容加载中
    const [saveLoading, setSaveLoading] = useState(false); // 保存中
    const [canvasLoading, setCanvasLoading] = useState(false); // 保存中
    const [lableListofNote, setLableListofNote] = useState({}); // 标签关联笔记列表
    const [labelDetailesParam, setLabelDetailesParam] = useState({
        pageIndex:1,
        pageSize:10,
        timeType:"1"
    });
    const [selectNote, setSelectNote] = useState([]); // 选中的标签关联笔记列表
    const [ExplainLableList, setExplainLableList] = useState([]); // 讲解标签
    const [ExplainLableInputVisible, setExplainLableInputVisible] = useState(false);
    const [ExplainLableInputValue, setExplainLableInputValue] = useState('');
    const [selectLable, setSelectLable] = useState(''); //当前选重点的节点数据

    // 查询所有的公开笔记
    const [publicNodeListParam, setPublicNodeListParam] = useState({
        pageIndex:1,
        pageSize:10,
        timeType:"1"
    });
    const [publicNodeList, setPublicNodeList] = useState({});
    const [selectPublicNote, setSelectPublicNote] = useState([]); // 选中的标签关联笔记列表
    const [detailsLoading, setDetailsLoading] = useState(false);

    // 节点属性
    const [nodeAttribute, setNodeAttribute] = useState({
        nodeName: "",
        nodeType: "", // custom/system
        labelId: null,
        intro: "",
    });
    const [selectedNodeParentNodeData, setSelectedNodeParentNodeData] = useState({}); // 选中节点的父节点
    const [addNodeList, setAddNodeList] = useState([]); // 被添加的节点数组
    const [filterLabelWord, setFilterLabelWord] = useState(""); // 标签过滤关键字

    // 节点类型
    const nodeTypeList = [
        {text: "自定义", value: "custom",},
        {text: "系统标签", value: "system",},
    ];

    useEffect(() => {
        document.addEventListener('click', documentClickCallback);
        document.addEventListener('keyup', tabFunc);
        document.addEventListener('mouseup', endMoveFunc);
        return () => {
            document.removeEventListener('click', documentClickCallback);
            document.removeEventListener('keyup', tabFunc);
            document.removeEventListener('mouseup', endMoveFunc);
        }
    }, [])

    // tab 创建子节点
    useEffect(() => {
        if (isCreateNode > 0) {
            createNode();
        }
    }, [isCreateNode])

    // 模态框打开时 模拟阻止模态框事件冒泡
    useEffect(() => {
        isOpenRef.current = isOpen;
    }, [isOpen])

    // 监听是否可以关闭自定义右键菜单
    useEffect(() => {
        if (isCloseMenu) {
            closeMenu();
        }
    }, [isCloseMenu])

    useEffect(() => {
        getAllNote();
        if(searchParams.systemId){
            getSystemDetails(searchParams.systemId, true)
        }
    }, [])

    // 根据标签Id获取关联笔记
    useEffect(() => {
        if(selectLable.labelId || selectLable.id){
            getNoteBaseOnTheTagID();
        }
    }, [labelDetailesParam])

    // 获取所有的公开笔记
    useEffect(() => {
        if(publicNodeListParam.knowledgeLabelId){
            getPublicNodeList();
        }
    }, [JSON.stringify(publicNodeListParam)])

    useEffect(()=>{
        if (ExplainLableInputVisible && ExplainLableInputRef.current) {
            ExplainLableInputRef.current.focus()
        }
    },[ExplainLableInputVisible]);

    async function getAllNote () {
        try{
            const res = await Api.Note.getAllLabelName({data:{}});
            if(res&&res.code === 0){
                setLabelList(res.data)
            }
        }catch(errorInfor){}
    }

    // 阻止默认，冒泡
    function stopFunc (event) {
        event?.preventDefault();
        event?.stopPropagation();
    }

    // click 回调
    function documentClickCallback (e) {
        setIsCloseMenu(true);
    }

    // tab 回调
    function tabFunc (e) {
        if (e.code === 'Tab') {
            e?.preventDefault();
            listenerRef.current += 1;
            setIsCreateNode(listenerRef.current);
        }
    }

    // 鼠标右键 显示菜单
    function mouseRightFunc (event,item) {
        setSelectLable(item);
        stopFunc(event);
        if (event.target) {
            // 判断下面是否有讲解存在
            // let _menuList = [...menuList]
            // _menuList.map(item=>{
            //     if(item.key === "writeExplain"){
            //         item.hide = (item.noteList && item.noteList.length > 0) ? true : false
            //     }
            // })

            // setMenuList(_menuList);

            const documentDom = document.body || document.documentElement;
            const {clientWidth: documentWidth, clientHeight: documentHeight} = documentDom;
            const {pageX, pageY} = event;
            const menuDom = menuRef.current;
            const {clientWidth: menuWidth, clientHeight: menuHeight} = menuDom;
            let style = {
                left: pageX,
                top: pageY,
                right: 'unset',
                bottom: 'unset',
                visibility: 'visible',
            };
            if ((pageX + menuWidth + 40) > documentWidth) {
                style.left = "unset";
                style.right = 40;
            }
            if ((pageY + menuHeight + 40) > documentHeight) {
                style.top = "unset";
                style.bottom = 40;
            }
            if(item.intro === "custom" || !item.labelId){
                style.intro = "custom";
            }
            setSelfMenuStyle(style);
        }
    }

    // 关闭菜单 并 取消选中
    function closeMenu(isForce) {
        if (!isForce) {
            if (isOpenRef.current || isChangeColor) {
                setIsCloseMenu(false);
                return
            }
        }

        setSelfMenuStyle({})
        setSelectedTreeNodeData(null)
        setSelectedNodeParentNodeData({})
        setSelectedTreeNodePosition(null)
        setChoiceColor(null)
    }

    // 菜单点击功能
    function menuClickFunc (type) {
        let option = null;
        switch (type) {
            case typeObject.addChildNode:
                option = {
                    title: "添加子节点",
                    icon: null,
                    type: type,
                    width: 600,
                    onOk: (state) => {
                        addChildNode(state.addNodeList);
                    },
                    onCancel: (state) => {
                        setAddNodeList([])
                        setModalOption(null);
                        setIsOpen(false);
                        setFilterLabelWord("");
                    },
                };
                break;
            case typeObject.insertParentNode:
                option = {
                    title: "插入父节点",
                    icon: null,
                    type: type,
                    width: 600,
                    onOk: (state) => {
                        insertParentNode(state.nodeAttribute);
                    },
                    onCancel: (state) => {
                        setNodeAttribute({});
                        setModalOption(null);
                        setIsOpen(false);
                    },
                };
                setNodeAttribute({nodeType: "custom"});
                break;
            case typeObject.addBeforeNode:
                option = {
                    title: "添加兄弟节点（前）",
                    icon: null,
                    type: type,
                    width: 600,
                    onOk: (state) => {
                        addBrotherNode(state.addNodeList, typeObject.addBeforeNode);
                    },
                    onCancel: (state) => {
                        setAddNodeList([])
                        setModalOption(null);
                        setFilterLabelWord("");
                        setIsOpen(false);
                    },
                };
                break;
            case typeObject.addAfterNode:
                option = {
                    title: "添加兄弟节点（后）",
                    icon: null,
                    type: type,
                    width: 600,
                    onOk: (state) => {
                        addBrotherNode(state.addNodeList, typeObject.addAfterNode);
                    },
                    onCancel: (state) => {
                        setAddNodeList([])
                        setModalOption(null);
                        setFilterLabelWord("");
                        setIsOpen(false);
                    },
                };
                break;
            case typeObject.colorFill:
                setIsChangeColor(true);
                const background = selectedTreeNodeData?.background || {r:255,g:255,b:255,a:1};
                setChoiceColor({...background});
                break;
            case typeObject.linkNote:
                option = {
                    title: "挂接笔记",
                    icon: "icon-a-zu27018",
                    type: type,
                    width: "60%",
                    onCancel: () => {},
                    onOk: () => {},
                    render: () => {
                        return (<div>{isOpen ? 'a' : 'b'}</div>);
                    },
                };
                break;
            case typeObject.writeExplain:
                option = {
                    title: "撰写讲解",
                    icon: "icon-bianji1",
                    type: type,
                    width: "50%",
                    onOk: () => {
                        saveExplain(selectLable.id, ExplainLableList);

                    },
                    onCancel:()=>{
                        cancelModalFunc("onCancel");
                    },
                };
                break;
            case typeObject.noteDetail:
                option = {
                    title: "关联的想法",
                    icon: null,
                    type: type,
                    width: "80%",
                    footer: null,
                    onOk: (id) => {
                        // getSystemDetails(id);
                    },
                    onCancel:()=>{
                        setLabelDetailesParam({
                            pageIndex:1,
                            pageSize:10,
                            timeType:"1"
                        })
                        setSelectNote([]);
                        setLableListofNote({});
                        setSelectLable({});
                        setIsOpen(false);
                        setModalOption(null);
                    },
                };
                break;
            case typeObject.publicNoteList:
                 // 标签id带有时间戳
                // 如果是标签池选择的标签会有lableId,就使用lableID,否者使用ID
                let _knowledgeNoteLabelId = selectLable.labelId ? selectLable.labelId : selectLable.id;
                setPublicNodeListParam({
                    ...publicNodeListParam,
                    knowledgeLabelId:_knowledgeNoteLabelId
                })
                option = {
                    title: "请选择需要添加的想法",
                    icon: null,
                    type: type,
                    width: "80%",
                    onOk: (state) => {
                        addNewLabelRelationNote(state.selectPublicNote, state.publicNodeList);
                    },
                    onCancel:()=>{
                        setSelectPublicNote([]);
                        setPublicNodeListParam({
                            pageIndex:1,
                            pageSize:10,
                            timeType:"1"
                        });
                        setPublicNodeList({});
                        menuClickFunc(typeObject.noteDetail);
                    }
                };
                break;
            case typeObject.referenceSystem:
                getSystemList();
                option = {
                    title: "引用体系",
                    icon: null,
                    type: type,
                    width: 900,
                    getContainer: () => {},
                    onOk: (state) => {
                        getSystemDetails(state.systemId);
                    },
                    onCancel: () => {
                        setSystemId('');
                        setSystemList([]);
                        cancelModalFunc("onCancel");
                    }
                };
                break;
            case typeObject.setName:
            case typeObject.setAttribute:
                option = {
                    title: "节点属性",
                    icon: null,
                    type: type,
                    width: 600,
                    onOk: (state) => {
                        changeNodeAttribute(state.nodeAttribute);
                    },
                    onCancel: (state) => {
                        setNodeAttribute({});
                        setModalOption(null);
                        setIsOpen(false);
                    },
                };
                setNodeAttribute({
                    ...selectedTreeNodeData,
                    nodeType: selectedTreeNodeData.labelId ? "system" : "custom",
                    labelName: selectedTreeNodeData.nodeName,
                });
                break;
            case typeObject.delete:
                break;
        }
        if (option) {
            setIsOpen(true);
            setModalOption(option);
        }
        if (type !== typeObject.delete) {
            setSelfMenuStyle({})
        }
    }

    function getID (){
        let code = '';
        for (let i = 0; i < 6; i++) {
            code += parseInt(Math.random() * 10);
        }
        code = Date.now().toString() + code;
        return code;
    }

    async function saveExplain(id,ExplainLableList){
        setConfirmLoading(true);
        try {
            const row = await addExplainForm.validateFields();
            row.knowledgeNoteDetailId = getID();
            row.explainLabelList = ExplainLableList;
            row.nodeType = "explain";

            firstRecursiveData(id,row)

            // treeData[0].children.map(item=>{
            //     if(item.id === id){
            //         item.noteList.push(row);
            //         setTreeData(treeData)
            //         return false;
            //     }else if(item.children){
            //         RecursiveData(item.children,id,row);
            //     }
            // })

            setConfirmLoading(false);
            message.success("新增讲解成功");
            setIsOpen(false);
            setModalOption(null);
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    function firstRecursiveData(id,row){
        treeData[0].children.map(item=>{
            if(item.id === id){
                if(row){
                    item.noteList.push(row);
                }else{
                    item.noteList=[];
                }
                setTreeData(treeData)
                return false;
            }else if(item.children){
                RecursiveData(item.children,id,row);
            }
        })
    }

    function RecursiveData(data,id,row){
        data.map(item=>{
            if(item.id === id){
                if(row){
                    item.noteList.push(row);
                }else{
                    item.noteList=[];
                }
                setTreeData(treeData)
                return false;
            }else if(item.children){
                RecursiveData(item.children,id,row);
            }
        })
    }

    // 根据标签Id获取关联笔记
    async function getNoteBaseOnTheTagID(){
        setModalLoading(true)
        try{
            const res = await Api.Note.getLabelRelationNoteByLabelId({data:{
                knowledgeNoteLabelId:selectLable.labelId ? selectLable.labelId : selectLable.id,
                pageIndex:labelDetailesParam.pageIndex,
                pageSize:labelDetailesParam.pageSize,
                timeType:labelDetailesParam.timeType
            }});
            if(res&&res.code === 0 && res.data){
                let _data = res.data.records;
                setLableListofNote({
                    list:_data,
                    total:res.data.total
                });
            }else{
                setLableListofNote({});
            }
            setModalLoading(false);
        }catch(errorInfor){setModalLoading(false);}
    }

    // 获取引用体系列表
    async function getSystemList() {
        setModalLoading(true)
        try{
            const res = await Api.Note.getKnowledgeNoteSystemList({data:{}});
            if(res&&res.code === 0 && res.data){
                let List = [];
                res.data.map(item=>{
                    if(item.knowledgeNoteSystemDetailVOList){
                        List = [...List,...item.knowledgeNoteSystemDetailVOList]
                    }
                })
                setSystemList(List);
            }else{
                setSystemList([]);
            }
            setModalLoading(false);
        }catch(errorInfor){setModalLoading(false);}
    }

    // 根据引用体系ID获取详情
    async function getSystemDetails(id, init) {
        setCanvasLoading(true);
        try{
            const res = await Api.Note.getKnowledgeNoteSystemManagementById({data:{
                knowledgeNoteSystemManagementId:id
            }});
            setCanvasLoading(false);
            if(res&&res.code === 0 && res.data){
                let _data = res.data;
                if (init) {
                    // _data.knowledgeNoteSystemMessage[0].id = getID();
                    setTreeData(_data.knowledgeNoteSystemMessage);
                    setSystemName(_data.knowledgeNoteSystemTitle);
                } else {
                    createNode(_data.knowledgeNoteSystemMessage[0], typeObject.reference);
                }
                setSystemId('');
                setIsOpen(false);
                setModalOption(null);
            }
        }catch(errorInfor){setCanvasLoading(false);}
    }

    // 鼠标双击显示笔记列表
    function showModal (item) {
        if(item){
            setSelectLable(item);
        }else{
            setSelectLable({});
        }
        // 标签id带有时间戳
        // 如果是标签池选择的标签会有lableId,就使用lableID,否者使用ID
        let _knowledgeNoteLabelId = "";
        if(item){
            _knowledgeNoteLabelId = item.labelId ? item.labelId : item.id
        }else{
            _knowledgeNoteLabelId = selectLable.id
        }
        setLabelDetailesParam({
            ...labelDetailesParam,
            knowledgeNoteLabelId:_knowledgeNoteLabelId
        })
        menuClickFunc(typeObject.noteDetail);
    }

    // 开始选择汇总项
    function startAddSummaryFunc () {
        setIsAdding(true)
    }

    // 确定汇总项目
    function confirmSummaryFunc () {
        if (isHaveSummaryNode) {
            setIsAdding(false)
        }
    }

    // 取消汇总
    function cancelSummaryFunc () {
        setIsAdding(false)
    }

    // 选中某个节点
    function selectNodeFunc (node, position, parentNode) {
        if (selfMenuStyle.visibility === "visible" && node.id !== selectedTreeNodeData?.id) {
            setSelfMenuStyle({})
        }
        setIsCloseMenu(false);
        cancelChangeColor(); // 取消颜色修改并关闭颜色弹窗
        setSelectedTreeNodePosition(position);
        setSelectedTreeNodeData(node);
        setSelectedNodeParentNodeData(parentNode);
    }

    // 删除某个节点
    function deleteNodeFunc () {
        if (selectedTreeNodePosition?.length > 1) {
            let newPosition = [...selectedTreeNodePosition];
            let newTreeData = JSON.parse(JSON.stringify(treeData));
            let firstPosition = newPosition.shift();
            let lastPosition = newPosition.pop();
            let parentNodeData = newTreeData[firstPosition];
            for (let i = 0; i < newPosition.length; i++) {
                if (parentNodeData?.children) {
                    const idx = newPosition[i];
                    parentNodeData = parentNodeData.children[idx];
                } else {
                    break;
                }
            }
            if (parentNodeData?.children) {
                parentNodeData?.children.splice(lastPosition, 1);
            }

            setTreeData(newTreeData)
        }
        closeMenu()
    }

    // 节点名称修改
    function treeNodeNameChange (name) {
        if (selectedTreeNodeData) {
            selectedTreeNodeData.nodeName = name;
            setRefresh(!refresh)
        }
    }

    // 创建树节点
    const createNode = (node, type) => {
        if (!selectedTreeNodeData) {
            return message.warning("请先选择操作节点！！！")
        }

        if (!type) {
            if (selectedTreeNodePosition?.length > 9) {
                return message.error("层级已达限制！！！")
            }
        }

        let newNode = {
            id: getID(),
            nodeName: '新节点',
            noteList: [],
            children: [],
            intro: '',
            labelId: null,
        };
        if (node) {
            newNode = node;
        }

        if (type === typeObject.reference) {
            Object.assign(selectedTreeNodeData, node, {id: getID()});
        } else if (type === typeObject.insertParentNode) {
            const selfNode = JSON.parse(JSON.stringify(selectedTreeNodeData));
            Object.assign(selectedTreeNodeData, node, {id: getID(), children: [selfNode]});
        } else {
            if (selectedTreeNodeData.children) {
                selectedTreeNodeData.children.push(newNode)
            } else {
                selectedTreeNodeData.children = [newNode];
            }
        }

        setRefresh(!refresh);
    }

    // 取消改变颜色 并 关闭弹窗
    function cancelChangeColor (isCancel=true) {
        if (isCancel && selectedTreeNodeData && choiceColor) {
            selectedTreeNodeData.background = choiceColor;
        }
        setChoiceColor(null);
        setIsChangeColor(false);
    }

    // 生成节点
    function productTreeNode (it, position, props={}, parentNode) {
        const {id, nodeName, children, labelId, intro,} = it;
        const background = it?.background || {r:255,g:255,b:255,a:1};
        const haveChildren = children && children.length > 0;
        const isSelected = selectedTreeNodeData?.id === id;
        const rgbLevel = getRgbLevel(background);
        const colorStyle = {
            color: rgbLevel > 150 ? '#333' : '#fff',
        }
        const isOnlyRootNode = !(treeData[0]?.children?.length > 0);
        return (
            <div className="treeNodeBox" {...props} key={`${position.join("-")}`}>
                <div
                    id={id}
                    className={`treeNode ${isSelected ? "selectedNode" : ""}`}
                    style={{background: rgbToStr(background)}}
                    // onMouseDown={stopFunc}
                    onClick={(e) => {
                        stopFunc(e);
                        selectNodeFunc(it, position, parentNode);
                    }}
                    onContextMenu={(e) => {
                        selectNodeFunc(it, position, parentNode);
                        mouseRightFunc(e,it);
                    }}
                    onDoubleClick={(e)=>showModal(it)}
                >
                    {
                        (isAdding && !haveChildren) ?
                            (<i className="checkedBox" />) :
                            (<span><Iconfont type="" /></span>)
                    }
                    {
                        (isSelected || isOnlyRootNode) ? (
                            <div
                                className="directAddNodeBox"
                                onClick={(e) => e?.stopPropagation()}
                                onDoubleClick={(e) => e?.stopPropagation()}
                                onContextMenu={(e) => e?.stopPropagation()}
                            >
                                <Tooltip placement={"right"} title="添加子节点" overlayClassName="addBtnTip">
                                    <span
                                        className="addNodeBtn rightBtn"
                                        onClick={() => menuClickFunc(typeObject.addChildNode)}
                                    ><Iconfont type="icon-jia" /></span>
                                </Tooltip>
                                {
                                    selectedNodeParentNodeData?.id ? (
                                        <React.Fragment>
                                            <Tooltip placement={"left"} title="添加父节点" overlayClassName="addBtnTip">
                                                <span
                                                    className="addNodeBtn leftBtn"
                                                    onClick={() => menuClickFunc(typeObject.insertParentNode)}
                                                ><Iconfont type="icon-jia" /></span>
                                            </Tooltip>
                                            <Tooltip placement={"right"} title="向前添加兄弟节点" overlayClassName="addBtnTip">
                                                <span
                                                    className="addNodeBtn topBtn"
                                                    onClick={() => menuClickFunc(typeObject.addBeforeNode)}
                                                ><Iconfont type="icon-jia" /></span>
                                            </Tooltip>
                                            <Tooltip placement={"right"} title="向后添加兄弟节点" overlayClassName="addBtnTip">
                                                <span
                                                    className="addNodeBtn bottomBtn"
                                                    onClick={() => menuClickFunc(typeObject.addAfterNode)}
                                                ><Iconfont type="icon-jia" /></span>
                                            </Tooltip>
                                        </React.Fragment>
                                    ) : undefined
                                }
                            </div>
                        ) : undefined
                    }
                    {/* 节点描述（后期可能的功能） */}
                    {/*{*/}
                    {/*    intro ? (*/}
                    {/*        <Tooltip placement="topLeft" title={intro} trigger={"click"} overlayClassName="introTooltipBox">*/}
                    {/*            <span*/}
                    {/*                className="introBtn"*/}
                    {/*                onClick={(e) => e?.stopPropagation()}*/}
                    {/*                onDoubleClick={(e) => e?.stopPropagation()}*/}
                    {/*                onContextMenu={(e) => e?.stopPropagation()}*/}
                    {/*            ><Iconfont type="icon-xiaoxi" /></span>*/}
                    {/*        </Tooltip>*/}
                    {/*    ) : undefined*/}
                    {/*}*/}
                    <span className="treeNodeName" style={colorStyle}>
                        {labelId ? (<Iconfont iStyle={{color: rgbLevel > 150 ? "" : "#fff"}} type={"icon-biaoqian"} />) : undefined}
                        <span>{nodeName}</span>
                    </span>
                    {/*{*/}
                    {/*    labelId ? (*/}
                    {/*        <span className="treeNodeName" style={colorStyle}>{nodeName}</span>*/}
                    {/*        */}
                    {/*    ) : (*/}
                    {/*        <div className="inputBox">*/}
                    {/*            <Input*/}
                    {/*                className="treeNodeNameInput"*/}
                    {/*                placeholder={'输入节点名称'}*/}
                    {/*                value={nodeName}*/}
                    {/*                tabIndex={-1} // 禁止 tab 选中*/}
                    {/*                style={colorStyle}*/}
                    {/*                onChange={e => treeNodeNameChange(e.target.value)}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}
                    <div
                        className="colorFillBox"
                        style={{display: (isSelected && isChangeColor) ? "block" : ""}}
                        onClick={stopFunc}
                        onDoubleClick={stopFunc}
                        onContextMenu={stopFunc}
                    >
                        <div className="colorFillContentBox">
                            <ChromePicker
                                width={"100%"}
                                color={background}
                                onChangeComplete={(color)=> {
                                    selectedTreeNodeData.background = color.rgb;
                                    setRefresh(!refresh);
                                }}
                            />
                            <div className="operateColorBox">
                                <span
                                    className="operateBtn confirmBtn"
                                    onClick={() => cancelChangeColor(false)}
                                >确定</span>
                                <span
                                    className="operateBtn cancelBtn"
                                    onClick={() => cancelChangeColor()}
                                >取消</span>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    haveChildren ? (
                        <div className="treeChildNodeBox">
                            {productTree(children, [...position], id, it)}
                        </div>
                    ) : undefined
                }
            </div>
        )
    }

    // 生成树
    function productTree (data=[], position, parentId, parentNode) {
        return (
            data?.map((it, index) => {
                return MindMap({
                    parentDomId: parentId,
                    domMindMapId: `${it.id}-MindMap`,
                    dom: productTreeNode(it, [...position, index], {}, parentNode),
                    lineColor: '#1A9DF9',
                })
            })
        )
    }

    // 生成分享截图
    async function createClipImage () {
        const imageWrapper = clipImgAreaRef.current;
        const boundingClientRect = imageWrapper.getBoundingClientRect()
        const canvas = await html2canvas(imageWrapper, {
            backgroundColor: null,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            scale: window.devicePixelRatio * (2),
            x:  0,
            y:  0,
            width: boundingClientRect.width,
            height: boundingClientRect.height
        })
        return canvas ? dataURLtoBlob(canvas.toDataURL('image/png')) : '';
    }

    // base64 转 blob
    function dataURLtoBlob (dataurl) {
        const arr = dataurl.split(',')
        // 注意base64的最后面中括号和引号是不转译的
        const _arr = arr[1].substring(0, arr[1].length - 2)
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(_arr)
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], 'Bold.png', { type: mime})
    }

    // 开始拖拽
    function startMoveFunc (e) {
        if (!isOpenRef.current) {
            setIsMoving(true);
            setMoveStartPosition({
                x: e.pageX,
                y: e.pageY,
            })
        }
    }

    // 拖拽
    function movingFunc (e) {
        if (isMoving) {
            const {pageX, pageY} = e;
            const {x, y} = moveStartPosition;
            const {left, top,} = positionStyle;
            setMoveStartPosition({x: pageX, y: pageY});
            setPositionStyle({
                left: left + pageX - x,
                top: top + pageY - y,
            });
        }
    }

    // 结束拖拽
    function endMoveFunc (e) {
        setIsMoving(false)
    }

    function cancelModalFunc (type){
        // 延迟关闭 模拟阻止点击冒泡
        if(type === 'onCancel'){
            setTimeout(() => {
                setIsOpen(false);
                setModalOption(null);
            })
        }
    }

    function renderLabel (type, selectedLabelIdList, valueLabelId, labelClickFunc, filterValue, tableHeight) {
        const isSetAttribute = type === typeObject.setAttribute || type === typeObject.setName;
        let newLabelList = labelList;
        if (filterValue) {
            newLabelList = labelList.filter(item => item.labelName.includes(filterValue));
        }
        return (
            <div className="labelListBox" onClick={stopFunc}>
                <div className="scrollBox" style={ isSetAttribute ? {maxHeight: tableHeight - 100} : {}}>
                    {
                        newLabelList?.length > 0 ?
                            newLabelList.map((item) => {
                                const {knowledgeNoteLabelId:id, labelName} = item;
                                // 标签可以重复使用，直接使用id作为key会导致ID重复页面渲染错乱
                                const timeStamp = Date.now();
                                const newID = `${item.knowledgeNoteLabelId}-${timeStamp}`
                                let isActive = valueLabelId === id;
                                if (valueLabelId === undefined) {
                                    isActive = false;
                                }
                                let isSelected = selectedLabelIdList.includes(id);
                                let isEnable = !isSelected && !isActive;
                                if (isSetAttribute) {
                                    const isSelfChoice = selectedTreeNodeData.labelId === id;
                                    isEnable = (!isSelected || isSelfChoice) && !isActive;
                                    isSelected = isSelected && !isSelfChoice;
                                }
                                return (
                                    <span
                                        className={`labelItem ${isSelected ? 'selectedStatus' : ''} ${isActive ? "activeStatus" : ""}`}
                                        key={newID}
                                        onClick={() => {
                                            labelClickFunc(isEnable, id, labelName);
                                        }}
                                    >{labelName}</span>
                                )
                            })
                            :
                            (<NoneData text={"暂无标签"} />)
                    }
                </div>
            </div>
        )
    }

    // ============= 弹窗内容显示 ==============
    function renderModalContentFunc (type) {
        let content = null, tableHeight = document.querySelector(".systemDesignBox").clientHeight - 284;
        switch (type) {
            case typeObject.linkNote:
                content = (
                    <div>{isOpen ? 'a' : 'b'}</div>
                )
                break;
            case typeObject.writeExplain:
                content = (
                    <div className="writeExplainBox">
                        <Form
                            form={addExplainForm}
                            component={false}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 14 }}
                            preserve={false}
                        >
                            <Form.Item
                                label={"讲解标题"}
                                key={'knowledgeNoteDetailTitle'}
                                name={'knowledgeNoteDetailTitle'}
                                rules={[{ required:  true, message: `请输入讲解标题!` }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={"讲解内容"}
                                key={'knowledgeNoteDetailMessage'}
                                name={'knowledgeNoteDetailMessage'}
                                rules={[{ required:  true, message: `请输入讲解内容!` }]}
                            >
                                <TextArea />
                            </Form.Item>
                            <Form.Item
                                label={"讲解标签"}
                                key={'knowledgeNoteDetailId'}
                                name={'knowledgeNoteDetailId'}
                            >
                                <div>
                                    {ExplainLableList.map((item,index)=>{
                                        return <Tag key={index}
                                        onClose={(e) => {
                                            e.preventDefault();
                                            handleExplainLableClose(item);
                                        }} key={item}>{item}</Tag>
                                    })}
                                    {ExplainLableInputVisible&& (
                                        <Input
                                            ref={ExplainLableInputRef}
                                            type="text"
                                            size="small"
                                            style={{
                                                width: 78,
                                            }}
                                            value={ExplainLableInputValue}
                                            onChange={handleExplainLableInputChange}
                                            onBlur={handleExplainLableInputConfirm}
                                            onPressEnter={handleExplainLableInputConfirm}
                                        />
                                    )}
                                    {!ExplainLableInputVisible&& (
                                        <Tag onClick={showExplainLableInput} className="site-tag-plus"
                                        ><PlusOutlined /></Tag>
                                    )}
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                )
                break;
            case typeObject.noteDetail:
                content = (
                    <Spin tip="数据加载中" spinning={modalLoading}>
                        <div>
                            <div className="noteOpration">
                                <div
                                    className="timeSort"
                                    // onClick={sortValueChange}
                                >
                                    <div style={{
                                        width: '80px',
                                        cursor: 'pointer'}}
                                        onClick={sortValueChange}
                                    >
                                        {labelDetailesParam.timeType === '2' ? "时间升序":"时间降序"}
                                        <CaretUpOutlined style={labelDetailesParam.timeType === '2' ?{color:"#60B4F6"}:{}} />
                                        <CaretDownOutlined style={labelDetailesParam.timeType === '1' ?{color:"#60B4F6"}:{}} />
                                    </div>
                                </div>
                                <Space value={"large"}>
                                    <Tooltip placement={'top'} title={"删除笔记"}>
                                        <span
                                            className="operateBtn deleteSortBtn"
                                            onClick={deleteNote}
                                        ><Iconfont type={"icon-delete"} /></span>
                                    </Tooltip>
                                    <Tooltip placement={'top'} title={"添加笔记"}>
                                        <span
                                            className="operateBtn addSystemBtn"
                                            onClick={addNote}
                                        ><Iconfont type={"icon-roundadd"} /></span>
                                    </Tooltip>
                                </Space>
                            </div>
                            <div style={{height:tableHeight,overflow:"auto"}}>
                                <Checkbox.Group
                                    style={{width: '100%'}}
                                    onChange={checkboxChange}
                                    value={selectNote}
                                >
                                    {selectLable.noteList&&selectLable.noteList.length>0?(
                                        <div className="noteListItem" key={selectLable.noteList[0].id}>
                                            <Checkbox value={selectLable.noteList[0].id}>{selectLable.noteList[0].knowledgeNoteDetailTitle}</Checkbox><span className="typeAdd">(新增)</span>
                                            <div className="noteDetails">{selectLable.noteList[0].knowledgeNoteDetailMessage}</div>
                                        </div>
                                    ):""}

                                    {lableListofNote.list&&lableListofNote.list.length>0&&lableListofNote.list.map(item=>{
                                        return(
                                        <div className="noteListItem" key={item.knowledgeNoteDetailId}>

                                            <Checkbox value={item.knowledgeNoteDetailId} style={{marginBottom: '5px'}}>
                                                {/* 0笔记 1摘录 */}
                                                {item.dataType === 0?(<Iconfont type={'icon-bijiben'} iStyle={{
                                                    // float: "left",
                                                    margin: "3px 5px 0 0",
                                                }}/>):(<Iconfont type={'icon-biaoqian'} iStyle={{
                                                    // float: "left",
                                                    margin: "3px 5px 0 0",
                                                }}/>)}

                                                {item.knowledgeNoteDetailTitle}
                                            </Checkbox>
                                            {/* 0新笔记 1旧笔记 */}
                                            {item.isNews === 0 ? <span className="typeAdd">NEW</span>:""}
                                            {item.dataType === 0 ? <div className="noteDetails">{item.knowledgeNoteDetailMessage}</div>:""}
                                            <div className="noteOriginal">{item.originalMsg}</div>
                                            <div className="source" onClick={()=>{goKnowledgeDetails(item)}}>--- 来源<span>《{item.knowledgeName}》</span></div>
                                        </div>
                                        )
                                    })}

                                    {(!lableListofNote.list || lableListofNote.list.length <= 0) &&
                                    (!selectLable.noteList || selectLable.noteList.length<=0) &&
                                    <NoneData/>}

                                </Checkbox.Group>
                            </div>
                            {lableListofNote.list&&lableListofNote.list.length>0?(
                                <SelfPagination
                                    current={labelDetailesParam.pageIndex}
                                    total={lableListofNote.total}
                                    onSizeChange={onSizeChange}
                                />
                            ):""}
                        </div>
                    </Spin>
                )
                break;
            case typeObject.publicNoteList:
                content = (
                    <Spin tip="数据加载中" spinning={modalLoading}>
                        <div>
                            <div className="noteOpration">
                                <div
                                    className="timeSort"
                                    // onClick={publicSortValueChange}
                                >
                                    <div style={{
                                        width: '80px',
                                        cursor: 'pointer'}}
                                        onClick={publicSortValueChange}
                                    >
                                        {publicNodeListParam.timeType === '2' ? "时间升序":"时间降序"}
                                        <CaretUpOutlined style={publicNodeListParam.timeType === '2' ?{color:"#60B4F6"}:{}} />
                                        <CaretDownOutlined style={publicNodeListParam.timeType === '1' ?{color:"#60B4F6"}:{}} />
                                    </div>
                                </div>
                                <div>
                                    <Search
                                        placeholder=""
                                        allowClear
                                        onSearch={onPublicNoteSearch}
                                        style={{
                                            width: 200,
                                        }}
                                    />

                                    {/* onSearch={onSearch}
                                value={searchValue}
                                onChange={onSearchValueChange} */}
                                </div>
                            </div>
                            <div style={{height: tableHeight - 16,overflow:"auto"}}>
                                <Checkbox.Group
                                    style={{width: '100%'}}
                                    onChange={publicNoteListCheckboxChange}
                                    value={selectPublicNote}
                                >
                                    {publicNodeList.records&&publicNodeList.records.length>0?publicNodeList.records.map(item=>{
                                        return(<div className="noteListItem" key={item.knowledgeNoteDetailId}>
                                            <Checkbox value={item.knowledgeNoteDetailId} style={{marginBottom: '5px'}}>
                                                {/* 0笔记 1摘录 */}
                                                {item.dataType === 0?(<Iconfont type={'icon-bijiben'} iStyle={{
                                                    // float: "left",
                                                    margin: "3px 5px 0 0",
                                                }}/>):(<Iconfont type={'icon-biaoqian'} iStyle={{
                                                    // float: "left",
                                                    margin: "3px 5px 0 0",
                                                }}/>)}

                                                {item.knowledgeNoteDetailTitle}

                                            </Checkbox>

                                            {item.dataType === 0 ? <div className="noteDetails">{item.knowledgeNoteDetailMessage}</div>:""}

                                            <div className="noteOriginal">{item.originalMsg}</div>

                                            <div className="source" onClick={()=>{goKnowledgeDetails(item)}}>--- 来源<span>《{item.knowledgeName}》</span></div>
                                        </div>)
                                    }):<NoneData/>}
                                </Checkbox.Group>
                            </div>
                            {publicNodeList.records&&publicNodeList.records.length>0?(
                                <SelfPagination
                                    current={publicNodeListParam.pageIndex}
                                    total={publicNodeList.total}
                                    onSizeChange={(page, pageSize) => {
                                        setPublicNodeListParam({
                                            ...publicNodeListParam,
                                            pageIndex:page,
                                            pageSize:pageSize,
                                        })
                                    }}
                                />
                            ):""}
                        </div>
                    </Spin>)
                break;
            case typeObject.referenceSystem:
                content = (
                    <Spin tip="数据加载中" spinning={modalLoading}>
                        <div className="systemItemBox">
                            {systemList.map((item)=>{
                                const {knowledgeNoteSystemManagementId, knowledgeNoteSystemUrl, knowledgeNoteSystemTitle} = item;
                                return(
                                    <div
                                        onClick={()=> selectSystem(item)}
                                        className="systemItem"
                                        key={knowledgeNoteSystemManagementId}
                                        style={systemId === knowledgeNoteSystemManagementId ?{boxShadow: 'rgb(1 160 250) 0px 0.1rem 0.5rem 0.05rem'}:{}}
                                    >
                                        <div className="systemImg">
                                            <img src={knowledgeNoteSystemUrl} alt=""/>
                                        </div>
                                        <div className="systemName">
                                            <span>{knowledgeNoteSystemTitle}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Spin>
                )
                break;
            case typeObject.setName:
            case typeObject.setAttribute:
            case typeObject.insertParentNode:
                content = (
                    <div className="setAttributeBox">
                        {/*<div className="attrItemBox">*/}
                        {/*    <span className="labelText">节点描述：</span>*/}
                        {/*    <div className="attrContent">*/}
                        {/*        <Input.TextArea*/}
                        {/*            value={nodeAttribute.intro}*/}
                        {/*            rows={2}*/}
                        {/*            placeholder="输入节点描述"*/}
                        {/*            maxLength={50}*/}
                        {/*            showCount={true}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                setNodeAttribute({*/}
                        {/*                    ...nodeAttribute,*/}
                        {/*                    intro: e.target.value,*/}
                        {/*                })*/}
                        {/*            }}*/}
                        {/*        ></Input.TextArea>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="attrItemBox">
                            <span className="labelText">节点类型：</span>
                            <div className="attrContent">
                                {/* 类型选择 */}
                                <div className="nodeTypeListBox">
                                    {
                                        nodeTypeList.map(item => {
                                            const {text, value} = item;
                                            const isActive = nodeAttribute.nodeType === value;
                                            return (
                                                <div
                                                    key={value}
                                                    className={`radioItem ${isActive ? "activeStatus" : ""}`}
                                                    onClick={() => {
                                                        if (isActive) {
                                                            return
                                                        }
                                                        setNodeAttribute({
                                                            ...nodeAttribute,
                                                            nodeType: value,
                                                        })
                                                    }}
                                                >
                                                    <i className="checkedBox" />
                                                    <span className="typeText">{text}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="attrItemBox">
                            <span className="labelText">节点名称：</span>
                            <div className="attrContent">
                                {
                                    nodeAttribute.nodeType === "custom" ? (
                                        <Input
                                            value={nodeAttribute.nodeName}
                                            placeholder="输入节点名称"
                                            onChange={(e) => {
                                                setNodeAttribute({
                                                    ...nodeAttribute,
                                                    nodeName: e.target.value,
                                                })
                                            }}
                                        />
                                    ) : (
                                        renderLabel(
                                            type,
                                            parentNodeSelectedLabelIdList,
                                            nodeAttribute.labelId,
                                            (isEnable, id, labelName) => {
                                                if (isEnable) {
                                                    setNodeAttribute({
                                                        ...nodeAttribute,
                                                        labelId: id,
                                                        labelName: labelName,
                                                    })
                                                }
                                            },
                                            undefined, tableHeight,
                                        )
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )
                break;
            case typeObject.addChildNode:
            case typeObject.addBeforeNode:
            case typeObject.addAfterNode:
                let labelIdList = [...parentNodeSelectedLabelIdList];
                if (type === typeObject.addChildNode) {
                    labelIdList = [...selectedLabelIdList]
                }
                addNodeList.map(item => labelIdList.push(item.labelId));
                content = (
                    <div className="addNodeListBox" style={{height: tableHeight - 20}}>
                        {/* left box */}
                        <div className="addAreaBox">
                            {/* 自定义添加 */}
                            <div className="customBox">
                                <span className="titleText">自定义节点：</span>
                                <div className="inputBox">
                                    <Input
                                        placeholder="输入节点名称"
                                        value={nodeAttribute.nodeName}
                                        onChange={(e) => {
                                            setNodeAttribute({
                                                nodeName: e.target.value,
                                            })
                                        }}
                                    />
                                    <span
                                        className="addNodeBtn"
                                        style={nodeAttribute.nodeName ? {} : {opacity: "0.5", cursor: "not-allowed"}}
                                        onClick={() => {
                                            if (nodeAttribute.nodeName) {
                                                setAddNodeList([
                                                    ...addNodeList,
                                                    {
                                                        id: getID(),
                                                        labelId: null,
                                                        nodeName: nodeAttribute.nodeName,
                                                        noteList: [],
                                                        intro: "",
                                                    }
                                                ]);
                                                setNodeAttribute({});
                                            }
                                        }}
                                    >添加</span>
                                </div>
                            </div>
                            {/* 系统标签 */}
                            <div className="systemBox">
                                <div className="systemHeadBox">
                                    <span className="titleText">标签池：</span>
                                    <Input
                                        placeholder="输入标签关键字"
                                        value={filterLabelWord}
                                        onChange={(e) => setFilterLabelWord(e.target.value)}
                                    />
                                </div>
                                {
                                    renderLabel(
                                        type,
                                        labelIdList,
                                        undefined,
                                        (isEnable, id, labelName) => {
                                            if (isEnable) {
                                                setAddNodeList([
                                                    ...addNodeList,
                                                    {
                                                        id: getID(),
                                                        labelId: id,
                                                        nodeName: labelName,
                                                        noteList: [],
                                                        intro: "",
                                                    },
                                                ])
                                            }
                                        },
                                        filterLabelWord,
                                    )
                                }
                            </div>
                        </div>
                        {/* right box */}
                        <div className="selectedNodeListBox">
                            <span className="titleText">待添加节点：</span>
                            <div className="nodeListBox">
                                {
                                    addNodeList.length > 0 ? addNodeList.map((item, idx) => {
                                        const {nodeName, id} = item;
                                        return (
                                            <div className="nodeItem" key={id}>
                                                <span className="nodeName" title={nodeName}>{nodeName}</span>
                                                <div className="operateBox">
                                                    {
                                                        idx > 0 ? (
                                                            <Tooltip placement="top" title={"上移"}>
                                                                <span
                                                                    className="operateBtn moveUpBtn"
                                                                    onClick={() => {
                                                                        let list = [...addNodeList];
                                                                        let self = list.splice(idx, 1);
                                                                        list.splice(idx - 1, 0, self[0]);
                                                                        setAddNodeList(list);
                                                                    }}>
                                                                    <Iconfont type="icon-B" />
                                                                </span>
                                                            </Tooltip>
                                                        ) : undefined
                                                    }

                                                    {
                                                        idx < (addNodeList.length - 1) ? (
                                                            <Tooltip placement="top" title={"下移"}>
                                                                <span
                                                                    className="operateBtn moveDownBtn"
                                                                    onClick={() => {
                                                                        let list = [...addNodeList];
                                                                        let self = list.splice(idx, 1);
                                                                        list.splice(idx + 1, 0, self[0]);
                                                                        setAddNodeList(list);
                                                                    }}>
                                                                    <Iconfont type="icon-xiangxia" />
                                                                </span>
                                                            </Tooltip>
                                                        ) : undefined
                                                    }

                                                    <Tooltip placement="top" title={"删除"}>
                                                        <Popconfirm
                                                            placement={"right"}
                                                            title={"确定删除吗？"}
                                                            overlayClassName={"deletePupConfirmBox"}
                                                            onConfirm={() => {
                                                                addNodeList.splice(idx, 1);
                                                            }}
                                                        >
                                                            <span className="operateBtn deleteBtn">
                                                                <Iconfont type="icon-quxiao" />
                                                            </span>
                                                        </Popconfirm>
                                                    </Tooltip>
                                                </div>

                                            </div>
                                        )
                                    }) : NoneData({text: "请从左侧添加节点！"})
                                }
                            </div>
                        </div>
                    </div>
                )
                break;
        }
        return content;
    }


    const goKnowledgeDetails = async(item) => {
        if(detailsLoading){return false;}
        setDetailsLoading(true);
        try{
            let res = await Api.Message.checkMessageStatus({
                data: {
                    id:item.knowledgeId
                },
            });
            setDetailsLoading(false)
            if(res.code === 0){
                // // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
                let _code = res.data;
                if(_code === -1){
                    message.info("当前知识已被删除");
                }else if(_code === 1){
                    jumpToPage({...item, type: ""});
                }else{
                    message.info("当前知识已取消发布")
                }
            }

            // let res = await KnowledgeApply(item.id || item.knowledgeId,4);
            // setDetailsLoading(false)
            // if(res.bool){
            // }
        }catch{setDetailsLoading(false)}
    }

    //  --------------
    const handleExplainLableClose = (removedTag) => {
        const newTags = ExplainLableList.filter((tag) => tag !== removedTag);
        setExplainLableList(newTags)
    };
    const showExplainLableInput = () => {
        setExplainLableInputVisible(true);
    };
    const handleExplainLableInputChange = (e) => {
        setExplainLableInputValue(e.target.value);
    };
    const handleExplainLableInputConfirm = () => {
        if (ExplainLableInputValue && ExplainLableList.indexOf(ExplainLableInputValue) === -1) {
            setExplainLableList([...ExplainLableList, ExplainLableInputValue]);
        }
        setExplainLableInputVisible(false);
        setExplainLableInputValue('');
    };

    const sortValueChange = () => {
        let _timeSort = labelDetailesParam.timeType === '1' ? '2' : '1';

        setLabelDetailesParam({
            ...labelDetailesParam,
            timeType:_timeSort
        })
    }

    const publicSortValueChange = () => {
        let _timeSort = publicNodeListParam.timeType === '1' ? '2' : '1';

        setPublicNodeListParam({
            ...publicNodeListParam,
            timeType:_timeSort
        })
    }

    // 删除关联笔记
    const deleteNote = () => {
        console.log(labelDetailesParam,"labelDetailesParam")
        if(selectNote.length<=0){
            message.info("请选择需要删除的数据")
            return false;
        }

        ModalConfirm({
            title: `您确认要删除这${selectNote.length}条数据吗?`,
            onOk:async(resolve, reject)=> {
                try{

                    // 删除讲解
                    if(selectLable.noteList&&selectLable.noteList.length>0){
                        let _Index = selectNote.indexOf(selectLable.noteList[0].id);
                        if(_Index != -1){
                            selectNote.splice(_Index,1);
                            firstRecursiveData(selectLable.noteList[0].id);
                            selectLable.noteList = [];
                            setSelectLable(selectLable);
                        }
                    }

                    if(selectNote.length<=0){
                        message.info("删除成功");
                        resolve();

                        let _labelDetailesParam = {...labelDetailesParam}
                        _labelDetailesParam.pageIndex = 1;
                        setLabelDetailesParam(_labelDetailesParam);

                        return false;
                    }

                     // 笔记和摘录的ID分开传
                    let ids = {
                        knowledgeNoteDetailId:[],
                        knowledgeExcerptId:[]
                    }

                    lableListofNote.list.map(item=>{
                        // dataType 0笔记 1摘录
                        if(selectNote.includes(item.knowledgeNoteDetailId)){
                            if(item.dataType === 0){
                                // 笔记
                                ids.knowledgeNoteDetailId.push(item.knowledgeNoteDetailId)
                            }else{
                                // 摘录
                                ids.knowledgeExcerptId.push(item.knowledgeNoteDetailId)
                            }
                        }
                    })

                    // 标签id带有时间戳
                    // 如果是标签池选择的标签会有lableId,就使用lableID,否者使用ID
                    let _knowledgeNoteLabelId = selectLable.labelId ? selectLable.labelId : selectLable.id;

                    const res = await Api.Note.removeLabelNote({data:{
                        // knowledgeNoteDetailId:selectNote,
                        knowledgeNoteLabelId:_knowledgeNoteLabelId,
                        ...ids
                    }});
                    if(res&&res.code === 0){
                        resolve();
                        message.success("删除成功");

                        let _labelDetailesParam = {...labelDetailesParam}
                        _labelDetailesParam.pageIndex = 1;
                        setLabelDetailesParam(_labelDetailesParam)
                    }else{
                        reject();
                    }
                }catch{}
            },
        })
    }

    // 打开共享笔记列表弹窗
    function addNote () {
        setLabelDetailesParam({
            pageIndex:1,
            pageSize:10,
            timeType:"1"
        })
        menuClickFunc(typeObject.publicNoteList);
    }

    // 获取共享笔记
    async function getPublicNodeList(){
        setModalLoading(true)
        try {
            const res = await Api.Knowledge.getPublicNodeList({data:{
                ...publicNodeListParam,
                // knowledgeLabelId:selectLable.id
            }});
            setModalLoading(false);
            if(res&&res.code === 0){
                setPublicNodeList(res.data);
            }else{}
        } catch(errInfo) {
            setModalLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 搜索笔记
    function onPublicNoteSearch (value) {
        setPublicNodeListParam({
            ...publicNodeListParam,
            searchMessage:value,
            pageIndex:1,
        })
    }

    // 多选框选择
    function publicNoteListCheckboxChange (checkedValues) {

        setSelectPublicNote(checkedValues);
    }

    // 给标签新增关联的笔记
    async function addNewLabelRelationNote(checkedValues, publicNodeList){
        if(checkedValues.length <= 0){
            message.info("请选择要添加的笔记")
            return false;
        }
        setConfirmLoading(true);
        try {
            // 笔记和摘录的ID分开传
            let ids = {
                knowledgeNoteDetailId:[],
                knowledgeExcerptId:[]
            }

            publicNodeList.records.map(item=>{
                // dataType 0笔记 1摘录
                if(checkedValues.includes(item.knowledgeNoteDetailId)){
                    if(item.dataType === 0){
                        // 笔记
                        ids.knowledgeNoteDetailId.push(item.knowledgeNoteDetailId)
                    }else{
                        // 摘录
                        ids.knowledgeExcerptId.push(item.knowledgeNoteDetailId)
                    }
                }
            })

            let _knowledgeNoteLabelId = selectLable.labelId ? selectLable.labelId : selectLable.id;

            const res = await Api.Note.addNewLabelRelationNote({data:{
                knowledgeNoteLabelId:_knowledgeNoteLabelId,
                knowledgeNoteLabelName:selectLable.nodeName,
                ...ids
            }});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                setSelectPublicNote([]);
                setSelectNote([]);
                message.info("添加成功")

                // 重新查询详情
                getNoteBaseOnTheTagID();

                // 重置数据
                setPublicNodeListParam({
                    pageIndex:1,
                    pageSize:10,
                    timeType:"1"
                });
                menuClickFunc(typeObject.noteDetail);
                setPublicNodeList({});
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    function checkboxChange (event) {
        setSelectNote(event)
    }

    // 选择体系
    function selectSystem (system) {
        setSystemId(system.knowledgeNoteSystemManagementId);
    }

    // 确定修改节点属性
    function changeNodeAttribute (attribute) {
        let node = {
            nodeName: "",
            labelId: null,
            intro: attribute.intro || "",
        }
        if (attribute.nodeType === "custom") {
            if (!attribute.nodeName) {
                return message.warning("节点名称不能为空！");
            }

            node.nodeName = attribute.nodeName;
        } else {
            if (!attribute.labelId) {
                return message.warning("请选择系统标签！");
            }
            node.nodeName = attribute.labelName;
            node.labelId = attribute.labelId;
        }

        createNode(node, typeObject.reference);
        setIsOpen(false);
        setModalOption(null);
        setNodeAttribute({});
    }

    // 确定插入父节点
    function insertParentNode (attribute) {
        let node = {
            nodeName: "",
            labelId: null,
            intro: attribute.intro,
            noteList: [],
        }
        if (attribute.nodeType === "custom") {
            if (!attribute.nodeName) {
                return message.warning("节点名称不能为空！");
            }

            node.nodeName = attribute.nodeName;
        } else {
            if (!attribute.labelId) {
                return message.warning("请选择系统标签！");
            }
            node.nodeName = attribute.labelName;
            node.labelId = attribute.labelId;
        }

        createNode(node, typeObject.insertParentNode);
        setIsOpen(false);
        setModalOption(null);
        setNodeAttribute({});
    }

    // 添加兄弟节点
    function addBrotherNode (nodeList, type) {
        if (nodeList.length < 1) {
            return message.warning("请添加需要创建的兄弟节点！");
        }

        if (!selectedTreeNodeData) {
            return message.warning("请先选择操作节点！！！")
        }

        if (selectedNodeParentNodeData.children) {
            const idx = selectedNodeParentNodeData.children.findIndex(item => item.id === selectedTreeNodeData.id);
            if (type === typeObject.addAfterNode) {
                selectedNodeParentNodeData.children.splice(idx + 1, 0, ...nodeList);
            } else {
                selectedNodeParentNodeData.children.splice(idx, 0, ...nodeList);
            }
        }

        setIsOpen(false);
        setModalOption(null);
        setAddNodeList([]);
        setFilterLabelWord("");
    }

    // 添加子节点
    function addChildNode (nodeList) {
        if (nodeList.length < 1) {
            return message.warning("请添加需要创建的子节点！");
        }

        const isOnlyRootNode = !(treeData[0]?.children?.length > 0);
        if (isOnlyRootNode) {
            if (treeData[0].children) {
                treeData[0].children.push(...nodeList)
            } else {
                treeData[0].children = [...nodeList];
            }
            setIsOpen(false);
            setModalOption(null);
            setAddNodeList([]);
            setFilterLabelWord("");
            return
        }

        if (!selectedTreeNodeData) {
            return message.warning("请先选择操作节点！！！")
        }

        if (selectedTreeNodePosition?.length > 9) {
            return message.error("层级已达限制！！！")
        }

        if (selectedTreeNodeData.children) {
            selectedTreeNodeData.children.push(...nodeList)
        } else {
            selectedTreeNodeData.children = [...nodeList];
        }
        setIsOpen(false);
        setModalOption(null);
        setAddNodeList([]);
        setFilterLabelWord("");
    }

    // 操作栏
    function renderOperateBox () {
        return (
            <div className="topToolBox">
                {
                    isAdding ? (
                        <div className="operateSummaryBox">
                            <span className="tipText">选择需要汇总的节点！</span>
                            <div className="operateSummaryResultBox">
                                <Tooltip placement={"bottom"} title="确定汇总">
                                            <span
                                                className={`summaryBtn confirmBtn ${isHaveSummaryNode ? "" : "disabled"}`}
                                                onClick={() => confirmSummaryFunc()}
                                            ><Iconfont type={"icon-duigou"} /></span>
                                </Tooltip>
                                <Tooltip placement={"bottom"} title="取消汇总">
                                            <span
                                                className="summaryBtn cancelBtn"
                                                onClick={() => cancelSummaryFunc()}
                                            ><Iconfont type={"icon-quxiao"} /></span>
                                </Tooltip>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="operateBtn"
                            onClick={() => startAddSummaryFunc()}
                        >
                            <Iconfont type={"icon-tree-01"} />
                            <span className="btnName">添加汇总</span>
                        </div>
                    )
                }
            </div>
        )
    }

    // 保存体系
    async function confirmSaveSystem () {
        if(!systemName){
            message.info("请输入体系名称");
            return false;
        }
        if(!treeData[0].children){
            message.info("请增加体系节点");
            return false;
        }
        setSaveLoading(true);
        cancelChangeColor();
        closeMenu(true);
        // setTimeout(() => {
            // 获取图片
            let res = await createClipImage()
            uploadImgs(res)
        // })
    }
    // 上传图片获取id
    const uploadImgs = async(file) => {
        if(file){
            const formData = new FormData();
            formData.append("file",file);

            const res = await Api.Common.uploadFile({
                data:formData,
            });
            if(res&&res.code === 0){
                saveKnowledgeSystem(res.data.id)
            }
        }else{}
    }

    const saveKnowledgeSystem = async(fileId) => {
        try{
            const res = await Api.Note.saveKnowledgeNoteSystem({
                data:{
                    fileId,
                    knowledgeNoteSystemId:searchParams.sortId,
                    knowledgeNoteSystemTitle:systemName,
                    knowledgeNoteSystemMessage:treeData,
                    knowledgeNoteSystemManagementId:isCreate?null:searchParams.systemId
                },
            });
            if(res&&res.code === 0){
                message.info(isCreate?"新增成功":"修改成功",2,()=>{
                    history.go(-1);
                });
            }
        }catch(errorInfo){

        }

    }

    function changeSystemName (e) {
        setSystemName(e.target.value)
    }

    // 取消保存体系
    function cancelSaveSystem () {
        ModalConfirm({
            title: `取消后您所做的操作将不会保存?`,
            onOk:async(resolve, reject)=> {
                history.go(-1);
                resolve();
            },
        })
    }

    const {
        title, icon, color='#333', type, width=540,
        onOk, onCancel, getContainer, footer,
    } = modalOption || {};

    const isCreate = !searchParams.systemId; // 创建 or 编辑 体系
    const isHaveLabel = labelList && labelList.length > 0; // 可选择标签列表是否为空
    const isHaveSummaryNode = summaryNodeList.length > 1; // 是否有参与汇总的节点
    let selectedLabelIdList = [];
    if (selectedTreeNodeData?.children) {
        let children = selectedTreeNodeData.children;
        for (let child of children) {
            if (child.labelId) {
                selectedLabelIdList.push(child.labelId);
            }
        }
    }

    let parentNodeSelectedLabelIdList = [];
    if (selectedNodeParentNodeData?.children) {
        let children = selectedNodeParentNodeData.children;
        for (let child of children) {
            if (child.labelId) {
                parentNodeSelectedLabelIdList.push(child.labelId);
            }
        }
    }

    const onSizeChange = (page, pageSize) => {
        setLabelDetailesParam({
            ...labelDetailesParam,
            pageIndex:page,
            pageSize:pageSize,
        })
    };

    const isCustom = selfMenuStyle?.intro === "custom";

    const state = {
        systemId, selectLable, ExplainLableList, selectPublicNote,
        nodeAttribute, addNodeList, publicNodeList
    };

    return (
        <div className="knowledgeSystemOperateFrame">

            {/* header */}
            <div className="headerBox">
                <div className="boxName">
                    <Iconfont type={isCreate ? "icon-xinjian" : "icon-bianji3"} />
                    <div className="systemNameBox">
                        <span className="boxName">知识体系：</span>
                        <div className="inputBox">
                            <Input
                                placeholder="输入体系名称"
                                value={systemName}
                                onChange={changeSystemName}
                            />
                        </div>
                    </div>
                </div>
                <div className="operateBox">
                    <span className="operateBtn cancelBtn" onClick={() => cancelSaveSystem()}>取消</span>
                    <span className="operateBtn saveBtn" onClick={() => saveLoading ? {} : confirmSaveSystem()}>
                        {saveLoading?<Spin indicator={<LoadingOutlined
                            style={{
                                fontSize: 12,
                                color:"#fff",
                                marginRight:"3px",
                                float: 'left'
                            }}
                        />} />:""}
                    保存</span>
                </div>
            </div>
            {/* content */}
            <div className="contentBox">
                {/* 操作 & 画布 */}
                <div className="rightBox">
                    {/* 操作 添加汇总 */}
                    {/* {renderOperateBox()} */}

                    {/* 画布 */}

                    <div
                        ref={designRef}
                        className="systemDesignBox"
                        style={{cursor: isMoving ? 'grab' : ''}}
                        onMouseDown={startMoveFunc}
                        onMouseMove={movingFunc}
                    >
                        <Spin tip="数据加载中" spinning={canvasLoading}>
                            <div
                                className="treeRootBox"
                                style={positionStyle}
                                onContextMenu={stopFunc}
                            >
                                {
                                    treeData.map((item, idx) => {
                                        return (productTreeNode(item, [idx], {style: {padding: '2rem', fontVariant: "normal"}, ref: clipImgAreaRef}, null));
                                    })
                                }
                            </div>
                            <span
                                className="reductionPositionBtn"
                                onMouseDown={stopFunc}
                                onClick={() => {
                                    setPositionStyle({left: 0, top: 0})
                                }}
                            >
                                <Iconfont type={"icon-zhongzhi-fangkuang"} />
                            </span>
                        </Spin>
                    </div>
                </div>
            </div>
            {/* 右键菜单 */}
            <div
                className="selfMenuBox"
                style={selfMenuStyle}
                ref={menuRef}
                onContextMenu={stopFunc}
                onClick={stopFunc}
            >
                <div className="scrollBox">
                    {
                        menuList.map(item => {
                            const {icon, text, key, hide} = item;
                            if(hide){
                                return false;
                            }
                            let content = (
                                <div
                                    className="menuItem"
                                    key={key}
                                    onClick={() => {
                                        if(key === typeObject.linkNote){
                                            showModal()
                                        }else{
                                            menuClickFunc(key)
                                        }
                                    }}
                                >
                                    <Iconfont type={icon} />
                                    <span className="menuItemName">{text}</span>
                                </div>
                            )
                            if (key === typeObject.delete) {
                                content = (selectedTreeNodePosition?.length > 1) ? (
                                    <Popconfirm
                                        className="deleteConfirmBox"
                                        placement={selfMenuStyle.left === 'unset' ? 'left' : 'right'}
                                        title={"确认删除吗？"}
                                        onConfirm={deleteNodeFunc}
                                        okText="确认"
                                        cancelText="取消"
                                    >
                                        {content}
                                    </Popconfirm>
                                ) : undefined
                            } else if (key === typeObject.referenceSystem && selectedTreeNodeData?.children?.length > 0) {
                                content = null;
                            } else if (key === typeObject.writeExplain && (!isCustom || selectLable.noteList?.length > 0)) {
                                content = null
                            } else if (key === typeObject.insertParentNode && !selectedNodeParentNodeData) {
                                content = null;
                            } else if (key === typeObject.setName && selectedTreeNodeData?.labelId) {
                                content = null;
                            }
                            return content
                        })
                    }
                </div>
            </div>

            {/* Modal弹窗 */}
            <Modal
                open={isOpen && modalOption}
                destroyOnClose={true}
                width={width}
                wrapClassName={"selfDesignModal knowledgeSystemOperateModal"}
                centered={true}
                closable={true}
                maskClosable={false}
                footer={footer}
                title={(
                    <div className="titleBox" style={{color,}}>
                        {icon ? <Iconfont type={icon} /> : '' }
                        <span>{title}</span>
                    </div>
                )}
                getContainer={() => {
                    if (getContainer) {
                        return getContainer()
                    }
                    return designRef.current
                }}
                onCancel={() => onCancel(state)}
                onOk={() => onOk(state)}
                confirmLoading={confirmLoading}
            >
                {isOpen && modalOption?(
                    <div className="modalContentBox">
                        {renderModalContentFunc(type)}
                    </div>
                ):""}
            </Modal>
        </div>
    )
};

export default knowledgeSystemOperate;
