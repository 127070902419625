import React, {useState} from 'react';
import { message } from 'antd'
import SparkMD5 from 'spark-md5'

import Api from "../../api";

import './index.scss';

const getMD5 = (file, fileListID) => {
    return new Promise((resove, reject) => {
        // 使用sparkMD5的ArrayBuffer类，读取二进制文件
        const spark = new SparkMD5.ArrayBuffer()
        const fileReader = new FileReader()
        // 异步操作，读完后的结果
        fileReader.onload = (e) => {
            // 把文件开始传入spark
            spark.append(e.target.result)
            // spark计算出MD5后的结果
            const _md5 = spark.end()
            resove(_md5)
            // 下面可以写一些自己需要的业务代码, 例如 fileItem.fileMD5 = _md5
        }
        // fileReader读取二进制文件
        fileReader.readAsArrayBuffer(file)
    })
}

const checkExist = async(allMd5List) => {
    // 根据MD5查询是否有上传失败的数据
    const res = await  Api.Common.checkFragment({
        data:allMd5List,
    });
    let {code, data=[]} = res;

    if(code === 0&&data.length > 0){
        return data;
    }else{
        return [];
    }
}

const SplitUpload = async (fileList,type) => {
    // 单个切片文件大小为2MB
    const chunkSize = 2097152;
    // 分片的最小值 10M
    const chunkMinSize = 10485760;
    let chunkFlag = false, _fileIds = [], fileId = "";
    
    if(fileList && fileList.length > 0) {
        for(let i = 0; i < fileList.length; i++) {
            
            if(fileList[i].size > chunkMinSize) {
                chunkFlag = true;
            }
            // 获取文件的总分片数
            const chunkNum = Math.ceil(fileList[i].size / chunkSize)
            // 取两个md5值作为整体文件的唯一标识
            let fileMd5 = ''
            if (chunkNum >= 2) {
                let startMd5 = await getMD5(fileList[i].slice(0, 1 * chunkSize))
                let endMd5 = await getMD5(fileList[i].slice((chunkNum-1) * chunkSize, chunkNum * chunkSize))
                fileMd5 = startMd5 + endMd5;
                chunkFlag = true;
            } else {
                fileMd5 = await getMD5(fileList[i])
            }
            let res 
            // 判断文件是否存在
            // 判断是否为政策跟踪上传
            if (!type) {
                res = await Api.Common.checkFileExist({
                    data:{fileMd5:fileMd5},
                });
            } else {
                res = await Api.Common.checkFileExistCommon({
                    data:{fileMd5:fileMd5},
                });
            }
            const { exist, id } = res.data;

            if(exist) {
                message.success('文件上传成功')
                _fileIds.push(res.data);
                // 跳过这个文件,不传了
            } else {
                const allMd5List = [], fileDataList = [], param = {
                    fileMd5:fileMd5, 
                    chunkFlag:chunkFlag,
                    chunkNum:chunkNum,
                    file:fileList[i],
                    chunkSize:chunkSize,
                    existingMd5:[]
                };
                if(chunkFlag){ //需要分片上传

                    // 先循环获取出文件分片后所有的MD5值
                    for(let index=0; index<chunkNum; index++){
                        let currentChunkMd5 = await getMD5(fileList[i].slice(index * chunkSize, (index + 1) * chunkSize));
                        allMd5List.push(currentChunkMd5);
                        fileDataList.push(fileList[i].slice(index * chunkSize, (index + 1) * chunkSize))
                    }

                    // 获取是否有已经上传过的 -- 有就是断点续传
                    const existingMd5 = await checkExist(allMd5List);

                    fileId = await uploadFile({
                        ...param,
                        existingMd5:existingMd5,
                        fileDataList:fileDataList,
                        allMd5List: allMd5List,
                        type
                    });
                }else{
                    let currentChunkMd5 = await getMD5(fileList[i]);
                    allMd5List.push(currentChunkMd5);
                    fileDataList.push(fileList[i]);
                    fileId = await uploadFile({
                        ...param,
                        fileDataList:fileDataList,
                        allMd5List: allMd5List,
                        type
                    });
                }
                _fileIds.push(fileId);
            }
        }
        console.log(_fileIds,"_fileIds_fileIds")
        return _fileIds;
    } else {
        console.log('请先选择文件')
    }
}

const uploadFile = async(param)=>{
    // 文件不存在，准备上传
    let _fileId = {};
    let successList = 0;
    for(let currentChunk = 0; currentChunk < param.allMd5List.length; currentChunk++) {
        let uploadItem = param.allMd5List[currentChunk];
        //                                  根据MD5来排除已经上传过的文件
        if(param.existingMd5.length <= 0 || param.existingMd5.indexOf(uploadItem) === -1){
        // if(currentChunk !=2 && currentChunk!=4 && currentChunk!=5 && currentChunk!=7){
            let formData = new FormData();
            // 分片上传
            formData.append("chunkFlag", param.chunkFlag);
            if(param.chunkFlag){
                // 分片总数
                formData.append("chunks", param.chunkNum);
                // 当前分片数
                formData.append("currentChunk", currentChunk);
                // 分片大小
                formData.append("chunkSize", param.chunkSize);
            }
            // 文件类型
            formData.append('type', param.file.type)
            // 文件总大小
            formData.append("size", param.file.size);
            // 文件名
            formData.append("name", param.file.name);
            // 整个文件的id值，及md5值
            formData.append("fileMd5", param.fileMd5);
            // 计算当前文件分片的md5值
            formData.append("currentChunkMd5", uploadItem);
            formData.append("file", param.fileDataList[currentChunk]);
            let res
            // 判断是否为政策跟踪上传
            if (!param?.type) {
                res = await Api.Common.fragmentUpload({
                    data:formData,
                });
            } else {
                res = await Api.Common.fragmentUploadCommon({
                    data:formData,
                });
            }
            if(res&&res.code === 0){
                const { fileId } = res.data;
                successList = successList+1;
                // setPercent(Math.ceil((100/param.chunkNum)*(param.existingMd5.length+successList)));
                if(fileId){
                    _fileId = res.data;
                }
            }
        }
    }
    if(_fileId){
        message.success('文件上传成功');
        return _fileId;
    }else{
        message.error('文件上传失败');
        return false;
    }
}




export default SplitUpload;

