import react, {useEffect,useState} from 'react';
import { Col, Row, Card, Image as AntdImage, message, Tooltip } from 'antd';
import { IdcardOutlined, SearchOutlined } from '@ant-design/icons';

import TopSearchBar from "../../components/TopSearchBar";

import Api from "../../api";
import storage from "../../utils/storage";

import './index.scss';

const { Meta } = Card;

const KnowledgeCentre = ({history}) => {

    const [departmentData, setDepartmentData] = useState([]);

    const [knowledgeData, setKnowledge] = useState([]);

    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    useEffect(()=>{
        getInitDate();
        sessionStorage.removeItem("tabsKey");
        sessionStorage.removeItem("selecthKey");
        sessionStorage.removeItem("departmentTreeId");
        sessionStorage.removeItem("policyThemeId");
        sessionStorage.removeItem("policyThemeName");
        sessionStorage.removeItem("sectionType");
        sessionStorage.removeItem("policyType");
        sessionStorage.removeItem("projectId");
    },[])

    const getInitDate = async() => {
        const res = await Api.Knowledge.getAllKnowledger({});
        if(res && res.code === 0){
            setKnowledge(res.data);
        }

        const resDepartment = await Api.Department.getDepartmentListAll({data:{
            knowledgeRel:0
        }});
        if(resDepartment&&resDepartment.code === 0){
            setDepartmentData(resDepartment.data);
        }
    }


    const goDetails = (type,id) => {
        if(type === 'department'){ //部门
            
        }else{ //知识类型

        }
        history.push(`/page/knowledgeContent?type=${type}&id=${id}`);
    }

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onSearch = () => {
        if(searchValue){
            storage.setItem('searchData', JSON.stringify({
                text:searchValue,
                searchType:selecthValue
            }));
            history.push(`/page/knowledgeSearch`);
        }else{
            message.info('请输入需要搜索的内容')
        }
        
    }

    return(
    <div className="knowledgeCentreFrame">
        <div className="topBox">
            <div className="topBg"></div>
            <TopSearchBar/>
        </div>
        <div className="content">
            <div className="leftBox">
                <div className="title">部门入口</div>
                <div className="leftContent">
                    {departmentData.map(item=>{
                        return (
                            <div 
                                key={item.id}
                                onClick={()=>goDetails('department',item.id)}
                                className="departmentCard"
                            >
                                <div className="departmentCardIcon">
                                    <IdcardOutlined /> <span className='number'>（{item.count || 0}）</span>
                                </div>
                                <div className="departmentCardTitle">{item.name}</div>
                            </div>
                        )
                    })}
                    <i></i><i></i>
                </div>
            </div>
            <div className="righBox">
                <div className="title">知识类型</div>
                <div className="righContent">
                    {knowledgeData.map(item=>{
                        return (
                            <div
                                key={item.id}
                                onClick={()=>goDetails('knowledge',item.id)}
                                className="knowledgeCard"
                            >
                                <div className="knowledgeCardImage">
                                    {item.url?<AntdImage
                                        width={64}
                                        preview={false}
                                        src={item.url}
                                    />:''}
                                </div>
                                <div className="knowledgeCardTitle">{item.knowledgeTypeName}</div>
                                <Tooltip title={item.intro} placement="topLeft">
                                    <div className="knowledgeCardDescription" title={item.intro}>{item.intro}</div>
                                </Tooltip>
                            </div>
                        )
                    })}
                    <i></i><i></i><i></i>
                </div>
            </div>
        </div>
        
    </div>
    )
}

export default KnowledgeCentre;