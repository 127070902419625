import React, {useEffect, useState, memo} from 'react';
import { Button, Space, Avatar, message, Input, Form, Modal, Spin, Segmented} from 'antd';
import { LikeOutlined } from '@ant-design/icons'

import Api from "../../../../api/index";
import {pagingParam} from '../../../../utils/defaultValue'
import Image from '../../../../components/Image/Image'
import Iconfont from "../../../../components/Iconfont/Iconfont";
import storage from "../../../../utils/storage";
import {getSearchParams} from '../../../../utils/tool'

import './index.scss'

const { TextArea } = Input;

const DiscussBox = ({knowledgeDetail,siderType,discussTotal,setDiscussTotals}) => {
    const [addForm] = Form.useForm();
    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo"));
    
    const items = [
        { label: '默认', value: 0},
        { label: '最新', value: 1},
    ];

    // 讨论
    const [CommentList, setCommentList] = useState([]);

    const [CommentPagination, setCommentPagination] = useState({});
    const [CommentQueryType, setCommentQueryType] = useState(0);

    const [parentId, setParentId] = useState(0);
    const [replyCommentUserId, setReplyCommentUserId] = useState('');
    
    // 评论
    const [QuestionBoxType, setQuestionBoxType] = useState(false);
    const [ConfirmLoading, setConfirmLoading] = useState(false);
    
    // 回复
    const [ReplyContentType, setReplyContentType] = useState(false);

    const [CommentPaging, setCommentPaging] = useState({
        ...pagingParam
    });


    // 加载中
    const [QuestionLoading, setQuestionLoading] = useState(false);
    const [CommentLoading, setCommentLoading] = useState(false);
    const [ReplyLoading, setReplyLoading] = useState(false);


    const addEventListenerF = () => {
        document.querySelector('#centerContentBox').addEventListener("scroll",useFn);
    }

    const removeEventListenerF = () => {
        document.querySelector('#centerContentBox').removeEventListener("scroll", useFn);
    }

    const handleLoadMore = () => {
        // 为测试效果临时使用 message
        console.log("触底了");
        removeEventListenerF();
        // setQuestionLoading(true);
        setCommentPaging({
            "pageIndex":CommentPaging.pageIndex+1,
            "pageSize":10,
        });
    };

    /**
     * 判断是否触底
     * 此函数进行判断是否触底
     * @param    handler  必填  判断后执行的回调函数
     * @returns  null
     */
    const isTouchBottom = (handler) => {

        let scrollTop = Math.ceil(document.querySelector('#centerContentBox').scrollTop);
        // console.log('【滚动距离】', scrollTop);
        let ch = document.querySelector('#centerContentBox').clientHeight;
        // console.log('【可视区域】', ch);
        let sh = document.querySelector('#centerContentBox').scrollHeight;
        // console.log('【滚动条高度】', sh);
        if((scrollTop + ch) >= sh){
            handler();
        }
    };

    const useFn = () => {
        // 此处调用 加载更多函数
        isTouchBottom(handleLoadMore);
    };

    useEffect(()=>{
        getCommentList();
    },[knowledgeDetail.id, CommentQueryType, CommentPaging])

    const TabsOnchange = (activeKey) => {
        setCommentPaging({
            "pageIndex":1,
            "pageSize":10,
        });
        setCommentQueryType(activeKey)
    }

    const getCommentList = async() => {
        setQuestionLoading(true);
        try{
            let res = await Api.Knowledge.getCommenPageList({data:{
                "knowledgeId":knowledgeDetail.id,
                "queryType":CommentQueryType,
                ...CommentPaging,
                "type" : getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策）
            }});
            setQuestionLoading(false);
            if(res && res.code === 0&&res.data){
                let _data = res.data,_CommentList = [];
                let _records = JSON.stringify(_data.records);
                // 获取评论总数
                setDiscussTotals();
                // 设置分页
                setCommentPagination({
                    total:_data.total,
                    size:_data.size,
                    pages:_data.pages,
                    remaining:_data.total - (_data.pages*_data.size),
                })
                if(CommentPaging.pageIndex === 1){
                    _CommentList = [...JSON.parse(_records)]
                }else{
                    _CommentList = [...CommentList,...JSON.parse(_records)]
                }
                // 设置评论数据
                setCommentList(_CommentList);

                if(_data.total > _CommentList.length){
                    addEventListenerF();
                }else{
                    removeEventListenerF();
                }
            }else{}
        }catch{
            setCommentList([])
        }
    }

    // 提问
    const changeQuestion = () => {
        setParentId('0');
        setReplyCommentUserId('');
        setQuestionBoxType(true);
    }

    const onCancel = () => {
        setConfirmLoading(false);
        setQuestionBoxType(false);
        setReplyContentType(false);
    }

    const onOk = async() => {
        setConfirmLoading(true);
        try {
            const row = await addForm.validateFields();
            let res = await Api.Knowledge.addComment({data:{
                ...row,
                "knowledgeId":knowledgeDetail.id,
                "parentId":parentId,
                "replyCommentUserId":replyCommentUserId,
                "type" : getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策）
            }});
            setConfirmLoading(false);
            if(res && res.code === 0){
                onCancel();
                setCommentPaging({
                    "pageIndex":1,
                    "pageSize":10,
                });
                // 获取评论总数
                setDiscussTotals();
                message.success("发布成功");
            }else{}
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }
    // 回复
    const onRelease = (item) => {
        console.log(item,"itemitemitemitem")
        setParentId(item.id);
        setReplyCommentUserId(item.commentUserId);
        setReplyContentType(true);
    }
    const onProbe = (item,parentItem) => {
        
        if(parentItem){
            setParentId(parentItem.id);
        }else{
            console.log(item,"itemitemitemitem")
            setParentId(item.id);
        }
        
        setReplyCommentUserId(item.commentUserId);
        setReplyContentType(true);
    }

    const onLike = async(item) =>{
        try {
            let res = await Api.Knowledge.likeComment({data:{
                "id":item.id,
                "likeType":item.isLike ? 0 : 1,
                "type" : getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策）
            }});
            if(res && res.code === 0){
                // getCommentList();
                setCommentPaging({
                    "pageIndex":1,
                    "pageSize":10,
                });
            }else{}
        } catch (errInfo) {}
    }

    const getMoreAnswer = async(id,item,commentNum) => {
        
        let pageIndex = 1;
        // 处理事第几页
        if(commentNum > item.knowledgeCommentSonVOList.length){
            let currentIndex = Math.floor(item.knowledgeCommentSonVOList.length/10)
            pageIndex = currentIndex + 1
        }

        try {
            let res = await Api.Knowledge.getCommentAnswerByAnswerId({data:{
                "id":id,
                "pageIndex":pageIndex,
                "pageSize":"10",
                "type" : getSearchParams().type === 'policy' ? 1 : 0 //0知识 1政策）
            }});
            
            if(res && res.code === 0&&res.data){
                // 获取评论总数
                setDiscussTotals();
                let _CommentList = [...CommentList], _data = res.data.records;

                if(item.knowledgeCommentSonVOList.length<=2){
                    _data = _data.slice(item.knowledgeCommentSonVOList.length)
                }

                let assemblyDataList = await assemblyData(_CommentList,_data,id);

                setCommentList(_CommentList);
                setCommentLoading(false);
                setReplyLoading(false);
            }else{}
        } catch (errInfo) {
            setCommentLoading(false);
            setReplyLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const assemblyData = async(commentList,data,id) => {
        
        for(let i=0; i<commentList.length; i++){
            if(commentList[i].id === id){
                commentList[i].knowledgeCommentSonVOList=[
                    ...commentList[i].knowledgeCommentSonVOList,
                    ...data
                ];
                return commentList
            }else if(commentList[i].knowledgeCommentSonVOList && commentList[i].knowledgeCommentSonVOList.length>0){
                assemblyData(commentList[i].knowledgeCommentSonVOList,data,id);
            }
        }
    }


    return(
        <div>
            <div className="discussionBox">
                <div className="askQuestion">
                    {/* <Image
                        imgSrc={"132"}
                        iClassName="headSculpture"
                    /> */}
                    <div className={"headSculpture"}>
                        {userInfo && userInfo.userName && userInfo.userName.slice(0, 1)}
                    </div>
                    {/* commentUserName */}
                    <div className={"questionButton"} onClick={changeQuestion}>有疑问？立即求教！</div>
                    {/* <Input placeholder="有疑问？立即求教！" onClick={changeQuestion}/> */}
                </div>
                <div className="discussionOperation">
                    <span>{discussTotal}</span>条讨论
                    <Segmented options={items} defaultValue={0} onChange={TabsOnchange}/>
                </div>
                {/* <div>
                    <Tabs items={items} onChange={TabsOnchange}/>
                </div> */}

                {CommentList?CommentList.map(item=>{
                    return(<div key={item.id}>

                        <div className="clearfix CommentItem">
                            <Iconfont type={'icon-icon_tiwen'}/>
                            <div className="commentContent">
                                <div className="title">{item.commentTitle}</div>
                                <div className="commentMessage">{item.commentMessage}</div>
                                <div className="commentBotton">
                                    <span>{item.commentUserName}</span>
                                    <span>{item.commentTime}</span>
                                    <div className="commentRight">
                                        <Space>
                                            <span className="pointer" onClick={()=>{onRelease(item)}}>
                                                <Iconfont type={'icon-taolun'}/>回答
                                            </span>
                                            <span className="pointer" onClick={()=>{onLike(item)}}>
                                                <LikeOutlined style={item.isLike?{color:"#FF3320"}:{}}/>
                                                {item.commentLikeNum}
                                            </span>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {item.knowledgeCommentSonVOList?item.knowledgeCommentSonVOList.map(items=>{
                            // console.log(items,"itemsitemsitemsitems")
                            return(<div key={items.id}>

                                <div className="clearfix CommentItem CommentItemReply">
                                    {items.commentUserUrl?(
                                    <Avatar className="headSculpture" src={items.commentUserUrl} />
                                    ):(
                                        <div className={"headIcon"}>
                                            {items.commentUserName && items.commentUserName.slice(0, 1)}
                                        </div>
                                    )}
                                    
                                    <div className="commentContent">
                                        <div className="titleChild">{items.commentUserName}{items.replyCommentUserIsAuthor?"(作者)":""}</div>
                                        <div className="commentMessage">{items.replyCommentMessage}</div>
                                        <div className="commentBotton">
                                            <span>{items.replyCommentTime}</span>
                                            <div className="commentRight">
                                                <Space>
                                                    <span className="pointer" onClick={()=>{onProbe(items)}}><Iconfont type={'icon-taolun'}/>追问</span>
                                                    <span className="pointer" onClick={()=>{onLike(items)}}>
                                                        <LikeOutlined style={items.isLike?{color:"#FF3320"}:{}}/>
                                                        {/* <Iconfont type={'icon-dianzan'} iStyle={items.isLike?{color:"#FF3320"}:{}}/> */}
                                                        {items.commentLikeNum}
                                                    </span>
                                                    {/* <LikeOutlined onClick={()=>{onLike(items)}} style={items.isLike?{color:"rgb(240, 209, 85)"}:{}}/>{items.commentLikeNum} */}
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    {items.knowledgeCommentSonVOList?items.knowledgeCommentSonVOList.map(replyItems=>{
                                        return(
                                            <div key={replyItems.id} className="clearfix CommentItem CommentItemReplys">
                                                {replyItems.commentUserUrl?(
                                                    <Avatar className="headSculpture" src={replyItems.commentUserUrl} />
                                                ):(
                                                    <div className={"headIcon"}>
                                                        {replyItems.commentUserName && replyItems.commentUserName.slice(0, 1)}
                                                    </div>
                                                )}
                                                
                                                <div className="commentContent">
                                                    <div className="titleChild">{replyItems.commentUserName}
                                                        <Iconfont type={'icon-right'}/>
                                                        {replyItems.replyCommentUserName}{replyItems.replyCommentUserIsAuthor?"(作者)":""}
                                                    </div>
                                                    <div className="commentMessage">{replyItems.replyCommentMessage}</div>
                                                    <div className="commentBotton">
                                                        <span>{replyItems.replyCommentTime}</span>
                                                        <div className="commentRight">
                                                            <Space>
                                                                <span className="pointer" onClick={()=>{onProbe(replyItems,items)}}><Iconfont type={'icon-taolun'}/>回答</span>
                                                                <span className="pointer" onClick={()=>{onLike(replyItems)}}>
                                                                    <LikeOutlined style={replyItems.isLike?{color:"#FF3320"}:{}}/>
                                                                    {/* <Iconfont type={'icon-dianzan'} iStyle={replyItems.isLike.toString() === "true"?{color:"#FF3320"}:{color:"#414141"}}/> */}
                                                                    {replyItems.commentLikeNum}
                                                                </span>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }):""}
                                    {items.commentNum - (items.knowledgeCommentSonVOList.length) > 0?
                                    ReplyLoading ? <Spin size="small" /> :(
                                        <div className="probe"
                                            onClick={()=>{
                                                setReplyLoading(true);
                                                getMoreAnswer(items.id,items,items.commentNum);
                                            }}
                                        >
                                        <Iconfont type={'icon-xiala'} iStyle={{marginRight:10}}/>
                                        {Math.floor(items.knowledgeCommentSonVOList.length/10) === 0 ? 
                                        "查看全部追问":"查看更多"}
                                        </div>
                                    ):""}

                            </div>)
                        }):""}

                        {item.commentAnswerNum - (item.knowledgeCommentSonVOList.length) > 0 ?
                        CommentLoading ? <div className="example"><Spin size="small" /></div> :
                        (
                            <div className="moreAnswer"
                                onClick={()=>{
                                    setCommentLoading(true);
                                    getMoreAnswer(item.id,item,item.commentAnswerNum);
                                }}
                            >
                            <Iconfont type={'icon-xiala'} iStyle={{marginRight:10}}/>
                            {Math.floor(item.knowledgeCommentSonVOList.length/10) === 0 ? 
                                `展开全部回答（${item.commentAnswerNum - (item.knowledgeCommentSonVOList.length)}）`
                            : "查看更多"}
                            </div>
                        )
                        :""}

                    </div>)
                }):""}
                <div className="bottomText">{QuestionLoading?<div className="example"><Spin size="small" /></div>:"到底了"}</div>
            </div>

            <Modal 
                title="提问" 
                open={QuestionBoxType} 
                onOk={onOk} 
                onCancel={onCancel}
                centered={true}
                maskClosable={false}
                confirmLoading={ConfirmLoading}
                destroyOnClose={true}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 21 }}
                    preserve={false}
                    requiredMark={"Required"}
                >
                    <Form.Item
                        key={'commentTitle'}
                        name={'commentTitle'}
                        label={'标题'}
                        required
                        rules={[
                            // { required:  true, message: `请输入标题!` },
                            () => ({
                                validator(_, value) {
                                  if (value && value.trim()) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("请输入标题!")
                                  );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        key={'commentMessage'}
                        name={'commentMessage'}
                        label={'内容'}
                        required
                        rules={[
                            // { required:  true, message: `请输入内容!` },
                        () => ({
                            validator(_, value) {
                              if (value && value.trim()) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("请输入内容!")
                              );
                            },
                        }),]}
                    >
                        <TextArea rows={"5"} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal 
                title="回复" 
                open={ReplyContentType} 
                onOk={onOk} 
                onCancel={onCancel}
                maskClosable={false}
                confirmLoading={ConfirmLoading}
                destroyOnClose={true}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'commentMessage'}
                        name={'commentMessage'}
                        label={''}
                        rules={[
                            // { required:  true, message: `请输入内容!` },
                            () => ({
                                validator(_, value) {
                                  if (value && value.trim()) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("请输入内容!")
                                  );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default DiscussBox;