/*
    * 菜单
*/ 
import myAxios from '../utils/request.js';

export default {
    // 查询所有菜单列表
    async getAllMenuListTree (params={}, cb) {
        let res = await myAxios.getJson('/sysPermissions/listAllMenuTree', params);
        return res.data;
    },
    // 查询菜单列表
    async getMenuListTree (params={}, cb) {
        let res = await myAxios.getJson('/sysPermissions/listMenuTree', params);
        return res.data;
    },
    // 新增菜单
    async addMenu (params={}, cb) {
        let res = await myAxios.postJson('/sysPermissions/insert', params);
        return res.data;
    },
    // 删除菜单
    async deleteMenu (params={}, cb) {
        let res = await myAxios.deleteJson('/sysPermissions/delete', params);
        return res.data;
    },
    // 修改菜单
    async updateMenu (params={}, cb) {
        let res = await myAxios.postJson('/sysPermissions/update', params);
        return res.data;
    },
}