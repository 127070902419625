import React, { useState, useEffect} from 'react';
import {Layout, Tooltip, Spin, message, Button} from "antd";


import {zuStandStore} from "../../../../../zustand";
import './index.scss';

const AiBase = () => {
    const changeIsShowAi = zuStandStore(state => state.changeIsShowAiChat);
    const notYetOpen = () => {
        message.info("暂未开放")
    }

    return (
        <div className="aiBaseFrame">
            <div className="backgroundBox">
                <span className="entryBtn" onClick={
                    () => changeIsShowAi(true)
                    // () => notYetOpen()
                }>立即提问</span>
            </div>
        </div>
    )
}

export default AiBase;
