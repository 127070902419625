import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import {Tooltip, Modal, Form, Input, message, Tree, Empty, Spin} from "antd";

import Iconfont from "../../../../../components/Iconfont/Iconfont";
import Api from "../../../../../api";
import {ModalConfirm} from '../../../../../utils/tool'

import "./index.scss";

const knowledgeSystemManage = () => {
    const [addForm] = Form.useForm();
    const [expandKeyObject, setExpandKeyObject] = useState({});
    const [systemList, setSystemList] = useState([]);
    const [width, setWidth] = useState(0);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false); // modal弹窗是否显示
    const [modifyDate, setModifyData] = useState(false); // 修改时的数据
    const [currentSystemId, setCurrentSystemId] = useState('');
    const [ConfirmLoading, setConfirmLoading] = useState(false);
    const [selectedKeys,setSelectedKeys] = useState("");
    const [modalLoading, setModalLoading] = useState(false); // 弹窗内容加载中


    useEffect(() => {
        getList();

        // 添加窗口变化监听回调函数
        window.addEventListener('resize', resizeCallBack);
        return () => {
            // 移出窗口变化监听
            window.removeEventListener('resize', resizeCallBack)
        }
    }, [])

    // 窗口变化监听回调
    function resizeCallBack() {
        const domList = document.getElementsByClassName("systemListBox")
        if (domList && domList[0]) {
            setWidth(domList[0].clientWidth)
        }
    }

    // 跳转页面
    function toPage(url, isReplace) {
        isReplace ? history.replace(url) : history.push(url);
    }

    // 

    async function getList() {
        setModalLoading(true);
        try{
            const res = await Api.Note.getKnowledgeNoteSystemList({data:{}});
            setModalLoading(false);
            if(res&&res.code === 0 && res.data){
                // 默认全部展开
                let expandKey = {};
                for (let item of res.data) {
                    expandKey[item.knowledgeNoteSystemId] = 1;
                }
                setExpandKeyObject(expandKey);
                setSystemList(res.data);
                resizeCallBack();
            }else{
                setSystemList([]);
            }
        }catch(errorInfor){setModalLoading(false);}
    }

    // 修改分类名称
    function changeSortName(knowledgeNoteSystemName, sortId) {
        addForm.setFieldValue('knowledgeNoteSystemName',knowledgeNoteSystemName);
        setModifyData({
            knowledgeNoteSystemName, 
            id:sortId
        });
        setIsOpen(true);
    }

    // 删除分类
    function deleteSort(sortId) {
        ModalConfirm({
            title: `您确认要删除这条数据吗?`,
            onOk:async(resolve, reject)=> {
                try{
                    const res = await Api.Note.removeKnowledgeNoteSystemById({data:{
                        knowledgeNoteSystemManagementId:sortId
                    }});
                    if(res&&res.code === 0){
                        getList();
                        onCancel();
                        resolve();
                        message.success("删除成功");
                    }else{
                        reject();
                    }
                }catch{}
            },
        })
    }

    // 移动体系到指定分类
    function moveSystemToSort(systemId) {
        setCurrentSystemId(systemId);
    }
    const onSelect = (selectedKeys,e) => {
        setSelectedKeys(selectedKeys[0]);
    }
    const handleOk = async() => {
        if(!selectedKeys){
            message.info("选择移动知识体系的目标位置");
            return false;
        }
        try {
            setConfirmLoading(true);
            let res = await Api.Note.moveNewKnowledgeNoteSystem({
                data: { 
                    knowledgeNoteSystemManagementId:currentSystemId,
                    knowledgeNoteSystemId:selectedKeys
                 },
            });
            setConfirmLoading(false);
            if(res && res.code === 0){
                getList();
                message.success("移动成功");
                handleCancel()
            }else{}
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };
    const handleCancel = () => {
        setCurrentSystemId("");
        setSelectedKeys("")
    };

    // 删除体系
    async function deleteSystem(systemId) {
        ModalConfirm({
            title: `您确认要删除这条数据吗?`,
            onOk:async(resolve, reject)=> {
                try{
                    const res = await Api.Note.deleteKnowledgeNoteSystemById({data:{
                        knowledgeNoteSystemManagementId:systemId
                    }});
                    if(res&&res.code === 0){
                        getList();
                        resolve();
                        message.success("删除成功");
                    }else{
                        reject();
                    }
                }catch{}
            },
        })
    }
    
    function newCategory() {
        setIsOpen(true);
    }

    function closeModalFunc () {

    }

    function onCancel () {
        setIsOpen(false);
        addForm.setFieldValue('knowledgeNoteSystemName',"");
        setModifyData("")
    }

    async function onOk () {
        try {
            setConfirmLoading(true);
            const row = await addForm.validateFields();
            setConfirmLoading(false);
            if(modifyDate){
                row.id = modifyDate.id
                const res = await Api.Note.updateKnowledgeNoteSystem({data:row});
                if(res&&res.code === 0){
                    getList();
                    onCancel();
                }
            }else{
                const res = await Api.Note.addKnowledgeNoteSystem({data:row});
                if(res&&res.code === 0){
                    getList();
                    onCancel();
                }
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }
    
    
    return (
        <div className="knowledgeSystemManageFrame">
            {/* header */}
            <div className="headerBox">
                <span className="title">知识体系管理</span>
                <Tooltip placement={'left'} title={"新建分类"}>
                    <span className="addSortBtn"
                        onClick={newCategory}
                    >
                        <Iconfont type={"icon-roundadd"} />
                    </span>
                </Tooltip>
            </div>

            {/* content */}
            <div className="contentBox">
            <Spin tip="数据加载中" spinning={modalLoading}>
                {
                    systemList&&systemList.length>0?systemList.map((item) => {
                        const {knowledgeNoteSystemId: sortId, knowledgeNoteSystemName, knowledgeNoteSystemDetailVOList} = item;
                        let sortList = [...knowledgeNoteSystemDetailVOList];
                        const isExpand = expandKeyObject[sortId];
                        let _width = width <= 0 ?document.querySelector(".contentBox").clientWidth: width
                        const oneRowNumber = Math.floor((_width - 20) / 260);
                        if (oneRowNumber >= 2) {
                            const sortLen = sortList.length;
                            let remainder = sortLen % oneRowNumber;
                            let complementArr = [];
                            for (let i = 0; i < (oneRowNumber - remainder); i++) {
                                complementArr.push({
                                    id: `remainder-${i}`,
                                    isRemainder: true,
                                })
                            }
                            complementArr[0].isAdd = true;
                            sortList.push(...complementArr);
                        }
                        const rowNum = Math.ceil(sortList.length / oneRowNumber);
                        const maxHeight = rowNum * 390;
                        return (
                            <div className="sortItem" key={sortId}>
                                {/* 体系信息 & 操作 */}
                                <div className="infoAndOperateBox">
                                    {/* 体系信息 */}
                                    <div className="sortInfoBox">
                                        <span className="sortName">{knowledgeNoteSystemName}</span>
                                        <span
                                            className={`expandSortBtn ${isExpand ? 'expandStatus' : ''}`}
                                            onClick={() => {
                                                let expandKey = {...expandKeyObject, [sortId]: 1};
                                                if (isExpand) {
                                                    delete expandKey[sortId]
                                                }
                                                setExpandKeyObject(expandKey)
                                            }}
                                        ><Iconfont type={"icon-right"} /></span>
                                    </div>

                                    {/* 操作 */}
                                    <div className="sortOperateBox">
                                        <Tooltip placement={'top'} title={"重命名"}>
                                            <span
                                                className="operateBtn editSortBtn"
                                                onClick={() => changeSortName(knowledgeNoteSystemName, sortId)}
                                            ><Iconfont type={"icon-edit1"} /></span>
                                        </Tooltip>
                                        <Tooltip placement={'top'} title={"删除分类"}>
                                            <span
                                                className="operateBtn deleteSortBtn"
                                                onClick={() => deleteSort(sortId)}
                                            ><Iconfont type={"icon-delete"} /></span>
                                        </Tooltip>
                                        <Tooltip placement={'top'} title={"新建体系"}>
                                            <span
                                                className="operateBtn addSystemBtn"
                                                onClick={()=> toPage(`/page/knowledgeSystemOperate?sortId=${sortId}`)}
                                            ><Iconfont type={"icon-roundadd"} /></span>
                                        </Tooltip>
                                    </div>
                                </div>
                                {/* 体系分类列表 */}
                                <div className="systemListBox" style={isExpand?{}:{maxHeight: 0}}>
                                    {
                                        sortList.map(it => {
                                            const {knowledgeNoteSystemManagementId: systemId, knowledgeNoteSystemUrl, knowledgeNoteSystemTitle, isRemainder, isAdd} = it;
                                            let contentDom = (
                                                <div className="systemItem" key={systemId} style={{visibility: (isRemainder && !isAdd) ? 'hidden' : ''}}>
                                                    <div className="systemImg">
                                                        <img src={knowledgeNoteSystemUrl} alt=""/>
                                                    </div>
                                                    <div className="systemName">
                                                        <span>{knowledgeNoteSystemTitle}</span>
                                                    </div>
                                                    <div className="systemOperateBox">
                                                        <span
                                                            className="operateBtn editSystemBtn"
                                                            onClick={()=> toPage(`/page/knowledgeSystemOperate?systemId=${systemId}`)}
                                                        >
                                                            <Iconfont type={"icon-edit1"} />
                                                            <span>编辑</span>
                                                        </span>
                                                        <span
                                                            className="operateBtn moveSystemBtn"
                                                            onClick={() => moveSystemToSort(systemId)}
                                                        >
                                                            <Iconfont type={"icon-move"} />
                                                            <span>移动</span>
                                                        </span>
                                                        <span
                                                            className="operateBtn deleteSystemBtn"
                                                            onClick={() => deleteSystem(systemId)}
                                                        >
                                                            <Iconfont type={"icon-delete"} />
                                                            <span>删除</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                            if (isAdd) {
                                                contentDom = (
                                                    <div className="systemItem addSystemBox" key={systemId}>
                                                        <Tooltip placement={'top'} title="新建体系">
                                                             <span
                                                                 className="operateBtn"
                                                                 onClick={()=> toPage(`/page/knowledgeSystemOperate?sortId=${sortId}`)}
                                                             >
                                                                 <Iconfont type={"icon-roundadd"} />
                                                             </span>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            }
                                            return contentDom;
                                        })
                                    }
                                    {/* <div className="systemItem addSystemBox">
                                        <Tooltip placement={'top'} title="新建体系">
                                                <span
                                                    className="operateBtn"
                                                    onClick={()=> toPage(`/page/knowledgeSystemOperate?sortId=${sortId}`)}
                                                >
                                                    <Iconfont type={"icon-roundadd"} />
                                                </span>
                                        </Tooltip>
                                    </div> */}
                                </div>
                            </div>
                        )
                    }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                </Spin>
            </div>
            {/* Modal弹窗 */}
            <Modal
                open={isOpen}
                width={width}
                wrapClassName={"selfDesignModal"}
                centered={true}
                closable={true}
                maskClosable={false}
                title={modifyDate?"修改分类":"新建分类"}
                onCancel={() => onCancel()}
                onOk={() => onOk()}
                width={500}
                confirmLoading={ConfirmLoading}
            >
                <div className="modalContentBox">
                    <Form
                        form={addForm}
                        component={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        preserve={false}
                    >
                        <Form.Item
                            key={'knowledgeNoteSystemName'}
                            label={'类型名称'}
                            name={'knowledgeNoteSystemName'}
                            rules={[{ required: true, message:`知识类型名称!` }]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Modal 
                title="移动分类" 
                open={currentSystemId} 
                onOk={handleOk} 
                onCancel={handleCancel}
                confirmLoading={ConfirmLoading}
            >
                <p>选择移动知识体系的目标位置</p>
                <div
                    style={{
                        padding: '1rem',
                        background: '#ffffff'
                    }}
                >
                    <Tree
                        onSelect={onSelect}
                        treeData={systemList}
                        selectedKeys={[selectedKeys]}
                        fieldNames={{
                            title:"knowledgeNoteSystemName",
                            key:"knowledgeNoteSystemId",
                            children:"childList"
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default knowledgeSystemManage;