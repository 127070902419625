import React, { useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";

import './index.scss';

const KnowledgeExploration = () => {
    const historyInstance = useHistory();

    const goPage = (path) => {
        historyInstance.push(path)
    }

    return (
        <div className="knowledgeExplorationFrame">
            <div className="backgroundBox">
                <span className="entryBtn" onClick={() => goPage("/page/knowledgeCentre")}>进入知识中心</span>
            </div>
        </div>
    )
}

export default KnowledgeExploration;
