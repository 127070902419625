import React, {useState, useEffect, useRef, useContext} from "react";
import {Layout, Menu, Upload, message, Tooltip} from "antd";
import {
    BrowserRouter as Router,
    useHistory,
    Link,
    useLocation,
} from "react-router-dom";
import {LoadingOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";


import Iconfont from "../../../components/Iconfont/Iconfont.js";
import MyUpload from "../components/MyUpload.js";
import storage from "../../../utils/storage";
import {personalChildren} from "../../../routesConfig";
import Api from "../../../api";
import "./layout.scss";


const {Sider, Content} = Layout;
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};
import propsContext from './context';

const PersonalCenterLayoutBox = (props) => {
    const {children, match} = props;
    const containerRef = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const userinfo = storage.getItem("userInfo");
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(userinfo?.fileUrl); // 默认头像
    const [modalFlag, setModalFlag] = useState(false);
    const [modalFlag2, setModalFlag2] = useState(false);
    const [CurrentMenuKey, setCurrentMenuKey] = useState("");
    const [openKey, setOpenKey] = useState([]);
    const [currentPath, setCurrentPath] = useState("");
    const acceptedFileTypes = "image/png,image/jpeg,image/svg+xml,image/webp";
    let modalList = [
        {id: 1, title: "上传文档", icon: "icon-icon-cloud-upload"},
        {id: 2, title: "在线写作", icon: "icon-wenben"},
        {id: 3, title: "流程地图", icon: "icon-gongyiluxian"},
    ];

    const [uswerDept, setUswerDept] = useState("");


    useEffect(() => {

        getUswerDept();

        // 在文档对象上绑定点击事件处理程序
        const handleOutsideClick = (event) => {
            if (!containerRef.current.contains(event.target)) {
                setModalFlag(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        // 展开当前路径所在菜单
        let openKeyList = []
        for (const item of personalChildren) {
            const {children, path} = item;
            if (children?.length > 0) {
                let flag = false;
                for (let child of children) {
                    if (child.path === location.pathname) {
                        flag = true;
                        openKeyList = [path];
                        break
                    }
                }
                if (flag) {
                    break;
                }
            }
        }
        setOpenKey(openKeyList)
    }, [currentPath]);
    useEffect(() => {
        if (location.pathname === "/personalCenter") {
            setCurrentMenuKey("/personalCenter/myHome");
        } else {
            setCurrentMenuKey(location.pathname);
        }
    }, [location.pathname]);

    

    const getUswerDept = async () => {
        const res = await Api.Department.getUswerDept({});
        if (res && res.code == 0) {
            setUswerDept(res.data.seNames.join('，'))
        }
    };

    // 头像上传-文件上传获取fileId
    const handleChange = async (file) => {
        // console.log(file, "上传头像文件");
        if (file) {
            let fileTypeIsOk = acceptedFileTypes.includes(file.type);
            if (!fileTypeIsOk) {
                message.error("仅支持上传 PNG、JPEG 、WEBP 和 SVG 图片文件！")
                return false
            }
            console.log(file, "filefilefile")
            const formData = new FormData();
            formData.append("file", file);
            const res = await Api.Common.uploadFile({
                data: formData,
            });
            if (res && res.code == 0) {
                avaHandeleChange(res.data.id, res.data.url);
            }
        }
    };
    // 编辑头像
    const avaHandeleChange = async (fileId, url) => {
        try {
            let res = await Api.User.editAvatar({data: {fileId}});
            if (res && res.code == 0) {
                setImageUrl(url);
                let _userinfo = userinfo;
                _userinfo.fileUrl = url;
                storage.setItem("userInfo", _userinfo);
                message.success("头像上传成功");
            } else {
                message.error("上传失败，请重试");
            }
        } catch (error) {
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                暂无头像,请上传
            </div>
        </div>
    );
    const toOperate = (id) => {
        setModalFlag(false);
        if (id === 1) setModalFlag2(true);
        else if (id === 2) {
            storage.removeItem("knowledgeDetailInfo");
            let url = `/page/onLineWrite`
            window.open(url);
            // history.push("/page/onLineWrite");
        } else {
            message.info("暂未开放");
            // history.push("/personalCenter/myNote/noteManage");
        }
    };
    const iconStyle = {
        fontSize: "25px",
        marginRight: "15px",
    };

    function getItem(label, key, icon, children, disabled) {
        return {
            key,
            icon,
            children,
            label,
            disabled
        };
    }

    const menuRender = (list) => {
        return list.map((item) => {
            const {redirect, name, path, icon, children} = item;
            return getItem(
                redirect ? (name) : (<Link to={path} onClick={() => setCurrentMenuKey(path)}>{name}</Link>),
                path,
                icon ? (<Iconfont type={icon} iStyle={iconStyle}></Iconfont>) : null,
                children?.length > 0 ? menuRender(children) : null,
                ["/personalCenter/myEquity/pointsMall", "/personalCenter/myGrowth"].includes(path)
            );
        });
    };
    let items = menuRender(personalChildren);

    useEffect(() => {
        // 路由改变的时候获取当前菜单应该展开的key
        changeOpenKey(window.location.pathname)
        // 路由监听
        history.listen((path, action) => {
            changeOpenKey(path.pathname)
        });
    }, []);
    const changeOpenKey = (pathname) => {
        let _path = pathname.split("/");
        setOpenKey([`/${_path[1]}/${_path[2]}`]);
    }

    // const getOpenKeys = (openkey) => {
    //   const latestOpenKey = openkey.find((key) => openKey.indexOf(key) === -1);
    const getOpenKeys = (keyList) => {
        const latestOpenKey = keyList.find((key) => !openKey.includes(key));
        setOpenKey(latestOpenKey ? [latestOpenKey] : []);
    };
    const menuOnClick = (values) => {
        if (values.keyPath?.length < 2) {
            setOpenKey([])
        }
        if (values.key === "/personalCenter/myEquity/pointsMall") {
            history.replace(location.pathname);
            message.info("暂未开放");
        }
        if (values.key === "/personalCenter/myGrowth") {
            history.replace(location.pathname);
            message.info("暂未开放");
        }
    };

    return (
        <Layout>
            <Sider width="14.1rem" style={{marginRight: "0.5rem", background: "none"}} theme="light">
                <div className="leftBox">
                    {/* <div className="bg"></div> */}
                    <div className="topBox">
                        <div className="user">
                            <div className="ava">
                                <Upload
                                    name="avatar"
                                    accept="image/png,image/jpeg,image/svg+xml,image/webp"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={handleChange}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            // alt="avatar"
                                            style={{
                                                width: "110%",
                                                height: "110%"
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </div>

                            <div className="info">
                                <div>{userinfo?.userName}</div>
                                <span>{userinfo?.departmentName}</span>
                                <div style={{fontSize: '0.6rem', marginTop: 5}}>{uswerDept}</div>
                            </div>
                            <div className="uploadBtn" ref={containerRef}>
                                <Tooltip
                                    open={modalFlag}
                                    trigger={"click"}
                                    placement={"rightTop"}
                                    overlayClassName={"personalCenterTooltipBox"}
                                    title={(
                                        <div className="modal">
                                            {
                                                modalList.map((item) => {
                                                    const {id, icon, title} = item;
                                                    const isThree = String(id) === "3";
                                                    return (
                                                        <div
                                                            className={`modalBtn ${isThree ? "disabledBtn" : null}`}
                                                            key={id}
                                                            onClick={() => {
                                                                if (!isThree) {
                                                                    toOperate(id)
                                                                }
                                                            }}
                                                        >
                                                            <Iconfont type={icon}/>
                                                            <span>{title}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    )}
                                >
                                    <button
                                        style={{fontSize: "0.8rem", color: "#ff0003", cursor: "pointer"}}
                                        onClick={() => setModalFlag(!modalFlag)}
                                    >
                                        <UploadOutlined style={{marginRight: '10px', fontSize: "0.85rem"}}/>
                                        <span>上传知识</span>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className="menuBox">
                        <Menu
                            theme="light"
                            mode="inline"
                            items={items}
                            onOpenChange={getOpenKeys}
                            selectedKeys={[CurrentMenuKey]}
                            openKeys={openKey}
                            onClick={menuOnClick}
                        />
                    </div>

                </div>
            </Sider>
            <Content>
                <propsContext.Provider value={setCurrentPath}>
                    {children}
                </propsContext.Provider>
            </Content>
            {modalFlag2 ? (
                <MyUpload
                    modalFlag2={modalFlag2}
                    setModalFlag2={setModalFlag2}
                    uploadType={"knowledge"}
                ></MyUpload>
            ) : null}
        </Layout>
    );
};

export default PersonalCenterLayoutBox;
