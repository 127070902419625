import myAxios from '../utils/request.js';

export default {
    // 获取通知的未读数量
    async getStatusNum (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/getStatusNum', params);
        return res.data;
    },

    // 分页查询
    async getPageList (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/pageList', params);
        return res.data;
    },
    
    // 获取当前用户的消息配置开关
    async getNotificationSwitch (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/getNotificationSwitch', params);
        return res.data;
    },

    // 修改用户通知接收配置开关
    async updateNotificationSwitch (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/updateNotificationSwitch', params);
        return res.data;
    },

    // 根据条件获取当前用户的历史申请记录
    async getApplyPage (params={}, cb) {
        let res = await myAxios.postJson('/notificationManagement/applyPage', params);
        return res.data;
    },

    
}