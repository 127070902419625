import myAxios from '../utils/request.js';

export default {
    // 获取所有知识类型的ID及名称
    async getAllKnowledgeList (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeTypeManagement/getKnowledgerIdAndName', params);
        return res.data;
    },

    // 获取所有知识
    async getAllKnowledger (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeTypeManagement/getKnowledger', params);
        return res.data;
    },

    // 分页查询
    async getKnowledgeList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeTypeManagement/pageList', params);
        return res.data;
    },


    // 新增知识类型
    async addKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeTypeManagement/insert', params);
        return res.data;
    },
    
    
    // 删除知识类型
    async deleteKnowledge (params={}, cb) {
        let res = await myAxios.deleteJson('/knowledgeTypeManagement/delete', params);
        return res.data;
    },
    
    
    // 更新知识类型
    async updateKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeTypeManagement/update', params);
        return res.data;
    },

    // 判断当前知识是否可以需要审核
    async isNeedToReview (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/testKnowledgeId', params);
        return res.data;
    },

    /*
    * 知识审核
    */ 
    // 分页查询
    async getCheckList (params={}, cb) {
        let res = await myAxios.postJson('/checkManagement/pageList', params);
        return res.data;
    },
    // 新增
    async addCheck (params={}, cb) {
        let res = await myAxios.postJson('/checkManagement/insert', params);
        return res.data;
    },
    // 审核-同意或者拒绝
    async updateApply (params={}, cb) {
        let res = await myAxios.postJson('/checkManagement/updateApplyType', params);
        return res.data;
    },

    // 知识申请审核
    async reviewApply (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/knowledgeApplyFlow', params);
        return res.data;
    },
    /*
    * 知识中心
    */ 
    //    列表查询知识
    async getKnowledgePageList (params={}, cb) {
        let res = await myAxios.postJson('/knowledge/pageList', params);
        return res.data;
    },


    // 获取知识所属项目id和名称
    async getProjectIdAndName (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeProject/getProjectIdAndName', params);
        return res.data;
    },

    
    // 根据条件全文搜索知识库
    async getESSearch (params={}, cb) {
        let res = await myAxios.postJson('/ESKnowledgeController/fullTextSearch', params);
        return res.data;
    },


    
    // 获取政策主题id和名称
    async getPolicyeThemeList (params={}, cb) {
        let res = await myAxios.getJson('/policyTheme/getIdAndName', params);
        return res.data;
    },
    async addPolicyTheme (params={}, cb) {
        let res = await myAxios.postJson('/policyTheme/insert', params);
        return res.data;
    },
    async updatePolicyTheme (params={}, cb) {
        let res = await myAxios.postJson('/policyTheme/update', params);
        return res.data;
    },
    async deletePolicyTheme (params={}, cb) {
        let res = await myAxios.deleteJson('/policyTheme/deleteById', params);
        return res.data;
    },
    async getPolicyeKeyWards (params={}, cb) {
        let res = await myAxios.getJson('/policyTheme/getById', params);
        return res.data;
    },
    // 获取政策 国家、地方 等类型
    async getPolicyRegionList (params={}, cb) {
        let res = await myAxios.postJson('/policyRegion/getPolicyRegionList', params);
        return res.data;
    },
    // 获取政策数据列表
    async getPolicyPageList (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/policyPageList', params);
        return res.data;
    },
    // 删除政策主题
    async deletePolicyByPolicyId (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/deletePolicyByPolicyId', params);
        return res.data;
    },
    // 根据Id查询详细的政策主题信息
    async getByPolicyDetailBYPolicyId (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/getByPolicyDetailBYPolicyId', params);
        return res.data;
    },
    
    // 获取政策详情 - 新接口
    async getByPolicyDetail (params={}, cb) {
        let res = await myAxios.getJson('policyManagement/detailById', params);
        return res.data;
    },

    // 根据政策id获取预览地址
    async getPreviewUrlByPolicyId (params={}, cb) {
        let res = await myAxios.getJson('/files/getPreviewUrlByPolicyId', params);
        return res.data;
    },
    
    // 移动分类
    async moveKnowledgeType (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/moveKnowledgeToNewKnowledgeType', params);
        return res.data;
    },
    
    /*
    * 知识详情
    */ 
    //    知识详情
    async getKnowledgeDetailById (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/getKnowledgeDetailById', params);
        return res.data;
    },

    // 点赞
    async setKnowledgeLike (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/like', params);
        return res.data;
    },

    // 收藏
    async setKnowledgeCollection (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeCollectionManagement/collection', params);
        return res.data;
    },

    // 对政策进行点赞或收藏 id 政策id type 操作类型（0点赞 1收藏）
    async setPolicyLikeState (params={}, cb) {
        let res = await myAxios.getJson('/PolicyEsCenterController/operationPolicy', params);
        return res.data;
    },

    // 下载
    async onDownloadKnowledge (params={}, cb) {
        // let res = await myAxios.downloadJson('/knowledgeManagement/doDownload', params);
        let res = await myAxios.postJson('/knowledgeManagement/doDownload', params);
        return res;
    },

    // 判断是否是班子成员
    async ifTeamMembers (params={}, cb) {
        let res = await myAxios.getJson('/sysDepartment/getBanZi', params);
        return res.data;
    },


    // 保存阅读记录
    async saveReadRecord (params={}, cb) {
        let res = await myAxios.postJson('/userKnowledgeRead/saveReadRecord', params);
        return res.data;
    },

    // 用于三方服务前端生成预览编辑地址
    async getWPSFileEdit (params={}, cb) {
        let res = await myAxios.getJson(`/files/${params.fileId}/edit`, params);
        return res.data;
    },

    // 用于三方服务前端生成预览地址
    async getWPSFilePreview (params={}, cb) {
        let res = await myAxios.getJson(`/files/${params.fileId}/preview`, params);
        return res.data;
    },

     // 用于三方服务前端生成预览地址 - 参考研报
     async getWPSFileResearchReport (params={}, cb) {
        // let res = await myAxios.getJson(`/files/${params.fileId}/previewByResearchReportId`, params);
        let res = await myAxios.getJson(`/files/previewByResearchReportId`, params);
        return res.data;
    },

    // 新增标签信息
    async addKnowledgeLable (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteManagement/insert', params);
        return res.data;
    },

    // 分页查询标签
    async getKnowledgeLableList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteManagement/pageList', params);
        return res.data;
    },

    // 新增摘录内容
    async addExcerpt (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeExcerptManagement/addExcerpt', params);
        return res.data;
    },
    // 摘录分页查询
    async getExcerptList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeExcerptManagement/pageList', params);
        return res.data;
    },
    // 摘录删除
    async deleteExcerpt (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeExcerptManagement/deleteExcerptById', params);
        return res.data;
    },
    // 修改摘录
    async updateExcerpt (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeExcerptManagement/updateExcerpt', params);
        return res.data;
    },
   
    // 查询公开的笔记   
    async getPublicNodeList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteDetailManagement/searchKnowledgeNote', params);
        return res.data;
    },

    // 保存笔记
    async saveKnowledgeNote (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteDetailManagement/addNoteDetailMessage', params);
        return res.data;
    },

    // 共享笔记
    async shareNote (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeNoteDetailManagement/shareNote', params);
        return res.data;
    },

    // 获取笔记
    async getKnowledgeNoteList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteDetailManagement/pageList', params);
        return res.data;
    },

     // 删除笔记
     async deleteNoteDetailById (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeNoteDetailManagement/deleteNoteDetailById', params);
        return res.data;
    },

    async setNoteDetailLike (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeNoteDetailManagement/noteDetailLike', params);
        return res.data;
    },
    

    // 评论
    async addComment (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeCommentManagement/addComment', params);
        return res.data;
    },
    // 根据回复Id查询回复
    async getCommentAnswerByAnswerId (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeCommentManagement/getCommentAnswerByAnswerId', params);
        return res.data;
    },
    // 点赞评论
    async likeComment (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeCommentManagement/likeComment', params);
        return res.data;
    },
    // 获取评论列表
    async getCommenPageList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeCommentManagement/pageList', params);
        return res.data;
    },
    // 删除评论
    async removeComment (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeCommentManagement/removeComment', params);
        return res.data;
    },
    // 查询当前知识所有的评论数量
    async getTotalCommentNum (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeCommentManagement/getTotalCommentNum', params);
        return res.data;
    },


    // 翻译
    async getTransResult (params={}, cb) {
        let res = await myAxios.postJson('/translate/getTransResult', params);
        return res.data;
    },
    async getAllTranslation (params={}, cb) {
        let res = await myAxios.getJson('/translateInfo/getAllTranslation', params);
        return res.data;
    },
    
    async getLikeAndCollection (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeManagement/getCurrentKnowledgeIsLikeAndCollection', params);
        return res.data;
    },

    // 查看该政策是否点赞、收藏以及点赞量
    async getPolicyLikeAndCollection (params={}, cb) {
        let res = await myAxios.getJson('/PolicyEsCenterController/checkOperation', params);
        return res.data;
    },


    // 根据部门id查询知识类型子分类菜单树
    async getlistTreeByDepartmentId (params={}, cb) {
        let res = await myAxios.getJson('/departmentSubclassification/listTreeByDepartmentId', params);
        return res.data;
    },

    // 新增政策文件
    async addNewPolicy (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/addNewPolicy', params);
        return res.data;
    },
    // 站内搜索保存政策文件 
    async editPolicy (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/editPolicy', params);
        return res.data;
    },
    //   根据政策主题id获取信息--政策指标
    async getByPolicyThemeId (params={}, cb) {
        let res = await myAxios.getJson('/policyIndicator/getByPolicyThemeId', params);
        return res.data;
    },
    //  新增或者修改政策指标名称
    async savePolicyIndicator (params={}, cb) {
        let res = await myAxios.postJson('/policyIndicator/save', params);
        return res.data;
    },

    // 根据政策主题id生成预览地址 
    async getPolicyIndicatorPreviewUrl (params={}, cb) {
        let res = await myAxios.getJson('/getPolicyIndicatorPreviewUrl', params);
        return res.data;
    },
    // 根据政策主题id生成编辑地址
    async getPolicyIndicatorUrl (params={}, cb) {
        let res = await myAxios.getJson('/getPolicyIndicatorUrl', params);
        return res.data;
    },


    // 查询所有有审核权限的业务角色名称
    async selectNameByCheckPerm (params={}, cb) {
        let res = await myAxios.getJson('/businessRoles/selectNameByCheckPerm', params);
        return res.data;
    },
    
    // 根据ApplyId获取该申请的审批流程详情
    async getWorkflowDetailByApplyId (params={}, cb) {
        let res = await myAxios.getJson('/checkWorkflowRole/getWorkflowDetailByApplyId', params);
        return res.data;
    },
    
    // 根据checkId获取 审核流程详情（不包含后续未进行的流程信息）
    async getWorkflowDetailByCheckId (params={}, cb) {
        let res = await myAxios.getJson('/checkWorkflowRole/getWorkflowDetailByCheckId', params);
        return res.data;
    },
    // 根据知识id、规则下载文件
    async downByKnowledgeId (params={}, cb) {
        let res = await myAxios.downloadGetJson('/FileDownController/downByKnowledgeId', params);
        return res.data;
    },


    async getProjectList (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeProject/pageList', params);
        return res.data;
    },

    // 手动添加项目
    async addProject (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeProject/insert', params);
        return res.data;
    },

    // 导入项目
    async importProject (params={}, cb) {
        let res = await myAxios.axiosFunc({
            url: "/knowledgeProject/importProject",
            method: "post",
            headers: {
              //可加可不加
              "Content-Type": "multipart/form-data;",
            },
            ...params,
        });
        return res.data;
    },

    // 修改项目
    async updateProject (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeProject/update', params);
        return res.data;
    },

    // 删除项目
    async deleteProject (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeProject/delete', params);
        return res.data;
    },

    // 获取项目清单模板
    async getProjectTemplate (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeProject/getProjectTemplate', params);
        return res.data;
    },
    


    async getInventoryPage (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/inventoryPage', params);
        return res.data;
    },

    async getExportInventory (params={}, cb) {
        let res = await myAxios.postJson('/knowledgeManagement/exportInventory', params);
        return res.data;
    },


    // 根据知识类型id查询子菜单树 - 公共知识
    async getPublicListTree (params={}, cb) {
        let res = await myAxios.getJson('/pubDeptSub/listTree', params);
        return res.data;
    },

    // 新增公共知识子分类
    async addPublicTree (params={}, cb) {
        let res = await myAxios.postJson('/pubDeptSub/insert', params);
        return res.data;
    },
    
    // 修改公共知识子分类
    async updatePublicTree (params={}, cb) {
        let res = await myAxios.postJson('/pubDeptSub/update', params);
        return res.data;
    },

    // 删除公共知识子分类
    async deletePublicListTree (params={}, cb) {
        let res = await myAxios.getJson('/pubDeptSub/delete', params);
        return res.data;
    },

    // 获取公共知识的知识类型数据（在原有基础上不查询参考研报、政策跟踪）
    async getPutKnowledgeType (params={}, cb) {
        let res = await myAxios.postJson('/pubDeptSub/getPutKnowledgeType', params);
        return res.data;
    },
    

    // 获取公共知识
    async getPutKnowledgeList (params={}, cb) {
        let res = await myAxios.postJson('/pubKnowledgeRel/pageList', params);
        return res.data;
    },

    // 新增公共知识
    async addPutKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/pubKnowledgeRel/insert', params);
        return res.data;
    },

    // 修改公共知识分类
    async updatePutKnowledge (params={}, cb) {
        let res = await myAxios.postJson('/pubKnowledgeRel/update', params);
        return res.data;
    },

    // 删除公共知识
    async deletePutKnowledge (params={}, cb) {
        let res = await myAxios.getJson('/pubKnowledgeRel/delete', params);
        return res.data;
    },

    // 根据条件查询未添加至公共知识分类的数据
    async getUnPubList (params={}, cb) {
        let res = await myAxios.postJson('/pubKnowledgeRel/pageUnPubList', params);
        return res.data;
    },



    // 获取政策公共知识树
    async getPublicPolicyeTree (params={}, cb) {
        let res = await myAxios.getJson('/policyTheme/getIdAndName', params);
        return res.data;
    },
    // 新增公共树
    async addPublicPolicyTheme (params={}, cb) {
        let res = await myAxios.postJson('/policyTheme/insert', params);
        return res.data;
    },
    // 修改公共树
    async updatePublicPolicyTheme (params={}, cb) {
        let res = await myAxios.postJson('/policyTheme/update', params);
        return res.data;
    },
    // 删除公共树
    async deletePublicPolicyTheme (params={}, cb) {
        let res = await myAxios.deleteJson('/policyTheme/deleteById', params);
        return res.data;
    },

    // 获取公共政策数据列表
    async getPublicPolicyPageList (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/policyPageList', params);
        return res.data;
    },

    // 获取政策数据列表
    async getAllPublicPolicyList (params={}, cb) {
        let res = await myAxios.postJson('/policyManagement/policyPageList', params);
        return res.data;
    },

    // ES同步
    // 获取成功、失败统计数据
    async getESStatus (params={}, cb) {
        let res = await myAxios.postJson('/esStatus', params);
        return res.data;
    },

    async getEsStatusPageList (params={}, cb) {
        let res = await myAxios.postJson('/esStatus/pageList', params);
        return res.data;
    },

    // 将失败数据同步至es，如果为空表示全部同步
    async getEsStatusSyncToEs (params={}, cb) {
        let res = await myAxios.postJson('/esStatus/syncToEs', params);
        return res.data;
    },
    
    // 设置知识下载权限，1不可下载
    async setKnowledgeDownPerm (params={}, cb) {
        let res = await myAxios.getJson('/knowledgeManagement/setKnowledgeDownPerm', params);
        return res.data;
    }
};
