import React from 'react';
import Image from "../../components/Image/Image";
import './index.scss';
import storage from "../../utils/storage";

const ErrorPage = () => {
    return (
        <div className="errorPageFrame">
            <Image iClassName="notFoundImg" imgSrc={require("../../assets/img/error.png")}></Image>
            <span className="tipText">服务器维护中, 请稍后再试!</span>
        </div>
    );
}

export default ErrorPage;