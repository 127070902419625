import React, { useState, useEffect} from 'react';
import { Tooltip } from 'antd';
import { VerticalAlignTopOutlined } from '@ant-design/icons';

import './index.scss';
import storage from "../../utils/storage";
import DefaultScheme from './defaultScheme/index'
import OriginalIndex from './originalIndex.js'

const HomePage = () => {

    const [showBackTop, setShowBackTop] = useState(false)
    const [themeKey, setThemeKey] = useState(storage.getItem("theme"));

    useEffect(()=>{
        listeningScrolling();
    },[])

    // 订阅刷新主题
    useEffect(() => {
        document.addEventListener("theme", handleEvent);
        return () => {
            document.removeEventListener("theme", handleEvent);
        };
    }, []);

    const handleEvent = () => {
        setThemeKey(storage.getItem("theme"))
    }

    const listeningScrolling = () => {
        const divElement = document.querySelector('.homePageFrame');
 
        divElement.addEventListener('scroll', function(event) {
            let scrollTop = event.target.scrollTop 
            if(scrollTop > 100){
                setShowBackTop(true)
            }else{
                setShowBackTop(false)
            }
        });
    }


    const backTop = () => {
        const divElement = document.querySelector('.homePageFrame');

        divElement.scrollTop = 0
    }

    return (
        <div className="homePageFrame">

            {themeKey === "oldVersion" ? <OriginalIndex/> : <DefaultScheme/>}
            
            {showBackTop&&
                <Tooltip 
                    title="回到顶部"
                    getPopupContainer={()=>document.getElementById("backTop")}
                >
                    <div 
                        id="backTop"
                        className="backTop"
                        onClick={backTop}
                    >
                        <VerticalAlignTopOutlined />
                    </div>
                </Tooltip>
            }
        </div>
    );
}
export default HomePage;
