import React, {useState, useEffect, useRef, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {Input, Modal, Spin, message } from "antd";
import { CaretDownOutlined, CaretUpOutlined, ClockCircleOutlined } from '@ant-design/icons';

import Iconfont from "../../../../../../components/Iconfont/Iconfont";
import MindMap from "../../../../../../components/MindMap";
import NoneData from "../../../../../../components/NoneData";
import SelfPagination from "../../../../../../components/SelfPagination";
import {rgbToStr, getRgbLevel, getSearchParams, KnowledgeApply, jumpToPage} from "../../../../../../utils/tool";
import BackIcon from "../../../../../../components/BackIcon/index";
import Api from "../../../../../../api";

import "./index.scss";

const { Search } = Input;
let time = null;

const knowledgeSystemDetail = () => {
    const history = useHistory();
    const designRef = useRef('design'); // 画布ref
    let searchParams = getSearchParams(); // 路径传递的参数
    const [systemData, setSystemData] = useState({}); // 体系图数据
    const [modalOption, setModalOption] = useState(null); // 弹窗配置
    const [positionStyle, setPositionStyle] = useState({left: 0, top: 0}); // 拖拽画布的样式
    const [moveStartPosition, setMoveStartPosition] = useState({x: 0, y: 0}); // 拖拽画布时鼠标初始位置
    const [isMoving, setIsMoving] = useState(false); // 是否在拖拽中
    const [isOpen, setIsOpen] = useState(false); // modal弹窗是否显示
    const [labelDetailesParam, setLabelDetailesParam] = useState({
        pageIndex:1,
        pageSize:10,
        timeType:"1"
    });
    const [modalLoading, setModalLoading] = useState(false); // 弹窗内容加载中
    const [selectLable, setSelectLable] = useState(''); //当前选重点的节点数据
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [lableListofNote, setLableListofNote] = useState({}); // 标签关联笔记列表

    const [countDownEnable, setCountDownEnable] = useState('');
    const [hour, setHour] = useState(0)
    const [minute, setMinute] = useState(0)
    const [second, setSecond] = useState(0)

    useEffect(() => {
        document.addEventListener('mouseup', endMoveFunc);
        return () => {
            document.removeEventListener('mouseup', endMoveFunc);
        }
    }, [])

    useEffect(() => {
        getSetting();

        if(searchParams.systemId){
            getSystemDetails(searchParams.systemId)
        }
    }, [])

    // 根据标签Id获取关联笔记
    useEffect(() => {
        if(labelDetailesParam.knowledgeNoteLabelId){
            getNoteBaseOnTheTagID();
        }
    }, [JSON.stringify(labelDetailesParam)])

    const getSetting = async() => {
        try{
            let res = await Api.Settings.getModuleFunction({data:{}});
            if(res && res.code === 0 && res.data){
                let _data = res.data;
                _data.map(item=>{
                    if(item.moduleType == 2){
                        setCountDownEnable(item.isEnable)
                    }
                })
            }else{}
        }catch{}
    }

    const memoizedTimerFun = useMemo(() => () => {
        if(!time){
            let _hour = hour;
            let _minute = minute;
            let _second = second;
            time = setInterval(()=>{
                if(_second < 60){
                    _second = _second + 1;
                    setSecond(_second);
                }else if(minute < 60){
                    _second = 0;
                    _minute = _minute + 1;
                    setSecond(0);
                    setMinute(_minute)
                }else if(hour < 24){
                    _second = 0
                    _minute=0
                    _hour = _hour + 1;
                    setSecond(0);
                    setMinute(0);
                    setHour(_hour)
                }
            },1000)
        }

    }, []);


    useEffect(()=>{
        memoizedTimerFun();

        return()=>{
            clearInterval(time);
            time = null;
        }
    },[])

     // 根据引用体系ID获取详情
     async function getSystemDetails(id) {
        try{
            const res = await Api.Note.getKnowledgeNoteSystemManagementById({data:{
                knowledgeNoteSystemManagementId:id
            }});
            if(res&&res.code === 0 && res.data){
                setSystemData({
                    treeData:res.data.knowledgeNoteSystemMessage,
                    systemName:res.data.knowledgeNoteSystemTitle
                })
            }
        }catch(errorInfor){}
    }

    // 根据标签Id获取关联笔记
    async function getNoteBaseOnTheTagID(){
        setModalLoading(true)
        try{
            const res = await Api.Note.getLabelRelationNoteByLabelId({data:{
                knowledgeNoteLabelId:labelDetailesParam.knowledgeNoteLabelId,
                pageIndex:labelDetailesParam.pageIndex,
                pageSize:labelDetailesParam.pageSize,
                timeType:labelDetailesParam.timeType,
                noteDetailMessage:labelDetailesParam.searchMessage
            }});
            if(res&&res.code === 0 && res.data){
                let _data = res.data.records;
                setLableListofNote({
                    list:_data,
                    total:res.data.total
                });
            }else{
                setLableListofNote({});
            }
            setModalLoading(false);
        }catch(errorInfor){setModalLoading(false);}
    }

    // 阻止默认，冒泡
    function stopFunc (event) {
        event?.preventDefault();
        event?.stopPropagation();
    }

    // 菜单点击功能
    function menuClickFunc (type) {
        let option = null;
        switch (type) {
            case "noteDetail":
                option = {
                    title: "关联的想法",
                    icon: null,
                    type: "noteDetail",
                    width: "80%",
                };
                break;
        }
        if (option) {
            setIsOpen(true);
            setModalOption(option);
        }
    }

    // 鼠标双击显示笔记列表
    function showNoteList (item) {
        if(item){
            setSelectLable(item);
        }
        // 标签id带有时间戳
        // 如果是标签池选择的标签会有lableId,就使用lableID,否者使用ID
        let _knowledgeNoteLabelId = "";
        if(item){
            _knowledgeNoteLabelId = item.labelId ? item.labelId : item.id
        }else{
            _knowledgeNoteLabelId = selectLable.id
        }
        setLabelDetailesParam({
            ...labelDetailesParam,
            knowledgeNoteLabelId:_knowledgeNoteLabelId
        })
        menuClickFunc("noteDetail");
    }

    // 生成节点
    function productTreeNode (it, position, props={}) {
        const {id, nodeName, children,} = it;
        const background = it?.background || {r:255,g:255,b:255,a:1};
        const haveChildren = children && children.length > 0;
        const rgbLevel = getRgbLevel(background);
        const colorStyle = {
            color: rgbLevel > 150 ? '#333' : '#fff',
        }
        return (
            <div className="treeNodeBox" {...props} key={`${position.join("-")}`}>
                <div
                    id={id}
                    className={`treeNode`}
                    style={{background: rgbToStr(background)}}
                    onDoubleClick={()=>showNoteList(it)}
                >
                    <span className="treeNodeName" style={colorStyle}>{nodeName}</span>
                </div>
                {
                    haveChildren ? (
                        <div className="treeChildNodeBox">
                            {productTree(children, [...position], id)}
                        </div>
                    ) : undefined
                }
            </div>
        )
    }

    // 生成树
    function productTree (data=[], position, parentId) {
        return (
            data?.map((it, index) => {
                return MindMap({
                    parentDomId: parentId,
                    domMindMapId: `${it.id}-MindMap`,
                    dom: productTreeNode(it, [...position, index]),
                    lineColor: '#1A9DF9',
                })
            })
        )
    }

    // 开始拖拽
    function startMoveFunc (e) {
        setIsMoving(true);
        setMoveStartPosition({
            x: e.pageX,
            y: e.pageY,
        })
    }

    // 拖拽
    function movingFunc (e) {
        if (isMoving) {
            const {pageX, pageY} = e;
            const {x, y} = moveStartPosition;
            const {left, top,} = positionStyle;
            setMoveStartPosition({x: pageX, y: pageY});
            setPositionStyle({
                left: left + pageX - x,
                top: top + pageY - y,
            });
        }
    }

    // 结束拖拽
    function endMoveFunc (e) {
        setIsMoving(false)
    }

    // 弹窗内容显示
    function renderModalContentFunc (type) {
        let content = null
        // , tableHeight = document.querySelector(".contentBox").offsetHeight-276;
        switch (type) {
            case "noteDetail":
                content = (
                    <Spin tip="数据加载中" spinning={modalLoading}>
                        <div>
                            <div className="noteOpration">
                                <div
                                    className="timeSort"
                                >
                                    <div style={{
                                        width: '80px',
                                        cursor: 'pointer'}}
                                        onClick={sortValueChange}
                                    >
                                        {labelDetailesParam.timeType === '2' ? "时间升序":"时间降序"}
                                        <CaretUpOutlined style={labelDetailesParam.timeType === '2' ?{color:"#60B4F6"}:{}} />
                                        <CaretDownOutlined style={labelDetailesParam.timeType === '1' ?{color:"#60B4F6"}:{}} />
                                    </div>
                                </div>
                                <div>
                                    <Search
                                        placeholder=""
                                        onSearch={onPublicNoteSearch}
                                        allowClear
                                        style={{
                                            width: 200,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                            // style={{height:tableHeight,overflow:"auto"}}
                            >
                                {selectLable.noteList&&selectLable.noteList.length>0?(
                                    <div className="noteListItem" key={selectLable.noteList[0].id}>
                                        {selectLable.noteList[0].knowledgeNoteDetailTitle}<span className="typeAdd">(新增)</span>
                                        <div className="noteDetails">{selectLable.noteList[0].knowledgeNoteDetailMessage}</div>
                                    </div>
                                ):""}

                                {lableListofNote.list&&lableListofNote.list.length>0?lableListofNote.list.map(item=>{
                                    return(<div className="noteListItem" key={item.knowledgeNoteDetailId}>
                                        {/* 0笔记 1摘录 */}
                                        {item.dataType === 0?(<Iconfont type={'icon-bijiben'} iStyle={{
                                            // float: "left",
                                            margin: "3px 5px 0 0",
                                        }}/>):(<Iconfont type={'icon-biaoqian'} iStyle={{
                                            // float: "left",
                                            margin: "3px 5px 0 0",
                                        }}/>)}

                                        {item.knowledgeNoteDetailTitle}

                                        <div className="noteDetails">{item.knowledgeNoteDetailMessage}</div>
                                        <div className="noteOriginal">{item.originalMsg}</div>
                                        <div className="source" onClick={()=>{goKnowledgeDetails(item)}}>--- 来源<span>《{item.knowledgeName}》</span></div>
                                    </div>)
                                }):<NoneData/>}
                            </div>
                            {lableListofNote.list&&lableListofNote.list.length>0?(
                                <SelfPagination
                                    current={labelDetailesParam.pageIndex}
                                    total={lableListofNote.total}
                                    onSizeChange={onSizeChange}
                                />
                            ):""}
                        </div>
                    </Spin>
                )
                break;
        }
        return content;
    }

     // 搜索笔记
     function onPublicNoteSearch (value) {
        setLabelDetailesParam({
            ...labelDetailesParam,
            searchMessage:value,
            pageIndex:1,
        })
    }

    const sortValueChange = () => {
        let _timeSort = labelDetailesParam.timeType === '1' ? '2' : '1';

        setLabelDetailesParam({
            ...labelDetailesParam,
            timeType:_timeSort
        })
    }

    const onSizeChange = (page, pageSize) => {
        setLabelDetailesParam({
            ...labelDetailesParam,
            pageIndex:page,
            pageSize:pageSize,
        })
    };

    const goKnowledgeDetails = async(item) => {
        if(detailsLoading){return false;}
        setDetailsLoading(true);
        try{
            let res = await Api.Message.checkMessageStatus({
                data: {
                    id:item.knowledgeId
                },
            });
            setDetailsLoading(false)
            if(res.code === 0){
                // // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
                let _code = res.data;
                if(_code === -1){
                    message.info("当前知识已被删除");
                }else if(_code === 1){
                    jumpToPage({...item, type: ""});
                }else{
                    message.info("当前知识已取消发布")
                }
            }
        }catch{setDetailsLoading(false)}
    }

    // 关闭弹窗
    function closeModalFunc (func) {
        if (func) {
            func()
        }
        setIsOpen(false);
        setModalOption(null);
        setLableListofNote([]);
        setSelectLable({});
    }

    function backPage () {
        history.goBack();
    }

    const {
        title, icon, color='#333', type, onOk,
        onCancel, width=540, getContainer
    } = modalOption || {};

    const {treeData, systemName} = systemData;

    return (
        <div className="knowledgeSystemDetailFrame">
            {/* header */}
            <div className="headerBox">
                {/* 返回按钮 */}
                {/* <span className="backBtn" onClick={backPage}><Iconfont type="icon-fanhui" /></span> */}
                <BackIcon position="left"/>

                {/* 体系名称 */}
                <div className="systemNameBox">
                    <span className="systemName">{systemName}</span>
                </div>

                {/* 占位符 */}
                <i className="kongChar" />

                <div className="countdown"
                    style={countDownEnable===0?{opacity:1}:{}}
                >
                    <ClockCircleOutlined />
                    {/* userInfo.userName.slice(0, 1) */}
                    {/* {getCountdown()} */}
                    {hour.toString().length==1?'0'+hour:hour}:
                    {minute.toString().length==1?'0'+minute:minute}:
                    {second.toString().length==1?'0'+second:second}
                </div>

            </div>
            <div className="contentBox">
                {/* 画布 */}
                <div
                    ref={designRef}
                    className="systemDesignBox"
                    style={{cursor: isMoving ? 'grab' : ''}}
                    onMouseDown={startMoveFunc}
                    onMouseMove={movingFunc}
                >
                    <div
                        className="treeRootBox"
                        style={positionStyle}
                        onContextMenu={stopFunc}
                    >
                        {
                            treeData&&treeData.map((item, idx) => {
                                return (productTreeNode(item, [idx], {style: {padding: '2rem', fontVariant: "normal"},}));
                            })
                        }
                    </div>
                    <span
                        className="reductionPositionBtn"
                        onMouseDown={stopFunc}
                        onClick={() => {
                            setPositionStyle({left: 0, top: 0})
                        }}
                    >
                        <Iconfont type={"icon-zhongzhi-fangkuang"} />
                    </span>
                </div>
            </div>

            {/* Modal弹窗 */}
            <Modal
                open={isOpen && modalOption}
                destroyOnClose={true}
                width={width}
                wrapClassName={"selfDesignModal"}
                centered={true}
                closable={true}
                maskClosable={false}
                footer={null}
                title={(
                    <div className="titleBox" style={{color,}}>
                        {icon ? <Iconfont type={icon} /> : '' }
                        <span>{title}</span>
                    </div>
                )}
                getContainer={() => {
                    if (getContainer) {
                        return getContainer()
                    }
                    return designRef.current
                }}
                onCancel={() => closeModalFunc(onCancel)}
            >
                <div className="modalContentBox">
                    {renderModalContentFunc(type)}
                </div>
            </Modal>
        </div>
    )
}

export default knowledgeSystemDetail;
