import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Popconfirm, Space, Typography, Tooltip, Modal, message } from 'antd';

import Api from "../../../api";
import SelfTable from "../../../components/SelfTable"
import {pagingParam} from "../../../utils/defaultValue";
import BackIcon from "../../../components/BackIcon/index";
import {ModalConfirm} from '../../../utils/tool'

import './index.scss'

const { Link } = Typography;

const ProjectList = () => {
    
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    const [searchValue, setSearchValue] = useState('');

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listData, setListData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState('');

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [templateAddress, setTemplateAddress] = useState("");

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            width: '',
            editable: true,
        },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            保存
                        </Typography.Link>
                        <Popconfirm title="取消后数据将不会保存" onConfirm={cancel}>
                            <a>取消</a>
                        </Popconfirm>
                    </span>
                ) : (
                        <Space>
                            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                调整
                            </Typography.Link>
                        </Space>
                    );
            },
        },
    ];

    const isEditing = (record) => record.id === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        record,
        index,
        children,
        ...restProps
    }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `请输入${title}!`
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    useEffect(() => {
        getList();
    }, [searchData]);

    useEffect(() => {
        getTemplateAddress();
    }, []);

    const getTemplateAddress = async() => {
        try {
            const res = await Api.Knowledge.getProjectTemplate({data:{}});
            if(res.code === 0){
                setTemplateAddress(res.data)
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Knowledge.getProjectList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setDefaultDate(_data.records);
            setEditingKey('');
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
            setDefaultDate([]);
        }
    }

    // 修改
    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.projectName = searchValue;
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            "projectName":"",
        })
    }

    // 取消
    const cancel = () => {
        setListData([...defaultDate])
        setEditingKey('');
    };

    // 修改-保存
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            row.id = key;
            setTableLoading(true);
            const res = await Api.Knowledge.updateProject({data:row});
            if(res.code === 0){
                message.success("修改信息成功",2,()=>{
                    getList();
                })
            }else{
                setTableLoading(false);
            }
        } catch (errInfo) {
            setTableLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    const deleteProject = () => {
        if(selectedRowKey){
            ModalConfirm({
                title: `您确认要删除这条数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Knowledge.deleteProject({data:{id:selectedRowKey}});
                        resolve()
                        if(res&&res.code === 0){
                            getList();
                            setSelectedRowKey("");
                            message.success("删除成功")
                        }
                    }catch{}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

      // 新增
      const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            const res = await Api.Knowledge.addProject({data:row});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                message.success("新增信息成功",2,()=>{
                    setIsModalOpen(false);
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const importList = async() => {

    }

    // 打开
    const addProject = () => {
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
    }

    const handleFileChange = async() => {
        message.loading({
            content:'导入中..',
            key:"import"
        });
        const file = document.getElementById("uploadList").files[0];
        
        const formData = new FormData();
        formData.append("file",file);

        try {
            const res = await Api.Knowledge.importProject({
                data:formData
            });
            document.getElementById("uploadList").value = "";
            message.destroy("import");
            if(res&&res.code === 0){
                let msg = ""
                if(res.data.successNum > 0){
                    msg = `导入成功${res.data.successNum}条数据`
                }
                if(res.data.failNum > 0){
                    msg = msg === ""?`导入失败${res.data.failNum}条数据`:`${msg},导入失败${res.data.failNum}条数据`
                }
                message.info(msg,2,()=>{
                    getList();
                })
            }
        } catch (errInfo) {
            document.getElementById("uploadList").value = "";
            message.destroy("import");
            console.log('验证失败:', errInfo);
        }
        

    }

    const onChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    };

    return (
        <div className="projectListFrame">
             <div className="title">项目清单</div>
            <div className="operation">
                <Space size={"large"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end'
                    }}>
                        <Button type="primary">
                            导入项目清单
                            <input 
                                type="file" 
                                name="" 
                                id="uploadList"
                                accept=".xls,.xlsx,.xlsm,.doc,.docx,.docm,.pdf,.ppt,.pptx,.pptm,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                onChange={handleFileChange}
                            />
                        </Button>
                        <a 
                            style={{
                                fontSize: '0.6rem',
                                marginLeft: 5
                            }} 
                            download="清单模板.xlsx" 
                            href={templateAddress} 
                            target="_blank"
                        >
                        下载模板
                        </a>
                    </div>
                    <Button type="primary" onClick={addProject}>手动添加项目</Button>
                    <Button type="primary" danger onClick={deleteProject}>删除项目</Button>
                </Space>
                
                <div className="operationRight">

                    <Space size={"large"}>
                        <div className="operationItem">
                            <Input
                                placeholder="输入关键词搜索"
                                onChange={changeValue}
                                onPressEnter={onSearch}
                                style={{width:350,float:"right"}}
                                value={searchValue}
                            />
                        </div>
                        <Button type="primary" onClick={onSearch}>搜索</Button>
                        <Button onClick={onResetting}>重置</Button>
                    </Space>
                </div>
            </div>
            <BackIcon />
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        pagination={pagination}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        isEditing={isEditing}
                        onChange={onChanges} 
                    />
                </Form>
            </div>

            <Modal 
                title="新增项目" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                // forceRender
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    <Form.Item
                        key={"projectName"}
                        label={"项目名称"}
                        name={"projectName"}
                        rules={[{ required: true, message: `请输入项目名称!` }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ProjectList;
