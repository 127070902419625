import React, {useEffect, memo, useState} from 'react';
import { Switch, Tooltip } from 'antd';

import Iconfont from "../../../../components/Iconfont/Iconfont";
import {KnowledgeApply, downloadFileBlob, getSearchParams, jumpToPage} from '../../../../utils/tool'
import './index.scss'

const DetailsData = ({knowledgeDetail, shareDepartment}) => {

    const {type} = getSearchParams() || {};

    const [data, setData] = useState([]);

    const defaultData = [
        {
            title:"标题",
            key:"knowledgeTitle"
        },
        {
            title:"作者",
            key:"knowledgeUserRelVOS",
            render:(text)=>{
                // 参考研报作者为空的时候显示爬虫
                if(knowledgeDetail.knowledgeTypeId === '7' && knowledgeDetail.sourceType===1 && !text){
                    return "爬虫"
                } 
                
                let userNameList = [];
                if(text && text.length > 0){
                    text.map(item=>{
                        const {userId, userName, departmentMessage, jobName, } = item;
                        const content = `${userName}（${departmentMessage}）`;
                        userNameList.push(content)
                    })
                }
                
                if(knowledgeDetail.nameExts && knowledgeDetail.nameExts.length > 0){
                    knowledgeDetail.nameExts.map(item => {
                        userNameList.push(item)
                    })
                }

                if(userNameList.length <= 0){return ""}

                return(userNameList.map(item => {
                    return <div key={item} className="userNameList">
                        <Tooltip title={item}>
                            <span className="name">{item}</span>
                        </Tooltip>
                    </div>
                }))
                
            }
        },
        {
            title:"知识归属",
            key:"knowledgeDepartmentName"
        },
        {
            title:"知识类型",
            key:"knowledgeTypeName"
        },
        {
            title:"部门分类",
            key:"knowledgeSonDepartmentName"
        },
        {
            title:"所属项目",
            key:"projectName"
        },
        {
            title:"项目起始时间",
            key:"projectTime",
            render:(text)=>{
                if(!text || (text === "null~null")){return "--"}
                return text
            }
        },
        {
            title:"文档标签",
            key:"knowledgeDocumentLabelList",
            render:(text)=>{
                if(!text || (text && text.length <= 0)){return "--"}
                return(
                    text.map(item=>{
                        return <span key={item}>{item}</span>
                    })
                )
            }
        },
        {
            title:"分享范围",
            key:"shareDepartmentIds",
            render:(text)=>{
                if(!text || (text && text.length <= 0)){return "--"}
                return(
                    <div className="shareRangeBox">
                        {
                            text.map(item=>{
                                let name = shareDepartment[item];
                                if (name) {
                                    return <span key={item} title={name}>{name}</span>
                                }
                            })
                        }
                    </div>
                )
            }
        },
        // {
        //     title:"指定为推荐知识",
        //     key:"recommend",
        //     render:(record)=>{
        //         return(
        //             <div className={"recommendBox"}>
        //                 <Switch size={"small"} onChange={onChange} />否
        //             </div>
        //         )
        //     }
        // },
        {
            title:"创建者",
            key:"creator"
        }
        ,
        {
            title:"创建日期",
            key:"createDate"
        }
    ]

    const policyData = [
        {
            title:"标题",
            key:"knowledgeTitle"
        },
        {
            title:"来源",
            key:"source"
        },
        {
            title:"发文机构",
            key:"policyInstitution"
        },
        {
            title:"发文时间",
            key:"publicationTime"
        },
        {
            title:"发文字号",
            key:"docNum"
        },
        {
            title:"政策级别",
            key:"policyRegionId",
            render:(text)=>{
                if(!text || (text === "null~null")){return "--"}
                return text == '1' ? "国家" : text == '2' ? "地方" : ''
            }
        },
        {
            title:"主题摘要",
            key:"policyDigest",
        }
    ]

    useEffect(()=>{
        if(type === 'policy'){
            setData(policyData)
        }else{
            setData(defaultData)
        }
    },[knowledgeDetail])

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    return(
        <div className="detailsData">
            {data.map(item=>{
                if((item.key==="projectName" || item.key==="projectTime") && knowledgeDetail.knowledgeTypeId != '2'){
                    return ""
                }

                if(item.key==="knowledgeUserRelVOS" && (knowledgeDetail.knowledgeTypeId=="6" || knowledgeDetail.knowledgeTypeId=="7")){
                    return <div key={item.key} className="detailsItem">
                    <div className="detailsTitle">
                        <Iconfont type={'icon-fenlei'} 
                            iStyle={{
                                fontSize: '0.8rem',
                                color:"#537791",
                                float: 'left',
                                lineHeight: 'inherit'
                            }}
                        /><span>知识来源</span>
                    </div>
                    <div className="detailsContent">
                        {knowledgeDetail.knowledgeSource || "--"}
                    </div>
                </div>
                }

                return(
                    <div key={item.key} className="detailsItem">
                        <div className="detailsTitle">
                            <Iconfont type={'icon-fenlei'} 
                                iStyle={{
                                    fontSize: '0.8rem',
                                    color:"#537791",
                                    float: 'left',
                                    lineHeight: 'inherit'
                                }}
                            /><span>{item.title}</span>
                        </div>
                        <div className="detailsContent">
                            {item.render?item.render(knowledgeDetail[item.key]):(knowledgeDetail[item.key]?knowledgeDetail[item.key]:"--")}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


export default memo(DetailsData)