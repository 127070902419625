import { Form, Input, Button, Popconfirm, Table, Typography, Select, Modal, message, Checkbox, Tree, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';

import Api from "../../../../api";
import {pagingParam} from "../../../../utils/defaultValue";
import storage from '../../../../utils/storage'
import {ModalConfirm} from '../../../../utils/tool'
import SelfTable from "../../../../components/SelfTable/index"

import './index.scss'

const { confirm } = Modal;
const { TextArea } = Input;

const RoleList = ({tableHight}) => {
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    // 列表数据
    const [listData, setListData] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState({});

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [menuData, setMenuData] = useState([]);

    const [checkedMenuKeys, setCheckedMenuKeys] = useState([]);

    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {
        getMenuList();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);

    // 获取菜单列表数据
    const getMenuList = async() => {
        const res = await Api.Menu.getAllMenuListTree();
        if(res&&res.code === 0){
            let _data = res.data;
            setMenuData(_data);
        }
    }

    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Roles.getSysRolesList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
            setEditingKey('');
        }else{
            setListData([]);
        }
    }

    // 修改
    const edit = (record) => {
        setIsModalOpen(true);
        // console.log(JSON.stringify(record.menuIds))
        setCheckedMenuKeys(record.menuIds)
        setEditingKey(record);
        // addForm.setFieldsValue({
        //    ...record,
        // });
    };

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            row.menuIds = checkedMenuKeys;
            setConfirmLoading(true);
            if(editingKey){
                row.id = editingKey.id;
                const res = await Api.Roles.updateSysRoles({data:row});
                if(res.code === 0){
                    message.success("修改成功",2,()=>{
                        setConfirmLoading(false);
                        setIsModalOpen(false);
                        setCheckedMenuKeys([]);
                        getList();
                    })
                }
            }else{
                const res = await Api.Roles.addSysRoles({data:row});
                if(res&&res.code === 0){
                    message.success("新增成功",2,()=>{
                        setConfirmLoading(false);
                        handleCancel();
                        getList();
                    })
                }else{setConfirmLoading(false);}
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 打开新增角色权限
    const addRole = () => {
        setConfirmLoading(false);
        setIsModalOpen(true);
    }

    //删除角色权限
    const deleteRoleModal = (record) => {
        ModalConfirm({
            title: `您确认要删除这条数据吗?`,
            onOk:async(resolve, reject)=> {
                try{
                    const res = await Api.Roles.deleteSysRole({data:{id:record.id}});
                    resolve();
                    if(res && res.code === 0){
                        let newSearchData = {...searchData}
                        newSearchData.pageIndex = 1;
                        setSearchData(newSearchData);
                    }
                }catch{}
            },
        })
    }

    // 关闭新增角色权限
    const handleCancel = () => {
        setEditingKey("");
        setCheckedMenuKeys([])
        setIsModalOpen(false);
    }

    // 分页变化
    const paginationChange = (page, pageSize) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = page;
        newSearchData.pageSize = pageSize;
        setSearchData(newSearchData);
    }

    const columns = [
        // {
        //     title: '序号',
        //     dataIndex: 'index',
        //     width: '70px',
        //     editable: false,
        //     align:"center",
        //     render:(text, record, index)=>{
        //         return index+1
        //     }
        // },
        {
            title: '系统角色',
            dataIndex: 'name',
            width: '240px',
            align:"center",
            editable: true,
        },
        {
            title: '描述',
            dataIndex: 'description',
            width: '',
            align:"center",
            editable: true,
        },
        {
            title: '操作',
            width: '140px',
            align:"center",
            dataIndex: 'operation',
            render: (_, record) => {
                return(<Space>
                    <Typography.Link onClick={() => edit(record)}>
                        调整
                    </Typography.Link>
                    <Typography.Link type="danger" onClick={()=>{deleteRoleModal(record)}}>
                        删除
                    </Typography.Link>
            </Space>)
            },
        },
    ];

    const onCheck = (checkedKeys,e) => {
        let list = [];

        function treeLoad(lists){
            lists.map(item=>{
                list.push(item.id);
                if(item.children && item.children.length > 0){
                    treeLoad(item.children)
                }
            })
        }

        if(e.checked){
            let _node = [e.node]
            treeLoad(_node);

            let _checkedMenuKeys = Array.from(new Set([...checkedMenuKeys,...list]))

            setCheckedMenuKeys(_checkedMenuKeys)
        }else{
            let _node = [e.node]
            treeLoad(_node);

            let _checkedMenuKeys = checkedMenuKeys.filter((item) => {
                return !list.includes(item)
            });
            setCheckedMenuKeys(_checkedMenuKeys)
        }
    };

    const tableChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    }

    return (
        <div className="roleListFrame">
            <div className="title">角色及菜单权限管理</div>
            <div className="operation">
                <Button type="primary" onClick={addRole}>新增角色</Button>
                {/* <Button type="primary" danger onClick={deleteRoleModal} style={{marginLeft:"10px"}}>删除角色</Button> */}
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        dataSource={listData}
                        columns={columns}
                        pagination={{
                            ...pagination,
                            onChange: paginationChange,
                        }}
                        loading={tableLoading}
                    />
                </Form>
            </div>

            {/* {isModalOpen ?  */}
            <Modal
                title="新增角色及权限"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                // forceRender
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'name'}
                        label={'角色名称'}
                        name={'name'}
                        rules={[{ required: true, message: `请输入系统角色名称!` }]}
                        initialValue={editingKey.name}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={'description'}
                        label={'描述'}
                        name={'description'}
                        initialValue={editingKey.description}
                    >
                        <TextArea />
                    </Form.Item>

                    <Form.Item
                        key={'menuIds'}
                        label={'菜单权限'}
                        name={'menuIds'}
                    >
                        <div className="treeBox">
                            <Tree
                                checkable
                                checkedKeys={checkedMenuKeys}
                                defaultCheckedKeys={checkedMenuKeys}
                                // defaultExpandedKeys={checkedMenuKeys}
                                onCheck={onCheck}
                                treeData={menuData}
                                checkStrictly={true}
                                fieldNames={{
                                    title: 'name',
                                    key:'id'
                                }}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            {/* // :""} */}
        </div>
    );
};
export default RoleList;
