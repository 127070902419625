import React, { useEffect, useState } from "react";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { message, Pagination, Spin, Empty, Modal } from "antd";
import SelfPagination from "../../../components/SelfPagination";
import KnowledgeTypeTab from "../components/knowledgeTypeTab";
import KnowledgeItem from "../components/KnowledgeItem";
import { ModalConfirm } from "../../../utils/tool";
import storage from "../../../utils/storage";
import Api from "../../../api";
import "./index.scss";
function MyStar() {
  // 接口传递参数
  const [siftData, setSiftData] = useState({
    isCollectTime: null,
    isReadTime: null,
    knowledgeTypeId: null,
    pageIndex: 1,
    pageSize: 10,
  });
  // 展示数据
  const [collectionList, setCollectionList] = useState([]);
  // 请求数据的所有参数
  const [data, setData] = useState({});
  // tab筛选列表
  const [tabsData, setTabsData] = useState([]);
  const [tabsKey, setTabsKey] = useState("all");
  // 排序
  const [colTimeSortKey, setColTimeSortKey] = useState("default");
  const [readNumSortKey, setReadNumSortKey] = useState("default");
  // const [knowledgeId, setKnowledgeId] = useState();
  const [isSpinning, setIsSpinning] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [listHight, setListHight] = useState(0);
  useEffect(() => {
    resizeCallBack();
    // 添加窗口变化监听回调函数
    window.addEventListener('resize', resizeCallBack);
    return () => {
        // 移出窗口变化监听
        window.removeEventListener('resize', resizeCallBack)
    }
  }, [])

  // 窗口变化监听回调
  function resizeCallBack() {
      let rootHeight = document.querySelector("#root").offsetHeight;

      // 计算table内容的高度
      let divHeight= document.querySelector('.content').getBoundingClientRect().top;

      setListHight(rootHeight - divHeight - 126);
  }
  useEffect(() => {
    getSiftList();
  }, []);
  useEffect(() => {
    getList();
  }, [siftData]);
  const getList = async () => {
    setIsSpinning(true);
    let res = await Api.PersonalCenter.getKnowledgeCollection({
      data: siftData,
    });
    setCollectionList(res.data.records);
    setData(res.data);
    setIsSpinning(false);
  };
  const getSiftList = async () => {
    if (storage.getItem("siftTabs")?.length > 0) {
      setTabsData(storage.getItem("siftTabs"));
    } else {
      let res = await Api.PersonalCenter.getAllKnowledgeTab({});
      setTabsData(res);
    }
  };
  // 取消收藏
  const showModal = (id) => {
    // setKnowledgeId(knowledgeId);
    // setIsModalOpen(true);
    ModalConfirm({
      title: "确定取消收藏这条知识吗？",
      onOk: async (resolve, reject) => {
        try {
          let res = await Api.PersonalCenter.cancleCollection({
            data: { id },
          });
          if (res.data && res.data.code === 0) {
            let _siftData = { ...siftData };
            _siftData.pageIndex = 1;
            setSiftData(_siftData);
            message.success("取消收藏成功");
          }
          resolve();
        } catch {}
      },
    });
  };
  const tabsChange = (value) => {
    setTabsKey(value);
    let _siftData = { ...siftData };
    _siftData.knowledgeName = "";
    if (value === "all") {
      _siftData.knowledgeTypeId = null;
    } else {
      _siftData.knowledgeTypeId = value;
    }
    setSiftData(_siftData);
  };
  // 排序
  const colTimeSort = () => {
    let _siftData = { ...siftData };
    let nextSort;
    //排序方式的切换
    if (colTimeSortKey === "down") nextSort = "up";
    else if (colTimeSortKey === "up") nextSort = "default";
    else nextSort = "down";
    //修改当前排序方式
    setColTimeSortKey(nextSort);
    if (nextSort === "up") {
      _siftData.isCollectTime = 2;
    } else if (nextSort === "down") {
      _siftData.isCollectTime = 1;
    } else {
      _siftData.isCollectTime = null;
    }
    setSiftData(_siftData);
    // getList(_siftData);
  };
  const readNumSort = () => {
    let _siftData = { ...siftData };
    let nextSort;
    //排序方式的切换
    if (readNumSortKey === "down") nextSort = "up";
    else if (readNumSortKey === "up") nextSort = "default";
    else nextSort = "down";
    //修改当前排序方式
    setReadNumSortKey(nextSort);
    if (nextSort === "up") {
      _siftData.isReadTime = 2;
    } else if (nextSort === "down") {
      _siftData.isReadTime = 1;
    } else {
      _siftData.isReadTime = null;
    }
    setSiftData(_siftData);
    // getList(_siftData);
  };
  // 页码变化时
  const changePage = (page, pageSize) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = page;
    _siftData.pageSize = pageSize;
    setSiftData(_siftData);
    // getList(_siftData);
  };
  const toSearch = (value) => {
    let _siftData = { ...siftData };
    _siftData.knowledgeName = value;
    _siftData.pageIndex = 1;
    setSiftData(_siftData);
  };
  return (
    <div className="myStarFrame">
      <div className="tabs">
        {/* {isSpinning ? (
          <Spin />
        ) : (
          <> */}
            <KnowledgeTypeTab
              tabsData={tabsData}
              tabsChange={tabsChange}
              tabsKey={tabsKey}
              toSearch={toSearch}
            ></KnowledgeTypeTab>
          {/* </>
        )} */}
      </div>
      <div className="content">
        <div className="sort">
          <p onClick={colTimeSort}>
            按收藏时间排序
            <div className="sortIcon">
              <CaretUpFilled
                style={{ color: colTimeSortKey == "up" ? "#0099cc" : null }}
              />
              <CaretDownFilled
                style={{ color: colTimeSortKey == "down" ? "#0099cc" : null }}
              />
            </div>
          </p>
          <p onClick={readNumSort}>
            按阅读量排序
            <div className="sortIcon">
              <CaretUpFilled
                style={{ color: readNumSortKey == "up" ? "#0099cc" : null }}
              />
              <CaretDownFilled
                style={{ color: readNumSortKey == "down" ? "#0099cc" : null }}
              />
            </div>
          </p>
        </div>
        <div className="starView" style={{height:listHight}}>
          <Spin size="large" spinning={isSpinning}>
            {collectionList?.length > 0 ? (
              <KnowledgeItem
                list={collectionList}
                type={"star"}
                showModal={(knowledgeId) => showModal(knowledgeId)}
              ></KnowledgeItem>
            ) : (
              <div className="null">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Spin>
        </div>
        <div className="pager">
          {collectionList?.length > 0 ? (
            <SelfPagination
              current={siftData.pageIndex}
              onSizeChange={changePage}
              total={data.total * 1}
            ></SelfPagination>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MyStar;
