import React,{useState, useEffect} from 'react';
import { Card, Col, Row, message, Typography  } from 'antd';

import './index.scss';
import Iconfont from "../../components/Iconfont/Iconfont";
import storage from "../../utils/storage";

const { Meta } = Card;
const { Text } = Typography;

const Manage = ({history}) => {

    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo")?storage.getItem("userInfo"):{})
    
    const [directory, setDirectory] = useState([
        // {
        //     title:"部门知识管理",
        //     key:"knowledge",
        //     children:[
        //         {
        //             key:"1",
        //             title:"审核管理",
        //             description:"审核部门成员发布的知识",
        //             icon:"icon-shenheliebiao"
        //         },
        //         {
        //             key:"2",
        //             title:"已发布知识管理",
        //             description:"管理部门已经发布的知识",
        //             icon:"icon-gearmore-full"
        //         },
        //         {
        //             key:"3",
        //             title:"政策运维",
        //             description:"运行和维护部门跟踪的政策专题",
        //             icon:"icon-bijibendiannao"
        //         }
        //     ]
        // },
        // {
        //     title:"系统后台管理",
        //     key:"background",
        //     children:[
        //         {
        //             key:"4",
        //             title:"知识统计",
        //             description:"管理和统计系统知识、用户相关数据",
        //             icon:"icon-tongji"
        //         },
        //         {
        //             key:"5",
        //             title:"用户管理",
        //             description:"管理系统用户角色、权限、部门等信息",
        //             icon:"icon-yonghuguanli",
        //             url:"/page/manage/userList"
        //         },
        //         {
        //             key:"6",
        //             title:"部门及类型管理",
        //             description:"管理部门和知识类型",
        //             icon:"icon-shuyi_zhengfu"
        //         },
        //         {
        //             key:"7",
        //             title:"地图模板",
        //             description:"管理和新建知识地图模板",
        //             icon:"icon-ditu"
        //         },
        //         {
        //             key:"8",
        //             title:"日志管理",
        //             description:"查询和管理系统日志",
        //             icon:"icon-rizhi",
        //             url:"/page/manage/log"
        //         },
        //         {
        //             key:"9",
        //             title:"菜单管理",
        //             description:"管理用户菜单",
        //             icon:"icon-xitong-caidanguanli",
        //             url:"/page/manage/menuList"
        //         }
        //     ]
        // }
    ])

    useEffect(() => {
        let routeRes = storage.getItem('routeRes'),managementMenu = [], _topMenu=[];
        routeRes.data.map((item) => {
            if(item.type == 0 && item.icon){
                managementMenu=item.children
            }
        });

        managementMenu.map(item=>{
            if(item.type === 0){
                _topMenu.push({...item})
            }
        });
        setDirectory(_topMenu)

        sessionStorage.activeKey="";
        sessionStorage.removeItem("searchData");
        sessionStorage.removeItem("selectedTheme");

        // 重置已发布知识管理中记录的数据
        sessionStorage.removeItem("tabsKey");
        sessionStorage.removeItem("selecthKey");
        sessionStorage.removeItem("departmentTreeId");
        sessionStorage.removeItem("policyThemeId");
        sessionStorage.removeItem("policyThemeName");
        sessionStorage.removeItem("sectionType");
    }, []);

    const onchange = (item) => {
        if(item.path){
            if((item.path === "/page/knowledgeContent?type=department&inlet=manage") &&
                storage.getItem("userInfo").departmentName === "班子成员"){
                message.info("当前用户不可进行已发布知识管理");
                return false;
            }
            if(
                (item.path === "/page/knowledgeContent?type=department&inlet=manage")
                && !storage.getItem("userInfo").departmentId
            ){
                message.info("当前用户未绑定部门");
                return false;
            }

            sessionStorage.setItem('directory',JSON.stringify(item.children))
            storage.setItem('directory',item.children)
            history.push(item.path);
        }else{
            message.info("暂未开放")
        }
    }

    return (
        <div className="manageFrame">
        {directory.map((item,index)=>{
            return(<div className="manageList" key ={item.id}>
                <p className={"title"}>{item.name}</p>
                {index === 0 && <div className="departmentName">{userInfo&&userInfo.departmentName}</div>}
                {item.children.map(itemChild=>{
                    return(
                        <div className="manageCard" key={itemChild.id} onClick={()=>onchange(itemChild)}>
                            <div className="manageCardImage">
                                <Iconfont type={itemChild.icon} 
                                    iStyle={{
                                        fontSize: '4.5rem',
                                        textAlign: 'center',
                                    }}
                                />
                            </div>
                            <div className="manageCardTitle">{itemChild.name}</div>
                            <div className="manageCardDescription" title={itemChild.description}>{itemChild.description}</div>
                        </div>
                    )
                })}
                <i></i><i></i><i></i><i></i><i></i>
            </div>)
        })}
        </div>      
    );

}

Manage.propTypes = {};

export default Manage;
