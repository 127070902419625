import react, {useEffect,useState} from 'react';
import { Timeline, Typography, Space, Radio, Empty, Modal, Tree, Tabs } from 'antd';
import { FileTextOutlined, FilePptOutlined, FileWordOutlined, FilePdfOutlined } from '@ant-design/icons';

import './index.scss';
import {KnowledgeApply, getSearchParams, ModalConfirm} from '../../../../../utils/tool'
import SelfPagination from "../../../../../components/SelfPagination/index";
import KnowledgeFileIcon from "../../../../../components/KnowledgeFileIcon/index";
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import Api from "../../../../../api/index";

const { Text, Link, Title } = Typography;

const TimelineList = ({policyTypeChange,policyThemeName, listDate, policyType, pagination, paginationChange}) => {

    
    const items = [
        { label: '政策主题跟踪', key: '1'},
        { label: '政策指标查询', key: '2'},
    ];

    const [tabsActiveKey, setTabsActiveKey] = useState('1');

    const handleModeChange = (e) => {
        policyTypeChange(e.target.value);
    };
    
    const onTabsChange=(activeKey)=>{
        setTabsActiveKey(activeKey)
    }

    return (
        <div className="timelineListFrame">
            <Title style={{
                marginBottom: 18,
                marginLeft: 86
            }} level={4}>{policyThemeName}</Title>
            <Radio.Group
                onChange={handleModeChange}
                value={policyType}
                style={{
                    marginBottom: 18,
                    marginLeft: 88,
                }}
            >
                <Radio.Button value="">全部</Radio.Button>
                <Radio.Button value="0">国务院</Radio.Button>
                <Radio.Button value="1">国务部门</Radio.Button>
                <Radio.Button value="2">地方政府</Radio.Button>
            </Radio.Group>
            <div className="contentTabs">
                <Tabs items={items} activeKey={tabsActiveKey} onChange={onTabsChange}/>
            </div>

            {/* 政策指标查询 */}

            {listDate && listDate.length > 0 ? (
                <Timeline mode={'left'}>
                    {listDate.map(item=>{
                        return(<Timeline.Item key={item.id} label={item.createTime ? item.createTime.split(" ")[0]:''}>
                        <div className="iconAndTitle">
                            <div className="icon">
                                <KnowledgeFileIcon
                                    fileType={item.fileType}
                                />
                            </div>
                            <div>
                                <div className="title">
                                    <Text strong style={{fontSize:'0.8rem'}}>《{item.knowledgeTitle}》</Text>
                                </div>
                                <div>
                                    <Space size={"large"}>
                                        <Text type="secondary">{item.issuingAgency}</Text>
                                        <Text type="secondary">{item.docNum}</Text>
                                        <Text type={item.isEffective == 0 ? 'success' : 'danger'}>{item.isEffective == 0 ? '全文有效' : '失效'}</Text>
                                    </Space>
                                </div>
                               
                            </div>
                        </div>
                        <p>{item.knowledgeDigest}</p>
                        <div className="listItemFooter">
                            <div className="listItemFooterItem">
                                <Space size={"large"}>
                                    <Text type="secondary">知识类型：<Link>{item.knowledgeTypeName}</Link></Text>
                                    <Text type="secondary">作者：{item.creator}</Text>
                                    <Text type="secondary">创建时间：{item.createTime}</Text>
                                    <Text type="secondary">{item.knowledgeReadNum}次阅读</Text>
                                </Space>
                            </div>
                        </div>
                        </Timeline.Item>)
                    })}
                </Timeline>
            ):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {pagination && (listDate && listDate.length > 0)?(<div className="pagination">
                <SelfPagination
                    current={pagination.pageIndex} 
                    total={pagination.total} 
                    onSizeChange={paginationChange}
                />
            </div>):""}
        </div>
    )
}

export default TimelineList;