export default {
    addChildNode: "addChildNode",
    insertParentNode: "insertParentNode",
    addBeforeNode: "addBeforeNode",
    addAfterNode: "addAfterNode",
    colorFill: "colorFill",
    linkNote: "linkNote",
    writeExplain: "writeExplain",
    noteDetail: "noteDetail",
    publicNoteList: "publicNoteList",
    referenceSystem: "referenceSystem",
    setAttribute: "setAttribute",
    setName: "setName",
    delete: "delete",
    reference: "reference",
}
