import React from "react";
import { Tabs } from "antd";

import KnowledgeSystemManage from "./knowledgeSystemManage";
import NoteOriginal from "./noteOriginal";
import ExcerptList from "./ExcerptList";

import "./index.scss";

const noteManage = () => {
  return (
    <div className="noteManage">
      <Tabs
        defaultActiveKey="1"
        // onChange={onChange}
        items={[
          {
            label: `知识体系管理`,
            key: "1",
            children: <KnowledgeSystemManage />,
          },
          {
            label: `想法管理`,
            key: "2",
            children: <NoteOriginal />,
          },
          {
            label: `书摘管理`,
            key: "3",
            children: <ExcerptList />,
          },
        ]}
      />
    </div>
  );
};

export default noteManage;
