import React, {useState} from "react";
import { Tabs, Input } from "antd";

import KnowledgeSystem from "./knowledgeSystem";
import AllNote from "../noteManage/noteOriginal";
import ExcerptList from "../noteManage/ExcerptList";

import "./index.scss";

const { Search } = Input;

const noteSearch = () => {

  const [inputValue,setInputValue] = useState("")
  const [searchValue,setSearchValue] = useState("")

   // 搜索笔记
  const onSearch = (value) => {
    setSearchValue(value)
  }

  const onChange = (e) => {
    setInputValue(e.target.value)
  }
  

  const onTabsChange = () => {
    setInputValue("");
    setSearchValue("")
  }

  return (
      <div className="noteManage">
        <Tabs
            defaultActiveKey="1"
            onChange={onTabsChange}
            items={[
              {
                label: `知识体系`,
                key: "1",
                children: (<KnowledgeSystem searchValue={searchValue} />),
              },
              {
                label: `我的想法`,
                key: "2",
                children: (<AllNote searchValue={searchValue} type={"search"}/>),
              },
              {
                label: `我的书摘`,
                key: "3",
                children: (<ExcerptList searchValue={searchValue} type={"search"}/>),
              },
            ]}
        />
        <div className="noteSearchInput">
          <Search
              placeholder={"请输入标题/内容/标签搜索"}
              onSearch={onSearch}
              onChange={onChange}
              value={inputValue}
              allowClear
              style={{
                  width: 240,
              }}
          />
        </div>
      </div>
  );
};

export default noteSearch;
