import react, {useEffect,useState} from 'react';
import { Tabs } from 'antd';
import { CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';

import KnowledgeList from '../../../../components/KnowledgeList'

import './index.scss';

const { TabPane } = Tabs;

const ContentList = ({defaultSortKey = '',autoHight,sortChange, paginationChange, pagination, listDate, selecthValue, knowledgeTypeId}) => {

    // 排序
    const [timeSorts, setTimeSorts] = useState('');
    const [sortKeys, setSortKeys] = useState('');

    useEffect(()=>{
        if(defaultSortKey && (defaultSortKey == '2' || defaultSortKey == '3')){
            setTimeSorts(defaultSortKey);
            setSortKeys('4');
        }else{
            setSortKeys(defaultSortKey)
        }
        
    },[defaultSortKey])

    const sortValueChange = (value) => {
        let _sort = '';
        // 按时间排序时默认降序
        // 0 相关度 1 阅读量 4表示按时间排序 3 降序 2 升序
        if(value == '4'){
            _sort = timeSorts === '3' ? '2' : '3'
        }else{
            _sort = value
        }
        setSortKeys(value);
        setTimeSorts(_sort);
        sortChange(_sort,value)
    }

    return (
        <div className="contentListsFrame">
            <div className="sortBox">
                <Tabs onTabClick={sortValueChange} activeKey={sortKeys}>
                    <TabPane tab="按相关度排序" key={'0'}></TabPane>
                    <TabPane tab="按阅读量排序" key={'1'}></TabPane>
                    <TabPane tab={
                        <div className="timeSort">按时间排序
                            <CaretUpOutlined style={timeSorts === '3' ?{color:'rgba(0, 0, 0, 0.85)'}:{}} />
                            <CaretDownOutlined style={timeSorts === '2' ?{color:'rgba(0, 0, 0, 0.85)'}:{}} />
                        </div>
                    } key={'4'}></TabPane>
                </Tabs>
            </div>
            <KnowledgeList
                type="ES"
                listDate={listDate}
                pagination={pagination}
                paginationChange={paginationChange}
                autoHight={autoHight}
                selecthValue={selecthValue}
                knowledgeTypeId={knowledgeTypeId}
            />
        </div>
    )
}

export default ContentList;