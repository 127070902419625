import Login from "./login.js";
import Common from "./common.js";
import User from "./user";
import Department from "./department";
import Duties from "./duties";
import Menu from "./menu";
import Roles from "./roles";
import Knowledge from "./knowledge";
import Notice from "./notice";
import PersonalCenter from "./personalCenter.js";
import Home from "./home.js";
import Speciality from "./speciality";
import Group from "./group";
import Statistics from "./statistics";
import Settings from "./settings.js"
import Message from "./message.js"
import Note from "./note.js"
import Points from "./points.js"
import Chat from "./chat";
import Banner from "./banner";
import Tag from "./tag";

export default {
  Common,
  Login,
  User,
  Department,
  Duties,
  Menu,
  Roles,
  Knowledge,
  Notice,
  PersonalCenter,
  Home,
  Speciality,
  Group,
  Statistics,
  Settings,
  Message,
  Note,
  Points,
  Chat,
  Banner,
  Tag
};
