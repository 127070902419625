import React, { useState, useEffect } from 'react';
import { DatePicker, message, Typography, Timeline, Empty, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

import Api from "../../../api";
import SelfPagination from "../../../components/SelfPagination";
import Iconfont from "../../../components/Iconfont/Iconfont";
import {gethalfMonthDate, jumpToPage} from '../../../utils/tool'

import './index.scss';

const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const Comment = () => {

    // 查询数据
    const [searchData, setSearchData] = useState({
        "pageIndex": 1,
        "pageSize": 10,
        startTime: gethalfMonthDate()[0],
        endTime: gethalfMonthDate()[1],
    });

    const [dataList, setDataList] = useState([]);

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [spinningSate, setSpinningSate] = useState(false);

    useEffect(() => {
        getList();
    }, [searchData]);

    const getList = async() => {
        setSpinningSate(true);

        try{
            const res = await Api.Message.questNotificationPage({data:searchData});
            setSpinningSate(false);
            if(res && res.code ===0 && res.data){

                let _data = [...res.data.records]
                let newData = [];
                let nowDate = "";

                _data.map(item=>{
                    let date = item.createTime.split(" ")[0];
                    item.time = item.createTime.split(" ")[1];
                    if(nowDate === date){
                        newData[newData.length-1].list.push(item)
                    }else{
                        nowDate = date
                        newData.push({
                            date: date,
                            list: [item]
                        })
                    }

                })
                setDataList(newData);

                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{
                setDataList([]);
            }
            // 更新未读消息数量
            document.dispatchEvent(new CustomEvent('notify'));
        } catch (errInfo) {
            setDataList([]);
            setSpinningSate(false);
            console.log('验证失败:', errInfo);
        }
    }

    const onSizeChange = (page, pageSize) => {
        let _searchData = {...searchData};
        _searchData.pageIndex = page,
        _searchData.pageSize = pageSize,
        setSearchData(_searchData)
    }

    const dateChange = (date, dateString) => {
        let newSearchData = { ...searchData };
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        setSearchData(newSearchData);
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };


    const getDescription = (item) => {
        // parentId 0 提问 其他事回复
        if(item.parentId == 0){
            return <div className="title">{item.time}
            <span>{item.userName}</span>
            在<Link onClick={()=>{checkStatus(item)}}>《{item.knowledgeName}》</Link>
            中向你提问
            </div>
        }else{
            return <div className="title">
            {item.time}
            <span>{item.userName}</span>
            回复了你在
            <Link onClick={()=>{checkStatus(item)}}>《{item.knowledgeName}》</Link>
            中的评论
            </div>
        }
    }

    const checkStatus = async(item) => {
        let res = await Api.Message.checkMessageStatus({
            data: {
                id:item.knowledgeId,
                applyId:item.id
            },
        });

        if(res.code === 0){
            let _code = res.data;
            //  applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请
            // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章  ------ 该知识当前实际状态
            if(_code === -1){
                message.info("当前知识已被删除");
            }else if(_code != 1){
                message.info("当前知识已取消发布");
            }else {
                jumpToPage({...item, id: undefined, type: ""}, "comment")
            }
        }
    }

    return (
        <div className="commentFrame">
            <div className="operation">
                收到的评论
                <RangePicker
                    disabledDate={disabledDate}
                    value={[
                        searchData.startTime?moment(searchData.startTime):"",
                        searchData.endTime?moment(searchData.endTime):""
                    ]}
                    onChange={dateChange}
                    style={{marginLeft:20}}
                />
            </div>
            <div className="content">
                <Spin spinning={spinningSate}>
                    {dataList&&dataList.length>0 ? dataList.map(item=>{
                        return <div className="listItem" key={item.date}>
                            <p className="date">{item.date}</p>
                            <Timeline>
                                {item.list.map(items=>{
                                    return(<Timeline.Item>
                                        <div key={items.noticeId}>


                                            {getDescription(items)}

                                            <div className="questionTitle"  onClick={()=>{checkStatus(items)}}>
                                                <QuestionCircleOutlined style={{marginRight:5}}/>{items.commentTitle}
                                            </div>

                                            <div className="fileList">
                                                {items.commentMessage}
                                            </div>
                                        </div>
                                    </Timeline.Item>)
                                })}
                            </Timeline>
                        </div>
                    }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
            </div>
            {(dataList&&dataList.length>0)?(
                <div className="footer">
                    <SelfPagination
                        total={pagination.total}
                        current={pagination.pageIndex}
                        onSizeChange={onSizeChange}
                    />
                </div>
            ):""}
        </div>
    );
}

export default Comment;
