import React, {useState} from 'react';
import {Button, message, Select, Input} from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import Image from "../../components/Image/Image";

import {serachType} from '../../utils/defaultValue'
import storage from "../../utils/storage";

import KnowledgeTitle from '../../assets/img/knowledgeTitle.png'
import './index.scss';

const TopSearchBar = ({type}) => {
    const history = useHistory();

    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        // if(searchValueTrim){
            storage.setItem('searchData', JSON.stringify({
                text:searchValueTrim,
                searchType:selecthValue
            }));
            history.push('/page/knowledgeSearch');
        // }else{
        //     message.info('请输入需要搜索的内容')
        // }
        
    }

    return (
        <div className='topSearchBar'>
            <Image imgSrc={KnowledgeTitle} iClassName={type?"KnowledgeTitleIndex":"KnowledgeTitle"} />
            <div className="searchBar">
                {/* <SearchOutlined style={{color: '#A5BAC6'}}/> */}
                <Select 
                    value={selecthValue || '全文'}
                    size={"large"} 
                    options={[...serachType]}  
                    onChange={onSelect}
                    bordered={false}
                    style={{width:100}}>
                </Select>
                <Input
                    value={searchValue}
                    placeholder="输入关键字"
                    size={"large"}
                    bordered={false}
                    onChange={onSearchValueChange}
                    onPressEnter={onSearch}
                />
                <Button 
                onClick={onSearch} 
                type="primary" 
                size={"large"}>搜索一下</Button>
            </div>
        </div>    
    );
}
export default TopSearchBar;
