import React from 'react';
import './index.scss';
import Iconfont from "../Iconfont/Iconfont";

const noneData = ({text='暂无数据', icon='icon-kongshuju'}) => {
    return (
        <div className="noneDataBox">
            <Iconfont type={icon} />
            <span>{text}</span>
        </div>
    )
}

export default noneData