import myAxios from '../utils/request.js';

export default {
    // 分页查询banner信息
    async getBannerList (params={}, cb) {
        let res = await myAxios.postJson('/bannerInfo/pageList', params);
        return res.data;
    },

    // 获取 所有已上架的banner图信息
    async getAllBannerListOfNormal (params={}, cb) {
        let res = await myAxios.getJson('/bannerInfo/selectAll', params);
        return res.data;
    },

    // 根据id上架或下架banner
    async changeBannerStatus (params={}, cb) {
        let res = await myAxios.getJson('/bannerInfo/updateStatus', params);
        return res.data;
    },

    // 删除banner
    async deleteBanner (params={}, cb) {
        let res = await myAxios.getJson('/bannerInfo/delete', params);
        return res.data;
    },


    // 新增banner
    async createBanner (params={}, cb) {
        let res = await myAxios.postJson('/bannerInfo/insert', params);
        return res.data;
    },

    // 修改banner
    async updateBanner (params={}, cb) {
        let res = await myAxios.postJson('/bannerInfo/update', params);
        return res.data;
    },
}
