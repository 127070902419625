import React, {useState} from 'react';
import Image from "../../components/Image/Image";
import './404.scss';
import storage from "../../utils/storage";

const NotFound = ({history}) => {

    const [token,setToken] = useState(storage.getItem('token'));

    const goPage = (url) => {
        history.replace(url)
    }

    return (
        <div className="notFoundFrame">
            <Image iClassName="notFoundImg" imgSrc={require("../../assets/img/notFound.png")}></Image>
            <span className="tipText">您访问的页面不存在</span>
            <span className="backBtn" onClick={() => goPage(token ? '/page/homePage' : '/page/login')}>{token ? '回到首页' : '去登录'}</span>
        </div>
    );
}

export default NotFound;