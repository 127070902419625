import React, {useEffect, useState} from "react";
import {CaretUpFilled, CaretDownFilled} from "@ant-design/icons";
import {message, Pagination, Spin, Empty, Modal} from "antd";

import {ModalConfirm, KnowledgeApply} from "../../../../utils/tool";
import SelfPagination from "../../../../components/SelfPagination";
import KnowledgeTypeTab from "../../components/knowledgeTypeTab";
import KnowledgeItem from "../../components/KnowledgeItem";
import storage from "../../../../utils/storage";
import Api from "../../../../api";

import "./published.scss";

const Published = () => {
    const [siftData, setSiftData] = useState({
        isCreated: null,
        isReadNum: null,
        knowledgeTypeId: null,
        pageIndex: 1,
        pageSize: 10,
        type: null,
    });
    // tab筛选
    const [tabsData, setTabsData] = useState([]);
    const [publishedList, setPublishedList] = useState([]);
    const [tabsKey, setTabsKey] = useState("all");
    // 排序
    const [publishedTimeSortKey, setPublishedTimeSortKey] = useState("default");
    const [readNumSortKey, setReadNumSortKey] = useState("default");
    // 请求数据返回的参数
    const [data, setData] = useState({});
    const [isSpinning, setIsSpinning] = useState(true);
    const [documentWidth, setDocumentWidth] = useState(0);

    // 获取已上传数据
    useEffect(() => {
        window.addEventListener('resize', getDocumentWidth);
        getDocumentWidth();

        getSiftList();

        return () => {
            window.removeEventListener('resize', getDocumentWidth);
        }
    }, []);
    useEffect(() => {
        init();
    }, [siftData])

    // 监听窗口大小变化回调
    function getDocumentWidth () {
        const dom = document.documentElement || document.body;
        setDocumentWidth(dom.clientWidth);
    }

    // 获取已发布知识
    const init = async () => {
        setIsSpinning(true);
        let res = await Api.PersonalCenter.getKnowledgePublished({data: siftData});
        setPublishedList(res.data.records);
        setData(res.data);
        setIsSpinning(false);
    };
    const getSiftList = async () => {
        if (storage.getItem("siftTabs")) {
            setTabsData(storage.getItem("siftTabs"));
        } else {
            let res = await Api.PersonalCenter.getAllKnowledgeTab({});
            setTabsData(res);
        }
    };
    const tabsChange = (value) => {
        setTabsKey(value);
        let _siftData = {...siftData};
        _siftData.knowledgeName = "";
        if (value === "all") {
            _siftData.knowledgeTypeId = null;
        } else {
            _siftData.knowledgeTypeId = value;
        }
        setSiftData(_siftData);
        // init(_siftData);
    };
    // 排序
    const colTimeSort = () => {
        let _siftData = {...siftData};
        let nextSort;
        //排序方式的切换
        if (publishedTimeSortKey === "down") nextSort = "up";
        else if (publishedTimeSortKey === "up") nextSort = "default";
        else nextSort = "down";
        //修改当前排序方式
        setPublishedTimeSortKey(nextSort);
        if (nextSort === "up") {
            _siftData.isCreated = 2;
        } else if (nextSort === "down") {
            _siftData.isCreated = 1;
        } else {
            _siftData.isCreated = null;
        }
        setSiftData(_siftData);
    };
    const readNumSort = () => {
        let _siftData = {...siftData};
        let nextSort;
        //排序方式的切换
        if (readNumSortKey === "down") nextSort = "up";
        else if (readNumSortKey === "up") nextSort = "default";
        else nextSort = "down";
        //修改当前排序方式
        setReadNumSortKey(nextSort);
        if (nextSort === "up") {
            _siftData.isReadNum = 2;
        } else if (nextSort === "down") {
            _siftData.isReadNum = 1;
        } else {
            _siftData.isReadNum = null;
        }
        setSiftData(_siftData);
    };
    // 页码变化时
    const changePage = (page) => {
        let _siftData = {...siftData};
        _siftData.pageIndex = page;
        setSiftData(_siftData);
    };
    //取消发布
    const showModal = (knowledgeId) => {
        ModalConfirm({
            title: "确定取消发布这条知识吗？",
            onOk: async (resolve, reject) => {
                try {

                    let applyRres = await KnowledgeApply(knowledgeId, 3);
                    resolve();
                    if (applyRres.bool) {

                        let res = await Api.Knowledge.reviewApply({
                            data: {
                                knowledgeId,
                                applyForType: 3
                            },
                        });
                        if (res && res.code === 0) {
                            message.success("取消发布成功");
                            init();
                        } else {
                            reject();
                        }
                    }
                } catch {
                }
            },
        });
    };
    const toSearch = (value) => {
        let _siftData = {...siftData};
        _siftData.knowledgeName = value
        _siftData.pageIndex = 1
        setSiftData(_siftData)
    }

    const isHavePublish = publishedList?.length > 0;
    return (
        <div className="published">
            <div className="tabs">
                <KnowledgeTypeTab
                    tabsData={tabsData}
                    tabsChange={tabsChange}
                    tabsKey={tabsKey}
                    toSearch={toSearch}
                    // searchIsNull={true}
                ></KnowledgeTypeTab>
            </div>
            <div className="content">
                <div className="sort">
                    <p onClick={colTimeSort}>
                        按发布时间排序
                        <div className="sortIcon">
                            <CaretUpFilled
                                style={{
                                    color: publishedTimeSortKey == "up" ? "#0099cc" : null,
                                }}
                            />
                            <CaretDownFilled
                                style={{
                                    color: publishedTimeSortKey == "down" ? "#0099cc" : null,
                                }}
                            />
                        </div>
                    </p>
                    <p onClick={readNumSort}>
                        按阅读量排序
                        <div className="sortIcon">
                            <CaretUpFilled
                                style={{color: readNumSortKey == "up" ? "#0099cc" : null}}
                            />
                            <CaretDownFilled
                                style={{color: readNumSortKey == "down" ? "#0099cc" : null}}
                            />
                        </div>
                    </p>
                </div>
                <div className="knowledgeList">
                    <Spin size="large" spinning={isSpinning}>
                        {
                            isHavePublish ?
                                (
                                    <KnowledgeItem
                                        list={publishedList}
                                        type={"published"}
                                        showModal={(knowledgeId) => showModal(knowledgeId)}
                                    />
                                ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
                        }
                    </Spin>
                </div>
                {
                    isHavePublish ? (
                        <div className="pager">
                            <SelfPagination
                                current={siftData.pageIndex}
                                onSizeChange={changePage}
                                total={data.total * 1}
                                simple={documentWidth < 1100}
                            ></SelfPagination>
                        </div>
                    ) : undefined
                }
            </div>
        </div>
    );
};

export default Published;
