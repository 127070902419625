import react, { useEffect, useState } from "react";
import {
  Tabs,
  Modal,
  Button,
  Input,
  Divider,
  Select,
  message,
  Space,
  Empty,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./index.scss";

const { Search } = Input;
const KnowledgeTypeTab = ({
  tabsData,
  tabsKey,
  tabsChange,
  toSearch,
  searchIsNull,
}) => {
  const [serchValue, setSearchValue] = useState("");

  useEffect(()=>{
    setSearchValue("")
  },[tabsKey])

  const onSearch = (value) => {
    toSearch(serchValue);
  };

  const searchChange = (value, type) => {
    setSearchValue(value);
  }

  return (
    <div className="knowledgeTypeTabFrame">
      {tabsData?.length > 0 ? (
        <>
          <Tabs items={tabsData} activeKey={tabsKey} onChange={tabsChange} />
          {/* {!searchIsNull ? ( */}
          {/* <> */}
            {/* {serchBlock ? ( */}
              <div className="searchInput">
                <Search 
                  placeholder={"请输入标题搜索"}
                  value={serchValue}
                  onSearch={toSearch}
                  allowClear={true}
                  onChange={(e)=>{
                    searchChange(e.target.value)
                  }}
                  onPressEnter={onSearch}
                />
              </div>
          {/* //   ) : (
          //     <div className="searchIcon">
          //       <SearchOutlined onClick={handeleToSearch} />
          //     </div>
          //   )}
          // </> */}
          {/* ) : null} */}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: "1.2rem" }}
        />
      )}
    </div>
  );
};

export default KnowledgeTypeTab;
