import react, {useEffect,useState} from 'react';
import { Tooltip, message, Space, Empty, Modal, Tree, Spin } from 'antd';
import {
    FileTextOutlined, FileWordOutlined, FileZipOutlined,
    FilePptOutlined, FilePdfOutlined, FileExcelOutlined,
    FileJpgOutlined
} from '@ant-design/icons';

import Iconfont from '../Iconfont/Iconfont'
import './index.scss';

const KnowledgeFileIcon = ({fileType,isPic}) => {
    let type = fileType;

    let imgStyle = {
        fontSize: '1rem',
        color:"#6DD1E2",
    }, imgType =  ["png", "jpg", "jpeg", "gif", "bmp", "titf", "psd", "raw", "eps", "svg"]

    if (type === "pdf" && isPic === 1) {
        type = "pdf1";
    }

    if (imgType.includes(type)) {
        type = "img";
    }

    const fileIcon = {
        online: (<FileTextOutlined/>),
        docm: (<FileWordOutlined/>),
        doc: (<FileWordOutlined/>),
        docx: (<FileWordOutlined/>),
        ppt: (<FilePptOutlined/>),
        pptx: (<FilePptOutlined/>),
        pptm: (<FilePptOutlined/>),
        pdf: (<FilePdfOutlined/>),
        pdf1: (
            <Iconfont
                type={'icon-icon-png'}
                iStyle={{
                    fontSize: '1rem',
                    color:"#F43A59",
                    marginRight: '0.5rem',
                    marginTop: '1px',
                }}
            />
        ),
        xls: (<FileExcelOutlined />),
        xlsx: (<FileExcelOutlined />),
        xlsm: (<FileExcelOutlined />),
        zip: (
            <Iconfont
                type={'icon-zip'}
                iStyle={{
                    fontSize: '1rem',
                    color:"#99451b",
                }}
            />
        ),
        img: (<Iconfont
            type={'icon-image'}
            iStyle={imgStyle}
        />),
    };

    return (fileIcon[type] || (<FileTextOutlined/>))
}

export default KnowledgeFileIcon;
