import createStore from "zustand";

const zuStandStore = createStore((set, get) => {
    return {
        // 文件进度
        uploadFileList: [],
        fileProcessObject: {},
        fileUploadStatus: {},
        fileOtherData: {},
        uploadOpen: false,
        modalStatus: "small",
        knowledgeId: null,
        linkStatus: null, // success/closed/stop/fail/null
        isShowAiChat: false,


        changeUploadStateFunc: (myStore) => {
            return set(myStore || {})
        },

        getUploadStateFunc: () => {
            return get()
        },

        getIsShowAiChat () {
            return get().isShowAiChat;
        },

        changeIsShowAiChat (isShow) {
            return set({isShowAiChat: isShow})
        },
    }
})

export {zuStandStore};
