import React from 'react'
import {Spin} from 'antd'
import PropTypes from 'prop-types'
import Icon, {SyncOutlined, CloseOutlined,RightOutlined} from '@ant-design/icons'

import Api from "../../api";

import './index.scss'

const verifyFiles = require.context('../../assets/img/verify/', true, /\.jpeg$/);
const files = verifyFiles.keys()

// 防抖函数
function debounce(func, delay) {
  let timer;
  return function() {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      clearTimeout(timer)
      func()
    }, delay)
  }
}

class SliderVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moveCount: 0, // 移动次数
      moveMaxCount: 4, // 移动次数上限  达到上限则重新刷新滑块
      isSuccess: false,
      tipText: '', //移动次数上限  达到上限则重新刷新滑块
      isReload: false,
      bigImage: '',
      smallImage: '',
      isMobile: false,
      sliderLeft: 2,
      smallImageTop: 2,
      maxLeft: 50,
      successMoveLeft: 50,
      nonceStr:"",
      spinningType:false,
      defaultCanvasHeight:0
    }
  }

  sliderBox = null

  initMouseX = 0

  sliderDom = null

  smallImage = null

  componentDidMount() {
    const {userAgent} = navigator
    const mobileSign = ['Android','iPhone','SymbianOS','Windows Phone','iPad','iPod',]
    const isMobile = mobileSign.filter(item => userAgent.includes(item)).length > 0
    this.sliderBox = document.getElementById('sliderBox')
    this.sliderDom = document.getElementById('slider')
    this.smallImage = document.getElementById('smallImage')
    this.setState({
      isMobile,
    }, () => {
      this.getBigImage()
    })

    window.addEventListener('resize', debounce(this.getBigImage, 500))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.getBigImage, 500))
  }

  //  生成随机验证图片
  getBigImage = async() => {
    let canvasWidth = document.getElementById('contentBox')?.clientWidth;

    if(canvasWidth > 0){

      // 用于默认撑起弹窗高度
      let canvasHeight = canvasWidth / 1200 * 675;

      this.setState({
        spinningType:true,
        defaultCanvasHeight:canvasHeight
      })
      
      const res = await  Api.Login.getCaptcha({
          data:{
            canvasWidth:canvasWidth,
            blockHeight:50,
            blockWidth:50,
          },
      });

      if(res.code === 0 && res.data){
        this.setState({
          bigImage: res.data.canvasSrc,
          moveCount: 0,
          // tipText: '',
          sliderLeft: 2,
          maxLeft: (this.sliderBox?.clientWidth || 0) - 38,
          smallImage: res.data.blockSrc,
          smallImageTop: res.data.blockY,
          successMoveLeft: 0,
          nonceStr: res.data.nonceStr,
        })
      }
    }
    // console.log(res,"resresres")

    // const index = Math.floor(Math.random() * files.length)
    // const image = verifyFiles(files[index])
    // if (image === this.state.bigImage) {
    //   this.getBigImage()
    // } else {

    //   this.setState({
    //     bigImage: image,
    //     moveCount: 0,
    //     tipText: '',
    //     sliderLeft: 2,
    //     maxLeft: this.sliderBox.clientWidth - 38
    //   })
    // }
  }

  // 截取小图片 随机left top  36*36  扣除图洞
  clipImage = () => {
    const image = document.getElementById('bigImage')
    const width = image.clientWidth
    const height = image.clientHeight
    const smallMinLeft = 2; const smallMinTop = 2;
    const {clientWidth: smallWidth, clientHeight: smallHeight} = this.smallImage || {}
    const smallWidth2 = smallWidth * 2;
    const startLeft = Math.floor(Math.random() * (width - (smallWidth2 + smallMinLeft))) + smallWidth
    const startTop = Math.floor(Math.random() * (height - (smallHeight + smallMinTop)))
    const url = this.getImagePortion(image, smallWidth, smallHeight, startLeft, startTop)
    this.setState({
      smallImage: url,
      smallImageTop: startTop,
      successMoveLeft: startLeft,
    })
  }

  // 实际切图函数
  getImagePortion(imgObj, newWidth, newHeight, startX, startY, ratio=1){
    /* the parameters: - the image element - the new width - the new height - the x point we start taking pixels - the y point we start taking pixels - the ratio */
    // set up canvas for thumbnail
    const tnCanvas = document.createElement('canvas');
    const tnCanvasContext = tnCanvas.getContext('2d');
    tnCanvas.width = newWidth; tnCanvas.height = newHeight;

    /* use the sourceCanvas to duplicate the entire image. This step was crucial for iOS4 and under devices. Follow the link at the end of this post to see what happens when you don’t do this */
    const bufferCanvas = document.createElement('canvas');
    const bufferContext = bufferCanvas.getContext('2d');
    bufferCanvas.width = imgObj.width;
    bufferCanvas.height = imgObj.height;
    bufferContext.drawImage(imgObj, 0, 0, imgObj.width, imgObj.height);

    /* now we use the drawImage method to take the pixels from our bufferCanvas and draw them into our thumbnail canvas */
    tnCanvasContext.drawImage(bufferCanvas, startX * ratio,startY * ratio,newWidth * ratio, newHeight * ratio,0,0,newWidth,newHeight);
    return tnCanvas.toDataURL();
  }

  // 重新生成校验图片
  reloadImage = () => {
    if (this.state.isReload) {
      return
    }
    this.setState({
      isReload: true
    }, () => {
      const timeout = setTimeout(() => {
        clearTimeout(timeout)
        this.getBigImage()
        this.setState({
          isReload: false,
          isSuccess: false,
        })
      }, 500)
    })
  }


  sliderVerifyState = (msg, flag) => {
    // const {moveCount, moveMaxCount} = this.state;
    // if (moveCount >= (moveMaxCount - 1)) {
      this.reloadImage()
    // } else {
      this.setState({
        sliderLeft: 2,
        tipText: msg,
        isSuccess: false,
        // moveCount: moveCount + 1
      })
    // }
  }

  // 鼠标松开事件的方法实现 flag ：true 移动端 flase pc端
  mouseupHandler = (e, flag) => {
    // const ex = e || window.event || e.which
    // const nowX = ex.clientX
    // 移动端 ontouchend监听不到clientX
    // const offsetX = flag ? touchOffsetX : this.getOffsetX(nowX - this.initMouseX, 2, this.state.maxLeft)
    this.sliderDom.style.transition = 'left 0.2s linear'
    this.smallImage.style.transition = 'left 0.2s linear'
    const {sliderLeft, successMoveLeft, nonceStr} = this.state;

    this.success(sliderLeft,nonceStr);

    // if (Math.abs(sliderLeft - successMoveLeft) <= 5) { // 误差范围内都算成功
    //   this.setState({
    //     isSuccess: true,
    //     tipText: '手速快又准，恭喜你验证成功！',
    //   }, () => {
    //     this.success()
    //   })
    //   // setTimeout(() => {
    //   //
    //   // }, 1000)
    // } else {
    //   if (moveCount >= (moveMaxCount - 1)) {
    //     this.reloadImage()
    //   } else {
    //     this.setState({
    //       sliderLeft: 2,
    //       tipText: '非常遗憾，验证失败了，再试一次吧！',
    //       isSuccess: false,
    //       moveCount: moveCount + 1
    //     })
    //   }
    // }
    if (flag) {
      document.ontouchend = null
      document.removeEventListener('touchmove', this.touchmoveFunc, { passive: false });
    } else {
      document.onmousemove = null
      document.onmouseup = null
    }
  }

  // 鼠标移动事件的方法实现
  mousemoveHandler = (e, flag) => {
    const ex = e || window.event || e.which
    const nowX = ex.clientX
    const offsetX = this.getOffsetX(nowX - this.initMouseX, 2, this.state.maxLeft)
    this.setState({
      sliderLeft: offsetX
    })

    // 如果不设置滑块滑动时会出现问题（目前还不知道为什么）
    if (!flag) e.preventDefault()
  }

  // 监听函数
  touchmoveFunc = (e) => {
    this.mousemoveHandler(e.touches[0], this.state.isMobile)
  }

  // 给滑块添加鼠标按下事件
  handleMouseDown = (ev, flag) => {
    this.sliderDom.style.transition = ''
    this.smallImage.style.transition = ''
    if (this.state.isSuccess) {
      return
    }
    const ex = ev || window.event || ev.which
    this.initMouseX = ex.clientX
    this.setState({tipText: ''})
    if (flag) {
      document.addEventListener('touchmove', this.touchmoveFunc, { passive: false })
      document.ontouchend = e => {
        this.mouseupHandler(e.touches[0], flag)
      }
    } else {
      document.onmousemove = e => {
        this.mousemoveHandler(e, flag)
      }
      document.onmouseup = e => {
        this.mouseupHandler(e, flag)
      }
    }
  }

  // 获取鼠标当前需要移动多少距离的方法
  getOffsetX = (offset, min, max) => {
    let move = offset
    if (offset < min) {
      move = min
    } else if (offset > max) {
      move = max
    }
    return move
  }

  close = () => {
    const {closeFunc} = this.props
    closeFunc();
    this.setState({
      tipText: '',
    })
  }

  success = (sliderLeft,nonceStr) => {
    const {successFunc} = this.props;
    successFunc(sliderLeft,nonceStr)
  }

  closeSpinning = () => {
    this.setState({spinningType: false})
  }

  render() {
    const {
      isSuccess, tipText, isReload,
      bigImage, smallImage, isMobile,
      sliderLeft, smallImageTop, successMoveLeft,
      spinningType,
      defaultCanvasHeight
    } = this.state

    return (
      <div>
        <Spin spinning={spinningType} tip="加载中...">
          <div className="sliderVerifyFrame">
            {/* 图标 */}
            {/*<img src={verifyLogo} className={"verifyLogo"} alt="" />*/}
            {/* 关闭 */}
            <CloseOutlined className={"closeIcon"} onClick={() => this.close()} type="close" />
            {/* 背景图 */}
            <div id='contentBox' className={"contentBox"} style={{minHeight:defaultCanvasHeight}}>
              <img id='bigImage' className={"bigImage"} src={bigImage} 
              onLoad={() => this.closeSpinning()} 
              alt="" />
              {/* 阴影位置 */}
              <div className={"positionSign"} style={{left: successMoveLeft,top: smallImageTop,}} />
              {/* 小图 */}
              <span
                id='smallImage'
                className={"smallImg"}
                style={{
                backgroundImage: `url("${smallImage || ''}")`,
                left: sliderLeft,
                top: smallImageTop,
              }}
              />
              {/* 刷新 */}
              <SyncOutlined className={"reloadIcon"} spin={isReload} onClick={() => this.reloadImage()} />
            </div>
            {/* 滑块盒子 */}
            <div id='sliderBox' className={"sliderBox"}>
              拖动滑块完成拼图
              {/* 滑块 */}
              <span
                id='slider'
                className={"slider"}
                style={{left: sliderLeft}}
                onTouchStart={(e) => this.handleMouseDown(e.touches[0], isMobile)}
                onMouseDown={(e) => isMobile ? null: this.handleMouseDown(e, isMobile)}
              >
                <RightOutlined className={"right"} type="right" />
              </span>
            </div>
            {/* 提示文字 */}
            <div className={"tipBox"} style={{color: isSuccess ? '#009900' : 'red'}}>
              {tipText ? (<span>{tipText}</span>) : undefined}
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}

SliderVerify.propTypes = {
  closeFunc: PropTypes.func.isRequired,
  successFunc: PropTypes.func.isRequired,
}

export default SliderVerify
