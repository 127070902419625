import react, {useEffect,useState} from 'react';
import { Tabs, Modal, Button, Input, Divider, Select, message, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import storage from "../../utils/storage";
import {serachType} from '../../utils/defaultValue'

import './index.scss';

// tabsData - 渲染数据
// tabsKey - 当前选中的id
// tabsChange - 点击回调函数
// searchData - 列表查询数据

const KnowledgeType = ({tabsData,tabsKey,tabsChange, searchData ={}, knowledgeTypeId}) => {

    const [open, setOpen] = useState(false);
    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onClose = ()=>{
        setOpen(false)
    }

    const openSearch = ()=>{
        setOpen(true)
    }

    const onSearch = (value) => {
        let searchValueTrim = searchValue.trim();
        let _searchData = {...searchData,...{
            text:searchValueTrim,
            searchType:selecthValue,
            knowledgeTypeIds:searchData.knowledgeTypeId ? [searchData.knowledgeTypeId]: [],
            departmentIds:searchData.departmentId ? [searchData.departmentId]: [],
            sort:'0'
        }}

        delete _searchData.knowledgeTypeId;
        delete _searchData.departmentId;
        
        // if(searchValueTrim){
            storage.setItem('searchData', JSON.stringify(_searchData));
            window.open(`/page/knowledgeSearch`)
            // history.push(`/page/knowledgeSearch`);
        // }else{
        //     message.info('请输入需要搜索的内容')
        // }
    }

    return(<div className="knowledgeTypeFrame">
        <Tabs items={tabsData} activeKey={tabsKey} onChange={tabsChange}/>

        <div className="searchIcon" onClick={openSearch}>
            <SearchOutlined />
        </div>

        <Modal
            onCancel={onClose}
            open={open}
            title={"搜索"}
            footer={null}
            wrapClassName={"searchModal"}
            maskClosable={false}
        >
            <div className="searchBar">
                <Select 
                    value={selecthValue || '全文'}
                    size={"large"} 
                    options={knowledgeTypeId === '4' ? [
                        {
                            value: '1',
                            label: '全文'
                        },
                        {
                            value: '0',
                            label: '标题'
                        }
                    ]:[...serachType]}  
                    onChange={onSelect}
                    bordered={false}
                    style={{width:100}}>
                </Select>
                <Divider className='verticalDivider' type="vertical" />
                <Input
                    value={searchValue}
                    placeholder="输入关键字"
                    size={"large"}
                    bordered={false}
                    onChange={onSearchValueChange}
                    onPressEnter={onSearch}
                />
            </div>
            <div className="footer">
                <Space>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onClose} >取消</Button>
                </Space>
            </div>
        </Modal>
    </div>)
}

export default KnowledgeType