import React,{useState, useEffect} from 'react';
import { Tooltip, DatePicker, Button, Space, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment'

import SelfTable from "../../../../components/SelfTable/index"

import './index.scss'
import Api from "../../../../api";
import {pagingParam} from "../../../../utils/defaultValue";
import {downloadFileBlob, get7DaysDate} from "../../../../utils/tool";

const { RangePicker } = DatePicker;

const KnowledgeWorthStatistics = () => {

    const defalRange = get7DaysDate();

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [sortedInfo, setSortedInfo] = useState({});
    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        ...defalRange
    });

    const [operationData, setOperationData] = useState(defalRange);

    const [tableLoading, setTableLoading] = useState(false);

    const [listData, setListData] = useState([]);
    
    const columns = [
        {
            title: '知识名称',
            dataIndex: 'knowledgeName',
            width: '',
            ellipsis:true,
            render:(record)=>{
                return <Tooltip title={record} placement="topLeft">
                {record}
                </Tooltip>
            }
        },
        {
            title: '作者姓名',
            dataIndex: 'authorName',
            width: '200px',
            ellipsis:true,
            render:(record)=>{
                return <Tooltip title={record} placement="topLeft">
                {record}
                </Tooltip>
            }
        },
        {
            title: '工号',
            dataIndex: 'jobNum',
            width: '150px',
            ellipsis:true,
            render:(record)=>{
                return <Tooltip title={record} placement="topLeft">
                {record}
                </Tooltip>
            }
        },
        {
            title: '被阅读量(次）',
            dataIndex: 'readNum',
            width: '150px',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'readNum' ? sortedInfo.order : null,
        },
        {
            title: '被下载量（次）',
            dataIndex: 'downloadNum',
            width: '150px',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'downloadNum' ? sortedInfo.order : null,
        },
        {
            title: '被收藏量（次）',
            dataIndex: 'collectionNum',
            width: '150px',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'collectionNum' ? sortedInfo.order : null,
        },
        {
            title: '收获点赞量(次）',
            dataIndex: 'likeNum',
            width: '160px',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'likeNum' ? sortedInfo.order : null,
        },
        {
            title: '收获评论量（条）',
            dataIndex: 'commentNum',
            width: '170px',
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'commentNum' ? sortedInfo.order : null,
        }
      ];

    useEffect(()=>{
        getList()
    },[JSON.stringify(searchData)])

    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Statistics.getKnowledgeWorthStatisticsList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }

    const onChanges = (pagination, filters, sorter, extra) => {
        let newSearchData = {...searchData}
        newSearchData.typeSortMessage = sorter.field === 'uploadNum' ? 0:
        sorter.field === 'readNum' ? 1:
        sorter.field === 'downloadNum' ? 2:
        sorter.field === 'collectionNum' ? 3:
        sorter.field === 'likeNum' ? 4:
        sorter.field === 'commentNum' ? 5:
        sorter.field === 'answerNum' ? 6:'';
        
        newSearchData.sort = sorter.order === 'descend' ? 0 : sorter.order === 'ascend' ? 1 :'';

        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.field,
        });

        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    };

    const dateChange = (date, dateString) => {
        let newSearchData = {...searchData}
        newSearchData.startTime = dateString[0];
        newSearchData.endTime = dateString[1];
        newSearchData.pageIndex = 1;
        setSearchData(newSearchData);
    }

    const exportFile = async() => {
        try{
            const res = await Api.Statistics.getKnowledgeWorthStatisticsExport({data:searchData});
            if(res){
                downloadFileBlob(res,'知识价值统计明细.xlsx')
            }
        }catch{

        }
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        if(type === "timeRange"){
            newOperationData.startTime = value[0];
            newOperationData.endTime = value[1];
        }else{
            newOperationData[type] = value;
        }
        newOperationData.pageIndex = 1;
        setOperationData(newOperationData)
    }

    const onSearch = () => {
        operationData.authorName = operationData.authorName ? operationData.authorName.trim(): operationData.authorName
        operationData.authorJobNum = operationData.authorJobNum ? operationData.authorJobNum.trim(): operationData.authorJobNum
        operationData.knowledgeName = operationData.knowledgeName ? operationData.knowledgeName.trim(): operationData.knowledgeName
        
        setSearchData({
            ...searchData,
            ...operationData
        });
    }

    const onResetting = () => {
        setOperationData({
            ...defalRange,
            authorName:"",
            authorJobNum:"",
            knowledgeName:""
        });
        setSearchData({
            ...pagingParam,
            ...defalRange
        })
        setSortedInfo({});
    }

    const disabledDate = (current) => {
        // Can not select days after today and today
        return current && current > moment().endOf('day');
    };

    return(
        <div className="knowledgeWorthStatisticsFrame">
            <div className="title">知识价值统计</div>
            <div className="operation">
                <div className="operationLeft">
                    <div className="operationItem">
                        <span>选择时段：</span>
                        <RangePicker 
                        ranges={{
                            '当天': [moment(), moment()],
                            '近7天':[moment().subtract(7, 'day'), moment()],
                            '本月': [moment().startOf('month'), moment()],
                            '近三个月': [moment().subtract(3, 'months'), moment()],
                        }}
                        disabledDate={disabledDate}
                        value={operationData.startTime?[moment(operationData.startTime), moment(operationData.endTime)]:[]}
                        onChange={(date, dateString)=>{
                            searchChange(dateString,"timeRange")
                        }}/>
                    </div>
                    <div className="operationItem">
                        <span>知识名称：</span>
                        <Input value={operationData.knowledgeName} onChange={(e)=>{
                            searchChange(e.target.value,"knowledgeName")
                        }}/>
                    </div>
                    <div className="operationItem">
                        <span>作者姓名：</span>
                        <Input value={operationData.authorName} onChange={(e)=>{
                            searchChange(e.target.value,"authorName")
                        }}/>
                    </div>
                    <div className="operationItem">
                        <span>工号：</span>
                        <Input value={operationData.authorJobNum} onChange={(e)=>{
                            searchChange(e.target.value,"authorJobNum")
                        }}/>
                    </div>
                    <div className="operationItem" style={{width:170}}>
                        <Space size={"large"}> 
                            <Button type="primary" onClick={onSearch}>搜索</Button>
                            <Button onClick={onResetting}>重置</Button>
                        </Space>
                    </div>
                </div>
                <div className="operationRight">
                    <Button onClick={exportFile} icon={<DownloadOutlined />}>导出</Button>
                </div>
            </div>
            <div className="table">
                <SelfTable
                    dataSource={listData}
                    columns={columns}
                    pagination={pagination}
                    loading={tableLoading}
                    onChange={onChanges} 
                    scroll={{
                        x:'1700px'
                    }}
                />
                {/* <Table 
                    rowKey="id" 
                    columns={columns} 
                    bordered 
                    dataSource={listData} 
                    onChange={onChanges} 
                    pagination={{
                        total:pagination.total,
                        current:pagination.pageIndex,
                        pageSize:pagination.pageSize,
                        showSizeChanger:true,
                        showQuickJumper:true,
                        showTotal:()=> `总计${pagination.total}条数据`
                    }}
                    scroll={{
                        scrollToFirstRowOnChange:true,
                        y:tableHight
                    }}
                    loading={tableLoading}
                /> */}
            </div>
        </div>
    )
}
export default KnowledgeWorthStatistics