import myAxios from "../utils/request.js";

const prefixUrl = "/chat/third";

export default {
    // 获取该用户的智能问答和知识问答ID
    async getChatId (params={}, cb) {
        let res = await myAxios.getJson(`${prefixUrl}/sys/get/chatIds`, params);
        return res.data;
    },

    // 检查知识库是否为空
    async checkKnowledgeLibrary (params={}, cb) {
        let res = await myAxios.getJson(`${prefixUrl}/sys/check/knowledge`, params);
        return res.data;
    },

    // 查询问答 聊天记录列表
    async getChatList (params={}, cb) {
        let res = await myAxios.getJson(`${prefixUrl}/sys/list/chat`, params);
        return res.data;
    },

    // 点赞 / 点踩
    async changeMessageAttitude (params={}, cb) {
        let res = await myAxios.postJson(`${prefixUrl}/sys/edit/chatMessage`, params);
        return res.data;
    },

    // 停止回答
    async stopAnswer (params={}, cb) {
        let res = await myAxios.postJson(`${prefixUrl}/sys/stop/chat`, params);
        return res.data;
    },

    // 获取 wps 文件连接
    async getWPSFilePreview (params={}, cb) {
        let res = await myAxios.getJson(`/chat/files/${params.fileId}/previewUrl`, params);
        delete params.fileId;
        return res.data;
    },
};
