import React, { useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {Select, Input, Button} from 'antd';


import storage from "../../../../../utils/storage";
import {serachType} from '../../../../../utils/defaultValue'
import './index.scss';

const Search = ({}) => {
    const [title] = useState();
    let history = useHistory();

    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        storage.setItem('searchData', JSON.stringify({
            text:searchValueTrim,
            searchType:selecthValue
        }));
        history.push('/page/knowledgeSearch');
    }

    return (
        <div className="searchFrame">
            <div className="searchBar">
                <Select 
                    value={selecthValue || '全文'}
                    size={"large"} 
                    options={[...serachType]}  
                    onChange={onSelect}
                    bordered={false}
                    style={{width:100}}>
                </Select>
                <Input
                    value={searchValue}
                    placeholder="输入关键字"
                    size={"large"}
                    bordered={false}
                    onChange={onSearchValueChange}
                    onPressEnter={onSearch}
                />
                <Button 
                onClick={onSearch} 
                type="primary" 
                size={"large"}>搜索一下</Button>
            </div>
        </div>
    )
}

export default Search;
