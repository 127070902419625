import react, {useEffect,useState} from 'react';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import copy from "copy-to-clipboard";
import storage from "./storage.js";
import Api from "../api";

const { confirm } = Modal;
let detailsLoading = false;

// 高亮显示指定字符串
export const strHighlight=(str,targetStr)=>{
    const reg = new RegExp( targetStr , "g" )
    const res=str.replace(reg,`<span style='color: red;'>${targetStr}</span>`)
    return res
}

// 返回文件类型
export function getFileType() {
    return {
        fileType: {
            docx: 'icon-file-word',
            docm: 'icon-file-word',
            doc: 'icon-file-word',
            xlsx: 'icon-file-excel',
            xls: 'icon-file-excel',
            xlsm: 'icon-file-excel',
            pdf: 'icon-file-pdf1',
            png: 'icon-PNGtubiao',
            jpg: 'icon-JPGtubiao',
            jpe: 'icon-JPGtubiao',
            jpeg: 'icon-JPGtubiao',
            pjpeg: 'icon-JPGtubiao',
            gfif: 'icon-JPGtubiao',
            gif: 'icon-file-GIF',
            xml: 'icon-file-excel',
            xmind: 'icon-file-markdown',
            ppt: 'icon-file-ppt',
            pptx: 'icon-file-ppt',
            pptm: 'icon-file-ppt',
            zip: 'icon-zip',
            mpp: 'icon-file-markdown',
            pjp: 'icon-file_project',
            txt: 'icon-a-filetxt-line',
            default: 'icon-file',
        },
        enableViewFileType: [
            'docx','doc','docm','pdf','xls','xlsx','xlsm','ppt','pptx','pptm',
            'jpg','png','jpeg','gif',
            'txt', 'zip',
        ],
    }
}

// 获取地址栏搜索key-value
export function getSearchParams() {
    let searchStr = location.search.replace('?', '')
    let searchObject = {
        pathname: location.pathname,
    }
    if (searchStr) {
        const decodeSearchStr = searchStr
        let searchArr = decodeSearchStr.split('&')
        searchArr.forEach(item => {
            let arr = item.split("=")
            searchObject[arr[0]] = decodeURIComponent(arr[1])
        })
    }
    return searchObject
}

// 退出登录
export function logOutFc(){
    storage.clearAll();
    // localStorage.loginType = "logOut"
    location.replace('/login?isLogin=0');
}

// 获取近7天
export function get7DaysDate(){
    return {
        "startTime": moment().subtract(7, 'day').format('YYYY-MM-DD'),
        "endTime": moment().format('YYYY-MM-DD'),
    }
}

// 获取当天日期
export function getTodayDate(){
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const today = `${year}-${month}-${day}`;

    return [today, today]
}

// 获取本周开始和结束日期
export function getWeekDate() {
    const today = new Date();
    // 获取当前是星期几
    const day = today.getDay()-1;
    // 计算本周第一天的日期
    const startDate = new Date(today.getFullYear(), today.getMonth()+ 1, today.getDate() - day);
    // 计算本周最后一天的日期
    const endDate = new Date(today.getFullYear(), today.getMonth()+ 1, today.getDate() - day + 6);
    // 返回本周的日期范围

    const _startDate = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`
    const _endDate = `${endDate.getFullYear()}-${endDate.getMonth()}-${endDate.getDate()}`

    return [_startDate, _endDate]
}

// 获取昨天
export function getYesterdayDate() {
    var yesterday = moment().clone().subtract(1, 'days').format('YYYY-MM-DD'); // 使用moment.js的subtract方法将日期减去一天
    return [yesterday, yesterday]
}

// 获取近半个月的开始和结束日期
export function gethalfMonthDate() {
    const startDate = moment(new Date()).subtract(15, 'days').format('YYYY-MM-DD');
    const endDate = moment().format("YYYY-MM-DD");;
    return [startDate, endDate]
}

// 今年以来
export function geThisYearDateRange() {
    const startDate = moment().startOf('year').format('YYYY-MM-DD'); //今年第一天
    const endDate = moment().format('YYYY-MM-DD') //今天;
    return [startDate, endDate]
}

// 上一年度
export function gePreviousYear() {
    const startDate = moment().subtract(1,'year').startOf('year').format('YYYY-MM-DD');//去年第一天
    const endDate = moment().subtract(1,'year').endOf('year').format('YYYY-MM-DD');//去年最后一天
    return [startDate, endDate]
}

// 最近一年
export function getLastYear() {
    const startDate = moment().subtract(1,'year').format('YYYY-MM-DD');//一年前
    const endDate = moment().format('YYYY-MM-DD');//今天
    return [startDate, endDate]
}

export function downloadFileBlob(res,name){
    var elink = document.createElement('a');
    if(res.headers){
        var blob = new Blob([res.data]);
        elink.download = res.headers["content-disposition"].split('=')[1]; //decodeURI('787');
    }else{
        var blob = new Blob([res]);
        elink.download = name
    }
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
    window.URL.revokeObjectURL(elink.href); // 释放掉blob对象
}

export function downloadFile(res){
    // pdf的时候浏览器回默认打开，为了不影响当前页面流程，先打开新窗口展示
    let suffixs =  res.path.split('.')
    let suffix = suffixs[suffixs.length - 1]
    if(suffix === 'pdf'){
        window.open(res.path)
    }else{
        var elink = document.createElement('a');
        elink.download = res.fileName;
        elink.style.display = 'none';
        elink.href = res.path;
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
    }
}

// 颜色转字符串
export function rgbToStr (rgb) {
    const {r,g,b,a} = rgb;
    return `rgba(${r},${g},${b},${a})`;
}

// 判断颜色深浅
export function getRgbLevel(rgb) {
    const {r,g,b,a} = rgb;
    return (r * 0.299 + g * 0.587 + b * 0.114) / a;
}

export const KnowledgeApply = async(id,type)=>{
    // applyForType 1下载申请,2发布知识申请,3取消发布知识申请,4阅读权限申请
    try{
        let res = await Api.Knowledge.isNeedToReview({
            data:{
                knowledgeId:id,
                applyForType:type
            }
        });
        if(res.code == 0 && res.data){
            let _title = type === 1 ? '下载' :
                type === 2 ? '发布知识':
                type === 3 ? '取消发布知识':
                type === 4 ? '阅读':"";
            let _message = "";
            if(res.data.bool){
                return res.data;
            }else if(res.data.code === 2){
                // 0未通过，1通过，-1未申请，2待审批中，3申请已发送
                message.info(res.data.message);
                return false;
            }else if(res.data.code === 0){
                // 未通过可以重新发起请求
                _message = `你发起的${_title}请求被拒绝，你可以选择重新发起申请`
            }else if(!res.data.bool){
                _message = res.data.message
            }

            Modal.confirm({
                title: `${_title}提醒`,
                content: (
                  <div>
                    <p>{_message}</p>
                  </div>
                ),
                cancelText:"取消",
                okText:"申请",
                onOk: async() => {
                    return new Promise((resolve, reject) => {
                        goApplication(id,type,resolve, reject);
                    }).catch(() => {console.log('Oops errors!');reject()});
                },
            });

            return false;
        }
    }catch{}
}

const goApplication = async(id,type,resolve, reject) => {
    try{
        let res = await Api.Knowledge.reviewApply({
            data:{
                knowledgeId:id,
                applyForType:type,
            }
        });
        resolve();
        if(res.code == 0 && res.data){
            message.info(res.data.message);
        }
    }catch{}
}

export const ModalConfirm = (operation) => {
    confirm({
        title: operation.title,
        icon: <ExclamationCircleOutlined />,
        content: operation.content || '',
        onOk: ()=>{
            return new Promise((resolve, reject) => {
                operation.onOk(resolve, reject)
            }).catch(() => {console.log('Oops errors!');reject()});
        },
        onCancel() {},
    });
}

const openWPSFileUrl = async(relationFileId) => {
    try{
        let res = await Api.Knowledge.getWPSFilePreview({fileId:relationFileId});
        detailsLoading = false;
        if(res&&res.code===200&&res.data){
            window.open(res.data.link);
        }
    }catch{detailsLoading = false;}
}

export const goKnowledgeDetails = async(item, childItem)=>{
    if(detailsLoading){return false;}
    // 参考研报下爬虫数据默认分享范围是全部，可直接查看
    // sourceType 来源类型 默认0 1为爬虫
    // if(item.sourceType===1 && item.knowledgeTypeId === '7'){
    //     jumpToPage(item);
    // }else

    detailsLoading = true;
    if(item.type==='ES' && item.knowledgeTypeId === '4'){
        // 政策爬虫文件没有文件类型，默认给pdf
        detailsLoading = false;
        window.open(`/page/knowledgeDetails?knowledgeId=${item.id}&type=policy&knowledgeFileType=${item.fileType}&permission=true&keyword=${item.keyword}`)
        // if (item.titleHref) {
        //     detailsLoading = false;
        //     window.open(item.titleHref);
        // }else if(item.relationFileId){
        //     openWPSFileUrl(item.relationFileId);
        // }
    }else{
        try{
            let res = await Api.Knowledge.ifTeamMembers({});
                // 0 班子成员，1 表示不是班子成员，班子成员不需要审批
            if(res && res.code === 0 && res.data === 0){
                // 是班子成员
                jumpToPage(item, null, null, childItem);
            }else{
                // 检查是否需要走审批
                checkPermissions(item, childItem)
            }
        }catch{detailsLoading = false;}
    }
}

const checkPermissions = async(item, childItem) => {
    try{
        let res = await KnowledgeApply(item.id || item.knowledgeId,4);
        if(res.bool){
            jumpToPage(item, null, null, childItem);
        }else{
            detailsLoading = false;
        }
    }catch{detailsLoading = false;}
}

export const jumpToPage = (item, type, history, childItem) => {
    
    const {selecthValue, type: itemType, keyword, id: itemId, knowledgeId, fileType} = item;

    console.log(item, "=========ldf=======");
    detailsLoading = false;
    let id = itemId || knowledgeId;
    let encodedObject = encodeURIComponent(id);
    let url = "";
    let knowledgeFileType = fileType

    if(fileType === 'zip' && childItem){
        url = `/page/knowledgeDetails?knowledgeId=${childItem.id}&type=${itemType}&keyword=${keyword}&zipId=${id}`
        knowledgeFileType = childItem.fileType
    }else if(itemType === 'ES' && String(selecthValue) === '1'){
        url = `/page/knowledgeDetails?knowledgeId=${encodedObject}&type=${itemType}&keyword=${keyword}`
    } else {
        url = `/page/knowledgeDetails?knowledgeId=${encodedObject}`;
        if (type) {
            url += `&type=${type}`
        }
    }

    url += `&knowledgeFileType=${knowledgeFileType}&permission=true`

    if (history) {
        history.replace(url);
    } else {
        window.open(url)
    }
}

export async function getWPSFileUrl(fileId, keywords, callBack) {
    let flag = false;
    try {
        document.getElementById("office").innerHTML = "";
        let res = await Api.Chat.getWPSFilePreview({fileId});
        if (res && res.code === 200 && res.data) {
            flag = true;
            if (callBack) {
                callBack(res.data.link);
            }

            this.WPSSDK = OpenSDK.config({
                url: res.data.link,
                zoom: 0.6,
                mount: document.querySelector('#office'),
            })

            // 等待加载完毕
            await this.WPSSDK.ready();

            this.WPSAPP = this.WPSSDK.Application;
            console.log(this.WPSSDK);

            // ES搜索带入分片后的关键字
            // 是否有关键字
            if (keywords) {
                // 高亮文字并获取位置
                const data = await this.WPSAPP.ActiveDocument.Find.Execute(keyword, true);
                // 滚动到第一次出现的位置
                const range = await this.WPSAPP.ActiveDocument.Range(data[0].pos, data[0].pos + data[0].len);
                await this.WPSAPP.ActiveDocument.ActiveWindow.ScrollIntoView(range);
            }
            return res.data.link;
        } else {
            if (callBack) {
                callBack("none");
            }
        }
    } catch(e) {
        if (!flag && callBack) {
            callBack("none");
        }
        console.log(e);
    }
}

export function getWebSocketUrl () {
    let hostname = location.hostname;
    let wsUrlObject = {
        "localhost": "ws://192.168.255.6:9989", // 本地开发测试
        "knowledge-test.aicon.com.cn": "wss://knowledge-test.aicon.com.cn/api", // 测试环境
        "kms.cisdiconsulting.com": "wss://kms.cisdiconsulting.com/api", // 正式环境
        "demo-kms.aicon.com.cn": "wss://demo-kms.aicon.com.cn/api", // 演示环境
    };
    return wsUrlObject[hostname] || "ws://192.168.255.6:9989";
}

export function getAnswerWebSocketUrl () {
    let hostname = location.hostname;
    let wsUrlObject = {
        "localhost": "ws://192.168.255.82:80", // 本地开发测试
        "knowledge-test.aicon.com.cn": "wss://knowledge-test.aicon.com.cn/chat", // 测试环境
        "kms.cisdiconsulting.com": "wss://kms.cisdiconsulting.com/chat", // 正式环境
        "demo-kms.aicon.com.cn": "wss://demo-kms.aicon.com.cn/api", // 演示环境
    };
    return wsUrlObject[hostname] || "ws://192.168.255.82:80";
}

export function getLoginUrl () {
    let hostname = location.hostname;
    let wsUrlObject = {
        "localhost": "", // 本地开发测试
        "knowledge-test.aicon.com.cn": "https://service-platform.aicon.com.cn/page/index", // 测试环境
        "kms.cisdiconsulting.com": "https://cisdi.aicon.com.cn/page/index", // 正式环境
        "demo-kms.aicon.com.cn": "https://demo-cisdi.aicon.com.cn/page/index", // 演示环境
    };
    return wsUrlObject[hostname] || "";
}

// 复制字符串
export function copyToClip(str) {
    copy(str || "", {
        message: "复制失败，请升级浏览器！",
        format: "text/plain",
        debug: true,
        onCopy: () => {
            message.success("复制成功！")
        }
    });
}

// 判断值是否有效
export function valueIsValid(val) {
    let value = val;
    const isInvalid = value === undefined || value === null || value === "";
    if (isInvalid) {
        value = "";
    }
    return {value, isValid: !isInvalid};
}

// 处理响应
export function responseDataFilter (res, text="请求失败！", isTip=true) {
    const {code, data, msg} = res || {};
    if (code === 0) {
        return data || 1;
    } else if (isTip) {
        if (code !== undefined) {
            message.error(msg || res?.message || text);
        }
    }
}

export function createMapStateToProps (stateKeyList) {
    return function (storeState, ownProps) {
        let result = {}
        for (let item of stateKeyList) {
            const {alias, key} = item;
            result[alias || key] = storeState[key];
        }
        return result;
    }
}
export function createNapDispatchToProps (dispatchList= []) {
    return function (dispatch, ownProps) {
        let result = {}
        for (let item of dispatchList) {
            const {funcName, type} = item;
            result[funcName] = dispatch({type})
        }
        return result;
    }
}

// 判断是不是 视频
export function isVideoFunc(val) {
    let videoType = [
        "avi", "mp4", "mov", "qt", "mkv",
        "wmv", "xvid", "tc", "flv", "imax",
        "psd", "hr-hdtv",
    ];
    let isVideo = false;
    if (val) {
        if (val.includes("base64")) {
            isVideo = val.includes("video");
        } else {
            let arr = val.split(".");
            let lastStr = arr[arr.length - 1];
            isVideo = videoType.includes(lastStr);
        }
    }

    return isVideo;
}
