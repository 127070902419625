import React, { useState, useEffect} from 'react';
import {Spin, Empty, Tabs, Tooltip, Image, message} from 'antd'
import { VerticalAlignTopOutlined, SyncOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import Iconfont from "../../components/Iconfont/Iconfont";
import TopSearchBar from "../../components/TopSearchBar";
import KnowledgeList from '../../components/KnowledgeList'

import Api from '../../api'
import {pagingParam} from '../../utils/defaultValue'
import {goKnowledgeDetails} from '../../utils/tool'
import storage from "../../utils/storage";
import './originalIndex.scss';

const HomePage = () => {
    const history = useHistory();
    // const [fileList, setFileList] = useState([]);

    // const [uploading, setUploading] = useState(false);

    // const uploadProps = {
    //     fileList:fileList,
    //     showUploadList:{
    //         showPreviewIcon:false,
    //     },
    //     beforeUpload:(file, list)=>{
    //         return false;
    //     },
    //     onChange:({ file,fileList}) => {
    //         setFileList(fileList);
    //     }
    // }

    const items = [
        { label: '推荐知识', key: '0' },
        { label: '最新知识', key: '1' },
    ];

    const [recommendedKnowledge, setRecommendedKnowledge] = useState([]);
    const [activeKey, setActiveKey] = useState('0');
    const [loadingState, setLoadingState] = useState(false);

    const [hotKnowledgeSearchData, setHotKnowledgeSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [knowledgePagination, setKnowledgePagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "pages":0
    });
    const [hotKnowledgeList, setHotKnowledgeList] = useState([]);
    const [hotLoadingState, setHotLoadingState] = useState(false);

    const [professorList, setProfessorList] = useState([]);
    const [professorLoadingState, setProfessorLoadingState] = useState(false);

    const [questionAndAnswerList, setQuestionAndAnswerList] = useState([]);
    const [questionAndAnswerState, setQuestionAndAnswerState] = useState(false);

    const [hotSearchKey, setHotSearchKey] = useState('0');

    // 查询数据
    const [hotWordsSearchData, setHotWordsSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "pages":0
    });
    const [hotWordsList, setHotWordsList] = useState([]);


    useEffect(()=>{
        getKnowledgeList(0);

        // getProfessorList();

        // getQuestionAndAnswer();
    },[])


    useEffect(()=>{

        if(hotSearchKey === '0'){
            if(hotKnowledgeSearchData.pageIndex === 1){
                getHotKnowledgeList()
            }else{
                let newSearchData = {...hotKnowledgeSearchData}
                newSearchData.pageIndex = 1;
                setHotKnowledgeSearchData(newSearchData)
            }
        }else if(hotSearchKey === '1'){
            if(hotWordsSearchData.pageIndex === 1){
                getHotWordsList()
            }else{
                let newHotWordsSearchData = {...hotWordsSearchData}
                newHotWordsSearchData.pageIndex = 1;
                setHotWordsSearchData(newHotWordsSearchData)
            }
        }

    },[hotSearchKey])

    useEffect(()=>{
        if(hotSearchKey === '1'){
            getHotWordsList()
        }
    },[hotWordsSearchData])
    
    useEffect(()=>{
        if(hotSearchKey === '0'){
            getHotKnowledgeList()
        }
    },[hotKnowledgeSearchData])

    // 精选问答
    const getQuestionAndAnswer = async() => {
        setQuestionAndAnswerState(true);
        try{
            let res = await Api.Home.getHigherQuestionAnswer({data:pagingParam});
            setQuestionAndAnswerState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _records = res.data.records;
                setQuestionAndAnswerList(_records);
            }else{
                setQuestionAndAnswerList([]);
            }
        }catch{setQuestionAndAnswerState(false);}
    }

    // 热点知识
    const getHotKnowledgeList = async() => {
        setHotLoadingState(true);
        try{
            let res = await Api.Home.getHotKnowledgeList({data:{
                ...hotKnowledgeSearchData,
                hotFlag:1
            }});
            setHotLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _data = res.data;
                _data.records.map((item,index)=>{

                    if(_data.current > 1){
                        item.serialNumber = ((_data.current-1)*10)+(index+1);
                    }else{
                        item.serialNumber = _data.current*index;
                    }
                })
                setHotKnowledgeList(_data.records);
                setKnowledgePagination({
                    pageIndex: _data.current,
                    pageSize: _data.size,
                    pages:_data.pages,
                })
            }else{
                setHotKnowledgeList([]);
            }
        }catch{setHotLoadingState(false);}
    }

    // 搜素热词
    const getHotWordsList = async() => {
        setHotLoadingState(true);
        try{
            let res = await Api.Home.getHotWords({data:{
                ...hotWordsSearchData,
                hotFlag:1
            }});
            setHotLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _data = res.data;
                _data.records.map((item,index)=>{

                    if(_data.current > 1){
                        item.serialNumber = ((_data.current-1)*10)+(index+1);
                    }else{
                        item.serialNumber = _data.current*index;
                    }
                })
                setHotWordsList(_data.records);
                setPagination({
                    pageIndex: _data.current,
                    pageSize: _data.size,
                    pages:_data.pages,
                })
            }else{
                setHotWordsList([]);
            }
        }catch{setHotLoadingState(false);}
    }

    // 专家榜
    const getProfessorList = async() => {
        setProfessorLoadingState(true);
        try{
            let res = await Api.Home.getProfessorRanking({data:pagingParam});
            setProfessorLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _records = res.data.records;
                setProfessorList(_records);
            }else{
                setProfessorList([]);
            }
        }catch{setProfessorLoadingState(false);}
    }

    // 知识列表
    const getKnowledgeList = async(queryType) => {
        setLoadingState(true);
        try{
            let res = await Api.Home.getRecommendOrLatestKnowledge({data:{queryType}});
            setLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                let _records = res.data.records;
                for(let i=0; i<_records.length; i++){
                    let userList = _records[i].knowledgeUserRelVOList;
                    let userText = []
                    for(let j=0; j<userList.length; j++){
                        userText.push(userList[j].userName)
                    }
                    _records[i].authors = userText
                }

                setRecommendedKnowledge(_records);
            }else{
                setRecommendedKnowledge([]);
            }
        }catch{setLoadingState(false);}
    }

    const onTabsChange = (activeKey) => {
        setRecommendedKnowledge([]);
        setActiveKey(activeKey);
        getKnowledgeList(activeKey);
    }

    const changeHotSearch = (activeKey) => {
        setHotSearchKey(activeKey)
    }

    const exchangeHotList = () => {
        if(hotSearchKey === '1'){
            // 热词
            let newHotWordsSearchData = {...hotWordsSearchData}
            if(newHotWordsSearchData.pageIndex >= pagination.pages){
                newHotWordsSearchData.pageIndex = 1
            }else{
                newHotWordsSearchData.pageIndex = newHotWordsSearchData.pageIndex+1
            }
            setHotWordsSearchData(newHotWordsSearchData)
        }else{
            // 热点知识
            let newHotSearchData = {...hotKnowledgeSearchData}
            if(newHotSearchData.pageIndex >= knowledgePagination.pages){
                newHotSearchData.pageIndex = 1
            }else{
                newHotSearchData.pageIndex = newHotSearchData.pageIndex+1
            }
            setHotKnowledgeSearchData(newHotSearchData)
        }
    }
    
    const goSearchHotWords = (items) => {
        storage.setItem('searchData', JSON.stringify({
            text:items.hotWord,
            searchType:'1'
        }));
        history.push('/page/knowledgeSearch');
    }

    return (
        <div className="homePageFrame" id={"homePageFrame"}>
            <div className="homePageTopBox">
                <div className="homePageBg"></div>
                <div className="topBox">
                    <TopSearchBar type={"index"}/>
                </div>
            </div>
            <div className="homeTopSearchBox">
                {/* 搜索框 */}
            </div>
            <div className="homeContent">
                <div className="leftContent">
                    <div className="knowledge">
                        <Spin tip="数据加载中" spinning={loadingState}>
                            <Tabs items={items} onChange={onTabsChange}/>
                            <KnowledgeList
                                type={"home"}
                                // showProjectName={activeKey==='0'?true:false}
                                listDate={recommendedKnowledge}
                            />
                        </Spin>
                    </div>
                    {/* <div className="questionAndAnswer">
                        <Spin tip="数据加载中" spinning={questionAndAnswerState}>
                            <p>精选问答</p>
                            {questionAndAnswerList.map(items=>{
                                return(<div  className="questionAndAnswerItem">
                                    <div className="questionItem">
                                        <span>Q</span>
                                        <span>{items.questionTitle}</span>
                                    </div>
                                    <div className="answerItem">
                                        <span>A</span>
                                        <span>{items.questionTitle}</span>
                                        <span>{items.questionTitle}</span>
                                    </div>
                                    <div>
                                        <Space size={"large"} className="spaceBox">
                                            <span><MessageOutlined className="MessageOutlined"/>{items.answerNum}回答</span>
                                            <span><LikeOutlined className="LikeOutlined" />{items.likeNum}赞</span>
                                        </Space>
                                    </div>
                                </div>)
                            })}
                        </Spin>
                    </div> */}
                </div>
                <div className="rightContent">
                    <div className="hotKnowledgeList">
                        <div className="hotKnowledgeTitleBox">
                            <div className={`listTitle ${hotSearchKey === '0' && "selectedTitle"}`}
                                onClick={()=>changeHotSearch('0')}
                            >
                                <Iconfont type={'icon-shuba'}/>
                                <span>热点知识</span>
                            </div>

                            <div className={`listTitle ${hotSearchKey === '1' && "selectedTitle"}`}
                                onClick={()=>changeHotSearch('1')}
                            >
                                <Iconfont type={'icon-reci1'}/>
                                <span>搜索热词</span>
                            </div>
                            {/* {hotSearchKey === '1'&& */}
                                <div className="exchange" onClick={exchangeHotList}>
                                    <SyncOutlined />换一换
                                </div>
                            {/* } */}
                        </div>


                        <Spin tip="数据加载中" spinning={hotLoadingState}>
                            {hotSearchKey === '0' && <div>
                                {hotKnowledgeList && hotKnowledgeList.length > 0 ?hotKnowledgeList.map((items,index)=>{
                                    return(<div key={items.id} className="hotListItem"
                                        onClick={()=>goKnowledgeDetails(items)}
                                    >
                                    {index===0?<VerticalAlignTopOutlined style={{
                                            float: 'left',
                                            marginTop: '4px'
                                    }} />:
                                        <span
                                            className="index"
                                            style={index===1?{color:'#FF4721'}:
                                            index===2?{color:'#F0851A'}:
                                            index===3?{color:'#DDBD36'}:{color:'#999999'}}
                                        >{index}</span>
                                    }
                                        <Tooltip title={items.knowLedgeName} placement="topLeft">
                                            <span className="knowLedgeName" >{items.knowledgeName}</span>
                                        </Tooltip>

                                        {/* 0阅读、1点赞、2收藏、3评论 */}
                                        {
                                            items.hotType === 0 ? <Iconfont type={'icon-yuedujilu'}/> :
                                            items.hotType === 1 ? <Iconfont type={'icon-dianzan_kuai'}/> :
                                            items.hotType === 2 ? <Iconfont type={'icon-shoucangxiao'}/> :
                                            items.hotType === 3 ? <Iconfont type={'icon-pinglun'}/>: null
                                        }
                                       
                                    </div>)
                                }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>}

                            {hotSearchKey === '1' && <div>
                                {hotWordsList && hotWordsList.length > 0 ?hotWordsList.map((items,index)=>{
                                    return(<div key={items.id} className="hotListItem hotWordsListItem"
                                        onClick={()=>goSearchHotWords(items)}
                                    >
                                     {items.serialNumber===0?<VerticalAlignTopOutlined />:
                                        <span
                                            className="index"
                                            style={
                                                items.serialNumber===1?{color:'#FF4721'}:
                                                items.serialNumber===2?{color:'#F0851A'}:
                                                items.serialNumber===3?{color:'#DDBD36'}:
                                                {color:'#999999'}
                                            }
                                        >{items.serialNumber}</span>
                                    }
                                        <span className="hotWord" title={items.hotWord}>{items.hotWord}</span>

                                        {items.iconFlag===1&&
                                            // <Image
                                            //     width={60}
                                            //     preview={false}
                                            //     src={items.fileUrl}
                                            //     className="hotImg"
                                            // />
                                            <span className="hotLabel">热</span>
                                        }
                                    </div>)
                                }):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </div>}
                        </Spin>
                    </div>
                    {/* <div className="professorList">
                        <Spin tip="数据加载中" spinning={professorLoadingState}>
                            <div className="listTitle">
                                <SafetyOutlined className="professorIcon" />
                                <span>专家榜</span>
                            </div>
                            {professorList.map((items,index)=>{
                                return(<div key={items.professorId} className="hotListItem">
                                    <span
                                        className="indexs"
                                        style={index===0?{color:'#FF4721'}:
                                        index===1?{color:'#F0851A'}:
                                        index===2?{color:'#DDBD36'}:{color:'#999999'}}
                                    >{index+1}</span>
                                    <div className="headSculpture">图片</div>
                                    <div>
                                        <p className="professorName" title={items.professorName}>{items.professorName}</p>
                                        <Space size={"large"} className="spaceBox">
                                            <span><MessageOutlined className="MessageOutlined"/>{items.answerNum}回答</span>
                                            <span><LikeOutlined className="LikeOutlined" />{items.likeNum}赞</span>
                                        </Space>
                                    </div>
                                </div>)
                            })}
                        </Spin>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
export default HomePage;
