import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';

import Api from "../../../api";
import ContributeStatistics from './contributeStatistics/index';
import PersonalStatistics from './personalStatistics/index';
import PersonalStudyStatistics from './personalStudyStatistics/index';
import storage from "../../../utils/storage";
import KnowledgeWorthStatistics from './knowledgeWorthStatistics/index';
import DepartmentalContribution from './departmentalContribution/index';
import BackIcon from "../../../components/BackIcon/index";

import './index.scss';

const KnowledgeStatistics = () => {

    const [items, setItems] = useState(0);
    const [activeKey, setActiveKey] = useState("");
    const [departmentList, setDepartmentList] = useState([]);

    useEffect(() => {
        setItems([
            { label: '部门知识贡献概览', key: '1', children: <DepartmentalContribution departmentList={departmentList}/> }, // 务必填写 key
            { label: '部门知识贡献统计', key: '2', children: <ContributeStatistics departmentList={departmentList}/> },
            { label: '个人知识贡献统计', key: '3', children: <PersonalStatistics departmentList={departmentList}/> },
            { label: '知识价值统计', key: '4', children: <KnowledgeWorthStatistics departmentList={departmentList}/> },
            { label: '个人知识学习统计', key: '5', children: <PersonalStudyStatistics departmentList={departmentList}/> }
        ])

        let currntKey = sessionStorage.activeKey ? sessionStorage.activeKey : "1"
        setActiveKey(currntKey)
    }, [departmentList]);

    useEffect(()=>{
        setInitDate();
    },[])

    const setInitDate = async() => {
        const resDepartment = await Api.Department.getDepartmentListAll({});
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            newData.push({
                value: '',
                label: '无',
            })
            setDepartmentList(newData);
        }
    }

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        sessionStorage.activeKey = activeKey;
    }
   
    return (
        <div className="userFrame">
            <div  className="userTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={items}></Tabs>
            </div>
            <BackIcon />
        </div>
    );
};
export default KnowledgeStatistics;
