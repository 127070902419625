import React,{useState, useEffect} from 'react';
import { Table, DatePicker, Space, Button, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import BackIcon from "../../../components/BackIcon/index";
import Iconfont from "../../../components/Iconfont/Iconfont";
import './index.scss'

const PublicKnowledge = () => {

    const history = useHistory();

    const items = [
        {
            key:"1",
            title:"通用知识",
            description:"管理公共知识及其分组",
            icon:"icon-zhishi2",
            path:"/page/manage/publicKnowledge/generalKnowledge?type=generalKnowledge"
        },
        {
            key:"2",
            title:"通用参考研报",
            description:"运行和维护公共参考研报及分类",
            icon:"icon-shenheliebiao",
            path:"/page/manage/publicKnowledge/generalKnowledge?type=researchReport"
        },
        {
            key:"3",
            title:"通用政策",
            description:"运行和维护公共跟踪的政策专题",
            icon:"icon-bijibendiannao",
            path:"/page/manage/policyOperation?inlet=manage"
        }
    ]

    const onchange = (item) => {
        if(item.path){
            history.push(item.path);
        }else{
            message.info("暂未开放")
        }
    }

    return (
        <div className="publicKnowledgeFrame">
            <div className="title">通用知识管理</div>
            <BackIcon />

            <div className="manageList">
                {items.map(itemChild=>{
                    return(
                        <div className="manageCard" key={itemChild.key} onClick={()=>onchange(itemChild)}>
                            <div className="manageCardImage">
                                <Iconfont type={itemChild.icon}
                                    iStyle={{
                                        fontSize: '4.5rem',
                                        textAlign: 'center',
                                    }}
                                />
                            </div>
                            <div className="manageCardTitle">{itemChild.title}</div>
                            <div className="manageCardDescription" title={itemChild.description}>{itemChild.description}</div>
                        </div>
                    )
                })}
                <i></i><i></i><i></i><i></i><i></i>
            </div>
        </div>
    )
}
export default PublicKnowledge
