import myAxios from "../utils/request.js";

export default {
  // 获取数据总览
  async getShowData(params = {}, cb) {
    let res = await myAxios.postJson("/userPersonalData/pageList", params);
    return res.data;
  },
  // 获取阅读数据
  async getUserKnowledgeRead(params = {}, cb) {
    let res = await myAxios.postJson("/userKnowledgeRead/pageList", params);
    return res.data;
  },
  //  获取用户积分情况总览
  async getUserScore(params = {}, cb) {
    let res = await myAxios.postJson("/userScoreManagement/getUserScore", params);
    return res.data;
  },
  // 获取已上传数据
  async getUserKnowledgeUpload(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/getPersonalKnowledgePublished",
      params
    );
    return res.data;
  },
  // 获取我的收藏知识
  async getKnowledgeCollection(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeCollectionManagement/pageList",
      params
    );
    return res.data;
  },
  // 取消收藏
  async cancleCollection(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeCollectionManagement/cancleCollection",
      params
    );
    return res;
  },
  // 获取用户已上传的知识
  async getKnowledgePublished(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/getPersonalKnowledgePublished",
      params
    );
    return res.data;
  },
  // 获取未发布知识
  async getKnowledgeNotPublished(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/getPersonalKnowledgeUnPublished",
      params
    );
    return res.data;
  },
  // 未发布页面进行删除操作
  async deleteKnowledgeNotPublished(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/removeKnowledge",
      params
    );
    return res.data;
  },
  // 取消发布
  async cancelPublish(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeManagement/cancelPublish",
      params
    );
    return res.data;
  },
  // 点击发布
  async okPublish(params = {}, cb) {
    let res = await myAxios.getJson(
      "/knowledgeManagement/publishKnowledge",
      params
    );
    return res.data;
  },
  // 获取笔记原文管理
  async getKnowledgeNoteOwner(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeNoteDetailManagement/searchKnowledgeNoteOwner",
      params
    );
    return res.data;
  },
  // 上传后新增知识--保存/发布
  async addNewKnowledge(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeManagement/addKnowledge",
      params
    );
    return res.data;
  },
  // 获取积分变更记录
  async getUserPointsHistory(params = {}, cb) {
    let res = await myAxios.postJson("/userScoreDetail/pageList", params);
    return res.data;
  },
  // 获取tab筛选类型列表
  async getAllKnowledgeTab(params = {}, cb) {
    let tabsList = [];
    let res = await myAxios.getJson(
      "/knowledgeTypeManagement/getKnowledger",
      params
    );
    res.data.data.forEach((item) => {
      if (item.id != 4 && item.id != 8) {
        tabsList.push({
          label: item.knowledgeTypeName,
          key: item.id,
          value: item.id,
        });
      }
    });
    tabsList.unshift({ label: "全部知识", key: "all", value: "all" });
    return tabsList;
  },


  // 获取书摘管理列表
  async getMyExcerptList(params = {}, cb) {
    let res = await myAxios.postJson(
      "/knowledgeExcerptManagement/getMyExcerptVO",
      params
    );
    return res.data;
  },
};
