import react, {useEffect,useState} from 'react';
import { message, Typography, Select, DatePicker, Spin, Input, Button, Divider, Radio, Collapse, Tag, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Iconfont from "../../../components/Iconfont/Iconfont";
import Image from "../../../components/Image/Image";
import ContentList from "./contentList";

import Api from "../../../api";
import storage from "../../../utils/storage";
import {getTodayDate, getWeekDate} from "../../../utils/tool";
import {serachType,pagingParam} from '../../../utils/defaultValue'

import KnowledgeText from '../../../assets/img/knowledgeText.png'
import './index.scss';

const { Text, Link } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const KnowledgeSearch = () => {
    
    const [loadingState, setLoadingState] = useState(false);

    const [knowledgeType, setKnowledgeType] = useState([]);
    const [knowledgeTypeId, setKnowledgeTypeId] = useState([]);
    const [knowledgeTypeValue, setKnowledgeTypeValue] = useState([]);

    const [departmentData, setDepartmentData] = useState([]);
    const [departmentId, setDepartmentId] = useState([]);
    const [departmentValue, setDepartmentValue] = useState([]);

    const ptherKnowledgeType = [
        {
            label:"参考研报",
            key:'7',
            value:'7'
        },
        {
            label:"政策跟踪",
            key:'4',
            value:'4',
        }
    ]

    const [sortKey, setSortKey] = useState('0');

    const [collapseKey, setCollapseKey] = useState([]);
     
    const [searchData, setSearchData] = useState({
        ...pagingParam,
        "searchType":'1',
        "sort":'0' //0相关度排序，1阅读量排序 2 时间升序 3时间降序
    });

    const [dataRange, setDataRange] = useState([
        {
            value: '0',
            label: '时间不限'
        },
        {
            value: 'today',
            label: '一天内'
        },
        {
            value: 'week',
            label: '一周内'
        },
        {
            value: 'custom',
            label: '自定义'
        }
    ]);

    const [rangeKey, setRangeKey] = useState('0');
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    // 列表数据
    const [listDate, setListDate] = useState([]);
    const [listHight, setListHight] = useState(0);


    const [checkedDepartmentAll, setCheckedDepartmentAll] = useState(false);
    const [departmentIndeterminate, setDepartmentIndeterminate] = useState(false);

    const [checkedKnowledgeTypeAll, setCheckedKnowledgeTypeAll] = useState(false);
    const [knowledgeTypeIndeterminate, setKnowledgeTypeIndeterminate] = useState(false);

    const [disabledRadio, setDisabledRadio] = useState(false);
    const [disabledCheckbox, setDisabledCheckbox] = useState(false);
    

    useEffect(()=>{
        let _searchData = storage.getItem('searchData');
        console.log(_searchData,"_searchData")
        if(_searchData){
            // 知识中心带入的数据
            let searchDataJson = JSON.parse(_searchData);
            

            storage.removeItem('searchData');

            let _knowledgeTypeId = [];

            if(searchDataJson.knowledgeTypeIds){
                setKnowledgeTypeId(searchDataJson.knowledgeTypeIds)
            }

            setSelecthValue(searchDataJson.searchType? searchDataJson.searchType : "1");
            setSearchValue(searchDataJson.text)

            delete searchDataJson.departmentSubclassificationId;
            delete searchDataJson.policyRegionId 
            delete searchDataJson.policyThemeId

            if(_knowledgeTypeId[0] === '4'){
                delete searchDataJson.departmentIds;
                // 政策跟踪
            }

            if(searchDataJson.endTime || searchDataJson.startTime){
                setRangeKey(sessionStorage.rangeType)
            }

            let _defalutsearchData = {
                ...searchData,
                ...searchDataJson
            };

            // 查询数据
            setSearchData(_defalutsearchData);
            
            setCollapseKey(['1'])

            if(_knowledgeTypeId[0] != '4'){
                setDepartmentId(searchDataJson.departmentIds ? searchDataJson.departmentIds : [])
            }
            
            if(!searchDataJson.departmentIds || searchDataJson.departmentIds.length <= 0){
                // 第一次进入页面默认勾选上部门的全部按钮，但是后面的具体部门不需要勾选
                setCheckedDepartmentAll(true);
            }
            if(!searchDataJson.knowledgeTypeIds || searchDataJson.knowledgeTypeIds.length <= 0){
                // 类型默认勾选上除 政策和研报外的其他类型
                setCheckedKnowledgeTypeAll(true);
            }
        }else{
           
            // 第一次进入页面默认勾选上部门的全部按钮，但是后面的具体部门不需要勾选
            setCheckedDepartmentAll(true);
            // 类型默认勾选上除 政策和研报外的其他类型
            setCheckedKnowledgeTypeAll(true);
        }
    },[]);
    
    useEffect(()=>{
        let divHeight= document.querySelector('#root');
        setListHight(divHeight.offsetHeight-259);
    },[]);

    useEffect(()=>{
        console.log(searchValue,searchData,"请求数据")
        if(
            // 搜素输入框必须有值
            searchValue && 
            searchData.text &&
            // 除政策外的知识类型调用
            JSON.stringify(searchData.knowledgeTypeIds) != '[4]'
            // 等待部门和知识类型加载完成
            // departmentData.length>0&&
            // knowledgeType.length>0
        ){
            // 除政策外的知识类型，或者不选择知识类型
            if((searchData.knowledgeTypeIds && searchData.knowledgeTypeIds[0] != '4') || !searchData.knowledgeTypeIds){

                console.log(searchValue,searchData,"请求调用")

                storage.setItem('searchData',JSON.stringify(searchData))
                getResult();
            }
        }
    },[
        searchData.sort,
        JSON.stringify(searchData.knowledgeTypeIds),
        JSON.stringify(searchData.departmentIds),
        searchData.startTime,
        searchData.endTime,
        searchData.pageIndex,
        searchData.pageSize,
        searchData.text,
        searchData.searchType
    ]);

    
    useEffect(()=>{
        console.log(searchValue,searchData,"请求")
        if(
            searchValue && 
            searchData.text &&
            searchData.knowledgeTypeIds && 
            searchData.knowledgeTypeIds[0] == '4'
            // 等待部门和知识类型加载完成
            // departmentData.length>0&&
            // knowledgeType.length>0
        ){
            storage.setItem('searchData',JSON.stringify(searchData))
            // 政策跟踪
            // 政策跟踪不区分部门，所以不能监听departmentIds，监听departmentIds会导致接口请求两次
            getPolicyList();
        }
    },[
        searchData.sort,
        JSON.stringify(searchData.knowledgeTypeIds),
        searchData.startTime,
        searchData.endTime,
        searchData.pageIndex,
        searchData.pageSize,
        searchData.text,
        searchData.searchType
    ]);


    useEffect(()=>{
        if(knowledgeTypeId&&knowledgeType.length>0){
            let names = [], _knowledgeType = [...knowledgeType,...ptherKnowledgeType];
            
            _knowledgeType.map(item=>{
                if(knowledgeTypeId.includes(item.key)){
                    names.push(item)
                }
            })
            setKnowledgeTypeValue(names)
        }
        if(departmentId&&departmentData&&departmentData.length>0){
            let names = [];
            departmentData.map(item=>{
                if(departmentId.includes(item.key)){
                    names.push(item);
                    
                }
            })
            setDepartmentValue(names)
        }
        
    },[knowledgeTypeId, departmentId, knowledgeType, departmentData]);


    useEffect(()=>{
        console.log(departmentId,"departmentId")
        getKnowledgeList();
    },[JSON.stringify(departmentId)]);

    useEffect(()=>{
        console.log(knowledgeTypeId,"knowledgeTypeId")
        if(knowledgeTypeId[0] === '4'){

            // 政策跟踪时 - 不允许选择部门 - 重置搜索
            // setDepartmentData([{
            //     label:'全部',
            //     key:'',
            //     value:'',
            // }]);

            restDepartment();

        }else{
            getDepartmentList();
        }
    },[JSON.stringify(knowledgeTypeId)]);


    const restDepartment = () => {
        setDepartmentId([]);
        setDepartmentValue([]);
        setDepartmentData([]);

        if(searchData.departmentIds){
            let _searchData = {...searchData}
            _searchData.departmentIds = [];
            console.log(_searchData,14)
            setSearchData(_searchData);
        }
    }

    const getResult = async(value={}) => {
        
        setLoadingState(true);

        let _data = {
            ...searchData,
            ...value,
        }
        // 一个类型都不选的时候默认不查研报数据
        if(!searchData.knowledgeTypeIds || (searchData.knowledgeTypeIds&&searchData.knowledgeTypeIds.length <= 0)){
            _data.searchResearchReport = false
        }

        try {
            const res = await Api.Knowledge.getESSearch({data:_data})
            setLoadingState(false);
            if(res && res.code === 0 && res.data && res.data.records){
                setListDate(res.data.records);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{
                setListDate([]);
            }
        }catch{
            setLoadingState(false);
            setListDate([]);
        }
    }

    const getPolicyList = async(value={}) => {
        
        setLoadingState(true);

        let _data = {
            ...searchData,
            ...value
        }
        // ES搜索不能选部门
        let newData = {
            // departmentId:_data.departmentId,
            policySearch:_data.text,
            pageIndex:_data.pageIndex,
            pageSize:_data.pageSize,
            searchType:_data.searchType,
            sort:_data.sort,
            startTime:_data.startTime,
            endTime:_data.endTime,
        }

        try {
            let res = await Api.Knowledge.getPolicyPageList({data:{
                ...newData,
                searchPlace:666
            }});
            setLoadingState(false);
            if(res && res.code === 0 && res.data.records){
                setListDate(res.data.records)
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{setListDate([]);}
        } catch (errInfo) {
            setListDate([]);
            setLoadingState(false);
            console.log('验证失败:', errInfo);
        }
    }

    const onSearch = () =>{
        let searchValueTrim = searchValue.trim();
        if(searchValueTrim){
            setSearchValue(searchValueTrim);
            let _searchData = {...searchData}
            _searchData.text = searchValueTrim;
            _searchData.searchType = selecthValue;
            _searchData.pageIndex = 1;
            console.log(1)
            setSearchData(_searchData)
            // if(searchData.knowledgeTypeId === '4'){
            //     // 政策跟踪
            //     getPolicyList({
            //         text:searchValueTrim,
            //         searchType:selecthValue,
            //         pageIndex:1
            //     });
            // }else{
            //     getResult({
            //         text:searchValueTrim,
            //         searchType:selecthValue,
            //         pageIndex:1
            //     });
            // }
        }else{
            message.info('请输入需要搜索的内容')
        }
    }

    const getDepartmentList = async() => {
        let departmentData = [
        //     {
        //     label:'全部',
        //     key:'',
        //     value:'',
        // }
        ];

        // 获取部门列表
        try {
            const resDepartment = await Api.Department.getDepartmentListAll({data:{
                knowledgeTypeIds:knowledgeTypeId,
                knowledgeRel:0
            }});
            if(resDepartment&&resDepartment.code === 0){

                var departmentNamesArr = [...resDepartment.data];
                departmentNamesArr.sort(function (a, b) {
                    return b.name.length - a.name.length ; // 按照文本长度从大到小排序
                });

                departmentNamesArr.map(item=>{
                    departmentData.push({
                        label:item.name,
                        key:item.id,
                        value:item.id
                    })
                })

            }
        }catch{}

        setDepartmentData(departmentData);
    }

    const getKnowledgeList = async(type) => {
        let knowledgeData=[
        //     {
        //     label:'全部',
        //     key:'',
        //     value:'',
        // }
        ],newKnowledgeTypeId = [];
        
        // 获取知识类型
        try {
            const res = await Api.Knowledge.getPutKnowledgeType({data:{
                departmentIds:departmentId
            }});
            if(res && res.code === 0){
                res.data.map(item=>{

                    newKnowledgeTypeId.push(item.id)

                    knowledgeData.push({
                        label:item.knowledgeTypeName,
                        key:item.id,
                        value:item.id,
                    })
                })
            }
        }catch{}

        if(type === "defalut"){
            setKnowledgeTypeId([...newKnowledgeTypeId]);
            // setDisabledRadio(true);
            setCheckedKnowledgeTypeAll(true);
            // setKnowledgeTypeIndeterminate(false)
        }
        // else{
        //     setCheckedKnowledgeTypeAll(knowledgeTypeId.length === newKnowledgeTypeId.length && knowledgeTypeId.length>0)
        //     setKnowledgeTypeIndeterminate(!!knowledgeTypeId.length && knowledgeTypeId.length < newKnowledgeTypeId.length);
        // }
        let _searchData = {...searchData}
        
        // 政策跟踪
        if(knowledgeTypeId[0] === '4' || knowledgeTypeId[0] === '7'){
            setKnowledgeTypeIndeterminate(false);
            setDepartmentIndeterminate(false);
            // setDisabledCheckbox(true);

            if(knowledgeTypeId[0] === '4'){
                // 政策跟踪时 - 不允许选择部门 - 重置搜索
                setDepartmentData([]);
            }

            _searchData.knowledgeTypeIds = knowledgeTypeId;
            _searchData.pageIndex = 1;
            console.log(_searchData,'2-1')
            setSearchData(_searchData);

        }else if(knowledgeTypeId && knowledgeTypeId.length > 0){
            let ids = [];
            knowledgeTypeId.map(item=>{
                if(newKnowledgeTypeId.includes(item)){
                    ids.push(item)
                }
            })

            _searchData.knowledgeTypeIds = ids;
            _searchData.pageIndex = 1;
            console.log(_searchData,'2-2')
            setSearchData(_searchData);
            setKnowledgeTypeId(ids);
        }
        
        

        setKnowledgeType(knowledgeData);
    }

    // tabs改变
    const knowledgeChange = (checkedValue) => {
        // let value = (e&&e.target) ? e.target.value : '';
        // if(!value){
        //     setKnowledgeTypeValue('');
        // }

        // // 选择政策跟踪时重置回全文状态
        // if(value === '4'){
        //     setSelecthValue('1')
        // }
        // setKnowledgeTypeId(value);

        // let _searchData = {...searchData}
        // _searchData.knowledgeTypeId = value;
        // _searchData.pageIndex = 1;
        // setSearchData(_searchData);

        if(!checkedValue || checkedValue.length <= 0){
            setKnowledgeTypeValue('');
            // setDisabledRadio(false)
        }else{
            // setDisabledRadio(true)
        }
        
        setKnowledgeTypeId(checkedValue);

        let _searchData = {...searchData}
        _searchData.knowledgeTypeIds = checkedValue;
        _searchData.pageIndex = 1;
        console.log(_searchData,3)
        setSearchData(_searchData);

        setCheckedKnowledgeTypeAll(checkedValue.length === knowledgeType.length && checkedValue.length>0)
        setKnowledgeTypeIndeterminate(!!checkedValue.length && checkedValue.length < knowledgeType.length);
    }

    //  研报 政策
    const ontherKnowledgeChange = (checkedValue) => {

        let value = checkedValue.length > 1 ? checkedValue[1] : checkedValue.length > 0 ? checkedValue[0]: "";

        if(!value){
            setKnowledgeTypeValue('');
            // setDisabledCheckbox(false)
        }else{
            // setDisabledCheckbox(true)
        }

        // 选择政策跟踪时重置回全文状态
        if(value === '4'){
            setSelecthValue('1')
        }

        setKnowledgeTypeId(value?[value]:[]);
        setCheckedKnowledgeTypeAll(false)
        setKnowledgeTypeIndeterminate(false);

        let _searchData = {...searchData}
        _searchData.knowledgeTypeIds = value?[value]:[];
        _searchData.pageIndex = 1;
        console.log(_searchData,4)
        setSearchData(_searchData);
    }

    const departmentChange = (checkedValue) => {
        if(!checkedValue || checkedValue.length <= 0){
            setDepartmentValue([]);
        }else{

        }
        setDepartmentId(checkedValue);
        console.log(searchData,"searchDatasearchDatasearchDatasearchData")
        let _searchData = {...searchData}
        _searchData.departmentIds = checkedValue;
        _searchData.pageIndex = 1;
        console.log(_searchData,5)
        setSearchData(_searchData);

        setCheckedDepartmentAll(checkedValue.length === departmentData.length && checkedValue.length > 0)
        setDepartmentIndeterminate(!!checkedValue.length && checkedValue.length < departmentData.length);

    }

    const delectDepartment = (e) => {
        
        let _departmentId = departmentId.filter(item=>item!=e);
        
        departmentChange(_departmentId)

    }

    const delectKnowledgeChange = (e) => {
        
        let _knowledgeTypeId = knowledgeTypeId.filter(item=>item!=e);
        
        if(!_knowledgeTypeId || _knowledgeTypeId.length<=0){
            // setDisabledRadio(false);
            // setDisabledCheckbox(false);
        }

        knowledgeChange(_knowledgeTypeId);

    }

    // const departmentChange = (e) => {
    //     let value = (e&&e.target) ? e.target.value : ''
    //     if(!value){
    //         setDepartmentValue('');
    //     }
    //     setDepartmentId(value);

    //     let _searchData = {...searchData}
    //     _searchData.departmentId = value;
    //     _searchData.pageIndex = 1;
    //     setSearchData(_searchData);
    // }

    // 快捷时间范围
    const rangeChange = (e) => {
        let value = (e&&e.target) ? e.target.value : '0';
        sessionStorage.rangeType = value;
        setRangeKey(value);
        if(value == 'today'){
            dateChange('',getTodayDate())
        }else if(value == 'week'){
            dateChange('',getWeekDate())
        }else if(value != 'custom'){
            dateChange('',['',''])
        }
    }

    // 自定义时间范围
    const dateChange = (date, dateString) => {
        let _searchData = {...searchData}
        _searchData.startTime = dateString[0];
        _searchData.endTime = dateString[1];
        console.log(_searchData,6)
        setSearchData(_searchData)
    }

    // 分页变化
    const paginationChange = (pageIndex,pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        console.log(_searchData,7)
        setSearchData(_searchData);
    }

    // 排序变化
    const sortChange = (_sort,value) => {
        changeSearchValue('sort',_sort);
        setSortKey(value)
    }


    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onChangeCollapse = (value) => {
        setCollapseKey(value);
    }

    // 更新查询参数
    const changeSearchValue = (key,value)=>{
        let _searchData = {...searchData}
        _searchData[key] = value;
        console.log(_searchData,9)
        setSearchData(_searchData);
    }

    // 重置条件
    const clearSelectedParam = () => {
        let _searchData = {...searchData};
        _searchData.startTime = '';
        _searchData.endTime = '';
        _searchData.knowledgeTypeIds = [];
        _searchData.departmentIds = [];
        console.log(_searchData,8)
        setSearchData(_searchData);
        setDepartmentValue([]);
        setDepartmentId([]);
        setKnowledgeTypeValue([]);
        setKnowledgeTypeId([]);
        setRangeKey('0');

        // setDisabledCheckbox(false);
        // setDisabledRadio(false);

        setKnowledgeTypeIndeterminate(false);
        setDepartmentIndeterminate(false);
        setCheckedKnowledgeTypeAll(true);
        setCheckedDepartmentAll(true);
    }

    const onChangeDepartmentCheckedAll = () => {
        setCheckedDepartmentAll(!checkedDepartmentAll)
        let ids = [];
        if(!checkedDepartmentAll){
            departmentData.map(item=>{
                ids.push(item.value)
            })
            setDepartmentId(ids)
            setDepartmentIndeterminate(false);
        }else{
            setDepartmentId([])
            setDepartmentIndeterminate(false);
        }

        let _searchData = {...searchData}
        _searchData.departmentIds = ids;
        _searchData.pageIndex = 1;
        console.log(_searchData,10)
        setSearchData(_searchData);
    }

    const onChangeKnowledgeTypeCheckedAll = () => {
        setCheckedKnowledgeTypeAll(!checkedKnowledgeTypeAll);
        let ids = [];
        if(!checkedKnowledgeTypeAll){
            knowledgeType.map(item=>{
                ids.push(item.value)
            })
            setKnowledgeTypeId(ids)
            setKnowledgeTypeIndeterminate(false);
            // setDisabledRadio(true);
        }else{
            setKnowledgeTypeId([])
            setKnowledgeTypeIndeterminate(false);
            // setDisabledRadio(false);
        }

        let _searchData = {...searchData}
        _searchData.knowledgeTypeIds = ids;
        _searchData.pageIndex = 1;
        console.log(_searchData,11)
        setSearchData(_searchData);
    }


    return (<div className="knowledgeSearchFrame">
        <Spin tip="数据加载中" spinning={loadingState}>
            <div className='topSearch'>
                <div className='topTitle'>
                    <Iconfont type={'icon-shuba'} 
                        iStyle={{
                            fontSize: '1.2rem',
                            color:"#1599FB",
                            float: 'left',
                            lineHeight: 'inherit'
                        }}
                    /><Image imgSrc={KnowledgeText} iClassName="KnowledgeText" />
                </div>

                <div className="searchBar">
                    <Select 
                        value={selecthValue || '全文'}
                        size={"large"} 
                        options={knowledgeTypeId === '4' ? [
                            {
                                value: '1',
                                label: '全文'
                            },
                            {
                                value: '0',
                                label: '标题'
                            }
                        ]:[...serachType]}  
                        onChange={onSelect}
                        bordered={false}
                        style={{width:'5rem'}}>
                    </Select>
                    <Divider className='verticalDivider' type="vertical" />
                    <Input
                        value={searchValue}
                        placeholder="输入关键字"
                        size={"large"}
                        bordered={false}
                        onChange={onSearchValueChange}
                        onPressEnter={onSearch}
                        allowClear={true}
                    />
                    <Button 
                    onClick={onSearch} 
                    type="primary" 
                    size={"large"}>搜索一下</Button>
                </div>

                <Collapse 
                    activeKey={collapseKey} 
                    ghost 
                    onChange={onChangeCollapse}
                    expandIcon={() =><Iconfont type={'icon-shaixuan1'} 
                        iStyle={{
                            color:"#60B4F6",
                            marginRight:'8px',
                            lineHeight: 'inherit'
                        }}
                    />}
                >
                    <Panel header={collapseKey[0] == '1' ? "收起工具":"更多工具"} key="1">
                        <div className="moreSearchBar">
                            {(
                            (knowledgeTypeValue && knowledgeTypeValue.length>0) || 
                            (departmentValue && departmentValue.length>0) || 
                            rangeKey!='0') ? (
                            <div>
                                <Text type="secondary">已选条件</Text>
                                <div className="flexBox">

                                    {knowledgeTypeValue && knowledgeTypeValue.length>0 && knowledgeTypeValue.map(item=>{
                                        return<Tag
                                            className="edit-tag"
                                            key={item.value}
                                            closable={true}
                                            onClose={() => delectKnowledgeChange(item.value)}
                                        >
                                            {item.label}
                                        </Tag>
                                    })}

                                    {/* {knowledgeTypeValue?(<Tag
                                        className="edit-tag"
                                        key={1}
                                        closable={true}
                                        onClose={() => knowledgeChange()}
                                    >
                                        {knowledgeTypeValue}
                                    </Tag>):""} */}

                                    {departmentValue && departmentValue.length>0 && departmentValue.map(item=>{
                                        return<Tag
                                            className="edit-tag"
                                            key={item.value}
                                            closable={true}
                                            onClose={() => delectDepartment(item.value)}
                                        >
                                            {item.label}
                                        </Tag>
                                    })}

                                    {/* {departmentValue?(
                                    <Tag
                                        className="edit-tag"
                                        key={2}
                                        closable={true}
                                        onClose={() => departmentChange()}
                                    >
                                        {departmentValue}
                                    </Tag>
                                    ):""} */}

                                    {rangeKey!='0'?(<Tag
                                        className="edit-tag"
                                        key={999}
                                        closable={true}
                                        onClose={() => rangeChange()}
                                    >
                                        {rangeKey === 'today'?'一天内':rangeKey === 'week'?'一周内':'自定义' }
                                    </Tag>):''}
                                    <Tag color="#60B4F6" style={{cursor: 'pointer'}} onClick={clearSelectedParam}>重置</Tag>
                                </div>
                            </div>):''}

                            {knowledgeTypeId[0] != '4'&&<div>
                                <Text type="secondary">所属部门</Text>
                                <div className="flexBox departmentList">
                                    <div style={{display: 'flex'}}>
                                        <Checkbox 
                                            indeterminate={departmentIndeterminate} 
                                            checked={checkedDepartmentAll} 
                                            onChange={onChangeDepartmentCheckedAll}
                                            style={{float: 'left',minWidth:"110px"}}
                                        >全部</Checkbox>
                                        <Checkbox.Group name="department" onChange={departmentChange} value={departmentId}>
                                            {departmentData.map(item=>{
                                                return (<Checkbox key={item.value} value={item.value}>{item.label}</Checkbox>)
                                            })}
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </div>}
                            
                            <div>
                                <Text type="secondary">知识类型</Text>
                                <div className="flexBox knowledgeTypeList">
                                    <div style={{display: 'flex'}}>
                                        <Checkbox 
                                            indeterminate={knowledgeTypeIndeterminate} 
                                            checked={checkedKnowledgeTypeAll} 
                                            onChange={onChangeKnowledgeTypeCheckedAll}
                                            // disabled={disabledCheckbox}
                                            style={{float: 'left'}}
                                        >全部</Checkbox>

                                        <Checkbox.Group 
                                            name="knowledgeType" 
                                            onChange={knowledgeChange} 
                                            value={knowledgeTypeId}
                                            // disabled={disabledCheckbox}
                                        >
                                            {knowledgeType.map(item=>{
                                                return (<Checkbox key={item.value} value={item.value}>{item.label}</Checkbox>)
                                            })}
                                        </Checkbox.Group>
                                    </div>
                                    <Checkbox.Group 
                                        name="ptherKnowledgeType" 
                                        onChange={ontherKnowledgeChange} 
                                        value={knowledgeTypeId}
                                        style={{marginLeft:'118px'}}
                                        // disabled={disabledRadio}
                                    >
                                        {ptherKnowledgeType.map(item=>{
                                            return (<Checkbox key={item.value} value={item.value}>{item.label}</Checkbox>)
                                        })}
                                    </Checkbox.Group>
                                </div>
                            </div>
                            <div>
                                <Text type="secondary" name="time">时间</Text>
                                <div className="flexBox">
                                    <Radio.Group onChange={rangeChange} value={rangeKey}>
                                        {dataRange.map(item=>{
                                            return (<Radio key={item.value} value={item.value}>{item.label}</Radio>)
                                        })}
                                    </Radio.Group>
                                    {rangeKey == 'custom' ? <RangePicker onChange={dateChange} bordered={false}/>:''}
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>

            <div className="searchNumber">搜索到约<span>{pagination.total}</span>项结果</div>

            <div className="searchList">
                <ContentList
                    sortChange={sortChange}
                    paginationChange={paginationChange}
                    pagination={pagination}
                    searchData={searchData}
                    listDate={listDate}
                    autoHight={listHight}
                    defaultSortKey={sortKey}
                    selecthValue={selecthValue}
                    knowledgeTypeId={knowledgeTypeId}
                />
            </div>
        </Spin>
    </div>)
}

export default KnowledgeSearch;