import React from 'react'
import PropTypes from 'prop-types';
import './index.scss'

function MindMap (data={}) {
    const {parentDomId, domMindMapId, dom, lineWidth = 2, lineColor} = data
    const timer = setTimeout(() => {
        clearTimeout(timer)
        const parentDom = document.getElementById(parentDomId);
        const mindMapDom = document.getElementById(domMindMapId);

        if (parentDom && mindMapDom) {
            const parent = parentDom.getClientRects()[0]
            const mindMap = mindMapDom.getClientRects()[0]
            const mindMapLineTop = document.getElementById(domMindMapId + '-top')
            const mindMapLineBottom = document.getElementById(domMindMapId + '-bottom')
            const classObject = {
                top: ' topMindMap',
                bottom: ' bottomMindMap'
            }
            let height = (parent.y + (parent.height / 2)) - (mindMap.y+(mindMap.height / 2))
            let width = (parent.x + parent.width) - (mindMap.x)
            let address = height < 0 ? classObject.bottom : classObject.top;
            // 设置class
            mindMapDom.className = 'mindMapFrame ' + address
            const styleObject = {
                width: Math.abs(width / 2),
                height: Math.abs(height),
                borderWidth: lineWidth,
            }
            const commonStyle = {...styleObject}

            const topLineStyle = {
                left: `${0 - (styleObject.width * 2 / 20)}rem`
            }
            const bottomLineStyle = {
                left: `${0 - (styleObject.width / 20)}rem`
            }
            commonStyle.borderWidth = `${styleObject.borderWidth / 20}rem`
            if (lineColor) {
                commonStyle.borderColor = lineColor
            }
            // 位置不同 线的属性不同
            if (address) {
                const val = `calc(50% - ${styleObject.borderWidth / 2 / 20}rem)`
                if (address === 'topMindMap') {
                    commonStyle.top = val
                } else {
                    commonStyle.bottom = val
                }
            }
            commonStyle.width = `${commonStyle.width / 20}rem`
            commonStyle.height = `${commonStyle.height / 20}rem`
            const topObj = {...commonStyle, ...topLineStyle,}
            const bottomObj = {...commonStyle, ...bottomLineStyle,}
            for (let key in topObj) {
                mindMapLineTop.style[key] = topObj[key]
            }
            for (let key in bottomObj) {
                mindMapLineBottom.style[key] = bottomObj[key]
            }
        }
    }, 10)

    return (
        <div id={domMindMapId} className={'mindMapFrame'} key={parentDomId + '-' +domMindMapId}>
            <i id={domMindMapId+'-top'} className="mindMapLine topLine"></i>
            {dom}
            <i id={domMindMapId+'-bottom'} className="mindMapLine bottomLine"></i>
        </div>
    )
}

export default MindMap