import React from "react";
import {message, Modal, Spin, Tooltip, Popconfirm} from "antd";
import {copyToClip, responseDataFilter, getAnswerWebSocketUrl, getWPSFileUrl} from "../../../../utils/tool";
import storage from "../../../../utils/storage";
import * as marked from "marked";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import SelfDesignModal from "../../../../components/SelfDesignModal";
import NoneData from "../../../../components/NoneData";
import Api from "../../../../api";
import "./index.scss";

class ChatModal extends React.Component {
    lastAnswerText = "";
    lastIndex = -1;
    interval = null;
    WEBSOCKET_INSTANCE = null;
    resultHtml = "";
    timoutTimer = null;
    Api = Api.Chat;

    constructor(props) {
        super(props);
        this.state = {
            sysName: "CISDI_KNOWLEDGE_SYSTEM",
            sysSecret: "0F728C5C24A991034299D70ABE7149E9",
            userInfo: storage.getItem("userInfo") || {},
            dataList: [], // 会话信息列表
            intelligenceDataList: [], // 智能问答会话信息列表
            professionalDataList: [], // 知识问答会话信息列表
            sendQuestionText: "", // 需要发送的问题
            inputMessageLength: 0,
            width: 0,
            chatIsCreating: true, // 聊天室是否处于创建中
            sendStatus: null, // fail or null 消息发送状态
            isComplete: true, // 消息答复是否完结
            isAnswering: false, // 是否正在回答问题
            isStop: false, // 是否中断
            expandKeyObject: {}, // 回复消息下展开来源key
            sourceIsOpen: false, // 控制更多来源弹窗显示
            moreSourList: [], // 弹窗显示更多来源列表
            choiceSource: null,
            wpsUrl: null, // url/none/null
            chatTypeList: [
                {text: "智能问答", type: "intelligence"},
                {text: "知识问答", type: "professional"},
            ],
            isHaveKnowledgeLibrary: false, // 是否能进行知识问答
            currentType: "intelligence", // intelligence / professional
            intelligenceChatId: null, // 智能聊天ID
            knowLedgeChatId: null, // 知识库聊天ID
            isFocus: false,
        };
    }

    async componentDidMount() {
        this.createWebSocketFunc();
        window.addEventListener("resize", this.computedWidth);
        const widthState = this.computedWidth(undefined, true);
        const chatIdState = await this.getChatIdFunc();
        const libraryIdState = await this.checkKnowledgeLibraryFunc();

        this.getChatListFunc({...widthState, ...libraryIdState, ...chatIdState,}, chatIdState.intelligenceChatId);

        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isShow} = this.props;
        if (isShow) {
            this.chatAreaScrollBottom();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.computedWidth);

        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }

        this.WEBSOCKET_INSTANCE?.close();
    }

    // 获取该用户的智能问答和知识问答ID
    async getChatIdFunc () {
        const {userInfo, sysName} = this.state;
        const res = await this.Api.getChatId({
            data: {
                sysName,
                workNumber: userInfo?.jobNum,
            }
        });
        const data = responseDataFilter(res);
        let state = {};
        if (data) {
            state = {
                intelligenceChatId: data.ordinaryChatId,
                knowLedgeChatId: data.professionChatId,
            };
        }
        return state;
    }

    // 检查知识库是否为空
    async checkKnowledgeLibraryFunc () {
        const {userInfo, sysName} = this.state;
        const res = await this.Api.checkKnowledgeLibrary({
            data: {
                sysName,
                workNumber: userInfo?.jobNum,
            }
        });
        const data = responseDataFilter(res);
        let state = {};
        if (data?.isExist) {
            state = {
                isHaveKnowledgeLibrary: true,
            };
        }
        return state;
    }

    // 获取聊天记录
    async getChatListFunc (preState = {}, chatId) {
        let state = {
            // dataList: [],
        };

        // const id = chatId || this.getCurrentChatId();
        // if (id) {
        //     const res = await this.Api.getChatList({
        //         data: {
        //             chatId: id,
        //         },
        //         loading: true,
        //         loadingText: "聊天记录加载中...",
        //     })
        //
        //     const data = responseDataFilter(res);
        //     state.dataList = this.chatListFormat(data?.messages || []);
        // }

        this.setState({...preState, ...state}, () => {
            this.chatAreaScrollBottom();
        })
    }

    // 聊天记录转换格式
    chatListFormat (list, isAnswer) {
        let chatArr = [];
        list.forEach(item => {
            const {question, answer, messageId, status} = item;
            const questionObject = {
                "id": `question-${messageId}`,
                "userId": "123",
                "messageContent": question,
            };
            const answerObject = {
                ...item,
                "id": messageId,
                "userId": "robot",
                "messageContent": answer,
                status,
            };

            chatArr.push(questionObject, answerObject)
        });
        return chatArr;
    }

    // 获取当前聊天id
    getCurrentChatId () {
        const {currentType, intelligenceChatId, knowLedgeChatId} = this.state;
        return  currentType === "professional" ? knowLedgeChatId : intelligenceChatId; // intelligence / professional
    }

    // 创建websocket
    createWebSocketFunc () {
        this.setState({
            chatIsCreating: true,
        });
        if (!window.WebSocket) {
            message.error("浏览器不支持websocket！");
            return;
        }

        const {sysSecret, sysName, userInfo} = this.state;
        const workNumber = userInfo?.jobNum;
        const sign = `sysName=${sysName}&sign=${Date.now()}${Math.random()}&workNumber=${workNumber}`;
        const socket = new WebSocket(`${getAnswerWebSocketUrl()}/third/websocket/chat?${sign}`, [sysSecret]);

        socket.onopen = (evt) => {
            console.log("open");
            this.WEBSOCKET_INSTANCE = socket;
            this.linkSuccessFunc(socket);
        }

        socket.onmessage = (evt) => {
            this.receiveMessage(evt);
        }

        socket.onerror = (error) => {
            this.linkFailFunc();
        }

        socket.onclose = () => {
            console.log("closed");
            this.WEBSOCKET_INSTANCE = null;
            const {isComplete} = this.state;

            this.setState({
                isComplete: true,
                isAnswering: false,
            }, () => {
                if (!isComplete) {
                    this.addCopyBtnFunc();
                }
            })
        }
    }

    // 连接成功
    linkSuccessFunc () {
        console.log("link-success");
        let state = {
            chatIsCreating: false,
        }
        const {sendStatus} = this.state;
        if (sendStatus === "fail") {
            state.sendStatus = null;
        }
        this.recoverSendTextToInput(state);
    }

    // 连接失败
    linkFailFunc () {
        console.log("fail");
        this.WEBSOCKET_INSTANCE = null;
        message.error("连接失败，请稍候再试！");
        const {isAnswering} = this.state;
        if (isAnswering) {
            this.setState({
                sendStatus: "fail",
                chatIsCreating: true,
                isAnswering: false,
            })
        }

        const timer = setTimeout(() => {
            clearTimeout(timer);
            this.createWebSocketFunc();
        }, 30000);
    }

    // 接收消息
    receiveMessage (evt) {
        try {
            const data = evt.data;
            let state = {};
            let isRecoverText = false;
            if (data) {
                this.clearAnswerTextTimeout();
                const decodeData = JSON.parse(data);
                const {data: messageInfo, code, msg} = decodeData;
                const {isStop} = this.state;
                const chatId = this.getCurrentChatId();
                const codeStr = String(code);

                console.log(isStop);
                if (codeStr === "-1") {
                    state.isComplete = true;
                    this.clearAnswerTextInterval(3);
                    this.clickLinkChat(-1);
                } else if (codeStr === "500" || codeStr === "402") {
                    isRecoverText = codeStr === "402";
                    state.isComplete = true;
                    state.isAnswering = false;
                    this.clearAnswerTextInterval(31);
                    message.warning(msg);
                } else if (!isStop && chatId === messageInfo?.chatId) {
                    this.lastAnswerData = data;
                    let newList = [...this.state.dataList];
                    const {answer="", end: isComplete, source, status, messageId} = messageInfo;
                    const lastChatInfo = newList[newList.length - 1];
                    const isSlice = this.lastIndex > -1;
                    const lastChat = {
                        "id": messageId,
                        "userId": "robot",
                        "messageContent": isSlice ? answer.slice(0, this.lastIndex) : "",
                        status,
                        source,
                    };
                    this.lastAnswerText = isSlice ? answer.slice(this.lastIndex) : answer;
                    if (!this.interval) {
                        state.isAnswering = true;
                    }
                    this.startInputAnswerFunc();
                    if (lastChatInfo.id === messageId) {
                        newList.pop();
                    }
                    state.dataList = [...newList, lastChat];
                    state.isComplete = isComplete;
                }
            } else {
                state.isComplete = true;
                state.isAnswering = false;
            }

            if (isRecoverText) {
                state.dataList = [...this.state.dataList];
                state.dataList.pop();
                this.recoverSendTextToInput(state);
            } else {
                this.setState(state, () => {
                    this.chatAreaScrollBottom();
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    // 回显问题
    recoverSendTextToInput (preState) {
        const state = {...preState,}
        if (this.resultHtml) {
            const dom = document.createElement("div");
            dom.innerHTML = this.resultHtml;
            state.inputMessageLength = dom.textContent.length;
        }

        this.setState(state, () => {
            const dom = document.getElementById("inputContent");
            if (dom && this.resultHtml) {
                dom.innerHTML = this.resultHtml;
                this.resultHtml = "";
                this.moveEnd(dom);
            }
        })
    }

    // 光标移动到末尾
    moveEnd (dom) {
        const selection = window.getSelection();
        const range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(dom);
        range.collapse(false);
        selection.addRange(range);
        dom.focus();
        this.setState({isFocus: true});
    }

    // 计算宽度
    computedWidth = (e, init) => {
        const dom = document.getElementById("middleChatBox");
        if (dom) {
            let state = {
                width: dom.clientWidth,
            };
            if (init) {
                return state;
            }
            this.setState(state)
        }
    }

    // 清除interval定时器
    clearAnswerTextInterval (type, isClearText=true) {
        // console.log("========", type);

        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;

            if (isClearText) {
                this.lastAnswerText = "";
                this.lastIndex = -1;
            }
            const timer = setTimeout(() => {
                clearTimeout(timer);
                const dom = document.getElementById("inputContent");
                if (dom) {
                    this.moveEnd(document.getElementById("inputContent"));
                }
            }, 1000)

        }
    }

    // 清除timeout定时器
    clearAnswerTextTimeout (type) {
        // console.log("========", type);

        if (this.timoutTimer) {
            clearInterval(this.timoutTimer);
            this.timoutTimer = null;
        }
    }

    // 开始回答
    startInputAnswerFunc () {
        if (this.interval === null) {
            this.interval = setInterval(() => {
                if (this.lastAnswerText) {
                    const {dataList} = this.state;
                    let lastChat = dataList[dataList.length - 1];
                    if (lastChat) {
                        lastChat.messageContent += this.lastAnswerText.charAt(0);
                        this.lastAnswerText = this.lastAnswerText.slice(1);
                        this.lastIndex += 1;
                    }
                    this.setState({
                        dataList: [...dataList],
                    }, () => {
                        this.chatAreaScrollBottom();
                    })
                } else {
                    const {isComplete} = this.state;
                    if (isComplete) {
                        this.setState({
                            isAnswering: false,
                        }, () => {
                            this.addCopyBtnFunc();
                        });
                    } else {
                        this.clearAnswerTextTimeout();
                        this.timoutTimer = setTimeout(() => {
                            this.clearAnswerTextTimeout();
                            this.setState({
                                isComplete: true,
                                isAnswering: false,
                            }, () => {
                                if (!isComplete) {
                                    this.addCopyBtnFunc();
                                }
                            })
                        }, 30000); // 超时3分钟不回答就视作回答完毕
                    }

                    this.clearAnswerTextInterval("00", false);
                }
            }, 50);
        }
    }

    // 停止回答
    async stopInputAnswerFunc () {
        this.setState({
            isStop: true,
        }, async () => {
            const res = await this.Api.stopAnswer({
                data: {message: this.lastAnswerData},
                loading: true,
                loadingText: "回答停止中...",
            })
            const data = responseDataFilter(res);
            if (data) {
                this.setState({
                    isComplete: true,
                    isAnswering: false,
                }, () => {
                    const {dispatch} = this.props;
                    this.addCopyBtnFunc();
                    this.clearAnswerTextInterval(5);
                });
            }
        })
    }

    // 提示手动连接
    clickLinkChat (type) {
        console.log(type);
        Modal.warning({
            title: "提示: ",
            width: "80%",
            content: (<div style={{padding: "1rem 0 0.5rem"}}>聊天室已关闭，请重新连接！</div>),
            closable: false,
            maskClosable: false,
            centered: true,
            wrapClassName: "selfDesignModal",
            getContainer: () => document.getElementById("middleChatBox"),
            okText: "重新连接",
            okButtonProps: {
                type: "primary"
            },
            onOk: () => {
                this.getInputText();
                this.WEBSOCKET_INSTANCE?.close();
                this.setState({
                    chatIsCreating: true,
                    isAnswering: false,
                    sendStatus: null, // fail or null 消息发送状态
                    isComplete: true, // 消息答复是否完结
                    isStop: false,
                }, () => {
                    this.createWebSocketFunc();
                    this.getChatListFunc();
                });
            }
        })
    }

    // reset state
    resetStateFunc () {}

    // 填写询问的问题
    inputSendText() {
        const html = this.getInputText(false);
        this.setState({
            inputMessageLength: html.length,
        })
    }

    // 发送填写询问的问题
    async sendText() {
        const {dataList, isAnswering} = this.state;
        const chatId = this.getCurrentChatId();
        if (isAnswering || this.WEBSOCKET_INSTANCE === null) {
            if (!isAnswering) {
                this.clickLinkChat(0);
            }
            return
        }

        let newDataList = [...dataList];
        const html = this.getInputText(false);
        if (html.replace(/\s/g, "").length > 0) {
            const html = this.getInputText();
            let question = html.slice(0, 300);
            newDataList.push({
                id: Date.now().toString(),
                userId: "123",
                messageContent: html.slice(0, 300),
            });
            this.setState({
                dataList: newDataList,
                sendQuestionText: "",
                inputMessageLength: 0,
                isAnswering: true,
                isFocus: false,
                isStop: false,
            }, async () => {
                this.chatAreaScrollBottom();
                this.WEBSOCKET_INSTANCE.send(JSON.stringify(
                    {
                        question: question,
                        chatId: chatId,
                    }
                ))
            })
        } else {
            message.warning("输入的问题不能为空！");
            setTimeout(() => {
                this.getInputText();
                this.setState({
                    sendQuestionText: "",
                    inputMessageLength: 0,
                })
            }, )
        }
    }

    // 聊天区域滚动到底部
    chatAreaScrollBottom () {
        const dom = document.getElementById("chatScrollBox");
        if (dom) {
            dom.scrollTop = dom.scrollHeight;
        }
    }

    // 点赞/踩 回复的消息
    async changeSupportReplyMessage (id, status, index) {
        const word = status === "1" ? "点赞" : "点踩";
        const res = await this.Api.changeMessageAttitude({
            data: {
                messageId: id,
                status,
            },
            loading: true,
            loadingText: word + "中...",
        });
        const data = responseDataFilter(res);
        if (data) {
            message.success(word + "成功！");
            const {dataList} = this.state;
            let newDataList = [...dataList];
            let record = {...newDataList[index]};
            record.status = status;
            newDataList[index] = record;
            this.setState({
                dataList: newDataList,
            })
        }
    }

    // 复制回复的消息
    copyToClipReplyMessage (messageText) {
        copyToClip(messageText || "");
    }

    // 获取输入的问题
    getInputText (clear = true) {
        const dom = document.getElementById("inputContent");
        if (dom) {
            const result = dom.textContent;
            if (clear) {
                this.resultHtml = dom.innerHTML;
                dom.innerHTML = null;
            }
            return result;
        }
    }

    // 添加复制按钮
    addCopyBtnFunc () {
        const codeDomList = document.getElementsByTagName("code");
        if (codeDomList) {
            for (const codeDom of codeDomList) {
                let btnList = codeDom.getElementsByClassName("copyCodeBtn");
                if (btnList && btnList.length > 0) {
                    continue
                }

                let btn = document.createElement("span");
                btn.className = "copyCodeBtn";
                btn.innerHTML = "<i class='iconfont icon-fuzhi1' />";
                btn.addEventListener("click", (evt) => {
                    const evtDom = evt.target;
                    let parentDom = evtDom.parentElement;
                    if (evtDom.tagName === "I") {
                        parentDom = parentDom.parentElement;
                    }
                    copyToClip(parentDom.innerText);
                })
                codeDom.appendChild(btn);
            }
        }
    }

    // 渲染聊天信息
    renderChatList(color) {
        // const {knowledgeName = ""} = this.props;
        const {
            dataList, userInfo, expandKeyObject, width, chatIsCreating, currentType,
        } = this.state;
        const {userName} = userInfo || {};

        const tipTextObject = {
            professional: "您好，我是您的私人小赛知识问答助理，我是基于赛迪钢铁行业与赛迪咨询知识库结合的AI产品，专为您提供私有、专业、高效、准确的语言服务，现在您可以与我开启对话模式，欢迎您的到来。",
            intelligence: "您好，我是您的私人小赛智能助理，我是基于当今最火爆的人工智能大语言模型训练而成，专为您提供高效、便捷的语言服务，现在您可以与我开启对话模式，欢迎您来到人工智能的世界。"
        };

        return (
            <div id="chatScrollBox" className={`chatScrollBox`}>
                <div className="noneMessageTip">
                    <Iconfont type="icon-tishi"/>
                    <span style={{textAlign: "center"}}>{chatIsCreating ? `私人小赛助理准备中，请稍等!` : tipTextObject[currentType]}</span>
                </div>
                {
                    !chatIsCreating ?
                        dataList?.map((item, idx) => {
                            const {id, userId, messageContent, status: supportStatus, source} = item;
                            let sourceList = source ? JSON.parse(source) : [];
                            const isSelf = userId !== 'robot';
                            const messageContentId = `messageContent-${id}`;
                            const isExpand = expandKeyObject[id] > 0;
                            let formatContent = marked.parse(messageContent || "");
                            // 去掉最后一个换行
                            formatContent = formatContent.replace(/[\n|\r]$/, "");
                            return (
                                <div
                                    className={`chatMessageItem`}
                                    key={id}
                                >
                                    {/* 多选框 */}
                                    <i className={`checkBox`}/>
                                    {/* 消息行 */}
                                    <div className={`chatMessage ${isSelf ? "selfChatMessage" : ""}`}>
                                        {/* 头像 */}
                                        <div className="headIcon" style={isSelf ? {} : {background: color}}>
                                            {isSelf ? (userName?.charAt(0)) : (<Iconfont type={"icon-jiqirenpeixun"}/>)}
                                        </div>
                                        {/* 消息 */}
                                        <div
                                            className="messageContentBox"
                                            style={{maxWidth: width > 980 ? "40rem" : "",}}
                                        >
                                            <div
                                                className={`messageContent ${isSelf ? "" : "answerMessageContent"}`}
                                                id={messageContentId}
                                                dangerouslySetInnerHTML={{__html: formatContent}}
                                            ></div>
                                            {
                                                !isSelf ? (
                                                    <React.Fragment>
                                                        {/* 来源展示 */}
                                                        <div
                                                            className="sourceContentBox"
                                                            style={{display: sourceList?.length > 0 ? "" : "none"}}
                                                        >
                                                            {/* 查看来源 & 展开操作 & 更多 */}
                                                            <div
                                                                className={`sourceContentHeader ${isExpand ? "expandStatus" : ""}`}
                                                                onClick={() => this.expandSource(id)}
                                                            >
                                                                <div className="expandBox">
                                                                    <span className="viewSourceText">查看来源</span>
                                                                    <Iconfont type="icon-you"/>
                                                                </div>
                                                                {
                                                                    sourceList?.length > 3 ? (
                                                                        <span
                                                                            className="moreBtn"
                                                                            onClick={(e) => {
                                                                                e?.stopPropagation();
                                                                                this.viewMoreSource(sourceList);
                                                                            }}
                                                                        >更多</span>
                                                                    ) : undefined
                                                                }
                                                            </div>
                                                            {/* 来源列表 */}
                                                            <div className="sourceListBox"
                                                                 style={{maxHeight: isExpand ? "" : 0}}>
                                                                {
                                                                    (sourceList?.slice(0, 3)).map((item, idx) => {
                                                                        const {keyword: sourceTitle} = item;
                                                                        return (
                                                                            <span
                                                                                key={`${id}-source-${idx}`}
                                                                                className="sourceTitle overflowEllipsis"
                                                                                onClick={() => this.viewSourceDetail(item)}
                                                                            >{sourceTitle}</span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* 底部操作 */}
                                                        <div className="bottomTipBox">
                                                            <span className="tipText">以上内容为 AI 生成，不代表开发者立场，请勿删除或修改本标记</span>
                                                            <div className="operateBox">
                                                                <Tooltip placement={"top"} title={"答得不错"}>
                                                                        <span
                                                                            className={`operateBtn ${supportStatus === "1" ? "lightStatus" : ""}`}
                                                                            onClick={() => {
                                                                                if (supportStatus === "1") {
                                                                                    return
                                                                                }
                                                                                this.changeSupportReplyMessage(id, "1", idx);
                                                                            }}
                                                                        ><Iconfont type="icon-dianzan"/></span>
                                                                </Tooltip>
                                                                <Tooltip placement={"top"} title={"还不够好"}>
                                                                        <span
                                                                            className={`operateBtn ${supportStatus === "2" ? "lightStatus" : ""}`}
                                                                            onClick={() => {
                                                                                if (supportStatus === "-1") {
                                                                                    return
                                                                                }
                                                                                this.changeSupportReplyMessage(id, "2", idx);
                                                                            }}
                                                                        ><Iconfont type="icon-diancai"/></span>
                                                                </Tooltip>
                                                                <Tooltip placement={"top"} title={"点击可复制"}>
                                                                        <span
                                                                            className="operateBtn"
                                                                            onClick={() => {
                                                                                this.copyToClipReplyMessage(messageContent);
                                                                            }}
                                                                        ><Iconfont type="icon-fuzhi1"/></span>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : undefined
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : undefined
                }
            </div>
        )
    }

    // 展开来源
    expandSource(messageId) {
        const newExpandKey = {...this.state.expandKeyObject};
        if (newExpandKey[messageId]) {
            delete newExpandKey[messageId];
        } else {
            newExpandKey[messageId] = 1;
        }
        this.setState({
            expandKeyObject: newExpandKey,
        })
    }

    // 查看更多来源
    viewMoreSource(sourceList) {
        this.setState({
            sourceIsOpen: true,
            moreSourList: sourceList.map(item => {
                return {...item, key: Math.random()}
            }),
        })
    }

    // 查看来源详情
    async viewSourceDetail(source) {
        this.setState({
            choiceSource: source,
            wpsUrl: null,
            sourceIsOpen: false,
        }, () => {
            getWPSFileUrl(source?.fileId, source.content, (wpsUrl) => {
                this.setState({wpsUrl});
            });
        })
    }

    // 新窗口打开wps文件
    openWpsFile() {
        let dom = document.getElementById("office");
        let iframeDom = dom?.getElementsByTagName("iframe")[0];
        if (iframeDom && iframeDom.src) {
            window.open(iframeDom.src);
        } else {
            message.warning("文件不存在！！！")
        }
    }

    // 切换聊天模式
    changeChatType (type) {
        const {dataList, intelligenceDataList, professionalDataList} = this.state;
        const isProfessional = type === "professional";
        const key = isProfessional ? "intelligenceDataList" : "professionalDataList";
        this.setState({
            currentType: type,
            dataList: [...(isProfessional ? professionalDataList : intelligenceDataList)],
            [key]: [...dataList],
        })
    }

    // 清除聊天记录
    clearDataList () {
        const {currentType} = this.state;
        const isProfessional = currentType === "professional";
        const key = isProfessional ? "professionalDataList" : "intelligenceDataList";
        this.setState({
            dataList: [],
            [key]: [],
        })
    }

    render () {
        const {closeModalFunc, changeViewSizeFunc, isBig} = this.props;
        const {
            sendQuestionText, inputMessageLength,
            chatIsCreating, isAnswering, currentType,
            chatTypeList, sourceIsOpen, moreSourList,
            choiceSource, wpsUrl, isFocus, isHaveKnowledgeLibrary,
            dataList,
        } = this.state;
        const disabled = isAnswering || chatIsCreating;
        const sessionName = "小赛助手";
        const clearBtnDisabled = isAnswering || dataList?.length < 1;
        const isProfessional = currentType === "professional";
        const color = isProfessional ? "#f33a00" : "#1A9DF9";

        return (
            <div className="middleChatBox intelligenceChatFrame" id="middleChatBox">
                {/* 问答名称 & 切换 & 关闭按钮 */}
                <div className="chatHeadBox">
                    <div className="chatTitleBox">
                        {/* 问答名称 */}
                        <Iconfont type="icon-jiqirenpeixun" iStyle={{color}} />
                        <span className="sessionNameText overflowEllipsis" style={{color}}>{sessionName}</span>
                        <Popconfirm
                            title={"您确认清理聊天记录吗？"}
                            placement={"top"}
                            disabled={clearBtnDisabled}
                            onConfirm={() => this.clearDataList() }
                        >
                            <span className={`clearBtn ${clearBtnDisabled ? "disabledStatus" : ""}`}><Iconfont type="icon-qingchushujuku" /></span>
                        </Popconfirm>
                    </div>
                    <div className="chatHeadRightBox">
                        <div className="radioGroupBox" style={{visibility: isAnswering ? "hidden" : ""}}>
                            {
                                chatTypeList.map(item => {
                                    const {text, type} = item;
                                    const isEnable = isHaveKnowledgeLibrary ? true : (type === "intelligence");
                                    return (
                                        <div
                                            className={`radioItem ${currentType === type ? currentType : ""} ${isEnable ? "" : "disabledStatus"}`}
                                            key={type}
                                            onClick={() => {
                                                if (isAnswering) {
                                                    return message.warning("问题回答中...");
                                                }
                                                if (!isEnable) {
                                                    return message.warning("知识库未绑定，请联系管理员!");
                                                }
                                                this.changeChatType(type);
                                            }}
                                        >
                                            <i className="checkedBox"/>
                                            <span className="radioText">{text}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Tooltip placement={"top"} title={isBig ? "缩小弹窗" : "放大弹窗"}>
                            <span className="chatModalIconBtn" onClick={() => {
                                changeViewSizeFunc();
                                const timer = setTimeout(() => {
                                    clearTimeout(timer);
                                    this.chatAreaScrollBottom();
                                }, 300);
                            }}><Iconfont type={isBig ? "icon-suoxiao1" : "icon-fangda"} /></span>
                        </Tooltip>
                        <Tooltip placement={"top"} title={"关闭弹窗"}>
                            <span className="chatModalIconBtn closeChatModalBtn" onClick={closeModalFunc}><Iconfont type="icon-close1" /></span>
                        </Tooltip>
                    </div>
                </div>

                {/* 对话列表 */}
                <div className="chatListBox">
                    {this.renderChatList(color)}
                </div>
                {/* 问题输入框 */}
                <div className={`inputQuestionBox ${disabled ? "answeringStatus" : ""}`}>
                    {/* 输入框 */}
                    <div
                        className="inputBox"
                        placeholder={(inputMessageLength > 0 || disabled) ? "" : "输入你的问题或需求(300字以内)"}
                    >
                        {
                            disabled ? (<Spin tip={chatIsCreating ? "聊天室创建中..." : "答复中..."} size={"small"}></Spin>) : (
                                <div
                                    id="inputContent"
                                    className="inputContent"
                                    contentEditable={true}
                                    onClick={(e) => {
                                        if (!isFocus) {
                                            this.moveEnd(e.target);
                                        }
                                    }}
                                    onBlur={(e) =>{
                                        this.setState({isFocus: false});
                                    }}
                                    onInput={(val, e) => {
                                        this.inputSendText(val);
                                    }}
                                    dangerouslySetInnerHTML={{__html: sendQuestionText}}
                                    onKeyDown={(e) => {
                                        const {
                                            altKey, shiftKey, ctrlKey,
                                            key,
                                        } = e || {};

                                        if (key === "Enter" && !(altKey || shiftKey || ctrlKey)) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this.sendText();
                                        }
                                    }}
                                ></div>
                            )
                        }
                    </div>
                    {/* 发送按钮 */}
                    {/* 发送按钮 | 中止按钮 */}
                    <Tooltip placement={"top"} title={isAnswering ? "中断回答" : "发 送"}>
                        {
                            isAnswering ? (
                                <span
                                    className="sendQuestionBtn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.stopInputAnswerFunc()
                                    }}
                                >
                                    <Iconfont type="icon-zhongzhi" />
                                </span>
                            ) : (
                                <span className="sendQuestionBtn" onClick={() => this.sendText()}><Iconfont type="icon-icon-test" iStyle={{color: color}} /></span>
                            )
                        }
                    </Tooltip>
                </div>

                <SelfDesignModal
                    open={sourceIsOpen}
                    width={"60%"}
                    wrapClassName={"chatModalBox"}
                    content={(
                        <div className="moreSourceListBox">
                            <span className="moreSourceTitle">更多来源</span>
                            <div className="sourceListBox">
                                {
                                    moreSourList?.map((item, idx) => {
                                        const {keyword: sourceTitle, key} = item;
                                        return (
                                            <div key={key} className={`sourceItem`}>
                                                <div
                                                    className={`sourceHeader`}
                                                    onClick={() => this.viewSourceDetail(item)}
                                                >
                                                    <span className="sourceTitle overflowEllipsis">{sourceTitle}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )}
                    // maskStyle={{background: 'none', backdropFilter: "none",}}
                    // getContainer={() => document.getElementById("middleChatBox")}
                    onCancel={() => this.setState({sourceIsOpen: false,})}
                />

                <SelfDesignModal
                    open={choiceSource !== null}
                    width={"60%"}
                    wrapClassName={"chatModalBox"}
                    content={(
                        <Spin spinning={wpsUrl === null} size={"large"} tip={"文件加载中..."} style={{maxHeight: "100%"}}>
                            <div className="sourceDescBox">
                                <span className="sourceTitle">{choiceSource?.keyword}</span>
                                {
                                    wpsUrl === "none" ? (
                                        <div className="sourceDesc" style={{paddingTop: "0.5rem"}}>
                                            {NoneData({text: "知识库文件不存在！"})}
                                        </div>
                                    ) : undefined
                                }
                                <div
                                    id="office"
                                    className="sourceDesc "
                                    style={wpsUrl === "none" ? {
                                        width: 0,
                                        height: 0,
                                        overflow: "hidden"
                                    } : {paddingTop: "0.5rem"}}
                                ></div>
                                {
                                    (wpsUrl && wpsUrl === "none") ? (
                                        <Tooltip placement="left" title="新开页面查看">
                                        <span
                                            className="openFileBtn"
                                            onClick={() => this.openWpsFile()}
                                        ><Iconfont type={"icon-chuangkou"}/></span>
                                        </Tooltip>
                                    ) : undefined
                                }
                            </div>
                        </Spin>
                    )}
                    // maskStyle={{background: 'none', backdropFilter: "none",}}
                    // getContainer={() => document.getElementById("middleChatBox")}
                    onCancel={() => this.setState({
                        choiceSource: null,
                        wpsUrl: null,
                    })}
                />
            </div>
        )
    }
}

export default ChatModal;
