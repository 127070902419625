
import myAxios from '../utils/request.js';

export default {
    // 搜索热词
    async getHotLable (params={}, cb) {
        let res = await myAxios.postJson('/hotLable/pageList', params);
        return res.data;
    },

    // 删除热词
    async deleteHotLable (params={}, cb) {
        let res = await myAxios.postJson('/hotLable/delete', params);
        return res.data;
    },

    // 新增热词
    async addHotLable (params={}, cb) {
        let res = await myAxios.postJson('/hotLable/insert', params);
        return res.data;
    },

    // 修改热词
    async updateHotLable (params={}, cb) {
        let res = await myAxios.postJson('/hotLable/update', params);
        return res.data;
    },


    // 获取所有热点标签
    async getAllHotLable (params={}, cb) {
        let res = await myAxios.postJson('/hotLable/getAllHotLable', params);
        return res.data;
    },
    // 
}