import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Input, Checkbox, message, Tooltip, Table, Pagination,} from 'antd';
import { Resizable } from 'react-resizable';
import './index.scss';

class ResizeTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            height: 0,
        };
    }

    componentDidMount() {
        const columns = this.computedColumns(this.props.columns)
        this.computedWidth(null, columns)
        window.addEventListener('resize', this.computedWidth)
    }

    componentDidUpdate (preProps) {
        if (preProps) {
            const {columns: preColumns} = preProps
            const {columns} = this.props
            let preColStr = ''
            preColumns.forEach((item) => {
                preColStr += item.dataIndex
            })
            let colStr = ''
            columns.forEach((item) => {
                colStr += item.dataIndex
            })
            if (preColStr !== colStr) {
                this.setState({
                    columns: this.computedColumns(columns)
                }, () => {
                    this.computedWidth()
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.computedWidth)
    }

    computedColumns (columns=[]) {
        return columns.map((item, idx) => {
            let it = {...item}
            if (item.disableResize) {
                delete it.minWidth
            }
            return it
        })
    }

    computedWidth = (e, col)=> {
        const that = this
        const {columns: stateCol} = that.state
        const {tableBoxId, computedWidth=true} = that.props
        let columns = [...(col || stateCol || [])]
        if (computedWidth) {
            const dom = document.getElementById(tableBoxId);
            if (dom) {
                const len = columns.length;
                const width = dom.clientWidth / len;
                columns.forEach(item => {
                    if (item.width && !item.disableResize) {
                        item.width = width - 5;
                    }
                })
            }
        }
        const height = this.getHeight();
        that.setState({columns, height});
    }

    ResizeableTitle = (props) => {
        const { disableResize, onResize, width, ...restProps } = props;

        if (disableResize || !width) {
            return <th {...restProps} />;
        }

        return (
            <Resizable
                width={width}
                height={0}
                onResize={onResize}
                draggableOpts={{ enableUserSelectHack: false }}
            >
                <th {...restProps} />
            </Resizable>
        );
    }

    handleResize = (index) => (e, val) => {
        const { size } = val
        this.setState(({ columns }) => {
            const sizeWidth = size.width
            const nextColumns = [...columns];
            const {minWidth=0} = columns[index];
            nextColumns[index] = {
                ...nextColumns[index],
                width: sizeWidth > minWidth ? sizeWidth : minWidth,
            };
            return { columns: nextColumns };
        });
    };

    // 计算表格所占高度
    getHeight = (isReturn= true) => {
        const tableContent = document.getElementById(this.props?.tableBoxId || '')
        let height = 0
        if (tableContent) {
            const {pagination} = this.props
            let reacts = tableContent.getClientRects()[0]
            height = reacts.height - (pagination ? 82 : 42)
        }
        if (isReturn) {
            return height
        } else {
            this.setState({
                height
            })
        }
    }

    render() {
        const that = this
        const columns = that.state.columns || []
        const components = {
            header: {
                cell: that.ResizeableTitle,
            },
        };
        const newColumns = columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                disableResize: column.disableResize,
                onResize: that.handleResize(index),
            }),
        }));
        return (
            <Table
                className={"resizeTableFrame"}
                components={components}
                scroll={{y: this.state.height}}
                {...this.props}
                columns={newColumns}
                bordered={true}
            />
        );
    }
}

ResizeTable.propTypes = {
    tableBoxId: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    computedWidth: PropTypes.bool,
};

export default ResizeTable;
