import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';

import DepartmentList from './departmentList/index';
import KnowledgeList from './knowledgeList/index';
import storage from "../../../utils/storage";
import BackIcon from "../../../components/BackIcon/index";

import './index.scss';

const Department = () => {

    const [tableHight, setTableHight] = useState(0);

    const items = {
        'departmentList':<DepartmentList tableHight={tableHight}/>,
        'knowledgeList':<KnowledgeList tableHight={tableHight}/>
    }

    const [directory, setDirectory] = useState([]);
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        // 计算table内容的高度
        let divHeight= document.querySelector('.layoutContent');
        setTableHight(divHeight.offsetHeight-355);
    }, []);
    useEffect(() => {
        let directory = JSON.parse(sessionStorage.directory)
        let newDirectory = directory.map(item=>{
            return {
                label:item.name,
                key:item.id,
                children:items[item.path]
            }
        })
        setDirectory(newDirectory);
        let currntKey = sessionStorage.activeKey ? sessionStorage.activeKey : newDirectory[0].key;
        setActiveKey(currntKey);
    }, [tableHight]);

    const onChange = (activeKey) => {
        setActiveKey(activeKey)
        sessionStorage.activeKey = activeKey;
    }

    return (
        <div className="userFrame">
            <div  className="userTabs">
                <Tabs activeKey={activeKey} onChange={onChange} items={directory}></Tabs>
            </div>
            <BackIcon />
        </div>
    );
};
export default Department;
