import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Switch, InputNumber, Descriptions, Space, Typography, Select, Modal, message, Checkbox, Image, Upload } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import SelfTable from "../../../../components/SelfTable/index"

import Api from "../../../../api";
import {userAuthority,pagingParam} from "../../../../utils/defaultValue";
import {ModalConfirm} from '../../../../utils/tool'

import './index.scss'

const { TextArea } = Input;

const KnowledgeList = () => {
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    // 列表数据
    const [listData, setListData] = useState([]);

    // 所属部门
    const [department, setDepartment] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    // 当前操作的数据的图标
    const [editingIcon, setEditingIcon] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState('');
    
    const [tableLoading, setTableLoading] = useState(false);

    const [fileList, setFileList] = useState([])

    const [uploadFile, setUploadFile] = useState('')

    const [ifapproval, setIfApproval] = useState(false);
    const [approvalList, setApprovalList] = useState([]);
    const [approvalConfiguration, setApprovalConfiguration] = useState([
    ]);
    const [approvalProcessLoading, setApprovalProcessLoading] = useState(false);
    const [approvalType, setApprovalType] = useState("");
    const [approvalProcessModalType, setApprovalProcessModalType] = useState(false);

    // 是否修改审批流程 true修改，false不修改
    const [workflowFlag, setWorkflowFlag] = useState(false);

    const [displayData, setDisplayData] = useState({});
    const [auditData, setAuditData] = useState({
        // checkType0:{ //发布或取消发布 - 不修改默认分享范围
        // },
        // checkType1:{ //发布或取消发布 - 修改默认分享范围

        // },
        // checkType2:{ //阅读 - 本部门

        // },
        // checkType3:{ //阅读 - 其他部门在分享范围内

        // },
        // checkType4:{ //阅读 - 其他部门不在分享范围内

        // },
        // checkType5:{ //下载 - 本部门

        // },
        // checkType6:{ //下载 - 其他部门在分享范围内

        // },
        // checkType7:{ //下载 - 其他部门不在分享范围内

        // },
    });

    useEffect(() => {
        setInitDate();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);

    const setInitDate = async() => {
        const resDepartment = await Api.Department.getDepartmentListAll({
            data:{publishRel:0}
        });
        if(resDepartment && resDepartment.code === 0){
            let newData = resDepartment.data.map(item=>{
                return{
                    value: item.id,
                    label: item.name,
                    text: item.name,
                }
            });
            setDepartment(newData);
        }

        const resCheck = await Api.Knowledge.selectNameByCheckPerm({});
        if(resCheck && resCheck.code === 0){

            let newData0 = resCheck.data.map(item=>{
                return{
                    value: `${item.id}-0`,
                    label: `用户所属部门${item.name}`,
                    text: `用户所属部门${item.name}`,
                }
            });

            let newData1 = resCheck.data.map(item=>{
                return{
                    value: `${item.id}-1`,
                    label: `知识所属部门${item.name}`,
                    text: `知识所属部门${item.name}`,
                }
            });

            setApprovalList([...newData0,...newData1]);
        }
    }


    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Knowledge.getKnowledgeList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
            setEditingKey('');
            setEditingIcon([]);
        }else{
            setListData([]);
        }
    }

    const isEditing = (record) => record.id === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        record,
        index,
        children,
        changeType,
        ...restProps
    }) => {
        return (
            <td {...restProps}>
                <>{changeType === "checkbox" ? <Checkbox.Group
                    options={[...department]}
                    disabled={true}
                    defaultValue={record.departmentIds}
                /> : children}</>
            </td>
        );
    };

    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            
            // 修改
            if(editingKey){
                row.id = editingKey;
                uploadImgs(row,'modify');
            }else{
                uploadImgs(row,'add')
            }
            
            
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }
    // 调用新增接口
    const addKnowledge = async(row,id)=>{
       
        row.fileId = id;
        const res = await Api.Knowledge.addKnowledge({data:{...row,...auditData}});
        if(res&&res.code === 0){
            message.success("新增成功",2,()=>{
                setConfirmLoading(false);
                handleCancel();
                getList();
            })
        }else{setConfirmLoading(false);}
    }

    // 调用修改接口
    const modifyKnowledge = async(row,id) => {
        console.log(editingKey,"editingKey")
        if(id){
            row.fileId = id;
        }
        row.workflowFlag = workflowFlag
        const res = await Api.Knowledge.updateKnowledge({data:{
            ...row,
            ...auditData
        }});
        if(res&&res.code === 0){
            message.success("修改成功",2,()=>{
                setConfirmLoading(false);
                setEditingKey('');
                setEditingIcon([]);
                handleCancel();
                getList();
            })
        }else{
            setConfirmLoading(false);
        }
    }

    // 打开新增窗口
    const addModal = (record) => {
        
        // 回填数据
        if(record){

            addForm.setFieldsValue({
                knowledgeTypeName:record.knowledgeTypeName,
                intro:record.intro,
                departmentIds:record.departmentIds,
                fileId:record.fileId,
                sort:record.sort,
            });
            setEditingKey(record.id);

            setAuditData({
                checkType0:record.checkType0,
                checkType1:record.checkType1,
                checkType2:record.checkType2,
                checkType3:record.checkType3,
                checkType4:record.checkType4,
                checkType5:record.checkType5,
                checkType6:record.checkType6,
                checkType7:record.checkType7,
            })

            setDisplayData({
                checkType0:record.checkType0&&record.checkType0.length >0 ? record.checkWorkflowStr0.split("审批流程：")[1]:"",
                checkType1:record.checkType1&&record.checkType1.length >0 ? record.checkWorkflowStr1.split("审批流程：")[1]:"",
                checkType2:record.checkType2&&record.checkType2.length >0 ? record.checkWorkflowStr2.split("审批流程：")[1]:"",
                checkType3:record.checkType3&&record.checkType3.length >0 ? record.checkWorkflowStr3.split("审批流程：")[1]:"",
                checkType4:record.checkType4&&record.checkType4.length >0 ? record.checkWorkflowStr4.split("审批流程：")[1]:"",
                checkType5:record.checkType5&&record.checkType5.length >0 ? record.checkWorkflowStr5.split("审批流程：")[1]:"",
                checkType6:record.checkType6&&record.checkType6.length >0 ? record.checkWorkflowStr6.split("审批流程：")[1]:"",
                checkType7:record.checkType7&&record.checkType7.length >0 ? record.checkWorkflowStr7.split("审批流程：")[1]:"",
            })

            if(record.url){
                setFileList([{url:record.url}]);
            }
        }
       

        setConfirmLoading(false);
        setIsModalOpen(true);
    }

    // 关闭新增窗口
    const handleCancel = () => {
        setIsModalOpen(false);
        setFileList([]);
        setUploadFile('');
    }

    //删除
    const deleteModal = () => {
        if(selectedRowKey){
            ModalConfirm({
                title: `您确认要删除这条数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Knowledge.deleteKnowledge({data:{id:selectedRowKey}});
                        resolve();
                        if(res&&res.code === 0){
                            setSelectedRowKey("");
                            let newSearchData = {...searchData}
                            newSearchData.pageIndex = 1;
                            setSearchData(newSearchData);
                            message.success("删除成功");
                        }
                    }catch{}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }
    
    // 分页变化
    const paginationChange = (page, pageSize) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = page;
        newSearchData.pageSize = pageSize;
        setSearchData(newSearchData);
    }

    const columns = [
        {
            title: '知识类型',
            dataIndex: 'knowledgeTypeName',
            width: '150px',
            editable: true,
            align:"center",
        },
        {
            title: '简介',
            dataIndex: 'intro',
            width: '230px',
            editable: true,
            align:"center",
        },
        {
            title: '排序',
            dataIndex: 'sort',
            width: '120px',
            sorter: true,
            render: (status,records) => {
                return <InputNumber min={1} defaultValue={status} onPressEnter={(e)=>onSortChange(e.target.value,records)} />
            }
        },
        {
            title: '图标',
            dataIndex: 'fileId',
            width: '150px',
            editable: true,
            changeType:"upload",
            align:"center",
            render:(_,record)=>{
                return record.url ? <Image
                    width={60}
                    preview={false}
                    src={record.url}
                /> : ''
            }
        },
        {
            title: '默认分享范围',
            dataIndex: 'departmentIds',
            width: '260px',
            editable: true,
            changeType:"checkbox",
            align:"left",
            className:"table-align-center"
        },
        {
            title: <div>发布知识<br/>(不修改默认分享范围)</div>,
            dataIndex: 'checkWorkflowStr0',
            width: '200px',
            align:"left",
            className:"table-align-center",
        },
        {
            title: <div>发布知识<br/>(修改默认分享范围)</div>,
            dataIndex: 'checkWorkflowStr1',
            width: '200px',
            align:"left",
            className:"table-align-center"
        },

        {
            title: <div>阅读知识<br/>(本部门用户)</div>,
            dataIndex: 'checkWorkflowStr2',
            width: '200px',
            align:"left",
            className:"table-align-center"
        },
        {
            title: <div>阅读知识<br/>(在分享范围内的其他部门用户)</div>,
            dataIndex: 'checkWorkflowStr3',
            width: '220px',
            align:"left",
            className:"table-align-center"
        },
        {
            title: <div>阅读知识<br/>(不在分享范围内的其他部门用户)</div>,
            dataIndex: 'checkWorkflowStr4',
            width: '220px',
            align:"left",
            className:"table-align-center"
        },

        {
            title: <div>下载知识<br/>(本部门用户)</div>,
            dataIndex: 'checkWorkflowStr5',
            width: '200px',
            align:"left",
            className:"table-align-center"
        },
        {
            title: <div>下载知识<br/>(在分享范围内的其他部门用户)</div>,
            dataIndex: 'checkWorkflowStr6',
            width: '220px',
            align:"left",
            className:"table-align-center"
        },
        {
            title: <div>下载知识<br/>(不在分享范围内的其他部门用户)</div>,
            dataIndex: 'checkWorkflowStr7',
            width: '220px',
            align:"left",
            className:"table-align-center"
        },
        {
            title: '操作',
            width: '140px',
            dataIndex: 'operation',
            align:"center",
            render: (_, record) => {
                return <Typography.Link onClick={() => addModal(record)}>
                            调整
                        </Typography.Link>
            },
        },
    ];

    const onSortChange = async(value,records) => {
        try {
            let param = {
                // ...records,
                id:records.id,
                sort: parseInt(value),
                workflowFlag:false,
            }
            const res = await Api.Knowledge.updateKnowledge({data:param});
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 获取上传的图片
    const handleChange = ({ file,fileList: newFileList }) => {
        setFileList(newFileList);
        setUploadFile(file);
    };

    const uploadImg = (file, fileList) => {
        return false;
    };

    // 上传图片获取id
    const uploadImgs = async(row,type) => {
        
        if(uploadFile.name){
            const formData = new FormData();
            formData.append("file",uploadFile);
    
            const res = await Api.Common.uploadFile({
                data:formData,
            });
            if(res&&res.code === 0){
                
                if(type === 'add'){
                    addKnowledge(row,res.data.id)
                }else{
                    modifyKnowledge(row,res.data.id)
                }
            }else{
                setConfirmLoading(false);
            }
        }else{
            modifyKnowledge(row)
        }
        
        
    }

    // 上传按钮
    const uploadButton = (
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            上传图标
          </div>
        </div>
    );

    const toChineseNumber = (n) => {
        if (!Number.isInteger(n) && n < 0) {
          throw Error('请输入自然数');
        }
        const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿'];
        const charArray = String(n).split('');
        let result = '';
        let prevIsZero = false;
        //处理0  deal zero
        for (let i = 0; i < charArray.length; i++) {
          const ch = charArray[i];
          if (ch !== '0' && !prevIsZero) {
            result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
          } else if (ch === '0') {
            prevIsZero = true;
          } else if (ch !== '0' && prevIsZero) {
            result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1];
          }
        }
        //处理十 deal ten
        // if (n < 100) {
        //   result = result.replace('一十', '十');
        // }
        return result;
    }

    const setUpApprovalProcess = (type) => {
        if((auditData[type] && auditData[type].length > 0)){
            setIfApproval(true);
            // 回显数据
            getApprovalProcess(auditData[type])
            
        }else{
            setIfApproval(false);
        }
        
        // 打开弹窗
        setApprovalType(type);
        setApprovalProcessModalType(true)
    }

    const getApprovalProcess = (data) => {
        let list = []
        let fromData = {};
        data.map(item=>{
            list.push({ 
                key:item.key || item.id,
                value:item.businessRoleId+'-'+item.roleBelong
            })
            fromData[item.id || item.key] = item.businessRoleId+'-'+item.roleBelong
        })
        approveForm.setFieldsValue({
            ...fromData,
        });
        setApprovalConfiguration(list)
    }

    const approvalProcessOk = async() => {
        try{
            // if(ifapproval){
                if(ifapproval && approvalConfiguration.length <= 0){
                    message.info("请添加审批人！");
                    return false;
                }
                const row = await approveForm.validateFields();
                setApprovalProcessLoading(true);

                // let keys = Object.values(row);
                let list = {};
                let text = []
                let newList = [];

                for (let key in row) {
                    newList.push({
                        businessRoleId:row[key].split("-")[0],
                        roleBelong:row[key].split("-")[1],
                        key: key,
                    })
                }

                for(let i=newList.length-1; i>=0; i --){
                    let _key = newList[i].businessRoleId+"-"+newList[i].roleBelong;
                    
                    for(let l=0; l<approvalList.length; l ++){
                        if( _key === approvalList[l].value){
                            text.unshift(approvalList[l].label)
                        }
                    }
                }

                list.text = text;

                let _displayData = {...displayData}
                _displayData[approvalType] = text.join("->")
                
                let _auditData = {...auditData}
                _auditData[approvalType] = newList

                setAuditData(_auditData)
                setDisplayData(_displayData)
                approvalProcessCancel();
                setApprovalProcessLoading(false);
                setWorkflowFlag(true);
            // }else{
            //     setApprovalProcessModalType(false)
            // }
        }catch(errorinfo){
            setApprovalProcessLoading(false);
        }
    }

    const approvalProcessCancel = async() => {
        approveForm.resetFields();
        setApprovalConfiguration([]);
        setIfApproval(false);
        setApprovalProcessModalType(false);
    }

    const onSwitchChange = (checked) =>{
        setIfApproval(checked);
        setApprovalConfiguration([])
    }

    const addApproveList = async() => {
        try{
            const row = await approveForm.validateFields();
            setApprovalConfiguration([
                ...approvalConfiguration,
                { key:`approve${Date.now()}`,value:""}
            ])
        }catch{}
    }

    const removeApproveList = (records) => {
        let _approvalConfiguration = [];
        approvalConfiguration.map(item=>{
            if(item.key != (records.key || records.id)){
                _approvalConfiguration.push(item)
            }
        })

        let fromData = {};
        _approvalConfiguration.map(item=>{
            fromData[item.key] = item.value
        })

        approveForm.setFieldsValue({
            ...fromData,
        });

        setApprovalConfiguration(_approvalConfiguration)
    }

    const onApproveChange = (e,records) => {
        let _approvalConfiguration = [...approvalConfiguration];
        
        _approvalConfiguration.map(item=>{
            if(item.key === (records.key || records.id)){
                item.value = e
            }
        })

        let fromData = {};
        _approvalConfiguration.map(item=>{
            fromData[item.key] = item.value
        })

        approveForm.setFieldsValue({
            ...fromData,
        });
        setApprovalConfiguration(_approvalConfiguration)
    }

    
      
    return (
        <div className="knowledgeTypeListFrame">
            <div className="title">知识类型管理</div>
            <div className="operation">
                <Space size={"large"}>
                    <Button type="primary" onClick={addModal}>新增类型</Button>
                    <Button type="primary" danger onClick={deleteModal}>删除类型</Button>
                </Space>
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        isEditing={isEditing}
                        dataSource={listData}
                        columns={columns}
                        pagination={{
                            ...pagination,
                            onChange: paginationChange,
                        }}
                        loading={tableLoading}
                        scroll={
                            {
                                x:"3100px"
                            }
                        }
                        isShowSort={false}
                        footerHeight={75}
                    />
                </Form>
            </div>

            <Modal 
                title={editingKey?"修改知识类型":"新增知识类型" }
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                wrapClassName={"knowledgeAdd"}
                width={620}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'knowledgeTypeName'}
                        label={'知识类型'}
                        name={'knowledgeTypeName'}
                        rules={[{ required: true, message:`请输入知识类型!` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={'intro'}
                        label={'简介'}
                        name={'intro'}
                        rules={[{ required: true, message:`请输入简介!` }]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        key={'sort'}
                        label={'排序'}
                        name={'sort'}
                        rules={[{ required: true, message:`请输入排序!` }]}
                    >
                        <InputNumber min={1}/>
                    </Form.Item>
                    <Form.Item
                        key={'fileId'}
                        label={'图标'}
                        name={'fileId'}
                        rules={[{ required: true, message:`请选择图标!` }]}
                    >
                        <Upload
                            accept="image/png, image/jpeg, image/svg+xml"
                            listType="picture-card"
                            maxCount={1}
                            showUploadList={{
                                showPreviewIcon:false
                            }}
                            fileList={fileList.length > 0?fileList:editingIcon}
                            onChange={handleChange}
                            beforeUpload={uploadImg}
                            accept={"image/*"}
                        >
                            {(fileList.length > 0 || editingIcon.length > 0) ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                    
                    <Form.Item
                        key={'departmentIds'}
                        label={'默认分享范围'}
                        name={'departmentIds'}
                        style={{marginBottom:0}}
                        required
                        // rules={[{ required: true, message:`请选择分享范围!` }]}
                    >
                        <Checkbox.Group options={[...department]} />
                    </Form.Item>
                    <div className="departmentBox"><Checkbox checked disabled>本部门</Checkbox></div>

                    <div  className="approvalConfiguration">
                        <h3 className="topTitle">发布知识/取消发布知识</h3>
                        <div>
                            <div className="title">
                                <span>不修改默认分享范围</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType0")
                                }}>设置</Button>
                            </div>
                            {auditData.checkType0&&auditData.checkType0.length>0&&(<Descriptions column={1} size="small">
                                <Descriptions.Item label="审批层级">
                                    {auditData.checkType0?toChineseNumber(auditData.checkType0.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程"> 
                                    {displayData.checkType0}
                                </Descriptions.Item>
                            </Descriptions>)}

                            <div className="title">
                                <span>修改默认分享范围</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType1")
                                }}>设置</Button>
                            </div>
                            {auditData.checkType1&&auditData.checkType1.length>0&&(<Descriptions column={1} size="small">
                                <Descriptions.Item label="审批层级">
                                    {auditData.checkType1?toChineseNumber(auditData.checkType1.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType1}
                                </Descriptions.Item>
                            </Descriptions>)}
                        </div>

                        <h3 className="topTitle">阅读知识</h3>
                        <div>
                            <div className="title">
                                <span>本部门用户</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType2")
                                }}>设置</Button>
                            </div>
                            {displayData.checkType2&&auditData.checkType2.length>0&&(<Descriptions column={1} size="small">
                            <Descriptions.Item label="审批层级">
                                    {auditData.checkType2?toChineseNumber(auditData.checkType2.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType2}
                                </Descriptions.Item>
                            </Descriptions>)}

                            <div className="title">
                                <span>其他部门用户（在知识分享范围内）</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType3")
                                }}>设置</Button>
                            </div>
                            {displayData.checkType3&&auditData.checkType3.length>0&&(<Descriptions column={1} size="small">
                            <Descriptions.Item label="审批层级">
                                    {auditData.checkType3?toChineseNumber(auditData.checkType3.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType3}
                                </Descriptions.Item>
                            </Descriptions>)}

                            <div className="title">
                                <span>其他部门用户（不在知识分享范围内）</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType4")
                                }}>设置</Button>
                            </div>
                            {displayData.checkType4&&auditData.checkType4.length>0&&(<Descriptions column={1} size="small">
                                <Descriptions.Item label="审批层级">
                                    {auditData.checkType4?toChineseNumber(auditData.checkType4.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType4}
                                </Descriptions.Item>
                            </Descriptions>)}
                        </div>


                        <h3 className="topTitle">下载知识</h3>
                        <div>
                            <div className="title">
                                <span>本部门用户</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType5")
                                }}>设置</Button>
                            </div>
                            {displayData.checkType5&&auditData.checkType5.length>0&&(<Descriptions column={1} size="small">
                                <Descriptions.Item label="审批层级">
                                    {auditData.checkType5?toChineseNumber(auditData.checkType5.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType5}
                                </Descriptions.Item>
                            </Descriptions>)}

                            <div className="title">
                                <span>其他部门用户（在知识分享范围内）</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType6")
                                }}>设置</Button>
                            </div>
                            {displayData.checkType6&&auditData.checkType6.length>0&&(<Descriptions column={1} size="small">
                                <Descriptions.Item label="审批层级">
                                    {auditData.checkType6?toChineseNumber(auditData.checkType6.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType6}
                                </Descriptions.Item>
                            </Descriptions>)}

                            <div className="title">
                                <span>其他部门用户（不在知识分享范围内）</span>
                                <Button type="primary" size="small" onClick={()=>{
                                    setUpApprovalProcess("checkType7")
                                }}>设置</Button>
                            </div>
                            {displayData.checkType7&&auditData.checkType7.length>0&&(<Descriptions column={1} size="small">
                                <Descriptions.Item label="审批层级">
                                    {auditData.checkType7?toChineseNumber(auditData.checkType7.length):""}级审批
                                </Descriptions.Item>
                                <Descriptions.Item label="审批流程">
                                    {displayData.checkType7}
                                </Descriptions.Item>
                            </Descriptions>)}
                        </div>
                    </div>
                </Form>
            </Modal>


            <Modal 
                title="设置审批流程" 
                open={approvalProcessModalType} 
                onOk={approvalProcessOk} 
                onCancel={approvalProcessCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={approvalProcessLoading}
                wrapClassName={"approvalProcess"}
            >
                <h3 className="topTitle">选择是否需要审批</h3>
                <div className="title">
                    <span>是否需要审批</span>
                    <div className="approvalType">
                        <Switch checked={ifapproval} onChange={onSwitchChange} />
                        {ifapproval?"是":"否"}
                    </div>
                </div>
                {ifapproval?(<>
                    <h3 className="topTitle">选择审批人</h3>
                    <Form
                        form={approveForm}
                        component={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        preserve={false}
                    >
                        {approvalConfiguration.map((item,index)=>{
                            return(
                                <div className="formItem">
                                    <Form.Item
                                        key={item.key}
                                        label={`${toChineseNumber(index+1)}级审批人`}
                                        name={item.key}
                                        rules={[{ required: true, message:`请选择审批人!` }]}
                                    >
                                        <Select
                                            style={{
                                                width: '85%',
                                            }}
                                            value={item.value}
                                            onChange={(e) => onApproveChange(e,item)}
                                            options={approvalList}
                                        />
                                    </Form.Item>
                                    <DeleteOutlined 
                                        className="dynamic-delete-button"
                                        onClick={() => removeApproveList(item)}
                                    />
                                </div>
                            )
                        })}
                    </Form>
                    <div style={{textAlign:"center"}}>
                        <Button
                            type="dashed"
                            onClick={() => addApproveList()}
                            style={{
                                width: '40%',
                            }}
                            icon={<PlusOutlined />}
                        >
                            添加审批层级
                        </Button>
                    </div>
                </>):""}
            </Modal>
        </div>
    );
}
export default KnowledgeList;