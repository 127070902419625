/*
    * 分组
*/ 
import myAxios from '../utils/request.js';

export default {
    // 查询
    async getGroupList (params={}, cb) {
        let res = await myAxios.getJson('/sysUsersGroup/selectAll', params);
        return res.data;
    },
    // 删除
    async deleteGroup (params={}, cb) {
        let res = await myAxios.deleteJson('/sysUsersGroup/deleteById', params);
        return res.data;
    },
    // 修改
    async updateGroup (params={}, cb) {
        let res = await myAxios.postJson('/sysUsersGroup/update', params);
        return res.data;
    },
    // 新增
    async addGroup (params={}, cb) {
        let res = await myAxios.postJson('/sysUsersGroup/insert', params);
        return res.data;
    },
}