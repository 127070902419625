import react, {useEffect,useState} from 'react';
import { Timeline, Typography, Space, Empty, DatePicker, Tooltip, message, Input, Popconfirm, Modal, Spin, Tree } from 'antd';
import { BugOutlined, UploadOutlined, FileSearchOutlined, SearchOutlined, LinkOutlined } from '@ant-design/icons';
import moment from 'moment'
import {ModalConfirm, goKnowledgeDetails} from '../../../../utils/tool'
import SelfPagination from "../../../../components/SelfPagination/index";
import KnowledgeFileIcon from "../../../../components/KnowledgeFileIcon/index";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import Api from "../../../../api/index";
import storage from "../../../../utils/storage";


import './index.scss';

const { Text, Title, } = Typography;
const { RangePicker } = DatePicker;

const PolicyList = ({
    policyTypeChange,
    listDate,
    pagination,
    paginationChange,
    policyThemeName,
    policyType,
    searchData,
    getPolicyList,
    setToEditFormData,
    setOpen,
    operationChange,
    policyThemeId,
    setPolicyFromType,
    pageType
}) => {

    const policyTypeList = [
        {text: "全部", value: {id: "1",type: "", policyRegionId: ""}},
        {text: "国务院", value: {id: "2",type: "0", policyRegionId: ""}},
        {text: "部委", value: {id: "3", type: "1", policyRegionId: ""}},
        {text: "地方", value: {id: "4", type: "", policyRegionId: "2"}},
    ];

    const [tableHight, setTableHight] = useState(0);

    const [operationData, setOperationData] = useState({
        searchType:1 // 搜索类型 0标题、1全文
    });

    const [regions, setRegions] = useState({
        saidiCity: [],
        geographic: [],
    });
    const [departmentList, setDepartmentList] = useState([]);

    const [type, setType] = useState({id: "1"});

    const [currentArea, setCurrentArea] = useState({})

    const [contentWidth, setContentWidth] = useState(0);

    const sortTypeList = [
        {text: "按地理分区", key: "geographic"},
        {text: "按赛迪分区", key: "saidiCity"},
    ];// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [sortType, setSortType] = useState("geographic");// 赛迪分区(saidiCity) / 地理分区(geographic)
    const [selectedAreaList, setSelectedAreaList] = useState([]); // 选择的地方codes
    const [selectedRegions, setSelectedRegions] = useState(null); // 选择的地方对象
    const [selectedDepartList, setSelectedDepartList] = useState([]); // 选择的部委

    const [showSearch, setShowSearch] = useState(false); // 控制搜索输入框是否显示
    const [showDate, setShowDate] = useState(false); // 控制日历选择框是否显示
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false); // 控制选择地区/部委弹窗是否显示


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [listTree, setListTree] = useState(false);
    const [selectedKeys,setSelectedKeys] = useState("");
    const [modalSpinningType, setModalSpinningType] = useState(false);

    useEffect(()=>{
        window.addEventListener("resize", computeContentWidth);
        window.addEventListener("click", closeOperateBox);

        getRegionsList();

        getDepartmentList();

        computeContentWidth();

        return () => {
            window.removeEventListener("resize", computeContentWidth);
            window.removeEventListener("click", closeOperateBox);
        }
    },[])

    useEffect(()=>{
        setOperationData({
            policySearch:searchData.policySearch,
            endTime:searchData.endTime,
            startTime:searchData.startTime
        })
    },[JSON.stringify(searchData)])

    useEffect(()=>{

        let rootHeight = document.querySelector("#root").offsetHeight;
        let tableBodyTop = document.querySelector(".policyListFrame").getBoundingClientRect().top+90;
        let tableContentHeight = rootHeight-tableBodyTop-60;

        tableContentHeight = policyThemeName?tableContentHeight-50:tableContentHeight

        setTableHight(tableContentHeight);

    },[JSON.stringify(listDate)])

    useEffect(() => {
        cancelChangeRegions(selectedRegions);
        cancelChangeDepartment();
    }, [showSearch, showDate])

    useEffect(() => {
        setSelectedDepartList(policyType.countryDeptIds || []);
        setSelectedAreaList(policyType.codes || []);
    }, [policyType])

    const getRegionsList = async() => {
        try {
            let res = await Api.Common.getRegions({});
            if(res && res.code === 0 && res.data){
                setRegions(res.data)
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }
    const getDepartmentList = async() => {
        // 0 国务院 1 部委
        try {
            let res = await Api.Common.getDepartments({data:{type:1}});
            if(res && res.code === 0 && res.data){
                setDepartmentList(res.data[0].childList || [])
            }else{}
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 计算内容所占宽度
    const computeContentWidth = () => {
        const dom = document.getElementById("rightContentBox");
        if (dom) {
            setContentWidth(dom.clientWidth);
        }
    }

    // 点击关闭
    const closeOperateBox = () => {
        if (!operationData.policySearch) {
            setShowSearch(false);
        }
        if (!operationData.startTime) {
            setShowDate(false);
        }
        setTooltipIsOpen(false)
    }

    const handleModeChange = (value) => {
        setType(value);

        const val = {...value, codes: [], countryDeptIds: [],};
        delete val.id;
        policyTypeChange(val);
    };

    const deleteTheme = (item) => {
        ModalConfirm({
            title: '确定要删除这个政策数据吗？',
            onOk:async(resolve, reject)=> {
                try{
                    let res = await Api.Knowledge.deletePolicyByPolicyId({data:{
                        "departmentId":searchData.departmentId,
                        "policyId":item.elasticId || item.policyId,
                        "policySourceType":item.policySourceType,
                        "policyThemeId":searchData.policyThemeId,
                        "titleHref":item.titleHref
                    }});
                    if(res && res.code === 0){
                        resolve()
                        message.success("删除成功");
                        getPolicyList();
                    }else{
                        reject();
                    }
                }catch(errInfo){console.log('验证失败:', errInfo);reject();}
            },
        })
    }

    const updateTheme = (item) => {
        setToEditFormData(item);
        setOpen(true);
        setPolicyFromType(null)
    };


    // 爬虫文件，通过站内搜素添加到新的主题下，在新的主题列表中点击修改，查讯详情报错
    const getByPolicyDetailBYPolicyId = async(item) => {
        try {
            let res = await Api.Knowledge.getByPolicyDetailBYPolicyId({data:{
                policyId:item.elasticId || item.policyId,
                policySourceType:item.policySourceType,
                titleHref:item.titleHref,
            }});
            if(res && res.code === 0){
                setPolicyFromType("2")
                setOpen(true);
                setToEditFormData({
                    ...res.data,
                    fileType:item.fileType
                });
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const exgetDitals = (item) => {
        if (item.titleHref) {
            window.open(item.titleHref);
        }else if(item.relationFileId){
            getWPSFileUrl(item.relationFileId)
        }
    }

    const getWPSFileUrl = async(relationFileId) => {
        try{
            let res = await Api.Knowledge.getWPSFilePreview({fileId:relationFileId});
            if(res&&res.code===200&&res.data){
                window.open(res.data.link);
            }
        }catch{}
    }

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        newOperationData.pageIndex = 1;
        // 搜索类型 0标题、1全文
        newOperationData.searchType = 1;
        if(type === "timeRange") {
            newOperationData.startTime = value[0];
            newOperationData.endTime = value[1];

            operationChange(newOperationData)
        } else {
            newOperationData[type] = value;
            if(value === ""){
                operationChange(newOperationData)
            }
        }
        setOperationData(newOperationData)
        // operationChange(newOperationData);
    }
    const onSearch = () => {
        operationChange(operationData)
    }

    const regionsChange = (regions, codes) => {
        setSelectedAreaList(codes);
        setSelectedRegions(regions);
    }
    const cancelChangeRegions = (regions) => {
        setTooltipIsOpen(false);
        regionsChange(regions,[...(policyType.codes || [])]);
    }

    const confirmChangeRegions = (region, codes) => {
        setTooltipIsOpen(false);
        const params = {...type};
        delete params.id;
        policyTypeChange({
            ...params,
            codes: codes || [],
            regionId:"",
            name: region?.name,
        });
    }

    const departmentChange = (codes) => {
        setSelectedDepartList(codes);
    }
    const cancelChangeDepartment = () => {
        setTooltipIsOpen(false);
        departmentChange([...(policyType.codes || [])]);
    }

    const confirmChangeDepartment = (codes) => {
        setTooltipIsOpen(false);
        const params = {...type};
        delete params.id;
        policyTypeChange({
            ...params,
            countryDeptIds: codes || [],
        });
    }

    const deleteSelectedCode = (policyType, isDepartment, code, region) => {
        const key = isDepartment ? "countryDeptIds" : "codes";
        let selectedCodes = [...(policyType[key] || [])];
        const idx = selectedCodes.indexOf(code);
        console.log(selectedCodes, code);
        if (idx > -1) {
            selectedCodes.splice(idx, 1);
            if (isDepartment) {
                confirmChangeDepartment(selectedCodes);
            } else {
                confirmChangeRegions(region, selectedCodes);
            }
        }
    }

    const renderRegions = (sortType, selectedCodes) => {
        const areaList = regions[sortType] || [];
        let allAreaCodeList = [];
        areaList.forEach(areaItem => {
            const addressChildren = areaItem.children || [];
            addressChildren.forEach(addressItem => {
                allAreaCodeList.push(addressItem.code);
            })
        });
        const isAllSelected = selectedCodes.length >= allAreaCodeList.length;
        return (
            <div className="regionsPopoverBox">
                <div className="sortTypeListBox">
                    {
                        sortTypeList.map(item => {
                            const {text, key} = item;
                            const isActive = sortType === key;
                            return (
                                <span
                                    className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                    key={key}
                                    onClick={() => {
                                        if (!isActive) {
                                            setSortType(key)
                                        }
                                    }}
                                >{text}</span>
                            )
                        })
                    }
                </div>
                <div className="areaListBox">
                    <span
                        className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                        onClick={()=>{
                            regionsChange({
                                id: 999,
                                name:""
                            }, isAllSelected ? [] : allAreaCodeList)
                        }}
                    >全部地区</span>
                    <div className="regionsAllListBox">
                        {
                            areaList.map(item => {
                                let addressIsAllSelected = true;
                                const addressCodeList = [];
                                for (const addressItem of item.children) {
                                    const code = addressItem.code;
                                    let flag = selectedCodes.includes(code);
                                    if (addressIsAllSelected) {
                                        addressIsAllSelected = flag;
                                    }
                                    addressCodeList.push(code);
                                }
                                return (
                                    <div key={item.id} className="regionsList">
                                        <span
                                            className={`regionsArea ${(addressIsAllSelected || isAllSelected) ? "selectRegion":""}`}
                                            onClick={()=>{
                                                let codes = [...selectedCodes];
                                                if (addressIsAllSelected) {
                                                    addressCodeList.forEach(code => {
                                                        let idx = codes.indexOf(code);
                                                        codes.splice(idx, 1);
                                                    })
                                                } else {
                                                    addressCodeList.forEach(code => {
                                                        if (!codes.includes(code)) {
                                                            codes.push(code);
                                                        }
                                                    })
                                                }
                                                regionsChange(item, codes)
                                            }}
                                        >{item.name}</span>
                                        <div className="regionsBox">
                                            {
                                                item.children.map(it => {
                                                    const {code, name} = it;
                                                    const idx = selectedCodes.indexOf(code);
                                                    const isSelected = idx > -1;
                                                    return (
                                                        <span
                                                            key={code}
                                                            onClick={()=> {
                                                                let codes = [...selectedCodes];
                                                                if (isSelected) {
                                                                    codes.splice(idx, 1);
                                                                } else {
                                                                    codes.push(code);
                                                                }
                                                                regionsChange(it, codes);
                                                            }}
                                                            className={`regionsItem ${(isSelected || addressIsAllSelected || isAllSelected) ? "selectRegion":""}`}
                                                        >{name}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn" onClick={() => cancelChangeRegions(selectedRegions)}>取消</span>
                    <span className="operateBtn confirmBtn" onClick={() => confirmChangeRegions(selectedRegions, selectedAreaList)}>确定</span>
                </div>
            </div>
        )
    }

    const renderDepartmentList = (selectedCodes) => {
        let allCodeList = [];
        departmentList.forEach(item => {
            const departmentChildren = item.childList || [];
            departmentChildren.forEach(it => {
                allCodeList.push(it.id);
            })
        });
        const isAllSelected = selectedCodes.length >= allCodeList.length;
        return (
            <div className="regionsPopoverBox departmentPopoverBox">
                <div className="areaListBox">
                    <span
                        className={`regionsAllArea ${isAllSelected ? "selectRegion" : ""}`}
                        onClick={()=>{
                            departmentChange(isAllSelected ? [] : allCodeList)
                        }}
                    >全部部委</span>
                    <div className="regionsAllListBox">
                        {
                            departmentList.map((item, idx) => {
                                if(item.childList.length > 0){
                                    let departmentIsAllSelected = true;
                                    const departmentCodeList = [];
                                    for (const addressItem of item.childList) {
                                        const code = addressItem.id;
                                        let flag = selectedCodes.includes(code);
                                        if (departmentIsAllSelected) {
                                            departmentIsAllSelected = flag;
                                        }
                                        departmentCodeList.push(code);
                                    }
                                    return (
                                        <div key={idx} className="regionsList">
                                            <span
                                                className={`regionsArea ${(departmentIsAllSelected || isAllSelected) ? "selectRegion":""}`}
                                                onClick={()=>{
                                                    let codes = [...selectedCodes];
                                                    if (departmentIsAllSelected) {
                                                        departmentCodeList.forEach(code => {
                                                            let idx = codes.indexOf(code);
                                                            codes.splice(idx, 1);
                                                        })
                                                    } else {
                                                        departmentCodeList.forEach(code => {
                                                            if (!codes.includes(code)) {
                                                                codes.push(code);
                                                            }
                                                        })
                                                    }
                                                    departmentChange(codes)
                                                }}
                                            >{item.name}</span>
                                            <div className="regionsBox">
                                                {
                                                    item.childList.map(it => {
                                                        const idx = selectedCodes.indexOf(it.id);
                                                        const isSelected = idx > -1;
                                                        return (
                                                            <span
                                                                key={it.id}
                                                                onClick={()=> {
                                                                    let codes = [...selectedCodes];
                                                                    if (isSelected) {
                                                                        codes.splice(idx, 1);
                                                                    } else {
                                                                        codes.push(it.id);
                                                                    }
                                                                    departmentChange(codes);
                                                                }}
                                                                className={`regionsItem ${(isSelected || departmentIsAllSelected || isAllSelected) ? "selectRegion":""}`}
                                                            >{it.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                </div>
                <div className="footerOperateBox">
                    <span className="operateBtn cancelBtn" onClick={() => cancelChangeDepartment()}>取消</span>
                    <span className="operateBtn confirmBtn" onClick={() => confirmChangeDepartment(selectedDepartList)}>确定</span>
                </div>
            </div>
        )
    }

    let paginationObject = {};

    if (contentWidth) {
        if (contentWidth <= 650) {
            paginationObject.simple = true;
        } else if (contentWidth <= 720) {
            paginationObject.showLessItems = true;
        }
    }

    const sortTypeId = type.id;
    let selectedAreaOrDepartList = [];
    const isDepartment = sortTypeId === "3";
    
    if (isDepartment) {
        const selectedList = [...(policyType.countryDeptIds || [])];
        departmentList.forEach(item => {
            item.childList.forEach(it => {
                if (selectedList.includes(it.id)) {
                    selectedAreaOrDepartList.push({
                        code: it.id,
                        name: it.name,
                    });
                }
            })
        })
    } else if (sortTypeId === "4") {
        const selectedList = [...(policyType.codes || [])];
        regions[sortType]?.forEach(item => {
            item.children.forEach(it => {
                if (selectedList.includes(it.code)) {
                    selectedAreaOrDepartList.push(it);
                }
            })
        })
    }


    const getPublicpolicyTree = async () => {
        setModalSpinningType(true);
        try {
            let res = await Api.Knowledge.getPublicPolicyeTree({
                data: {
                    departmentId: "1702277047663071232"
                }
            });
            setModalSpinningType(false);
            if (res && res.code === 0 && res.data) {
                setListTree(res.data)
                
            }else{setListTree([])}
        } catch (errInfo) {
            setListTree([]);
            setModalSpinningType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const movePublicClassification = (item) => {
        getPublicpolicyTree();
        setIsModalOpen(true);
    }

    // 取消分享
    const cancelSharing = async(item) => {

    }

    const handleOk = async() => {
        if(!selectedKeys){
            message.info("选择移动知识的目标位置");
            return false;
        }
        try {
            setConfirmLoading(true);
            let res = await Api.Knowledge.moveKnowledgeType({
                data: { 
                    departmentSubclassificationId:selectedKeys,
                },
            });
            setConfirmLoading(false);
            if(res && res.code === 0){
                getList();
                message.success("移动成功");
                handleCancel()
            }else{}
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    const handleCancel = () => {
      setIsModalOpen(false);
      setSelectedKeys("")
    };

    const onSelect = (selectedKeys,e) => {
        const node = e.node;
        if (String(node?.policyThemeType) === "1") {
            setSelectedKeys(selectedKeys[0]);
        }
    }

    const getFooterOperation = (item) => {
        let content = "";
        
        if(pageType === "public"){
            content = (
                <div className="oprationBox">
                    <Space>
                        <Tooltip placement="topLeft" title="移动分类">
                            <Iconfont type="icon-move" onClick={(e)=>{
                                e.stopPropagation();
                                movePublicClassification(item)
                            }}/>
                        </Tooltip>
                        <Tooltip placement="topLeft" title="取消分享">
                            <Iconfont type="icon-quxiaolianjie" nClick={(e)=>{
                                e.stopPropagation();
                                cancelSharing(item)
                            }}/>
                        </Tooltip>
                    </Space>
                </div>
            )
        }else if(!policyThemeName){
            content = (
                <div className="oprationBox">
                    <Space>
                        <Tooltip placement="topLeft" title="修改">
                            <Iconfont type={'icon-bianji11'} onClick={(e)=>{
                                e.stopPropagation();
                                updateTheme(item)
                            }}/>
                        </Tooltip>
                        <Tooltip placement="topLeft" title="删除">
                            <Iconfont type={'icon-shanchu1'} onClick={(e)=>{
                                e.stopPropagation();
                                deleteTheme(item)
                            }}/>
                        </Tooltip>
                        {item.titleHref?<Tooltip placement="topLeft" title="原文地址">
                            <LinkOutlined onClick={(e)=>{
                                e.stopPropagation();
                                window.open(item.titleHref);
                            }}/>
                        </Tooltip>:null}
                    </Space>
                </div>
            )
        }else if(pageType === 'titleHref'){
            content = (
                <div className="oprationBox" style={{textAlign: 'right'}}>
                    <Space>
                        {item.titleHref?<Tooltip placement="topLeft" title="原文地址">
                            <LinkOutlined onClick={(e)=>{
                                e.stopPropagation();
                                window.open(item.titleHref);
                            }}/>
                        </Tooltip>:null}
                    </Space>
                </div>
            )
        }

        return content
    }

    const goPolicyDetails = (item) => {
        // 政策爬虫文件没有文件类型，默认给pdf
        window.open(`/page/knowledgeDetails?knowledgeId=${item.id}&type=policy&knowledgeFileType=${item.fileType}&permission=true`)
    }

    return (
        <div className="policyListFrame">
            <div className="operationGroup">
                {
                    policyTypeList.map(item => {
                        const {value, text} = item;
                        const isActive = type.id === String(value.id);
                        return (
                            <div
                                onClick={()=>{handleModeChange(value)}}
                                className={`groupItem ${isActive ? "groupItemChecked" : ""}`}
                                key={text}
                            >{text}</div>
                        )
                    })
                }
            </div>
            <div className="policyListContentBox">
                <div className="conditionBox">
                    <div className="conditionItem selectedAreaOrDepartmentBox" style={{display: ["3", "4"].includes(sortTypeId) ? "" : "none"}}>
                        <Tooltip
                            open={tooltipIsOpen}
                            overlayClassName={"areaOrDepartmentTooltipBox"}
                            placement={"bottomRight"}
                            title={(
                                <div className="areaOrDepartmentContentBox" onClick={e => e.stopPropagation()}>
                                    {
                                        isDepartment ?
                                            renderDepartmentList(selectedDepartList)
                                            :
                                            (sortTypeId === "4" ? renderRegions(sortType, selectedAreaList) : undefined)
                                    }
                                </div>
                            )}
                            trigger={"click"}
                            onClick={e => e?.stopPropagation()}
                        >
                            <span className="iconBtn" onClick={() => setTooltipIsOpen(!tooltipIsOpen)}><Iconfont type={"icon-guolvqi"} /></span>
                        </Tooltip>
                        <div
                            className="areaOrDepartmentContentBox"
                            style={{display: selectedAreaOrDepartList.length > 0 ? "" : "none"}}
                        >
                            <i></i>
                            <div className="selectedOperateBox">
                                {
                                    (policyType.codes?.length > 0 || policyType.countryDeptIds?.length > 0) ? (
                                        <Popconfirm
                                            title={`确定清空所选${isDepartment ? "部委" : "地方"}吗？`}
                                            placement="right"
                                            onConfirm={() => {
                                                if (isDepartment) {
                                                    confirmChangeDepartment([]);
                                                } else {
                                                    confirmChangeRegions({}, [])
                                                }
                                            }}
                                            overlayClassName={"deletePupConfirmBox"}
                                        >
                                            <div className="listItem deleteBtn">
                                                <span className="clickBtn">< Iconfont type="icon-shanchu1" /></span>
                                                <span className="itemText">清空</span>
                                            </div>
                                        </Popconfirm>
                                    ) : undefined
                                }
                            </div>
                            <div className="areaOrDepartmentListBox">
                                {
                                    selectedAreaOrDepartList.map(item => {
                                        const {code, name} = item;
                                        return (
                                            <div className="listItem" key={code}>
                                                <span className="itemText" title={name}>{name}</span>
                                                <span
                                                    className="clickBtn"
                                                    onClick={() => deleteSelectedCode(policyType, isDepartment, code, selectedRegions)}
                                                ><Iconfont type="icon-close1" /></span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()}>
                        {
                            (showDate || operationData.startTime) ? (
                                <RangePicker
                                    className="choiceDateBox"
                                    style={{width: "10rem"}}
                                    suffixIcon={undefined}
                                    value={operationData.startTime?[moment(operationData.startTime), moment(operationData.endTime)]:[]}
                                    onChange={(date, dateString)=>{
                                        searchChange(dateString,"timeRange")
                                    }}
                                />
                            ) : undefined
                        }
                        <span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.startTime) {
                                    setShowDate(!showDate)
                                }
                            }}
                        ><Iconfont type={"icon-riqi2"} /></span>
                    </div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()}>
                        {
                            (showSearch || operationData.policySearch) ? (
                                <Input
                                    className="searchInputBox"
                                    value={operationData.policySearch}
                                    placeholder={"输入标题或内容关键字"}
                                    allowClear={true}
                                    onPressEnter={()=> {
                                        onSearch();
                                    }}
                                    onChange={(e)=>{
                                        searchChange(e.target.value,"policySearch");
                                    }}
                                />
                            ) : undefined
                        }

                        <span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.policySearch) {
                                    setShowSearch(!showSearch)
                                }
                            }}
                        ><Iconfont type={"icon-search"} /></span>

                    </div>
                </div>

                <div className="rightContentBox" id="rightContentBox">
                    <div className="listContent">
                        {listDate && listDate.length > 0 ? (
                            <Timeline mode={'left'}>
                                {
                                    listDate.map( item => {
                                        return (
                                            <Timeline.Item
                                                onClick={()=>goPolicyDetails({
                                                    ...item,
                                                    type:"ES",
                                                    knowledgeTypeId:"4",
                                                })}
                                                key={item.policyId || item.elasticId} label={item.publicationTime}
                                            >
                                                <div className="iconAndTitle">
                                                    <div className="icon">
                                                        {/* 文件图标 */}
                                                        <KnowledgeFileIcon
                                                            fileType={item.fileType}
                                                        />
                                                    </div>
                                                    <div className="contentItem">
                                                        <div className="title">
                                                            <div
                                                                className="contentItemTitle"
                                                                dangerouslySetInnerHTML={{__html: item.policyTitle}}
                                                            />
                                                            {/* <Tooltip placement="topLeft">
                                                    <p className="contentItemTitle">《{item.policyTitle}》</p>
                                                </Tooltip> */}
                                                            {/* <Text
                                                ellipsis={true}
                                                strong
                                                style={{fontSize:'0.8rem'}}>《{item.policyTitle}》</Text> */}
                                                            {/* 0爬虫 1手动上传政策 2站内搜索添加 */}
                                                            {/* 来源 */}

                                                            {getFooterOperation(item)}
                                                            
                                                        </div>

                                                        <div>
                                                            <Space size={"middle"} style={{marginLeft:7}}>

                                                                {item.policyInstitution&&<Text type="secondary">{item.policyInstitution}</Text>}

                                                                {item.docNum&&<Text type="secondary">{item.docNum}</Text>}

                                                                {item.isEffective === 0?(<Text type={'success'}>全文有效</Text>):
                                                                    item.isEffective === 1?(<Text type={'danger'}>失效</Text>):""}

                                                                {item.isManual === 1&&(<span>（手动摘要）</span>)}

                                                                <span className="policySourceType">
                                                        {item.policySourceType === 0 ?<BugOutlined />:
                                                            item.policySourceType === 1 ?<UploadOutlined />:
                                                                item.policySourceType === 2 ?<FileSearchOutlined />:""}
                                                    </span>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 是否是手动摘要(1是，2否) */}
                                                {item.isManual === 1?(
                                                    <div
                                                        className="contentText"
                                                        title={item.policyDigest}
                                                        dangerouslySetInnerHTML={{__html: item.policyDigest}}
                                                    />
                                                    // <p className="contentText" title={item.policyDigest}>{item.policyDigest}</p>
                                                ):(
                                                    <div
                                                        className="contentText"
                                                        title={item.policyContent}
                                                        dangerouslySetInnerHTML={{__html: item.policyContent}}
                                                    />
                                                    // <p className="contentText" title={item.policyContent}>{item.policyContent}</p>
                                                )}

                                                {/*<div className="listItemFooter">*/}
                                                {/*    <div className="listItemFooterItem">*/}
                                                {/*        <Space size={"large"}>*/}

                                                {/*            <span>发文时间：{item.publicationTime || "--"}</span>*/}

                                                {/*        </Space>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/* <Tooltip placement="topLeft" title={item.isManual === 1 ? item.policyDigest: item.policyContent}> */}

                                                {/* </Tooltip> */}
                                            </Timeline.Item>
                                        )
                                    })
                                }
                            </Timeline>
                        ):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </div>
                    {pagination && (pagination.total> 0)?(<div className="pagination">
                        <SelfPagination
                            current={pagination.pageIndex}
                            total={pagination.total}
                            onSizeChange={paginationChange}
                            {...paginationObject}
                        />
                    </div>):""}
                </div>
            </div>

            <Modal 
                title="移动文件" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
            >
                <p>选择移动知识的目标位置</p>
                <div
                    style={{
                        padding: '1rem',
                        background: '#ffffff'
                    }}
                >
                    <Spin size="small" spinning={modalSpinningType}>
                        <Tree
                            onSelect={onSelect}
                            treeData={listTree}
                            selectedKeys={[selectedKeys]}
                            fieldNames={{
                                title:"policyName",
                                key: "id",
                                children:"children",
                                isLeaf:"policyThemeType",
                            }}
                        />
                    </Spin>
                </div>
            </Modal>
        </div>
        )
    }

export default PolicyList;
