import react, {useEffect,useState} from 'react';
import { Input, Typography, Radio, Empty, Modal, Space, message, Spin, Checkbox, Cascader, Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, FileWordOutlined, FilePdfOutlined } from '@ant-design/icons';

import './index.scss';
import storage from "../../../../../utils/storage";
import SelfPagination from "../../../../../components/SelfPagination/index";
import KnowledgeFileIcon from "../../../../../components/KnowledgeFileIcon/index";
import Api from "../../../../../api/index";
import {serachType,pagingParam} from '../../../../../utils/defaultValue'

import "./index.scss";

const { Text, Link, } = Typography;
const { Search } = Input;

const PolicyListModal = ({searchModalOpen, onCloseSearchModal, defaultSearchValue, defaultSelecthValue,setToEditFormData, setOpen, selectedTheme, setPolicyFromType}) => {
    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo")?storage.getItem("userInfo"):{})
    const [timeSort, setTimeSort] = useState('0');
    const [sortKey, setSortKey] = useState('0');
    const [searchValue, setSearchValue] = useState("");

    const [listDate, setListDate] = useState([]);

    const [spinningType, setSpinningType] = useState(false);

    const [selecthValue, setSelecthValue] = useState('1');

    // 查询数据
    const [modalSearchData, setModalSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [modalPagination, setModalPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [cascaderValue, setCascaderValue] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedList, setCheckedList] = useState({});
    const [indeterminate, setIndeterminate] = useState(false);
    const [modalSelectTree, setModalSelectTree] = useState([]);

    useEffect(()=>{
        getAllDepartment()
    },[])

    useEffect(()=>{
        if(searchModalOpen){
            getPolicyList();
        }
    },[JSON.stringify(modalSearchData)])

    const getAllDepartment = async() => {
        try{
            const res = await Api.Department.getDepartmentListAll({
                data:{}
            });
            if(res && res.code === 0 && res.data){
                res.data.map(item=>{
                    item.policyName = item.name;
                    item.isLeaf = false;
                })
                setModalSelectTree([...res.data]);
            }else{
                setModalSelectTree([...res.data]);
            }
        }catch{}
    }

    useEffect(()=>{
        setSelecthValue(defaultSelecthValue);
    },[defaultSelecthValue])
    
    const getPolicyList = async() => {
        setListDate([]);
        setSpinningType(true);
        try {
            let res = await Api.Knowledge.getAllPublicPolicyList({data:{
                ...modalSearchData,
            }});
            setSpinningType(false);
            if(res && res.code === 0 && res.data.records){

                let _data = res.data;
                // 勾选了全选按钮后，当前筛选的类型下的数据都选中
                if(checkedAll){
                    let ids = [];
                    _data.records.map(item=>{
                        ids.push(item.elasticId || item.policyId)
                    })
                    checkedList[_data.current] = ids;
                    console.log(checkedList,"checkedList")
                    setCheckedList({...checkedList});
                    setIndeterminate(false);
                }
                
                setModalPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total": _data.total
                });

                setListDate(_data.records);

            }else{}
        } catch (errInfo) {
            setSpinningType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const handleOk = () => {
        console.log(checkedList,"checkedList")
        if(JSON.stringify(checkedList) != "{}"){
            let ids = [];
            for (let key in checkedList){
                ids = [...ids,...checkedList[key]]
            }
            console.log(ids)
            
        }else{
            message.info('请输入需要添加的政策')
        }
    }
    

    const sortValueChange = (value) => {
        let _sort = '';
        // 按时间排序时默认降序
        // 0 相关度 1 阅读量 4表示按时间排序 3 降序 2 升序
        if(value === '4'){
            _sort = timeSort === '3' ? '2' : '3'
        }else{
            _sort = value
        }
        setSortKey(value);
        setTimeSort(_sort);
        modalSearchData.sort = _sort;
        setModalSearchData(modalSearchData)
    }

    // 搜索弹窗
    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }
   
    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        let _searchData = {...modalSearchData}
        _searchData.siteSearch = searchValueTrim;

        if(cascaderValue && cascaderValue.length > 0){
            _searchData.departmentId = cascaderValue[0]
        }else{
            _searchData.departmentId = ""
        }

        if(cascaderValue && cascaderValue.length > 1){
            _searchData.policyThemeId = cascaderValue[cascaderValue.length-1];
        }else{
            _searchData.policyThemeId = ""
        }
        console.log(cascaderValue,"cascaderValue")
        setModalSearchData(_searchData)
    }

    const onSelect = (value) => {
        modalSearchData.searchType = value
        setModalSearchData(modalSearchData)
        setSelecthValue(value);
    }

    // 分页变化
    const paginationChange = (pageIndex,pageSize) => {
        let _searchData = {...modalSearchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setModalSearchData(_searchData);
    }

    const getDitals = (item) => {
        if (item.titleHref) {
            window.open(item.titleHref);
        }else if(item.relationFileId){
            getWPSFileUrl(item.relationFileId)
        }
    }

    const getWPSFileUrl = async(relationFileId) => {
        try{
            let res = await Api.Knowledge.getWPSFilePreview({fileId:relationFileId});
            if(res&&res.code===200&&res.data){
                window.open(res.data.link);
            }
        }catch{}
    }

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const loadData = async(selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        console.log(targetOption,"targetOption")
        if(!targetOption.children){
            getpolicyTree(targetOption)
        }
    }


    const getpolicyTree = async (targetOption) => {
        try {
            let res = await Api.Knowledge.getPublicPolicyeTree({
                data: {
                    departmentId: targetOption.id
                }
            });
            targetOption.loading = false;
            if(res && res.code === 0 && res.data){
                res.data.map(item=>{
                    if(targetOption.children && targetOption.children.length > 0){
                        item.isLeaf = false;
                    }
                })
                targetOption.children = [...res.data];
                setModalSelectTree([...modalSelectTree]);
            }else{
                targetOption.isLeaf = true;
                targetOption.children = [];
                setModalSelectTree([...modalSelectTree]);
            }
        } catch (errInfo) {
            targetOption.isLeaf = true;
            targetOption.children = [];
            setModalSelectTree([...modalSelectTree]);
            console.log('验证失败:', errInfo);
        }
    }


    const onCascaderChange = (value, selectedOptions) => {
        setCascaderValue(value)
        console.log(value, selectedOptions)
    }

    const onResetting = () => {
        setSearchValue("");
        setModalSearchData({
            ...pagingParam,
            "knowledgeName":"",
        })
        setCascaderValue([])
    }

    const onChangeCheckedAll = () => {
        setCheckedAll(!checkedAll)
        if(!checkedAll){
            let ids = [];
            listDate.map(item=>{
                ids.push(item.elasticId || item.policyId)
            })
            checkedList[modalPagination.pageIndex] = ids;
            setCheckedList({...checkedList});
            setIndeterminate(false);
        }else{
            checkedList[modalPagination.pageIndex] = [];
            setCheckedList({...checkedList})
            setIndeterminate(false);
        }
    }

    const checkOnchange = (e) => {
        let _newList = checkedList[modalPagination.pageIndex]
        if(e.target.checked){
            _newList = _newList ? [..._newList,e.target.value]:[e.target.value]
        }else{
            _newList = _newList.filter(item=>item!=e.target.value)
        }
        checkedList[modalPagination.pageIndex] = _newList;
        setCheckedList({...checkedList})
        setIndeterminate(!!_newList.length && _newList.length < listDate.length);
    }

    return (
        <div>
            <Modal
                onCancel={onCloseSearchModal}
                open={searchModalOpen}
                onOk={handleOk}
                title={"选择要添加的政策"}
                wrapClassName={"publicPolicyListModal"}
                maskClosable={false}
                width={900}
                zIndex={900}
            >
                <div className="operation">
                    <div className="operationItem">
                        <span className="title">类型：</span>
                        <Cascader 
                            value={cascaderValue}
                            options={modalSelectTree} 
                            loadData={loadData} 
                            onChange={onCascaderChange} 
                            fieldNames={{
                                label: 'policyName', 
                                value: 'id', 
                            }}
                            changeOnSelect
                            allowClear
                        />
                    </div>
                    <div className="operationItem">
                        <Input
                            placeholder="输入关键词搜索"
                            onChange={changeValue}
                            onPressEnter={onSearch}
                            value={searchValue}
                            allowClear
                        />
                    </div>
                    <div className="operationItem" style={{width:150}}>
                        <Space size={"large"}> 
                            <Button type="primary" onClick={onSearch}>搜索</Button>
                            <Button onClick={onResetting}>重置</Button>
                        </Space>
                    </div>
                </div>

                <Spin size="small" spinning={spinningType}>
                    {listDate&&listDate.length>0?(
                    <div className="listBox" style={{height:400}}>
                        {listDate.map(item => {
                            let checkedValue = checkedList[modalPagination.pageIndex] ? checkedList[modalPagination.pageIndex] :[]
                            return(
                            <div key={item.elasticId || item.policyId} className="listItem">
                                <Checkbox 
                                    value={item.elasticId || item.policyId} 
                                    checked={checkedValue.includes(item.elasticId || item.policyId)}
                                    style={{float: 'left'}}
                                    onChange={checkOnchange}
                                ></Checkbox>
                                <div onClick={()=>getDitals(item)}>
                                    <div>
                                        <div className="listItemHeader">
                                        
                                            {/* 文件图标 */}
                                            <KnowledgeFileIcon
                                                fileType={item.fileType}
                                            />
                                            
                                            {/* 标题 */}
                                            <div className="listTitle" dangerouslySetInnerHTML={{__html: item.policyTitle}} />
                                        </div>
                                        
                                        {/* 摘要 */}
                                        
                                        {item.isManual === 1?(
                                            <div className="listItemContent" dangerouslySetInnerHTML={{__html: item.policyDigest || "--"}} />
                                        ):(
                                            <div className="listItemContent" dangerouslySetInnerHTML={{__html: item.policyContent || "--"}} />
                                        )}
                                    </div>

                                    <div className="listItemFooter">
                                        <div className="listItemFooterItem">
                                            <Space size={"large"}>
                                                <span>
                                                发文机构：<span className="jumpPointer">{item.policyInstitution || "--"}</span>
                                                </span>

                                                <span>
                                                发文字号：<span className="jumpPointer">{item.docNum || "--"}</span>
                                                </span>
                                                
                                                <span>发文时间：{item.publicationTime || "--"}</span>
                                                
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )    
                        })}
                    </div>
                ):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
                <SelfPagination
                    current={modalPagination.pageIndex} 
                    total={modalPagination.total} 
                    onSizeChange={paginationChange}
                />
                <div className="selectAll">
                    <Checkbox 
                        indeterminate={indeterminate} 
                        checked={checkedAll} 
                        onChange={onChangeCheckedAll}
                    >全选</Checkbox>
                </div>
            </Modal>
        </div>
    )
}

export default PolicyListModal;