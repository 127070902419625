import { Button, Typography, Table, Space, Modal, Form, Input, Select, Radio, TreeSelect, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import storage from '../../../../utils/storage'
import Api from "../../../../api";
import {ModalConfirm} from '../../../../utils/tool'
import SelfTable from "../../../../components/SelfTable/index"

import './index.scss'

const { confirm } = Modal;

const MenuMmanagement = ({tableHight}) => {
    const [addForm] = Form.useForm();
    const [data, setData] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [addDate, setAddDate] = useState([
        {
            title: '菜单类型',
            dataIndex: 'type',
            key: 'type',
            type: 'radio',
            option:[
                {
                    value: 0,
                    label: '目录',
                },
                {
                    value: 1,
                    label: '菜单',
                },
                {
                    value: 2,
                    label: '按钮',
                }
            ]
        },
        {
            title: '是否为系统管理',
            dataIndex: 'permsType',
            type: 'radio',
            option:[
                {
                    value: 1,
                    label: '是',
                },
                {
                    value: 0,
                    label: '否',
                }
            ]
        },
        {
            title: '是否隐藏',
            dataIndex: 'visible',
            type: 'radio',
            option:[
                {
                    value: 1,
                    label: '是',
                },
                {
                    value: 0,
                    label: '否',
                }
            ]
        },
        {
            title: "序号",
            dataIndex: "order",
        },
        {
            title: "名称",
            dataIndex: "name",
        },
        {
            title: "描述",
            dataIndex: "description",
            required: 'false'
        },
        {
            title: '路由路径',
            dataIndex: 'path',
            key: 'path',
            required: 'false'
        },
        {
            title: '路径',
            dataIndex: 'url',
            key: 'url',
            required: 'false'
        },
        {
            title: '图标地址',
            dataIndex: 'icon',
            key: 'icon',
            required: 'false'
        },
    ]);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [radioChecked, setRadioChecked] = useState('');

    const [editkey, setEditkey] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            width: '300px',
            align:"center",
        },
        {
            title: "序号",
            dataIndex: "order",
            key: 'order',
            width: '80px',
            align:"center",
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            width: '150px',
            align:"center",
        },
        {
            title: '权限类型',
            dataIndex: 'permsType',
            key: 'permsType',
            width: '90px',
            align:"center",
            render: (text) => {
                // 权限类型（1管理权限 2审核权限）
                return text == 0 ? '管理权限' : text == 1 ? '审核权限' : ""
            }
        },
        {
            title: '菜单类型',
            dataIndex: 'type',
            key: 'type',
            width: '90px',
            align:"center",
            render: (text) => {
                // 类型 0：目录 1：菜单 2：按钮
                return text == 0 ? '目录' : text == 1 ? '菜单' : text == 2 ? '按钮' : ""
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: '70px',
            align:"center",
            render: (text) => {
                // 0有效；1删除
                return text == 0 ? '有效' : text == 1 ? '删除' : ""
            }
        },
        {
            title: '权限标识',
            dataIndex: 'perms',
            key: 'perms',
            align:"center",
            width: '120px'
        },
        {
            title: '路由路径',
            dataIndex: 'path',
            key: 'path',
            width: '150px'
        },
        {
            title: '路径',
            dataIndex: 'url',
            key: 'url',
            width: '150px'
        },
        {
            title: '是否隐藏',
            dataIndex: 'visible',
            key: 'visible',
            width: '90px',
            align:"center",
            render: (text) => {
                return text == 0 ? '否' : text == 1 ? '是' : ""
            }
        },
        {
            title: '操作',
            width: '160px',
            align:"center",
            dataIndex: 'operation',
            render: (_, record) => {
                return <>
                    <Space>
                        <Typography.Link onClick={()=>{addMenu(record)}}>
                            新增
                        </Typography.Link>
                        <Typography.Link onClick={()=>{updataMenu(record)}}>
                            修改
                        </Typography.Link>
                        <Typography.Link type="danger" onClick={()=>{deleteMenu(record)}}>
                            删除
                        </Typography.Link>
                    </Space>

                </>
            },
        },
    ];

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        if(!isModalOpen){
            addForm.resetFields();
        }
    }, [isModalOpen]);

    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Menu.getAllMenuListTree();
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setData(_data);
        }else{
            setData([]);
        }
    }

    // 修改
    const updataMenu = (record) => {
        setIsModalOpen(true);
        setEditkey(record.id);
        setRadioChecked(record.type);
        record.parentid = record.parentid == 0 ? '' : record.parentid;
        addForm.setFieldsValue({
           ...record,
        });
    }

    // 删除
    const deleteMenu = (record) => {
        ModalConfirm({
            title: `您确认要删除这条数据吗?`,
            onOk:async(resolve, reject)=> {
                try{
                    const res = await Api.Menu.deleteMenu({data:{id:record.id}});
                    resolve();
                    if(res && res.code === 0){
                        getList();
                        message.success("删除成功")
                    }
                }catch{}
            },
        })
    }

    const addMenu = (record) => {
        if(record && record.id){
            addForm.setFieldsValue({
                type:1,
                permsType:1,
                visible:0,
                parentid:record.id,
            });
        }else{
            addForm.setFieldsValue({
                type:1,
                permsType:1,
                visible:0,
            });
        }
        setRadioChecked(1);
        setEditkey('');
        setIsModalOpen(true);
    }

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            if(row.parentid === editkey){
                message.info("父级菜单不能为自己");
                return false
            }
            row.url = row.url ? row.url.trim():"";
            setConfirmLoading(true);
            if(editkey){
                row.id = editkey
                const res = await Api.Menu.updateMenu({data:row});
                if(res&&res.code === 0){
                    message.success("修改菜单成功",2,()=>{
                        setConfirmLoading(false);
                        setIsModalOpen(false);
                        setEditkey('');
                        getList();
                    })
                }else{setConfirmLoading(false);}
            }else{
                const res = await Api.Menu.addMenu({data:row});
                if(res&&res.code === 0){
                    message.success("新增菜单成功",2,()=>{
                        setConfirmLoading(false);
                        setIsModalOpen(false);
                        setEditkey('');
                        getList();
                    })
                }else{setConfirmLoading(false);}
            }

        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    // 关闭新增
    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
        setEditkey('');
        setRadioChecked('');
    }

    // 菜单类型切换
    const radioChange = (e,item) => {
        if(item.dataIndex === 'type'){
            setRadioChecked(e.target.value);
        }
    }

    return (
        <div className="menuListFrame">
            <div className="title">菜单管理</div>
            <div className="operation">
                <Button type="primary" onClick={addMenu}>新增菜单</Button>
            </div>
            <div className="table">
                <SelfTable
                    dataSource={data}
                    columns={columns}
                    loading={tableLoading}
                    // footerHeight={54}
                    isShowSort={false}
                />
                {/* <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={data}
                    scroll={{
                        scrollToFirstRowOnChange:true,
                        y:tableHight
                    }}
                    loading={tableLoading}
                /> */}
            </div>

            <Modal
                title="新增菜单"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                // forceRender
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                >
                    <Form.Item
                        key={'parentid'}
                        label={'上级菜单'}
                        name={'parentid'}
                    >
                        <TreeSelect
                            allowClear
                            treeDefaultExpandAll
                            treeData={data}
                            fieldNames={{
                                label:"name",
                                value:"id"
                            }}
                        />
                    </Form.Item>

                    {addDate.map(item => {
                        return (
                            <Form.Item
                                key={item.dataIndex}
                                label={item.title}
                                name={item.dataIndex}
                                rules={[
                                    {
                                        required: item.required === 'false' ? false: true,
                                        message: (item.type === 'select'||item.type === 'radio') ? `请选择${item.title}!` : `请输入${item.title}!`
                                    },
                                    // {
                                    //     validator: async (_, names) => {
                                    //         if (_.field === 'order' && (!names || !Number.isInteger(parseInt(names)))) {
                                    //             return Promise.reject(new Error('序号请输入数字!'));
                                    //         }
                                    //     }
                                    // }
                                ]}
                            >
                                {item.type === 'radio' ?
                                <Radio.Group onChange={(e)=>radioChange(e,item)} options={item.option}></Radio.Group>
                                :item.type === 'select' ? <Select
                                    options={item.option}
                                /> :<Input
                                    type={item.dataIndex === 'order' ? 'number':'text'}
                                />}
                            </Form.Item>
                        )
                    })}
                    {radioChecked != 0 ?(<Form.Item
                        key={'perms'}
                        label={'标识'}
                        name={'perms'}
                        // rules={[{ required: true, message: `请输入标识!` }]}
                    >
                        <Input />
                    </Form.Item>):''}
                </Form>
            </Modal>
        </div>
    )
}
export default MenuMmanagement;
