import React, { useEffect, useState, useRef } from "react";
import {
  Spin,
  Empty,
  Modal,
  Input,
  Tag,
  Tooltip,
  message,
  Switch,
  Form,
} from "antd";
import {
  PlusOutlined,
} from "@ant-design/icons";


import KnowledgeFileIcon from "../../../../../components/KnowledgeFileIcon";
import SelfPagination from "../../../../../components/SelfPagination";
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import Ueditor from "../../../../../components/Ueditor/Ueditor";
import NoteEllipsis from "../../../components/NoteEllipsis";
import {jumpToPage, ModalConfirm} from "../../../../../utils/tool";
import Api from "../../../../../api";
import "./index.scss";


const { TextArea } = Input;


const noteOriginal = ({searchValue, type}) => {
  const lableInputRef = useRef(null);
  const [nodeForm] = Form.useForm();

  const [noteList, setNoteList] = useState([]);
  const [total, setTotal] = useState(null);
  const [siftData, setSiftData] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  // 控制笔记内容的展开
  const [isSpinning, setIsSpinning] = useState(true);
  // 获取笔记参数
  const [noteData, setNoteData] = useState({});

  const [confirmLoading, setConfirmLoading] = useState(false);
  //编辑器的宽度
  const [onLIneConfig, setOnLIneConfig] = useState({
    // readonly: true,
    initialFrameHeight: "100%",
    initialFrameWidth: "100%",
    toolbars: [["fullscreen", "source", "undo", "redo", "bold"]],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandKeyObject, setExpandKeyObject] = useState({});

  // 笔记标签
  const [lableList, setLableList] = useState([]);
  const [lableInputVisible, setLableInputVisible] = useState(false);
  const [lableInputValue, setLableInputValue] = useState('');

  useEffect(() => {
    getNoteOriginal();
  }, [siftData]);

  useEffect(() => {
    let _siftData = { ...siftData };
    _siftData.searchMessage = searchValue;
    _siftData.pageIndex = 1;
    setSiftData(_siftData);
  }, [searchValue]);

  // 获取原文管理数据
  const getNoteOriginal = async () => {
    setIsSpinning(true);
    try {
      let res = await Api.PersonalCenter.getKnowledgeNoteOwner({
        data: siftData,
      });
      if (res.data && res.code === 0) {
        let _data = res.data
        // 默认全部展开
        let expandKey = {};
        for (let item of _data.records) {
            expandKey[item.knowledgeId] = 1;
        }
        setExpandKeyObject(expandKey);

        setNoteList(_data.records);
        setTotal(_data.total);
        setIsSpinning(false);
      }
    } catch (error) {
      setIsSpinning(false);
    }
  };

  // 编辑弹框
  const showModal = ({ obj, knowledgeId }) => {
    setLableList(obj.noteDetailLabelList || []);
    nodeForm.setFieldsValue({
      "noteDetailMessage":obj.knowledgeNoteDetailMessage,
      "knowledgeNoteTitle":obj.knowledgeNoteDetailTitle,
      "noteDetailLabelList":obj.noteDetailLabelList ? obj.noteDetailLabelList.join(",") : ""
    });
    setNoteData({
      id: obj.knowledgeNoteDetailId,
      knowledgeId: knowledgeId,
    });
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      const row = await nodeForm.validateFields();

      if(lableList&&lableList.length<=0){
        message.info("请添加标签！")
        return false;
      }

      setConfirmLoading(true);
      let _noteData = { ...noteData,...row };
      _noteData.noteDetailLabelList = lableList;

      let res = await Api.Knowledge.saveKnowledgeNote({ data: _noteData });
      setIsModalOpen(false);
      setConfirmLoading(false);
      if (res.data) {
        message.success("修改成功");
        getNoteOriginal();
      } else {
        message.error("修改失败，请重试");
      }
    } catch (error) {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteNote = async (obj) => {
    ModalConfirm({
      title: "确认删除这条笔记吗？",
      onOk: async (resolve, reject) => {
        try {
          let res = await Api.Knowledge.deleteNoteDetailById({
            data: { id: obj.knowledgeNoteDetailId },
          });
          resolve();
          if (res.data) {
            getNoteOriginal();
            message.success("删除成功");
          }
        } catch (error) {}
      },
    });
  };

  const handeleIsOwner = async (e, knowledgeNoteDetailId, knowledgeId) => {
    let res = await Api.Knowledge.shareNote({
      data: {
        id: knowledgeNoteDetailId,
      },
    });
    if (res.data && res.code === 0) {
      message.success(res.msg);
      getNoteOriginal();
    }
  };

  const changePage = (page, pageSize) => {
    let _siftData = { ...siftData };
    _siftData.pageIndex = page;
    _siftData.pageSize = pageSize;
    setSiftData(_siftData);
  };


  const handleLableClose = (removedTag) => {
    const newTags = lableList.filter((tag) => tag !== removedTag);
    nodeForm.setFieldsValue({
      "noteDetailLabelList":newTags.join(","),
  });
    setLableList(newTags)
  };
  const showLableInput = () => {
      setLableInputVisible(true);
  };
  const handleLableInputChange = (e) => {
      setLableInputValue(e.target.value);
  };
  const handleLableInputConfirm = () => {
      if (lableInputValue && lableList.indexOf(lableInputValue) === -1) {
          setLableList([...lableList, lableInputValue]);
          nodeForm.setFieldsValue({
            "noteDetailLabelList":[...lableList, lableInputValue].join(","),
          });
      }
      setLableInputVisible(false);
      setLableInputValue('');
  };

  const goKnowlageDetail = async(item,obj) => {
    let res = await Api.Message.checkMessageStatus({
      data: {
          id:item.knowledgeId
      },
    });
    if(res.code === 0){
      // // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
      let _code = res.data;
      if(_code === -1){
          message.info("当前知识已被删除");
      }else if(_code === 1){
        sessionStorage.noteBelongMessage = obj.noteBelongMessage;
        jumpToPage({...item, id: undefined, type: ""}, `undefined&fileType=${item.fileType}&startIndex=${obj.startIndex}&endIndex=${obj.endIndex}`);
      }else{
          message.info("当前知识已取消发布")
      }
    }
  }

  return (
    <div className="noteOriginalFrame">
      <div className="originalManage">
        <Spin spinning={isSpinning} size="large">
          {noteList && noteList.length > 0 ? (
            <>
              {noteList.map((item) => {
                  let isExpand = expandKeyObject[item.knowledgeId];
                  return (
                    <div className="knowledge" key={item.knowledgeId}>
                      <div className="title" onClick={() => {
                              let expandKey = {...expandKeyObject,[item.knowledgeId]:1};
                              if (isExpand) {
                                  delete expandKey[item.knowledgeId]
                              }
                              setExpandKeyObject(expandKey)
                          }}>
                        <div style={{flex:1}}>
                          <KnowledgeFileIcon
                            fileType={item.fileType}
                          ></KnowledgeFileIcon>
                          <span>{item.knowledgeName}</span>
                        </div>
                        <span className={`expandSortBtn ${isExpand ? 'expandStatus' : ''}`}><Iconfont type={"icon-right"} /></span>
                      </div>

                      <div className="noteView" style={isExpand?{}:{maxHeight: 0}}>

                        {item.knowledgeNoteOwnerDetailVOList &&
                          item.knowledgeNoteOwnerDetailVOList.map((obj) => {

                            return (
                              <div
                                className="noteDetail"
                                key={obj.knowledgeNoteDetailId}
                              >
                                <div className="topTitle">
                                  <div className="noteName">
                                    <Iconfont type={'icon-dangdang'} iStyle={{flex:1}}/>
                                    {obj.knowledgeNoteDetailTitle}
                                  </div>
                                  {!type&&(<div className="noteOpr">
                                    <Iconfont type={'icon-bianji2'}
                                      onClick={(e) => {
                                        showModal({
                                          obj,
                                          knowledgeId: item.knowledgeId,
                                          type: "edit",
                                        })
                                      }}
                                    />
                                    <Iconfont type={'icon-shanchu1'}
                                      onClick={() => deleteNote(obj)}
                                    />
                                    <div className="switch">
                                      <Switch
                                        size="small"
                                        defaultChecked={obj.isOwner === 1}
                                        onChange={(e) =>
                                          handeleIsOwner(
                                            e,
                                            obj.knowledgeNoteDetailId,
                                            item.knowledgeId
                                          )
                                        }
                                      />
                                      <div className="switchText">
                                        {obj.isOwner === 1 ? "共享" : "关闭"}
                                      </div>
                                    </div>
                                  </div>)}
                                </div>

                                <div className="noteMessage"
                                  onClick={(e) =>
                                    goKnowlageDetail(item,obj)
                                  }
                                >
                                  <NoteEllipsis
                                    text={obj.knowledgeNoteDetailMessage}
                                    noteBelongMessage={obj.noteBelongMessage}
                                  ></NoteEllipsis>
                                </div>

                                <div className="labelList">
                                  {obj.noteDetailLabelList&&obj.noteDetailLabelList.map(item=>{
                                    return <span>{item}</span>
                                  })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              {/* 分页器 */}
              <div className="pager">
                {total / siftData.pageSize > 0 ? (
                  <SelfPagination
                    total={total * 1}
                    onSizeChange={changePage}
                    current={siftData.pageIndex}
                  ></SelfPagination>
                ) : null}
              </div>
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
        <Modal
          title="修改笔记"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          confirmLoading={confirmLoading}
        >
          <Form
            form={nodeForm}
            component={false}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            preserve={false}
          >
            <Form.Item
              key={'knowledgeNoteTitle'}
              label={'想法'}
              name={'knowledgeNoteTitle'}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 30) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("标题最多不超过30个字!")
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="单击键入标题（选填）"/>
            </Form.Item>
            <Form.Item
              label={'想法内容'}
              key={'noteDetailMessage'}
              name={'noteDetailMessage'}
              rules={[{ required:  true, message: `请输入想法内容!` }]}
            >
                <TextArea rows={10} placeholder="输入内容"/>
            </Form.Item>

            <Form.Item
                label={"标签"}
                key={"noteDetailLabelList"}
                name={'noteDetailLabelList'}
                className="noteLabelList"
                // required
                rules={[{ required:  true, message: `请添加标签!` }]}
            >
                <div>
                    {lableList.map((item,index)=>{
                        return <Tag closable={true}
                          key={index}
                          onClose={(e) => {
                              e.preventDefault();
                              handleLableClose(item);
                            }} key={item}>{item}</Tag>
                    })}
                    {lableInputVisible&& (
                        <Input
                            ref={lableInputRef}
                            type="text"
                            size="small"
                            style={{
                                width: 78,
                            }}
                            value={lableInputValue}
                            onChange={handleLableInputChange}
                            onBlur={handleLableInputConfirm}
                            onPressEnter={handleLableInputConfirm}
                        />
                    )}
                    {!lableInputVisible&& (
                        <Tag
                            onClick={showLableInput}
                            className="site-tag-plus"
                            style={{cursor: "pointer"}}
                        ><PlusOutlined /></Tag>
                    )}
                </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default noteOriginal;
