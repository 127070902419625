import React, {useState, useEffect, useRef} from  "react";
import PropTypes from 'prop-types';
import {Carousel} from "antd";

import "./index.scss";
import Iconfont from "../Iconfont/Iconfont";

const ViewImg = (props) => {
    const {viewImgList, isShowViewImg, closeViewImg, viewImgIdx} = props;

    const carouselRef = useRef();

    useEffect(() => {
        console.log(viewImgList,"viewImgListviewImgListviewImgList")
        console.log(viewImgIdx,"viewImgIdxviewImgIdx")
        carouselRef.current?.goTo(viewImgIdx);
    }, [viewImgIdx,viewImgList]);

    const previousFunc = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        carouselRef.current?.prev();
    }

    const nextFunc = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        carouselRef.current?.next();
    }

    return (
        <div className="viewImgBox" style={{display: isShowViewImg ? "" : "none"}}>
            <div className="viewImgContentBox">
                <span className="closeBtn" onClick={() => closeViewImg()}><Iconfont type="icon-close1" /></span>
                <div className="clickBtn previousBtn" onClick={() => previousFunc()}>
                    <Iconfont type="icon-right" />
                </div>
                <div className="viewImgCarouselBox">
                    <Carousel dots={false} ref={carouselRef}>
                        {
                            viewImgList.map(item => {
                                return (
                                    <div className="viewImgItemBox" key={item.id}>
                                        <img src={item.url} alt={item.name} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="clickBtn nextBtn" onClick={() => nextFunc()}>
                    <Iconfont type="icon-right" />
                </div>
            </div>
        </div>
    )
};

ViewImg.propTypes = {
    viewImgList: PropTypes.array.isRequired,
    isShowViewImg: PropTypes.bool,
    closeViewImg: PropTypes.func,
    viewImgIdx: PropTypes.number || PropTypes.string,
}

export default ViewImg;
